import axios from "axios";
import store from "../store";
import { toast } from "react-toastify";
import { logout, token } from "../reducers/ApplicationSlice";

const axiosClient = axios.create();
const { REACT_APP_USER_API } = process.env;

axiosClient.defaults.baseURL = REACT_APP_USER_API;

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
axiosClient.interceptors.response.use(
  function (response) {
    store.dispatch(token(response.headers["refresh-token"]));
    sessionStorage.setItem("token", response.headers["refresh-token"]);
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      //Add Logic to
      //1. Redirect to login page or
      //2. Request refresh token
      toast.dismiss("loading_data");
      store.dispatch(logout());
      sessionStorage.clear();
    }
    return Promise.reject(error);
  },
);

export function getUserApi(URL) {
  axiosClient.defaults.headers["Authorization"] = "Bearer " + store.getState().application.token;
  return axiosClient.get(`/${URL}`).then((response) => response);
}

export function postUserApi(URL, payload) {
  axiosClient.defaults.headers["Authorization"] = "Bearer " + store.getState().application.token;
  return axiosClient.post(`/${URL}`, payload).then((response) => response);
}

export function putUserApi(URL, payload) {
  axiosClient.defaults.headers["Authorization"] = "Bearer " + store.getState().application.token;
  return axiosClient.put(`/${URL}`, payload).then((response) => response);
}

export function deleteUserApi(URL) {
  axiosClient.defaults.headers["Authorization"] = "Bearer " + store.getState().application.token;
  return axiosClient.delete(`/${URL}`).then((response) => response);
}
