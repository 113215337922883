import TextInput from "../../components/Selectors/TextInput";

const UrlInput = ({ url, setNestedParameters }) => {
  const handleInputChange = (name, value) => {
    setNestedParameters("data_to_upload.api_parameters.url", value);
    try {
      const urlObj = new URL(value);
      const paramsObj = {};
      for (const [key, value] of urlObj.searchParams) {
        paramsObj[key] = {
          value: value,
          status: false,
          value_parameters: {},
        };
      }
      setNestedParameters("data_to_upload.api_parameters.parameters", paramsObj);
    } catch (error) {
      console.error("Invalid URL");
    }
  };

  return (
    <div>
      <TextInput value={url} onChange={handleInputChange} name={"url"} />
    </div>
  );
};

export default UrlInput;
