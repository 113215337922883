import SelectMulti from "../../../components/Selectors/SelectMulti";
import { variableFieldMulti } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { setModuleData } from "../../../reducers/indicatorSlice";
import IndustrialCurrent from "../IndicatorIndustrialHisto/IndustrialCurrent";

const CorrelationParam = () => {
  const dispatch = useDispatch();
  const module = useSelector((state) => state.indicator.moduleData);
  const onChange = (list) => {
    const target = [];
    list.forEach((opt) => target.push(opt.value));
    dispatch(setModuleData({ key: "target_variables", value: target }));
  };
  return (
    <div>
      <IndustrialCurrent time={true} />

      <label className="py-2 text-sm font-bold tracking-wide text-gray-700 dark:text-white ">Cible(s)</label>
      <SelectMulti onChange={onChange} options={variableFieldMulti(module["current"]["data_params"])} />
    </div>
  );
};
export default CorrelationParam;
