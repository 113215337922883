import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeOptions, renderEvent } from "./EventUtils";
import { getDataApi } from "../../axios/DataManagementApi";
import { set } from "../../reducers/dataSlice";

const EventCreator = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [event, setEvent] = useState(null);
  const moduleName = useSelector((state) => state.data.moduleName);
  const options = makeOptions(moduleName);
  const [eventRendered, setEventRendered] = useState();
  useEffect(() => {
    if (event !== null) setEventRendered(renderEvent(event.label));
  }, [event]);

  useEffect(() => {
    getDataApi("module?structure_id=" + sessionStorage.getItem("structure_id"))
      .then((res) => {
        console.debug(res);
        dispatch(
          set({
            type: "moduleName",
            data: res.data.data,
          }),
        );
      })
      .catch((error) => {
        console.debug(error);
      });
  }, [dispatch]);

  return (
    <div>
      <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
        <div className="flex gap-x-1.5">
          <IconButton bg={true} Icon={<Prev size={40} />} value={""} callback={() => history(-1)} />
          <h2 className="text-3xl font-semibold text-blue-900">Création d&apos;une action</h2>
        </div>
        <div className="mx-20 grid grid-cols-2 gap-2">
          <div>
            <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"action"}</label>
            <Select
              isSearchable={true}
              onChange={(option) => {
                setEvent(option);
              }}
              value={event}
              options={options}
            />
          </div>
        </div>
      </div>
      {eventRendered}
    </div>
  );
};
export default EventCreator;
