import { TableFilter } from "./TableFilter";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import DeleteModal from "../Selectors/DeleteModal";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { canRenderAction, canRenderComponent, makeSelectField } from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";

import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import store from "../../store";
import ActionButtons from "../Selectors/ActionButtons";
import { PrimaryButton, SecondaryButton } from "../Buttons/Button";
import { deleteUserApi, getUserApi } from "../../axios/UserManagementApi";
import { remove, set } from "../../reducers/dataSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const UsersList = () => {
  const { t } = useTranslation(["common", "button"]);

  const [userToDelete, setUserToDelete] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [canDelete] = useState(canRenderAction(ACTION_FLAGS.CANDELETE, COMPONENTS.USER_DISPLAY));
  const [canEdit] = useState(canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.USER_DISPLAY));
  const [authorizedUserCreation, setAuthorizedUserCreation] = useState(canRenderComponent(COMPONENTS.USER_CREATION));

  useEffect(
    () => () => {
      setAuthorizedUserCreation(canRenderComponent(COMPONENTS.USER_CREATION));
    },
    [authorizedComponent],
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [refresh, setRefresh] = useState(false);
  const structureList = useSelector((state) => state.data.structures);
  const data = useSelector((state) => state.data.users);
  const profileList = useSelector((state) => state.data.profiles);
  const profileOption = useCallback(() => makeSelectField(profileList, ["name"]), [profileList]);
  const history = useNavigate();
  console.debug(data);
  const createUser = () => history("/admin/users/create");
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("firstname", {
      id: "firstname",
      header: t("firstname"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("lastname", {
      id: "lastname",
      header: t("lastname"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: t("email"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "structure",
      header: t("structure"),
      cell: (info) => {
        const row = info.row.original;
        if (row.structure_id === "None" || row.structure_id === null) return "N/A";
        return structureList.find((structure) => structure.id === row.structure_id)?.name ?? "loading..";
      },
    }),
    columnHelper.display({
      id: "is_enable",
      header: t("state"),
      cell: (info) => {
        const row = info.row.original;
        return row.is_active ? <CheckIcon className={"h-5 w-5 text-blue-400 "} /> : <XMarkIcon className={"h-5 w-5 text-red-600 "} />;
      },
    }),
    columnHelper.display({
      id: "profile",
      header: t("profile"),
      cell: (info) => {
        const row = info.row.original;
        return profileOption().find((value) => value.value === row.id_role)?.label;
      },
    }),
    columnHelper.display({
      id: "actions",
      header: t("actions"),
      cell: (info) => (
        <ActionButtons
          handleEdit={() => {
            const row = info.row.original;
            history("/admin/users/edit/" + row.id_keycloak);
          }}
          handleDelete={() => {
            const row = info.row.original;
            setUserToDelete(row);
          }}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  console.debug(userToDelete);
  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
        <div className="mt-3 flex-row items-center gap-x-1 px-3 sm:flex lg:mt-0">
          <div className="flex-row items-center sm:flex lg:mt-0 ">
            <SecondaryButton
              isLoading={refresh}
              disabled={refresh}
              callback={() => {
                setRefresh(true);
                getUserApi("management")
                  .then((res) => {
                    store.dispatch(set({ type: "users", data: res.data["data"] }));
                    setRefresh(false);
                  })
                  .catch((error) => {
                    console.debug(error);
                    setRefresh(false);
                  });
              }}
              label={t("update", { ns: "button" })}
            />
          </div>
          <div className="flex-row items-center sm:flex lg:mt-0 ">
            {authorizedUserCreation && <PrimaryButton callback={createUser} label="Créer un utilisateur" />}
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
      {userToDelete && (
        <DeleteModal
          callback={() => {
            setIsDeleting(true);
            const structure_id = sessionStorage.getItem("structure_id");

            deleteUserApi("management?user_id=" + userToDelete.id_keycloak + "&structure_id=" + structure_id).then((res) => {
              console.debug(res);
              setIsDeleting(false);
              if (res.data.status === "success") {
                store.dispatch(remove({ type: "users", id: userToDelete.id_keycloak }));
                toast.success("L'utilisateur a été supprimé");
              }
              setUserToDelete(undefined);
            });
          }}
          loading={isDeleting}
          text={t("DELETE") + " " + userToDelete.firstname + " " + userToDelete.lastname + "?"}
          modalVisible={userToDelete}
          setModalVisible={setUserToDelete}
        />
      )}
    </div>
  );
};
export default UsersList;
