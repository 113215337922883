// React librairies
import { useState } from "react";
import Icon from "@mdi/react";
import * as ReactDOMServer from "react-dom/server";
import { Tooltip } from "react-tooltip";
// Icons
import { mdiEyeOff as Hide, mdiEye as View, mdiHelpCircleOutline as Help } from "@mdi/js";
import classNames from "classnames";

function PasswordInput({ disabled = false, label, name, onChange, placeholder = "Mot de passe...", width = "w-full", tooltip = null, value }) {
  //Use state
  const [viewPassword, setViewPassword] = useState(false);

  const stringValue = value ? value : "";

  const handlePasswordVisibility = () => {
    setViewPassword(!viewPassword);
  };

  const inputType = viewPassword ? "text" : "password";

  return (
    <>
      <label className={"text-sm font-bold tracking-wide text-gray-700"}>
        {label}
        {tooltip !== null && (
          <span className="ml-3" data-tooltip-html={ReactDOMServer.renderToStaticMarkup(tooltip)} id={"password"} data-tooltip-id={"passwordTooltip"}>
            <Icon path={Help} size={0.9} className="text-lg dark:text-white" />
          </span>
        )}
      </label>
      <div className="flex">
        <div className="z-10 flex w-10 items-center justify-center pl-1 text-center" onClick={handlePasswordVisibility}>
          {viewPassword ? <Icon path={Hide} size={1} /> : <Icon path={View} size={1} />}
        </div>
        <input
          type={inputType}
          disabled={disabled}
          className={classNames(
            width,
            "-ml-10 block rounded-lg border border-neutral-300 bg-neutral-50  pl-10 text-sm text-neutral-900 invalid:border-pink-500 invalid:text-pink-600 focus:border-blue-500 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-75 ",
          )}
          name={name}
          autoComplete={"off"}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          value={stringValue}
        />
      </div>
      <Tooltip id="passwordTooltip" place="top" className={"z-20"} />
    </>
  );
}

export default PasswordInput;
