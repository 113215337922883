import uniqueId from "lodash.uniqueid";
import { verifyInput } from "../../utils/verifyInput";
import Modal from "./Modal";
import TextInput from "../Selectors/TextInput";
import Select from "react-select";
import { DeleteButton, PrimaryButton } from "../Buttons/Button";
import Icon from "@mdi/react";
import { mdiPlus, mdiTableRowPlusAfter, mdiTableRowRemove } from "@mdi/js";
import { useTranslation } from "react-i18next";
import NumericInput from "../Inputs/NumericInput";
import { useState } from "react";
import { isEmptyString } from "../../utils/common";
import { toast } from "react-toastify";

const MappingValueModal = ({ callback, label, openModal, mappingValue, updateMappingValue }) => {
  const { t } = useTranslation(["common"]);
  const initRow = () => {
    return {
      id: uniqueId(),
      locked: false,
      name: "",
      type: "",
      visualization_format: {},
      mapping_information: {},
    };
  };
  const addRow = () => {
    updateMappingValue([...mappingValue, initRow()]);
  };
  const removeRow = (id) => {
    updateMappingValue(mappingValue.filter((val) => val.id !== id));
  };
  const handleInputModal = (id, value) => {
    if (value.includes("-")) return;
    if (value.includes(" ")) return;
    const newValue = mappingValue.find((elem) => elem.id === id);
    const arrayIndex = mappingValue.findIndex((elem) => elem.id === id);
    const tmpArray = [...mappingValue];
    newValue["name"] = value.toLowerCase();
    tmpArray[arrayIndex] = newValue;
    updateMappingValue(tmpArray);
  };
  const handleTextInput = (id, value) => {
    const newValue = mappingValue.find((elem) => elem.id === id);
    const arrayIndex = mappingValue.findIndex((elem) => elem.id === id);
    const tmpArray = [...mappingValue];
    newValue["mapping_information"] = value;
    tmpArray[arrayIndex] = newValue;
    updateMappingValue(tmpArray);
  };
  const onVisualizationChange = (id, name, value) => {
    const newValue = mappingValue.find((elem) => elem.id === id);
    const arrayIndex = mappingValue.findIndex((elem) => elem.id === id);
    const tmpArray = [...mappingValue];
    newValue["visualization_format"][name] = value;
    tmpArray[arrayIndex] = newValue;
    updateMappingValue(tmpArray);
  };
  const onTypeChange = (id, value) => {
    const tmpArray = [...mappingValue];
    const newValue = mappingValue.find((elem) => elem.id === id);
    const arrayIndex = mappingValue.findIndex((elem) => elem.id === id);
    newValue["type"] = value;
    switch (newValue.type) {
      case "string":
        newValue["mapping_information"] = 100;
        newValue["visualization_format"] = {
          description: "",
          visualization_type: "text",
        };
        break;
      case "url":
        newValue["mapping_information"] = 100;
        newValue["visualization_format"] = {
          description: "",
          visualization_type: "url",
        };
        break;
      case "int":
      case "float":
        newValue["mapping_information"] = { min: 0, max: 100, min_included: true, max_included: true };
        newValue["visualization_format"] = {
          description: "",
          visualization_type: "dynamic_numerical",
        };
        break;
      case "date":
        newValue["mapping_information"] = {};
        newValue["visualization_format"] = {
          description: "",
          visualization_type: "date",
        };
        break;
      case "ordered":
        newValue["mapping_information"] = {};
        newValue["visualization_format"] = {
          description: "",
          visualization_type: "ordered_categorical",
          label: [],
        };
        break;
      case "nominal":
        newValue["mapping_information"] = {};
        newValue["visualization_format"] = {
          description: "",
          visualization_type: "nominal_categorical",
          label: [],
        };
        break;
      default:
        break;
    }
    tmpArray[arrayIndex] = newValue;
    updateMappingValue(tmpArray);
  };
  const mappingInformationType = (row) => {
    switch (row.type) {
      case "float":
      case "int":
        return (
          <div className=" grid grid-cols-2 gap-1">
            <NumericInput
              label={t("min")}
              value={row.mapping_information.min}
              name={row.id}
              onChange={(name, value) =>
                handleTextInput(name, {
                  min: value,
                  max: row.mapping_information.max,
                  min_included: true,
                  max_included: true,
                })
              }
            />
            <NumericInput
              label={t("max")}
              value={row.mapping_information.max}
              name={row.id}
              onChange={(name, value) =>
                handleTextInput(name, {
                  min: row.mapping_information.min,
                  max: value,
                  min_included: true,
                  max_included: true,
                })
              }
            />
          </div>
        );
      case "string":
      case "url":
        return <NumericInput label={t("size")} onChange={handleTextInput} value={row.mapping_information} name={row.id} />;
      default: //date, categorical
        return <p className={"pt-6 text-xl"}>-</p>;
    }
  };
  const visualizationFormat = (row) => {
    const numericOptions = [
      { value: "dynamic_numerical", label: t("dynamic") },
      { value: "semi_static_numerical", label: t("static") },
    ];
    switch (row.type) {
      case "int":
      case "float":
        return (
          <div className=" grid grid-cols-2 gap-1">
            <TextInput
              label={t("description")}
              onChange={(name, value) => onVisualizationChange(name, "description", value)}
              value={row.visualization_format.description}
              name={row.id}
            />
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">{t("type")}</label>
              <Select
                isSearchable={false}
                isClearable={false}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => {
                    return { ...base, zIndex: 9999 };
                  },
                }}
                onChange={(newValue) => onVisualizationChange(row.id, "visualization_type", newValue.value)}
                options={numericOptions}
                value={numericOptions.find((opt) => opt.value === row.visualization_format.visualization_type)}
              />
            </div>
          </div>
        );
      case "date":
      case "string":
      case "url":
        return (
          <TextInput
            label={t("description")}
            onChange={(name, value) => onVisualizationChange(name, "description", value)}
            value={row.visualization_format.description}
            name={row.id}
          />
        );
      case "ordered":
      case "nominal":
        return (
          <div className=" grid grid-cols-4 gap-1">
            <TextInput
              label={t("description")}
              onChange={(name, value) => onVisualizationChange(name, "description", value)}
              value={row.visualization_format.description}
              name={row.id}
            />
            <div className={"col-span-3"}>
              <LabelEditor onVisualizationChange={onVisualizationChange} row={row} />
            </div>
          </div>
        );
      default:
        return <p className={"pt-6 text-xl"}>-</p>;
    }
  };
  const mappingTypeField = [
    {
      label: t("numeric"),
      options: [
        { value: "int", label: t("int") },
        { value: "float", label: t("float") },
      ],
    },
    {
      label: t("categorical"),
      options: [
        { value: "nominal", label: t("nominal") },
        { value: "ordered", label: t("ordered") },
      ],
    },
    {
      label: t("date"),
      options: [{ value: "date", label: t("date") }],
    },
    {
      label: t("text"),
      options: [
        { value: "string", label: t("string") },
        { value: "url", label: t("url") },
      ],
    },
  ];
  const columns = mappingValue.map((row, index) => (
    <div key={index} className={"grid grid-cols-9 gap-x-4 py-0.5 odd:bg-gray-100 even:bg-gray-50"}>
      <div className={"place-self-center py-6"}>{<TextInput value={row["name"]} name={row.id} onChange={handleInputModal} />}</div>
      <div className={"w-full place-self-center py-6"}>
        <Select
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => {
              return { ...base, zIndex: 9999 };
            },
          }}
          getOptionValue={(option) => option.value} // This line is important
          value={mappingTypeField.reduce((selected, option) => {
            const found = option.options.find((opt) => opt.value === row.type);
            return found ? found : selected;
          }, null)}
          menuPosition={"absolute"}
          onChange={(option) => onTypeChange(row.id, option.value)}
          options={mappingTypeField}
          id={row.id}
        />
      </div>
      <div className={"col-span-2 place-self-center pb-6"}>{mappingInformationType(row)}</div>
      <div className={"col-span-4"}>{visualizationFormat(row)}</div>
      <div className={"place-self-center"}>
        <div className={"py-6"}>
          <DeleteButton callback={() => removeRow(row.id)} label={<Icon path={mdiTableRowRemove} size={1} />} />
        </div>
      </div>
    </div>
  ));

  const styleOption = (option) => (
    <div className="flex gap-0.5">
      <p className="font-bold">Nom:</p>
      <p>{option.name}</p>
      <p className="font-bold">Type:</p>
      <p>{option.type}</p>
    </div>
  );

  const handleLock = (tmpArray, key) => {
    const newValue = tmpArray[key];
    newValue["locked"] = verifyInput(newValue);
  };

  const Header = () => (
    <div>
      <div className={"grid grid-cols-9 gap-x-0.5 rounded-t bg-blue-900"}>
        <div className={"group place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>{t("name")}</div>
        <div className={"group place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>{t("type")}</div>
        <div className={"group col-span-2 place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>
          {t("mapping_information")}
        </div>
        <div className={"group col-span-4 place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>
          {t("visualization_format")}
        </div>
        <div className={"group place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>
          <PrimaryButton callback={addRow} label={<Icon path={mdiTableRowPlusAfter} size={1} />} />{" "}
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      name={"mappingValue"}
      columns={columns}
      lockValues={handleLock}
      callback={callback}
      values={mappingValue}
      header={<Header />}
      updateValues={updateMappingValue}
      label={label}
      openModal={openModal}
      optionsLabel={mappingValue
        .filter((value) => value.locked)
        .map((value) => {
          return {
            id: value.id,
            value: value.name,
            label: styleOption(value),
          };
        })}
    />
  );
};
export default MappingValueModal;

const LabelEditor = ({ row, onVisualizationChange }) => {
  const { t } = useTranslation(["common"]);
  const [label, setLabel] = useState("");

  const [description, setDescription] = useState("");
  const addRow = () => {
    const updates = structuredClone(row.visualization_format.label);
    if (isEmptyString(label)) {
      toast.warn(t("EMPTY_LABEL"));
      return;
    }
    updates.push({ label: label, description: description });
    onVisualizationChange(row.id, "label", updates);
    setDescription("");
    setLabel("");
  };
  const removeRow = (label) => {
    const updates = structuredClone(row.visualization_format.label).filter((value) => value.label !== label);
    onVisualizationChange(row.id, "label", updates);
  };
  const makeOptions = (label) => {
    return { value: label.label, label: label.label };
  };
  const options = row.visualization_format.label.map((label) => makeOptions(label));
  return (
    <div>
      <label className="text-sm font-bold tracking-wide text-gray-700">{t("label")}</label>
      <div>
        <div className={"flex gap-x-0.5"}>
          <div className={"grid grid-cols-2 gap-x-0.5"}>
            <TextInput placeholder={t("value")} value={label} onChange={(name, value) => setLabel(value)} />
            <TextInput placeholder={t("description")} value={description} onChange={(name, value) => setDescription(value)} />
          </div>
          <PrimaryButton label={<Icon path={mdiPlus} size={1} />} callback={() => addRow()} />
        </div>
        <Select
          placeholder={"-"}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          menuIsOpen={false}
          options={options}
          value={options}
          isMulti={true}
          isSearchable={false}
          isClearable={false}
          onChange={(newValue, actionMeta) => removeRow(actionMeta.removedValue.label)}
        />
      </div>
    </div>
  );
};
