import { flexRender } from "@tanstack/react-table";
import { Tooltip } from "react-tooltip";
import { useId } from "react";

export function TableBodyHoverable({ table, callback }) {
  const id = useId();
  return (
    <tbody className="max-h-96 overflow-hidden bg-white">
      {table.getRowModel().rows.map((row) => (
        <tr id={id + row.id} className={"cursor-pointer odd:bg-gray-50"} key={row.id}>
          {row.getVisibleCells().map((cell) => {
            console.debug(id + row.id);
            return (
              <td key={cell.id} className={"overflow-hidden border p-2 text-justify dark:bg-gray-900"}>
                <Tooltip className=" z-40 bg-black p-0 opacity-100" id={id + row.id}>
                  {callback(row)}
                </Tooltip>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
}
