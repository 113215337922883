import { useState } from "react";
import { getThreshold } from "../utils";

import { useDispatch, useSelector } from "react-redux";
import { setIndicatorParams } from "../../../reducers/indicatorSlice";
import Select from "react-select";
import { formatThreshold } from "../../../network/DataManagementApi";
import SwitchButton from "../../../components/Selectors/SwitchButton";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { RefreshButton } from "../../../components/Buttons/Button";
import { getDataApi } from "../../../axios/DataManagementApi";
import { dataLoadingToast } from "../../../components/Toasts/DataLoadingToast";

const IndustrialParam = () => {
  const dispatch = useDispatch();
  const module = useSelector((state) => state.indicator.moduleData);
  const obs = sessionStorage.getItem("observed_system_id");
  const [threshold, setThreshold] = useState(getThreshold(obs));
  const [selectValue, setSelectValue] = useState([]);
  const [selectedField, setSelectedField] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();
  const setLabel = (value, option) => {
    console.debug(value, option);
    if (option.action === "clear") value = "";
    dispatch(setIndicatorParams({ key: "threshold_label", value: value.value }));
    dispatch(setIndicatorParams({ key: "state_i", value: [] }));
    setSelectValue([]);
    setSelectedField(value);
  };
  const setField = () => {
    const field = [];
    threshold.forEach((value) => {
      field.push({ label: value.label, value: value.label });
    });
    return field;
  };
  const setMultiField = () => {
    const options = [];
    const index = threshold.findIndex((value) => value.label === module.indicator_params.threshold_label);
    const formula = threshold[index].formula;
    Object.keys(formula).forEach((value) => options.push({ value: value, label: value + " : " + formula[value] }));
    return options;
  };
  const setStates = (options) => {
    setSelectValue(options);
    const state_i = [];
    options.forEach((item) => {
      state_i.push(item.value);
    });
    dispatch(setIndicatorParams({ key: "state_i", value: state_i }));
  };
  return (
    <div>
      <SwitchButton
        label={"Indicateur de temps"}
        value={module.indicator_params.time_indicators}
        onChange={(e) => dispatch(setIndicatorParams({ key: "time_indicators", value: e }))}
      />

      <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white ">Seuil à considérer</label>
      <div className={"flex gap-x-1.5"}>
        <CreatableSelect
          className={"min-w-[18rem]"}
          menuPosition={"fixed"}
          onCreateOption={() => history("/analytics/threshold/create")}
          options={setField()}
          value={selectedField}
          onChange={setLabel}
        />
        <RefreshButton
          disabled={isLoading}
          isLoading={isLoading}
          callback={() => {
            setIsLoading(true);
            dataLoadingToast({ isLoading: true });
            getDataApi("threshold?structure_id=" + sessionStorage.getItem("structure_id"))
              .then((res) => {
                dataLoadingToast({ success: true });
                formatThreshold(res.data.data);
                setThreshold(getThreshold(obs));
                setIsLoading(false);
              })
              .catch((error) => {
                setIsLoading(false);
                dataLoadingToast({ error: true });
                console.debug(error);
              });
          }}
        />
      </div>

      {module.indicator_params.threshold_label !== "" ? (
        <div>
          <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white ">Etat à considérer</label>
          <Select isMulti value={selectValue} onChange={setStates} options={setMultiField()} />{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default IndustrialParam;
