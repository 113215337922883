import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { dataList, makeSelectField } from "../../utils/common";
import { RefreshButton } from "../../components/Buttons/Button";
import { toast } from "react-toastify";
import { getDataApi } from "../../axios/DataManagementApi";
import { set } from "../../reducers/dataSlice";

export const IndicatorEvent = ({ selectedEvent, setEvent, keyName }) => {
  const [updateEvent, setUpdateEvent] = useState(false);
  const { event } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const history = useNavigate();
  return (
    <Fragment>
      <h1 className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white ">Action à déclencher</h1>

      <div className={"flex gap-x-1.5"}>
        <CreatableSelect
          placeholder={"Selectionner une action..."}
          className={"min-w-[18rem]"}
          value={selectedEvent === undefined ? undefined : makeSelectField(event, ["id", "module_name"]).find((value) => value.id === selectedEvent)}
          menuPosition={"fixed"}
          options={makeSelectField(event, ["id", "module_name"])}
          onCreateOption={() => history("/analytics/event/create")}
          onChange={(newValue) => dispatch(setEvent({ key: keyName, value: newValue.value }))}
        />
        <RefreshButton
          isLoading={updateEvent}
          disabled={updateEvent}
          callback={() => {
            setUpdateEvent(true);
            toast.dismiss("loading-toast");
            toast.info("Mise à jour des actions", {
              toastId: "loading-toast",
              containerId: "loadingToast",
              isLoading: true,
            });

            getDataApi("event/" + sessionStorage.getItem("structure_id"))
              .then((res) => {
                setUpdateEvent(false);

                toast.update("loading-toast", {
                  render: "Chargement terminé",
                  type: toast.TYPE.SUCCESS,
                  hideProgressBar: false,
                  autoClose: 5000,
                  isLoading: false,
                });
                dispatch(
                  set({
                    type: "event",
                    data: dataList(res.data.data),
                  }),
                );
              })
              .catch((error) => {
                setUpdateEvent(false);
                console.debug(error);
                toast.update("loading-toast", {
                  render: <p className={"truncate"}> {error?.response?.data?.message}</p>,
                  type: toast.TYPE.ERROR,
                  hideProgressBar: false,
                  autoClose: 5000,
                  isLoading: false,
                });
              });
          }}
        />
      </div>
    </Fragment>
  );
};
