import CreatableSelect from "react-select/creatable";
import { DisplayType } from "./DisplayType";

export const DisplayValue = ({ operator, value, setValue, type }) => {
  if (value === null) return;
  if (operator === "list")
    return (
      <div>
        <label className="text-sm font-bold tracking-wide text-gray-700">Egal</label>
        <CreatableSelect
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(newValue, actionMeta) => {
            if (actionMeta.action === "clear") setValue({ in: [] });
            if (actionMeta.action === "remove-value") {
              const newValue = value["in"].filter((val) => val !== actionMeta.removedValue.value);
              setValue({ in: newValue });
            }
          }}
          placeholder={"Ajouter des valeurs..."}
          isMulti
          options={value["in"].map((val) => {
            return { value: val, label: val };
          })}
          value={value["in"].map((val) => {
            return { value: val, label: val };
          })}
          onCreateOption={(inputValue) => {
            const newValue = [...value["in"]];

            if (type === "int") {
              const toAdd = parseInt(inputValue);
              if (isNaN(toAdd)) return;
              newValue.push(toAdd);
            } else newValue.push(inputValue);
            setValue({ in: newValue });
          }}
        />
      </div>
    );
  if (operator === "gt") return <DisplayType type={type} label={"min (inclus)"} value={value[">="]} setValue={(val) => setValue({ ">=": val })} />;
  if (operator === "lt") return <DisplayType type={type} label={"max (inclus)"} value={value["<="]} setValue={(val) => setValue({ "<=": val })} />;
  if (operator === "range")
    return (
      <div className={"grid grid-cols-2"}>
        <DisplayType type={type} label={"min (inclus)"} value={value[">="]} setValue={(val) => setValue({ ">=": val, "<=": value["<="] })} />
        <DisplayType type={type} label={"max (inclus)"} value={value["<="]} setValue={(val) => setValue({ "<=": val, ">=": value[">="] })} />
      </div>
    );
};
