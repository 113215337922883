import DetectionAnomalieRightPanel from "./DetectionAnomalieRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const DetectionAnomalie = () => (
  <div className="mx-4 grid gap-4 py-2 lg:grid-cols-2">
    <IndicatorDataConfiguration isMethod={true} isTwoBloc={true} />
    <DetectionAnomalieRightPanel />
  </div>
);
export default DetectionAnomalie;
