import ObservedSystemSelect from "../Select/ObservedSystemSelect";

const DashBoardHeader = ({ instanceDisplay, setInstanceDisplay }) => (
  <nav className="bg-white">
    <div className="flex w-full justify-between gap-x-4 px-8 py-2">
      <ObservedSystemSelect instanceDisplay={instanceDisplay} setInstanceDisplay={setInstanceDisplay} />
    </div>
  </nav>
);
export default DashBoardHeader;
