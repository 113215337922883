import BasicTooltip from "../Tooltip/BasicTooltip";
import { PrimaryButton } from "../Buttons/Button";

/**
 * @param isLoading {boolean}
 * @param information {string}
 * @param description {string}
 * @param disabled {boolean}
 * @param field  {Object} id,name,type,tooltip
 * @param callback {function}
 * @param isMulti {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
const DataForm = ({ isLoading, information, description, disabled, field, callback, isMulti = false }) => (
  <div>
    <div className="m-8 block rounded border border-gray-200 bg-white px-4">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-2xl font-semibold">{information}</h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{description}</p>
      </div>
      <div className=" border-t border-gray-200">
        <div>
          {field.map((item, index) => (
            <div key={index} className="p-4 odd:bg-gray-50 hover:bg-gray-100">
              <div className=" flex items-center gap-2">
                <label className={"text-sm  font-bold tracking-wide text-gray-700"}> {item.name}</label>
                {item.tooltip !== false && <BasicTooltip tip={item.tooltip} />}
              </div>
              {item.type}
            </div>
          ))}
        </div>
        {!isMulti && (
          <div className="flex justify-center py-2">
            <PrimaryButton isLoading={isLoading} callback={callback} label={"Ajouter"} disabled={disabled} />
          </div>
        )}
      </div>
    </div>
  </div>
);

export default DataForm;
