import OutlierValidationRightPanel from "./OutlierValidationRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const OutlierValidation = () => (
  <div className="mx-4 grid gap-4 py-2 lg:grid-cols-2">
    <IndicatorDataConfiguration isVariable={false} />
    <OutlierValidationRightPanel />
  </div>
);
export default OutlierValidation;
