import { useLoaderData, useParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import { StateTimeLine } from "./StateTimeLine";
import { isEmptyObject, isEmptyString } from "../../utils/common";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import { getDataApi } from "../../axios/DataManagementApi";
import { useSelector } from "react-redux";
import { ActiveDisplay } from "./ActiveDisplay";
import { PrevButton } from "../../components/Buttons/Button";

const ObservedSystemStatus = () => {
  const { id } = useParams();
  const dataloaded = useLoaderData();
  const element = useSelector((state) => state.data.observed_system).find((obs) => obs.id === id);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [panelUrl, setPanelUrl] = useState(dataloaded.panel_url);
  const [pieChartUrl, setPieChartUrl] = useState(dataloaded.piechart_url);
  const [lastActive, setLastActive] = useState({});
  useEffect(() => {
    getDataApi("status/" + id + "?active=true").then((res) => {
      setLastActive(res.data.data);
      setEndTime(dayjs(res.data.data.timestamp).format("YYYY-MM-DDTHH:mm:ss"));
      setStartTime(dayjs(res.data.data.timestamp).subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"));
    });
  }, [id]);
  console.debug(lastActive);
  const handleTime = (start_time, end_time) => {
    dataLoadingToast({ isLoading: true, label: "chargement de l'historique" });
    const args = "?active=true&histo=true" + "&start_time=" + start_time + "&end_time=" + end_time;
    const url = "status/" + id + args;
    getDataApi(url.replaceAll("+", "%2B")).then((res) => {
      let msg = "chargement terminé";
      if (isEmptyObject(res.data.data)) {
        setPanelUrl([]);
        setPieChartUrl("");
        msg = "Pas de données entre ces dates";
      } else {
        setPieChartUrl(res.data.data.piechart_url);
        setPanelUrl(res.data.data.panel_url);
      }
      dataLoadingToast({ success: true, successMessage: msg });
    });
  };
  return (
    <Fragment>
      <div className={"mx-8 mt-8 rounded-lg border border-gray-200 bg-white px-8 py-4 shadow"}>
        <div className="flex justify-between gap-x-1.5">
          <div className={"flex gap-x-1.5"}>
            <div className={"h-fit"}>
              <PrevButton url={"/"} />
            </div>
            <h2 className="text-3xl font-semibold text-blue-900">{element.name}</h2>
          </div>
          <ActiveDisplay active={lastActive} />
        </div>
      </div>
      <div className={"m-4 grid grid-cols-2 gap-4"}>
        <div className="m-4  block h-[50vh]  rounded-lg border border-gray-200 bg-white shadow">
          {!isEmptyString(pieChartUrl) && (
            <div className="m-8 block rounded">
              <iframe className="h-[40vh] w-full " src={pieChartUrl} />
            </div>
          )}
        </div>
        <StateTimeLine
          startTime={startTime}
          endTime={endTime}
          panelUrl={panelUrl}
          callback={(start, end) => {
            setStartTime(start);
            setEndTime(end);
            handleTime(start, end);
          }}
        />
      </div>
    </Fragment>
  );
};
export default ObservedSystemStatus;
