import TabGroup from "../../components/Tab/TabGroup";
import { useState } from "react";
import { PredictionModelList } from "./PredictionModelList";
import { TrainingModelList } from "./TrainingModelList";

const ModeleList = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const panel = [
    {
      id: 1,
      component: <TrainingModelList />,
    },
    {
      id: 2,
      component: <PredictionModelList />,
    },
  ];

  return (
    <div>
      <div className="m-8">
        <TabGroup headers={["Entrainement", "Prediction"]} current={selectedIndex} changeCurrent={setSelectedIndex} panel={panel} />
      </div>
    </div>
  );
};
export default ModeleList;
