import TimezoneSelect from "react-timezone-select";
import TimeInput from "../Inputs/TimeInput";

const DateTimezone = ({ handleTextInput, field }) => {
  console.debug(field);
  const onTimezoneChange = (id, value) => {
    handleTextInput(
      field,
      {
        date: field.value.date,
        name: value.value,
      },
      "value",
    );
  };
  return (
    <div className="grid grid-cols-3 gap-x-0.5  ">
      <div className={"col-span-2"}>
        <TimeInput
          name={field.id}
          value={field.value.date}
          onChange={(id, value) => {
            handleTextInput(
              field,
              {
                date: value,
                name: field.value.name,
              },
              "value",
            );
          }}
        />
      </div>
      <TimezoneSelect displayValue={"UTC"} value={field.value.name} onChange={(e) => onTimezoneChange(field.id, e)} id={field.id} name={"timezone"} />
    </div>
  );
};
export default DateTimezone;
