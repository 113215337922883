import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";
import { useTranslation } from "react-i18next";

const LoadingPage = () => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-white text-blue-900">
      <div className={"animate-spin"}>
        <Icon path={mdiLoading} className={"h-32 w-32"} />
      </div>
      <div className="text-4xl font-bold">{t("dashboard_loading")}</div>
    </div>
  );
};

export default LoadingPage;
