import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { canRenderAction, getUserPreferences } from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableFilter } from "./TableFilter";
import { useTranslation } from "react-i18next";
import Table from "./Table";
import { mdiPencil } from "@mdi/js";
import DropdownMenu from "../Selectors/DropDownButton";

const StorageSystemList = () => {
  const { t } = useTranslation(["common"]);

  const [canEdit] = useState(canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.INSTANCE_DISPLAY));

  const [globalFilter, setGlobalFilter] = useState("");
  const storageSystemList = useSelector((state) => state.data.storage_system);
  const [data, setData] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    setData(storageSystemList);
  }, [storageSystemList]);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: t("name"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("description", {
      id: "desc",
      header: t("description"),
      cell: (info) => <p className={"w-full whitespace-normal"}>{info.getValue()}</p>,
    }),
    columnHelper.accessor("storage_type", {
      id: "storage_type",
      header: t("type"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions_button",
      cell: (info) => {
        const row = info.row.original;
        const options = [];
        if (canEdit)
          options.push({
            id: 0,
            label: "Modifier les caractéristiques",
            onClick: () => history("/storage_system/edit/" + row.id),
            path: mdiPencil,
          });
        return <DropdownMenu options={options} />;
      },
    }),
  ];
  const showId = getUserPreferences().find((preference) => preference.name === "showId")?.value ?? true;

  if (!showId) columns.splice(0, 1);
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
      </div>
      <Table table={table} />
    </div>
  );
};
export default StorageSystemList;
