import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Select from "react-select";
import { capitalizeFirstLetter, isEmptyObject, isEmptyString } from "../../utils/common";
import HorizontalLine from "../../components/HorizontalLine";
import TextInput from "../../components/Selectors/TextInput";
import { PrimaryButton } from "../../components/Buttons/Button";
import { generateFitlers, generatePayload, storageTypeOptions } from "./databaseFeedback.utils";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import axios from "axios";
import FieldsToUpload from "./FieldsToUpload";
import { PostgreSQLParam } from "./PostgreSQLParam";
import { MongoDBParam } from "./MongoDBParam";
import { ElasticSearchParam } from "./ElasticSearchParam";
import RequestParametersFiltersEditor from "./RequestParametersFiltersEditor";
import DatabaseFeedbackTaskModal from "./DatabaseFeedbackTaskModal";
import RequestParametersDynamicFiltersEditor from "./RequestParametersDynamicFiltersEditor";
const DatabaseFeedback = () => {
  const { id } = useParams();
  const { configured_source } = useSelector((state) => state.data);
  const { t } = useTranslation(["common", "button"]);
  const [storageType, setStorageType] = useState("postgresql");
  const [name, setName] = useState("");
  const [response, setResponse] = useState([]);
  const [responseWithType, setResponseWithType] = useState({});

  const [selectedFields, setSelectedFields] = useState([]);
  const [databaseTestLoading, setDatabaseTestLoading] = useState(false);

  const [crudeSourceId, setCrudeSourceId] = useState();
  const [filters, setFilters] = useState({});
  const [dynamicFilters, setDynamicFilters] = useState({});
  useEffect(() => {
    setCrudeSourceId(configured_source.find((configuration) => configuration.id === id).crude_source);
  }, [configured_source, id]);

  const [credentials, setCredentials] = useState({
    password: "",
    port: "",
    host: "",
    name: "",
    user: "",
    schema: "",
  });
  const [fieldsToUpload, setFieldsToUpload] = useState({});
  const handleStorageTypeChange = (newValue) => {
    setStorageType(newValue.value);
    setName("");
    setFilters({});
    if (newValue.value === "elasticsearch")
      setCredentials({
        url: "https://elasticsearch.prototype3.research.preditic.com",
        api_key: "aGYzVXZZUUJ6ckJwZFM3anZRLUg6dDJHMVBRMk9UenE3N3ItYUJ0SlhMQQ==",
        user: "local_test",
        pw: "to_delete",
      });
    if (newValue.value === "postgresql")
      setCredentials({
        host: "",
        port: "",
        name: "",
        user: "",
        password: "",
        schema: "public",
      });
    if (newValue.value === "mongodb")
      setCredentials({
        host: "",
        port: "",
        name: "",
        user: "",
        password: "",
      });
  };
  const connectToDatabase = () => {
    dataLoadingToast({ isLoading: true });
    setDatabaseTestLoading(true);
    const { REACT_APP_DATA_API } = process.env;

    const urlObj = new URL(REACT_APP_DATA_API + `external/database/${storageType}/${name}`);
    Object.entries(credentials).forEach((credential) => {
      urlObj.searchParams.set(credential[0], credential[1]);
    });
    axios
      .get(urlObj.toString())
      .then((res) => {
        const mapping = res.data.mapping;
        const mappingWithType = res.data.mapping_with_type;
        setResponse(mapping);
        setResponseWithType(mappingWithType);
        setFieldsToUpload({});
        setFilters({});
        setDatabaseTestLoading(false);
        dataLoadingToast({ success: true, successMessage: res.data.message });
      })
      .catch((error) => {
        dataLoadingToast({ error: true, errorMessage: error.data.message });
        setDatabaseTestLoading(false);
      });
  };

  const handleCredentialsChange = (name, value) => {
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };
  const setFieldToUpload = (key, value) => {
    const updates = structuredClone(fieldsToUpload);
    if (value === null) delete updates[key];
    else updates[key] = { name: value };
    setFieldsToUpload(updates);
  };
  const setFilter = (key, value) => {
    const updates = structuredClone(filters);
    if (value === null) delete updates[key];
    else {
      updates[key] = value;
    }
    setFilters(updates);
  };
  const setDynamicFilter = (key, value) => {
    const updates = structuredClone(dynamicFilters);
    if (value === null) delete updates[key];
    else {
      if (updates[key] === undefined) updates[key] = value;
      else {
        const tmp = Object.entries(value)[0];
        updates[key][tmp[0]] = tmp[1];
      }
    }
    setDynamicFilters(updates);
  };
  const isDisabled = Object.values(credentials).some((value) => isEmptyString(value)) || databaseTestLoading || isEmptyString(name);
  return (
    <div className={"p-8"}>
      <div className={"flex w-full flex-col gap-4 rounded-lg border-blue-900 bg-white py-4 shadow-md"}>
        <div>
          <h3 className="px-4 text-2xl font-semibold text-blue-900">
            {capitalizeFirstLetter(t("data_feedback"))} - {configured_source.find((configuration) => configuration.id === id).description}
          </h3>
          <HorizontalLine />
        </div>
        <div>
          <div className={"grid grid-cols-1 gap-4 p-4 lg:grid-cols-4"}>
            <div>
              <label className="font-bold tracking-wide text-gray-700">Type de base de données</label>
              <Select onChange={handleStorageTypeChange} value={storageTypeOptions.find((st) => st.value === storageType)} options={storageTypeOptions} />
            </div>
            <div className={"col-span-3"}>
              <TextInput label={"Nom de la table"} name={"name"} value={name} onChange={(_name, value) => setName(value)} />
            </div>
          </div>
          {storageType === "postgresql" && <PostgreSQLParam credential={credentials} handleCredentialsChange={handleCredentialsChange} />}
          {storageType === "mongodb" && <MongoDBParam credential={credentials} handleCredentialsChange={handleCredentialsChange} />}
          {storageType === "elasticsearch" && <ElasticSearchParam credential={credentials} handleCredentialsChange={handleCredentialsChange} />}
        </div>
        <div className={"flex justify-center"}>
          <PrimaryButton label={"Tester"} callback={connectToDatabase} isLoading={databaseTestLoading} disabled={isDisabled} />
        </div>
        <FieldsToUpload
          setFieldToUpload={setFieldToUpload}
          setSelectedFields={setSelectedFields}
          fieldsToUpload={fieldsToUpload}
          crudeSourceId={crudeSourceId}
          response={response}
          selectedFields={selectedFields}
        />
        <div className={"flex flex-col gap-x-2 px-4"}>
          <label className="font-bold tracking-wide text-gray-700">Filtres</label>
          <RequestParametersFiltersEditor
            isElasticSearch={storageType === "elasticsearch"}
            filters={filters}
            mappingWithType={responseWithType}
            setFilter={setFilter}
            mapping={response}
          />
        </div>

        <div className={"flex flex-col gap-x-2 px-4"}>
          <label className="font-bold tracking-wide text-gray-700">Filtres dynamiques</label>
          <RequestParametersDynamicFiltersEditor
            crudeSourceId={crudeSourceId}
            filters={dynamicFilters}
            mappingWithType={responseWithType}
            setFilter={setDynamicFilter}
            mapping={response}
          />
        </div>
        <DatabaseFeedbackTaskModal
          payload={generatePayload(storageType, fieldsToUpload, credentials, generateFitlers(filters, dynamicFilters), id, crudeSourceId, name)}
          emptyFilters={isEmptyObject(filters) && isEmptyObject(dynamicFilters)}
        />
      </div>
    </div>
  );
};
export default DatabaseFeedback;
