//React librairies
import DashboardData from "../components/DashboardData/DashboardData";
import { useSelector } from "react-redux";
import DashboardLoad from "../components/DashboardData/DashboardLoad";

const Dashboard = () => {
  const { data } = useSelector((state) => state);
  const { dataLoaded } = useSelector((state) => state.application);
  return dataLoaded ? (
    <DashboardData crude_source={data.crude_source} observed_system={data.observed_system} storage_system={data.storage_system} />
  ) : (
    <DashboardLoad />
  );
};

export default Dashboard;
