import { useState } from "react";

import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { isEmptyObject, isEmptyString } from "../../utils/common";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { toast } from "react-toastify";
import { TablePagination } from "./TablePagination";
import { useSelector } from "react-redux";
import { TableFilter } from "./TableFilter";
import { PrimaryButton } from "../Buttons/Button";
import { getUserApi } from "../../axios/UserManagementApi";

const TableLogConnection = () => {
  const [data, setData] = useState([]);
  const structures = useSelector((state) => state.data.structures);
  const profile = useSelector((state) => state.application.profile);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("user_email", {
      id: "user_email",
      header: "utilisateur",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("structure_id", {
      id: "structure",
      header: "Structure",
      cell: (info) => {
        const structure_id = info.getValue();
        if (isEmptyString(structure_id)) return "N/A";
        if (!isEmptyObject(structures) && structures[structure_id] !== undefined) return structures[structure_id].name;

        return structure_id;
      },
    }),
    columnHelper.accessor("login_timestamp", {
      enableGlobalFilter: false,
      id: "login",
      header: "Connexion",
      cell: (info) => new Date(info.getValue() * 1000).toLocaleString(),
    }),
    columnHelper.accessor("logout_timestamp", {
      enableGlobalFilter: false,
      id: "logout",
      header: "déconnexion",
      cell: (info) => (info.getValue() === null ? "N/A" : new Date(info.getValue() * 1000).toLocaleString()),
    }),
  ];
  const update = () => {
    setLoading(true);
    const url = profile["id_role"] === 1 ? "users" : "users?structure_id=" + profile["structure_id"];
    getUserApi(url).then((res) => {
      const formatted = JSON.parse(res.data.data);
      toast.success("Informations mises à jour.");
      setData(formatted);
      setLoading(false);
    });
  };
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className=" m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className=" flex flex-row px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
        <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
          <PrimaryButton isLoading={loading} disabled={loading} callback={update} label="Mettre à jour" />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
        <TablePagination table={table} />
      </div>
    </div>
  );
};
export default TableLogConnection;
