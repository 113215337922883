import { Tab } from "@headlessui/react";
import TabHeader from "./TabHeader";
import TabPanel from "./TabPanel";

/**
 *
 * @param {string[]} headers
 * @param {number} current
 * @param {(index: number) => void} changeCurrent
 * @param {Object[]} panel
 * @constructor
 */
const TabGroup = ({ headers, current, changeCurrent, panel }) => (
  <div className={"w-full "}>
    <Tab.Group selectedIndex={current} onChange={changeCurrent}>
      <div className={"flex justify-between rounded-t-lg bg-white "}>
        <Tab.List className={`flex border-b border-gray-200  text-center font-medium text-gray-500 dark:border-gray-400 dark:text-gray-400`}>
          <TabHeader headers={headers} />
        </Tab.List>
      </div>
      <TabPanel panel={panel} />
    </Tab.Group>
  </div>
);
export default TabGroup;
