import { useState } from "react";
import TextInput from "../../components/Selectors/TextInput";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { postDataApi } from "../../axios/DataManagementApi";
import { toast } from "react-toastify";
import { PrimaryButton } from "../../components/Buttons/Button";
import { useTranslation } from "react-i18next";

const Structure = () => {
  const { t } = useTranslation(["common", "button"]);
  const history = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
  });
  const onChange = (name, value) => {
    setData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const add = () => {
    setLoading(true);
    postDataApi("structure", data).then(() => {
      toast.success("Structure créee");
      setLoading(false);
    });
  };

  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <div className="flex gap-x-1.5">
        <IconButton bg={true} Icon={<Prev size={40} />} value={""} callback={() => history("/admin/structures")} />
        <h2 className="text-3xl font-semibold text-blue-900">Création d&apos;une structure</h2>
      </div>
      <div className={"grid grid-cols-2 gap-4  border-b pb-2"}>
        <TextInput name={"name"} value={data["name"]} onChange={onChange} label={t("name")} />
        <TextInput name={"description"} value={data["description"]} onChange={onChange} label={t("description")} />
      </div>
      <div className={"flex justify-end gap-2 pt-2 "}>
        <PrimaryButton
          isLoading={isLoading}
          label={t("add", { ns: "button" })}
          callback={() => {
            add();
          }}
        />
      </div>
    </div>
  );
};
export default Structure;
