import { Fragment } from "react";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";

const Table = ({ table }) => (
  <Fragment>
    <div className="flex flex-col">
      <div className="overflow-visible">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="border-b border-gray-200 shadow">
            <table className="min-w-full divide-y divide-gray-200">
              <TableHeader table={table} />
              <TableBody table={table} />
            </table>
          </div>
        </div>
      </div>
    </div>
    <TablePagination table={table} />
  </Fragment>
);
export default Table;
