import { useState } from "react";
import { data_params } from "./EventUtils";
import Select from "react-select";
import { isEmptyString, makeSelectField, makeVariableField } from "../../utils/common";
import VariableTable from "./VariableTable";
import VariableTableHisto from "./VariableTableHisto";
import { useDispatch, useSelector } from "react-redux";
import { addDataParams, setBase } from "../../reducers/EventSlice";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons/Button";
import { dataStatusOptions } from "../../utils/field";

const DataParamCreation = () => {
  const sourceId = useSelector((state) => state.event.sourceId);
  const source = useSelector((state) => state.event.source).find((value) => value.id === sourceId);
  const histo = useSelector((state) => state.event.histo);
  const dispatch = useDispatch();
  const selectedSource = useSelector((state) => state.event.selectedSource);
  const configurations = useSelector((state) => state.data.configured_source);
  const [rowSelection, setRowSelection] = useState({});

  const preprocesses = (variables) => {
    const preprocessesList = structuredClone(variables.filter((variable) => variable[1].type !== "date"));
    preprocessesList.forEach((preprocess) => {
      preprocess[1]["current"] = "identity";
      preprocess[1]["historical"] = "identity";
      preprocess[2] = false;
    });
    return preprocessesList;
  };

  const [variables, setVariables] = useState(preprocesses(Object.entries(source.measure)));
  const [data, setData] = useState(structuredClone(data_params));
  const updateData = (name, value) => {
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const create = () => {
    const configuration_id = source.configuration[0];
    const measure_identifier = configurations.find((value) => value.id === configuration_id).measure_identifier;
    const current = structuredClone(data);
    console.debug(current);
    current.measure_identifier = measure_identifier;
    current.configured_sources = current.configured_sources.map((value) => value.value);
    const historical = structuredClone(current);
    Object.keys(rowSelection).forEach((row) => {
      const variable = variables[row];
      console.debug(variable[0], variable[1]["current"]);
      current.preprocess[variable[0]] = variable[1]["current"];
      if (histo) {
        historical.preprocess[variable[0]] = variable[1]["historical"];
      }
    });
    dispatch(addDataParams({ current: current, historical: histo ? historical : null }));
  };
  console.debug(makeVariableField(3, sourceId, source.measure));

  return (
    <div>
      <div>
        <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Timefield</label>
        <Select
          options={makeVariableField(3, sourceId, source.measure)}
          value={
            isEmptyString(data["time_field"])
              ? undefined
              : {
                  label: data["time_field"],
                  value: data["time_field"],
                  crude_source_id: source.id,
                }
          }
          onChange={(newValue) => updateData("time_field", newValue.value)}
          placeholder={"Index de temps..."}
        />
      </div>
      <div>
        <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Configuration</label>
        <Select
          isMulti
          value={data.configured_sources}
          onChange={(options) => updateData("configured_sources", options)}
          options={makeSelectField(
            configurations.filter((value) => value.crude_source === sourceId),
            ["description"],
          )}
          placeholder={"Configuration..."}
        />
      </div>
      <div className={"pb-2"}>
        <h1 className="py-2 text-sm font-bold tracking-wide text-gray-700 dark:text-white">Données à considérer</h1>
        <Select
          options={dataStatusOptions}
          onChange={(newValue) => {
            updateData("status", newValue.value);
          }}
          value={dataStatusOptions.find((value) => value.value === data["status"])}
        />
      </div>
      {histo ? (
        <VariableTableHisto row={rowSelection} setRow={setRowSelection} data={variables} setData={setVariables} />
      ) : (
        <VariableTable row={rowSelection} setRow={setRowSelection} data={variables} setData={setVariables} />
      )}

      <div className="flex justify-center gap-x-0.5">
        <SecondaryButton
          label={"Annuler"}
          callback={() => {
            dispatch(
              setBase({
                type: "selectedSource",
                value: selectedSource.filter((value) => value.value !== sourceId),
              }),
            );
            dispatch(setBase({ type: "sourceId", value: false }));
          }}
        />
        <PrimaryButton label={"ajouter"} callback={create} />
      </div>
    </div>
  );
};
export default DataParamCreation;
