import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setAlertSettings } from "../../../reducers/indicatorSlice";
import NumericInput from "../../../components/Inputs/NumericInput";
import { IndicatorEvent } from "../IndicatorEvent";

const DetectionAnomalieAlertSetting = () => {
  const dispatch = useDispatch();
  const alert_settings = useSelector((state) => state.indicator.moduleData.alert_settings);
  const typeOptions = [
    { value: "counting", label: "Comptage" },
    { value: "proportion", label: "Proportion" },
  ];
  return (
    <div>
      <label className="mb-1 block text-sm font-medium text-gray-900">Mode de contrôle</label>
      <Select
        className={"w-72"}
        onChange={(newValue) => dispatch(setAlertSettings({ key: "type", value: newValue.value }))}
        value={typeOptions.find((type) => type.value === alert_settings.type)}
        menuPosition={"fixed"}
        options={typeOptions}
      />
      <div className={"w-72"}>
        <NumericInput
          label={"Seuil à considérer"}
          name={"value"}
          onChange={(name, value) => dispatch(setAlertSettings({ key: name, value: value }))}
          min={0}
          value={alert_settings.value}
        />
      </div>
      <IndicatorEvent selectedEvent={alert_settings.event} setEvent={setAlertSettings} keyName={"event"} />
    </div>
  );
};
export default DetectionAnomalieAlertSetting;
