import { Disclosure } from "@headlessui/react";
import { HiChevronUp as ChevronUpIcon } from "react-icons/hi";

function IndicatorAccordion({ label, composant, open = true }) {
  return (
    <Disclosure defaultOpen={open}>
      {({ open }) => (
        /* Use the `open` state to conditionally change the direction of an icon. */
        <>
          <Disclosure.Button className="flex w-full justify-between rounded-t-lg border-b border-blue-900 px-4 py-2 text-left text-sm font-bold tracking-wide text-blue-900 hover:bg-gray-50 focus:outline-none">
            {label}
            <ChevronUpIcon className={`${open ? "rotate-180" : ""} h-5 w-5 text-blue-900`} />
          </Disclosure.Button>
          <Disclosure.Panel className={"px-10 pt-4"}>{composant}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default IndicatorAccordion;
