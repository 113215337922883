import { createSlice } from "@reduxjs/toolkit";

export const applicationSlice = createSlice({
  name: "application",
  initialState: {
    isAuthenticated: sessionStorage.getItem("isAuthenticated") === "true",
    profile: JSON.parse(sessionStorage.getItem("profile")),
    token: sessionStorage.getItem("token"),
    polling: false,
    authorized_pages: JSON.parse(sessionStorage.getItem("authorized_pages")),
    authorized_components: JSON.parse(sessionStorage.getItem("authorized_components")),
    showSidebar: sessionStorage.getItem("showSidebar") === "true",
    dataLoaded: false,
    showLabel: sessionStorage.getItem("showLabel") === "true",
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.profile = action.payload.profile;
      state.authorized_pages = action.payload.authorized_pages;
      state.authorized_components = action.payload.authorized_components;
      state.showSidebar = true;
      state.dataLoaded = false;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.profile = {};
      state.polling = false;
      state.authorized_pages = [];
      state.authorized_components = {};
      state.dataLoaded = false;
    },
    set: (state, action) => {
      state[action.payload.type] = action.payload.payload;
    },

    token: (state, action) => {
      state.token = action.payload;
    },
    setPolling: (state, action) => {
      state.polling = action.payload;
    },
    setLabel: (state, action) => {
      state.showLabel = action.payload;
      sessionStorage.setItem("showLabel", action.payload);
    },
  },
});

export const { login, logout, token, setPolling, set, setLabel } = applicationSlice.actions;

export default applicationSlice.reducer;
