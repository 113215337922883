import { useCallback, useState } from "react";
import { PrimaryButton, SecondaryButton } from "./Buttons/Button";
import NumericInput from "./Inputs/NumericInput";
import SwitchButton from "./Selectors/SwitchButton";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { putUserApi } from "../axios/UserManagementApi";

const PreferenceSection = ({ user_id, preference, onSave, isLoading }) => {
  const [data, setData] = useState(preference);

  const displayPreference = useCallback(() => {
    const handleChange = (name, value) => {
      const newData = data.map((pref) => (pref.name === name ? { ...pref, value: value } : pref));
      setData(newData);
    };
    return data.map((preference) => (
      <div key={preference.name}>
        {preference.type === "number" && (
          <div className={"w-40"}>
            <NumericInput step={5} min={0} max={50} onChange={handleChange} label={preference.name} name={preference.name} value={preference.value} />
          </div>
        )}
        {preference.type === "boolean" && (
          <SwitchButton label={preference.name} value={preference.value} onChange={(value) => handleChange(preference.name, value)} />
        )}
      </div>
    ));
  }, [data]);

  return (
    <div>
      <p className={"inline-block align-baseline text-lg"}>Preferences utilisateur</p>
      {displayPreference()}
      <div className={"flex justify-end"}>
        <div>
          <PrimaryButton disabled={isLoading} label={"Sauvegarder"} callback={() => onSave(data)} />
        </div>
      </div>
      <div className="my-3 flex  border-t border-neutral-400 font-normal ">{<PasswordHandler user_id={user_id} />}</div>
    </div>
  );
};

export default PreferenceSection;

const PwdInput = ({ name, label, setValue }) => {
  const [showPwd, setShowPwd] = useState(false);
  return (
    <div>
      <label htmlFor="password" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
        {label}
      </label>
      <div className={"flex"}>
        <button type={"button"} onClick={() => setShowPwd(!showPwd)} className={"z-10 flex w-10 cursor-pointer items-center justify-center pl-1 text-center"}>
          {!showPwd ? <EyeIcon className={"h-6 w-6"} /> : <EyeSlashIcon className={"h-6 w-6"} />}
        </button>
        <input
          onChange={(event) => setValue(event.target.value)}
          type={showPwd ? "text" : "password"}
          autoComplete={"current-password"}
          name={name}
          id={name}
          className="-ml-10 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-gray-900 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
          required=""
        />
      </div>
    </div>
  );
};
const PasswordHandler = ({ user_id }) => {
  const [newPassword, setNewPassword] = useState("");
  const [validationPassword, setValidationPassword] = useState("");
  const pwdRegEx = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$");
  return (
    <form className={"mt-2 block"}>
      <div>
        <p className={"inline-block align-baseline text-lg"}>Mot de passe</p>
        <div className="flex w-full gap-x-1.5 ">
          <PwdInput label={"Nouveau"} name={"password"} setValue={setNewPassword} />
          <PwdInput label={"Validation"} name={"validation"} setValue={setValidationPassword} />
        </div>
        <div className="mt-6 flex justify-center gap-x-0.5">
          <PrimaryButton
            label={"Modifier"}
            callback={() => {
              putUserApi("management?management_action=changepwd", {
                id: user_id,
                password: newPassword,
              })
                .then((res) => {
                  console.debug(res);
                })
                .catch((error) => {
                  console.debug(error);
                });
            }}
            disabled={!pwdRegEx.test(newPassword) || !pwdRegEx.test(validationPassword) || newPassword !== validationPassword}
          />
          <div>
            <SecondaryButton
              color={"bg-red-600"}
              hover={"hover:bg-red-700"}
              focus={"focus:outline-none focus:ring-2 focus:ring-red-400"}
              label={"Réinitialiser"}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
