import { DeleteButton, PrimaryButton } from "../../components/Buttons/Button";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import { dynamicFiltersOptions, dynamicTypeOptions } from "./databaseFeedback.utils";
import { isEmptyObject } from "../../utils/common";
import { useSelector } from "react-redux";
import RequestParametersDynamicFiltersDisplay from "./RequestParametersDynamicFiltersDisplay";

const RequestParametersDynamicFiltersEditor = ({ mapping, mappingWithType, setFilter, filters, crudeSourceId }) => {
  const { crude_source } = useSelector((state) => state.data);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [columnUsed, setColumnUsed] = useState(null);
  const [operator, setOperator] = useState("<=");
  const [value, setValue] = useState("NOW");
  const [type, setType] = useState("TIMESTAMP");
  const [measures, setMeasures] = useState([]);
  useEffect(() => {
    const measureOjbect = crude_source.find((crude_source) => crude_source.id === crudeSourceId)?.measure;
    if (!isEmptyObject(measureOjbect)) setMeasures(Object.keys(measureOjbect));
  }, [crudeSourceId, crude_source]);
  const handleAdd = () => {
    const filter = {
      [type]: {
        [operator]: value,
      },
    };
    setFilter(columnUsed, filter);
    handleCancel();
  };
  const handleCancel = () => {
    setColumnUsed(null);
    setOperator("<=");
    setValue("NOW");
    setType("TIMESTAMP");
    setModalIsOpen(false);
  };
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const options = mapping
      .filter((res) => mappingWithType[res] !== "NoneType")
      .map((res) => {
        return { label: res, value: res };
      });
    setOptions(options);
  }, [filters, mapping, mappingWithType]);
  const handleColumnUsedChange = (newValue) => {
    setColumnUsed(newValue.value);
  };
  const handleTypeChange = (newValue) => {
    setType(newValue.value);
    if (newValue.value === "TIMESTAMP") setValue("NOW");
    else setValue(null);
  };
  const handleOperatorChange = (newValue) => {
    setOperator(newValue.value);
  };
  const dynamicValue = () => {
    if (value) return { value: value, label: value };
    return null;
  };
  const dynamicOptions = () => {
    if (type === "TIMESTAMP") return [{ value: "NOW", label: "NOW" }];
    if (type === "INTERNAL")
      return measures.map((measure) => {
        return { label: measure, value: measure };
      });
  };
  return (
    <div>
      <div className={"grid grid-cols-4 bg-blue-900 font-semibold text-white"}>
        <p className={"flex items-center justify-center"}>Nom</p>
        <p className={"flex items-center justify-center"}>Type</p>
        <p className={"flex items-center justify-center"}>Valeur</p>
        <div className={"flex items-center justify-center"}>
          <PrimaryButton label={"Ajouter un filtre"} callback={() => setModalIsOpen(true)} />
        </div>
      </div>
      <RequestParametersDynamicFiltersDisplay setFilters={setFilter} filters={filters} />

      <div>
        <Transition appear show={modalIsOpen} as={Fragment}>
          <Dialog as="div" className={"relative z-50"} onClose={setModalIsOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            </Transition.Child>
            <div className="fixed inset-0 ">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className=" h-auto w-2/3 rounded-2xl  bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <div className={"flex flex-col gap-2"}>
                      <div className={"grid grid-cols-4 gap-x-1"}>
                        <div>
                          <label className="font-bold tracking-wide text-gray-700">Colonne utilisée</label>
                          <Select options={options} value={options.find((option) => option.value === columnUsed)} onChange={handleColumnUsedChange} />
                        </div>
                        <div>
                          <label className="font-bold tracking-wide text-gray-700">Type</label>
                          <Select
                            hideSelectedOptions
                            options={dynamicTypeOptions}
                            value={dynamicTypeOptions.find((option) => option.value === type) ?? null}
                            onChange={handleTypeChange}
                          />
                        </div>
                        <div>
                          <label className="font-bold tracking-wide text-gray-700">Opérateur</label>
                          <Select
                            hideSelectedOptions
                            options={dynamicFiltersOptions}
                            value={dynamicFiltersOptions.find((option) => option.value === operator) ?? null}
                            onChange={handleOperatorChange}
                          />
                        </div>
                        <div className={"col-span-1 gap-x-0.5"}>
                          <label className="font-bold tracking-wide text-gray-700">Valeur</label>
                          <Select
                            onChange={(newValue) => setValue(newValue.value)}
                            value={dynamicValue()}
                            options={dynamicOptions()}
                            isDisabled={type === "TIMESTAMP"}
                          />
                        </div>
                      </div>
                      <div className={"flex justify-end gap-x-0.5"}>
                        <DeleteButton label={"Annuler"} callback={handleCancel} />
                        <PrimaryButton label={"Ajouter"} callback={handleAdd} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
};
export default RequestParametersDynamicFiltersEditor;
