//React librairies
import ReactDOM from "react-dom/client";

//Components
import App from "./App";

//Redux
import { Provider } from "react-redux";
import store from "./store";

//Styles
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { hydrate } from "./reducers/dataSlice";
import { set as setApp } from "./reducers/ApplicationSlice";
import "./i18n";

const getDataFromState = () => {
  try {
    const persistedState = sessionStorage.getItem("userData");
    if (persistedState) return JSON.parse(persistedState);
  } catch (e) {
    console.log(e);
  }
};
const persistedState = getDataFromState();
if (persistedState) {
  store.dispatch(hydrate(persistedState));
  store.dispatch(setApp({ type: "dataLoaded", payload: true }));
}
// Add the event listener to save data before the page reloads
window.addEventListener("beforeunload", () => {
  const userData = JSON.stringify(store.getState().data);
  sessionStorage.setItem("userData", userData);
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
