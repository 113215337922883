import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//Component
import { postUserApi } from "../../axios/UserManagementApi";
import store from "../../store";
import { logout } from "../../reducers/ApplicationSlice";
import Icon from "@mdi/react";
import { mdiAccountOutline, mdiLogout } from "@mdi/js";

const Profile = ({ close, observedSystem }) => {
  const history = useNavigate();
  const { structure, profiles } = useSelector((state) => state.data);
  const profile = useSelector((state) => state.application.profile);
  const profileName = profiles.find((value) => value.id === profile["id_role"])?.name;
  return (
    <div className={"p-2"}>
      <span className="flex font-bold lg:text-xl">
        {profile.first_name} {profile.last_name}
      </span>
      <span className={"flex gap-x-1.5"}>
        <h1 className={"font-semibold"}>Structure:</h1>
        <p className={"truncate hover:overflow-visible"}>{structure.name}</p>
      </span>
      <span className={"flex gap-x-1.5"}>
        <h1 className={"font-semibold"}>SO:</h1>
        <p className={"truncate hover:overflow-visible"}>{observedSystem}</p>
      </span>
      <span className={"flex gap-x-1.5"}>
        <h1 className={"font-semibold "}>Role:</h1>
        <p className={"truncate hover:overflow-visible"}>{profileName ?? "N/A"}</p>
      </span>
      <div className={"p-1"}>
        <button
          onClick={() => {
            history("/profile");
            close();
          }}
          className={"group flex w-full items-center rounded-md p-2 text-sm hover:bg-blue-100"}
        >
          <Icon path={mdiAccountOutline} size={1} />
          <span className="ml-2">{"Voir profil"}</span>
        </button>
        <button
          onClick={() =>
            postUserApi("user/logout", {}).then(() => {
              store.dispatch(logout());
              sessionStorage.clear();
              history("/login");
            })
          }
          className={"group flex w-full items-center rounded-md p-2 text-sm hover:bg-blue-100"}
        >
          <Icon path={mdiLogout} size={1} />
          <span className="ml-2">{"Se déconnecter"}</span>
        </button>
      </div>
    </div>
  );
};

export default Profile;
