import { useLoaderData, useParams } from "react-router-dom";
import { useState } from "react";
import { TableFilter } from "../../components/Table/TableFilter";
import Table from "../../components/Table/Table";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { dataList, getUserPreferences } from "../../utils/common";
import { mdiDelete, mdiDeskLampOff, mdiDeskLampOn } from "@mdi/js";
import DropdownMenu from "../../components/Selectors/DropDownButton";
import { deleteDataApi, getDataApi, putDataApi } from "../../axios/DataManagementApi";
import { toast } from "react-toastify";
import TransformerCreatorModal from "./TransformerCreatorModal";
import ReactJson from "@microlink/react-json-view";
import { PrevButton } from "../../components/Buttons/Button";
import DeleteModal from "../../components/Selectors/DeleteModal";
import { useTranslation } from "react-i18next";
const TransformersList = () => {
  const { id } = useParams();
  const [data, setData] = useState(useLoaderData());
  const [globalFilter, setGlobalFilter] = useState("");
  const [deleteFlag, setDeleteFlag] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation(["transformers"]);
  const handleActivate = (transformerId) => {
    const payload = {
      observed_system_id: sessionStorage.getItem("observed_system_id"),
      configured_source_id: id,
      transformer_id: transformerId,
    };
    putDataApi("transformer/activate_transformer", payload).then((res) => {
      toast.success(res.data.message);
      getDataApi(`transformer/get_transformers?observed_system_id=${sessionStorage.getItem("observed_system_id")}&configured_source_id=${id}`)
        .then((res) => setData(dataList(res.data.data)))
        .catch(() => setData([]));
    });
  };
  console.debug(data);
  const handleDisable = () => {
    const payload = {
      observed_system_id: sessionStorage.getItem("observed_system_id"),
      configured_source_id: id,
    };
    putDataApi("transformer/disable_transformers", payload).then((res) => {
      toast.success(res.data.message);
      getDataApi(`transformer/get_transformers?observed_system_id=${sessionStorage.getItem("observed_system_id")}&configured_source_id=${id}`)
        .then((res) => setData(dataList(res.data.data)))
        .catch(() => setData([]));
    });
  };
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("description", {
      id: "desc",
      header: "description",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "parameters",
      header: "description",
      cell: (info) => <ReactJson enableClipboard={false} name={false} collapsed={true} src={info.row.original.parameters} />,
    }),

    columnHelper.display({
      id: "status",
      cell: (info) => (info.row.original.status ? "Actif" : "Inactif"),
      header: "Status",
    }),
    columnHelper.display({
      id: "actions_button",
      cell: (info) => {
        const row = info.row.original;
        const options = [];
        if (row.status)
          options.push({
            id: 1,
            label: "Désactiver",
            onClick: () => handleDisable(),
            path: mdiDeskLampOff,
          });
        else
          options.push({
            id: 1,
            label: "Activer",
            onClick: () => handleActivate(row.id),
            path: mdiDeskLampOn,
          });
        options.push({
          id: 2,
          label: "Supprimer",
          onClick: () => setDeleteFlag(row),
          path: mdiDelete,
        });
        return <DropdownMenu options={options} />;
      },
    }),
  ];
  console.debug(deleteFlag);
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  const showId = getUserPreferences().find((preference) => preference.name === "showId")?.value ?? true;

  if (!showId) columns.splice(0, 1);

  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      {deleteFlag && (
        <DeleteModal
          callback={() => {
            setIsDeleting(true);
            deleteDataApi(
              "transformer/delete_transformer?observed_system_id=" + sessionStorage.getItem("observed_system_id") + "&transformer_id=" + deleteFlag.id,
            ).then(() => {
              setIsDeleting(false);
              setDeleteFlag(undefined);
              toast.success("Le transformer a été supprimé");
              getDataApi(`transformer/get_transformers?observed_system_id=${sessionStorage.getItem("observed_system_id")}&configured_source_id=${id}`)
                .then((res) => setData(dataList(res.data.data)))
                .catch(() => setData([]));
            });
          }}
          loading={isDeleting}
          text={"Supprimer le transformer ?"}
          modalVisible={deleteFlag}
          setModalVisible={setDeleteFlag}
        />
      )}
      <div className={"flex gap-x-0.5 px-4 pt-4"}>
        <PrevButton url={"/"} />
        <h2 className="text-3xl font-semibold text-blue-800">{t("name")}</h2>
      </div>
      <div className="flex flex-col justify-between p-4 lg:flex-row">
        <div className="flex flex-row">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
        <TransformerCreatorModal setData={setData} configuredSourceId={id} />
      </div>
      <Table table={table} />
    </div>
  );
};
export default TransformersList;
