import { variableField } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { setTargetVariables } from "../../../reducers/indicatorSlice";
import LabelTooltip from "../../../components/Tooltip/LabelTooltip";
import Select from "react-select";

const CrosscorrelationParams = () => {
  const dispatch = useDispatch();
  const { target_variables, current } = useSelector((state) => state.indicator.moduleData);
  const onChange = (list) => {
    const target = [];
    list.forEach((opt) => target.push(opt.value));
    dispatch(setTargetVariables(target));
  };
  return (
    <div>
      <LabelTooltip label={"Cibles"} tip={"Au moins deux variables"} />
      <Select
        isMulti
        value={variableField(current["data_params"]).filter((value) => target_variables.includes(value.value))}
        onChange={onChange}
        options={variableField(current["data_params"])}
      />
    </div>
  );
};

export default CrosscorrelationParams;
