//React librairies
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fragment } from "react";

const ToastWrapper = () => (
  <Fragment>
    <ToastContainer
      containerId={"loadingToast"}
      transition={Slide}
      position="bottom-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      theme="light"
    />
  </Fragment>
);

export default ToastWrapper;
