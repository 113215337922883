import { useDispatch, useSelector } from "react-redux";
import { setTimeParams } from "../../reducers/EventSlice";
import NumericInput from "../../components/Inputs/NumericInput";

const TimeParam = () => {
  const bucket_size = useSelector((state) => state.event.data.current.time_params.bucket_size);
  const dispatch = useDispatch();
  return (
    <div className="px-4">
      <h2 className="text-xl font-semibold text-blue-900">Courant</h2>
      <NumericInput
        name={"bucket_size"}
        value={bucket_size}
        label={"Taille du bucket"}
        step={1}
        onChange={(name, value) =>
          dispatch(
            setTimeParams({
              type: "current",
              type2: name,
              value: value,
            }),
          )
        }
      />
    </div>
  );
};
export default TimeParam;
