import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useState } from "react";

import { putDataApi } from "../../axios/DataManagementApi";
import { postIndicatorApi } from "../../axios/IndicatorComputationApi";
import dayjs from "dayjs";
import { PrimaryButton } from "../../components/Buttons/Button";
import store from "../../store";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import { formatBackend } from "../../utils/date.utils";

const IndicatorRecap = ({ label, setResult }) => {
  const indicator = useSelector((state) => state.indicator);
  const [isloading, setLoading] = useState(false);
  const obs = sessionStorage.getItem("observed_system_id");

  const transformConf = () => {
    const ret = [];
    indicator["selConfiguration"].forEach((conf) => {
      ret.push(conf.value);
    });
    return ret;
  };

  const transformVariable = () => {
    const ret = [];
    indicator["selVariable"].forEach((variable) => {
      ret.push(variable.label);
    });
    return ret;
  };
  const handleMonitoring = () => {
    setLoading(true);
    const payload = structuredClone(indicator["moduleData"]);
    payload.script_parameters.data_fields = transformVariable();
    payload.script_parameters.observed_system = obs;
    payload.script_parameters.configured_sources = transformConf();
    payload.script_parameters.initial_timestamp = dayjs(payload.script_parameters.initial_timestamp).tz(payload.timezone).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    payload.script_parameters.final_timestamp = dayjs(payload.script_parameters.final_timestamp).tz(payload.timezone).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    payload.script_parameters.timestamp_field = payload.script_parameters.timestamp_field.label;

    const mode = payload.mode;
    delete payload.mode;
    delete payload.name;
    if (mode === 0) {
      putDataApi("configuration/simplemodule/" + obs, payload)
        .then((res) => {
          if (res === 401) return;
          const data = res.data;
          if (data["module_execution_result"][0]["control_status"] === true)
            toast.error(data["module_execution_result"][0]["control_message"], {
              autoClose: false,
            });
          else toast.info("Aucune anomalie détectée");
        })
        .catch((error) => console.debug(error));
    } else if (mode === 1) {
      putDataApi("configuration/module/" + obs, payload)
        .then((res) => {
          if (res === 401) return;
          console.log(res); //TODO handle res
        })
        .catch((error) => console.debug(error));
    }
  };
  const handleDetection = () => {
    setLoading(true);
    const module = structuredClone(indicator["moduleData"]);
    const { time_field } = module.module_params;
    const payload = {
      script_name: module.script_name,
      script_parameters: {
        observed_system: sessionStorage.getItem("observed_system_id"),
        parameters: {
          module_name: module.module_name,
          module_params: {
            historical: module.historical,
            current: module.current,
            model_params: module.module_params,
          },
          alert_settings: module.alert_settings,
        },
        initial_timestamp: formatBackend(module.initial_timestamp, module.timezone),
      },
      frequency: module.frequency,
      validity_days: module.validity_days,
      time_slot: module.time_slot,
    };
    payload.script_parameters.parameters.module_params.current.time_params.start_time = "";
    payload.script_parameters.parameters.module_params.current.time_params.end_time = "";
    payload.script_parameters.parameters.module_params.current.data_params[0].preprocess = {
      ...payload.script_parameters.parameters.module_params.current.data_params[0].preprocess,
      [time_field]: "identity",
    };
    payload.script_parameters.parameters.module_params.historical.data_params[0].preprocess = {
      ...payload.script_parameters.parameters.module_params.historical.data_params[0].preprocess,
      [time_field]: "identity",
    };
    const historicalMode = module.historicalMode;

    if (historicalMode === "count") delete payload.script_parameters.parameters.module_params.historical.time_params.time;
    else delete payload.script_parameters.parameters.module_params.historical.time_params.count;

    putDataApi("configuration/module/" + obs, payload)
      .then((res) => {
        setLoading(false);
        if (res === 401) return;
        toast.warn(res["data"]["message"] + " - " + res["data"]["module_instance_id"], {
          autoClose: false,
          closeOnClick: false,
        });
      })
      .catch((error) => console.debug(error));
  };
  const parseFormulaV1 = (formula, blocks) => {
    let ret = formula;
    blocks.forEach((block) => {
      Object.keys(block.preprocess).forEach((value) => {
        ret = ret.replaceAll(value, "['" + value + "']");
      });
    });
    return ret;
  };
  const handleAnalytique = () => {
    setLoading(true);
    dataLoadingToast({ label: "Calcul en cours...", isLoading: true });
    const payload = structuredClone(indicator["moduleData"]);
    payload.current.computation_params.formula = parseFormulaV1(payload.current.computation_params.formula, payload.current.data_params);
    payload.current.time_params.start_time = formatBackend(payload.current.time_params.start_time, payload.timezone);
    payload.current.time_params.end_time = formatBackend(payload.current.time_params.end_time, payload.timezone);
    delete payload["mode"];
    delete payload["name"];
    delete payload["timezone"];
    postIndicatorApi("indicator/analyticalformula/" + obs, payload)
      .then((res) => {
        dataLoadingToast({ success: true, successMessage: "Calcul terminé" });

        setLoading(false);
        setResult(res.data);
        console.debug(res);
      })
      .catch((error) => {
        dataLoadingToast({ error: true, errorMessage: error?.response?.data?.message });
        setLoading(false);
      });
  };
  const handleCorrelation = () => {
    setLoading(true);
    const payload = structuredClone(indicator["moduleData"]);
    payload.current.computation_params.formula = parseFormulaV1(payload.current.computation_params.formula, payload.current.data_params);
    payload.current.time_params.start_time = formatBackend(payload.current.time_params.start_time, payload.timezone);
    payload.current.time_params.end_time = formatBackend(payload.current.time_params.end_time, payload.timezone);

    delete payload["name"];
    delete payload["timezone"];
    delete payload["script_name"];
    delete payload["mode"];
    postIndicatorApi("indicator/correlation/" + obs, payload)
      .then((res) => {
        if (res.data.status === "success") toast.success(res.data.message);
        else toast.error(res.data.message);
        setLoading(false);
        setResult(res.data);
        console.debug(res);
      })
      .then((error) => {
        console.debug(error);
        setLoading(false);
      });
  };

  const handleConfigurationAlerte = () => {
    setLoading(true);
    const data = structuredClone(indicator["moduleData"]);
    const payload = {
      script_name: data.script_name,
      frequency: data.frequency,
      validity_days: data.validity_days,
      time_slot: data.time_slot,
      script_parameters: {
        observed_system: obs,
        parameters: {
          current: data.current,
          historical: data.historical,
          properties_params: data.properties_params,
          alert_params: data.alert_params,
        },
        initial_timestamp: dayjs(data.initial_timestamp).tz(data.timezone).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        timezone: data.timezone,
      },
    };

    payload.script_parameters.parameters.current.time_params.start_time = formatBackend(
      payload.script_parameters.parameters.current.time_params.start_time,
      payload.script_parameters.timezone,
    );
    payload.script_parameters.parameters.current.time_params.end_time = formatBackend(
      payload.script_parameters.parameters.current.time_params.end_time,
      payload.script_parameters.timezone,
    );
    const mode = data.mode;
    if (mode === 1) delete payload.script_parameters.parameters.historical;
    else {
      const historicalMode = data.historicalMode;
      if (historicalMode === "count") delete payload.script_parameters.parameters.historical.time_params.time;
      else delete payload.script_parameters.parameters.historical.time_params.count;
    }
    putDataApi("configuration/module/" + obs, payload)
      .then((res) => {
        if (res === 401) return;
        toast.warn(res["data"]["message"] + " - " + res["data"]["module_instance_id"], {
          autoClose: false,
          closeOnClick: false,
        });
      })
      .catch((error) => console.debug(error));
  };
  const handleIndustrialHisto = () => {
    setLoading(true);
    dataLoadingToast({ label: "Calcul en cours...", isLoading: true });

    const payload = structuredClone(indicator["moduleData"]);
    payload.current.computation_params.formula = parseFormulaV1(payload.current.computation_params.formula, payload.current.data_params);
    payload.current.time_params.start_time = formatBackend(payload.current.time_params.start_time, payload.timezone);
    payload.current.time_params.end_time = formatBackend(payload.current.time_params.end_time, payload.timezone);

    if (payload["mode"] === 1) delete payload["historical"];
    else {
      const historicalMode = payload.historicalMode;
      if (historicalMode === "count") delete payload.historical.time_params.time;
      else delete payload.historical.time_params.count;
    }
    delete payload["historicalMode"];
    delete payload["mode"];
    delete payload["timezone"];
    delete payload["name"];

    postIndicatorApi("indicator/industrialindicator/" + obs, payload)
      .then((res) => {
        dataLoadingToast({ success: true, successMessage: "Calcul terminé" });
        setLoading(false);
        setResult(res.data);
        console.debug(res);
      })
      .catch((error) => {
        dataLoadingToast({ error: true, errorMessage: error?.response?.data?.message });

        console.debug(error);
        setLoading(false);
      });
  };
  const handleTimeIndicator = () => {
    const data = structuredClone(indicator["moduleData"]);
    const payload = {
      script_name: data.script_name,
      script_parameters: {
        observed_system: sessionStorage.getItem("observed_system_id"),
        parameters: {
          current: data.current,
          historical: data.historical,
          indicator_params: data.indicator_params,
          properties_params: data.properties_params,
          event: data.event,
        },
        execution_interval: data.execution_interval,
        initial_timestamp: formatBackend(data.initial_timestamp, data.timezone),
      },
      frequency: data.frequency,
      validity_days: data.validity_days,
      time_slot: data.time_slot,
    };
    payload.script_parameters.parameters.current.time_params.start_time = formatBackend(
      payload.script_parameters.parameters.current.time_params.start_time,
      payload.script_parameters.timezone,
    );
    payload.script_parameters.parameters.current.time_params.end_time = formatBackend(
      payload.script_parameters.parameters.current.time_params.end_time,
      payload.script_parameters.timezone,
    );
    const mode = data.mode;

    if (mode === 1) delete payload.script_parameters.parameters.historical;
    else {
      const historicalMode = data.historicalMode;
      if (historicalMode === "count") delete payload.script_parameters.parameters.historical.time_params.time;
      else delete payload.script_parameters.parameters.historical.time_params.count;
    }
    putDataApi("configuration/module/" + obs, payload)
      .then((res) => {
        if (res === 401) return;
        toast.warn(res["data"]["message"] + " - " + res["data"]["module_instance_id"], {
          autoClose: false,
          closeOnClick: false,
        });
      })
      .catch((error) => console.debug(error));
  };
  const handleOutlier = () => {
    setLoading(true);
    const data = structuredClone(indicator["moduleData"]);
    const payload = {
      script_name: data.script_name,
      script_parameters: {
        observed_system: sessionStorage.getItem("observed_system_id"),
        parameters: data.parameters,
        timezone: data.timezone,
        initial_timestamp: formatBackend(data.initial_timestamp, data.timezone),
      },
      validity_days: data.validity_days,
      frequency: data.frequency,
      time_slot: data.time_slot,
    };

    const source_id = indicator["selSource"];
    const { crude_source } = store.getState().data;
    payload.script_parameters.parameters.measure_identifier = crude_source.find((source) => source.id === source_id).measure_identifier;
    payload.script_parameters.parameters.start_time = "";
    payload.script_parameters.parameters.end_time = "";
    console.debug(payload);
    putDataApi("configuration/module/" + obs, payload)
      .then((res) => {
        setLoading(false);
        if (res === 401) return;
        toast.warn(res["data"]["message"] + " - " + res["data"]["module_instance_id"], {
          autoClose: false,
          closeOnClick: false,
        });
      })
      .catch((error) => {
        setLoading(false);
        console.debug(error);
      });
  };
  const handleCrosscorrelation = () => {
    setLoading(true);
    dataLoadingToast({ label: "Calcul en cours...", isLoading: true });

    const { current, timezone, target_variables, script_name } = structuredClone(indicator["moduleData"]);
    const payload = {
      current: current,
      target_variables: target_variables,
    };
    payload.current.time_params.start_time = formatBackend(payload.current.time_params.start_time, timezone);
    payload.current.time_params.end_time = formatBackend(payload.current.time_params.end_time, timezone);
    console.debug(payload);
    postIndicatorApi("indicator/" + script_name + "/" + obs, payload)
      .then((res) => {
        setLoading(false);
        dataLoadingToast({ success: true, successMessage: "Calcul terminé" });

        setResult(res.data);
        console.debug(res);
      })
      .catch((error) => {
        console.debug(error);
        dataLoadingToast({ error: true, errorMessage: error?.response?.data?.message });
        setLoading(false);
      });
  };
  const handlePca = () => {
    setLoading(true);
    toast.info("Calcul en cours..", { toastId: "loading-toast", containerId: "loadingToast", isLoading: true });

    const { current, timezone, pca_params, script_name } = structuredClone(indicator["moduleData"]);
    const payload = {
      current: current,
      pca_params: pca_params,
    };
    payload.current.time_params.start_time = formatBackend(payload.current.time_params.start_time, timezone);
    payload.current.time_params.end_time = formatBackend(payload.current.time_params.end_time, timezone);
    console.debug(payload);
    postIndicatorApi("indicator/" + script_name + "/" + obs, payload)
      .then((res) => {
        setLoading(false);
        toast.update("loading-toast", {
          render: "Calcul terminé",
          type: toast.TYPE.SUCCESS,
          hideProgressBar: false,
          autoClose: 5000,
          isLoading: false,
        });
        setResult(res.data);
        console.debug(res);
      })
      .catch((error) => {
        console.debug(error);
        toast.update("loading-toast", {
          render: <p className={"truncate"}> {error?.response?.data?.message}</p>,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
          autoClose: 5000,
          isLoading: false,
        });
        setLoading(false);
      });
  };

  function handleStatus() {
    setLoading(true);
    const { script_name, frequency, validity_days, time_slot, timezone, initial_timestamp, indicator_id, threshold_label, defining_status, shift, is_active } =
      structuredClone(indicator["moduleData"]);
    const payload = {
      script_name: script_name,
      script_parameters: {
        observed_system: obs,
        parameters: {
          start_time: "",
          end_time: "",
          indicator_id: indicator_id,
          threshold_label: threshold_label,
          defining_status: defining_status,
        },
        initial_timestamp: formatBackend(initial_timestamp, timezone),
        timezone: timezone,
        shift: shift,
        is_active: is_active,
      },
      frequency: frequency,
      validity_days: validity_days,
      time_slot: time_slot,
    };

    putDataApi("configuration/module/" + obs, payload)
      .then((res) => {
        setLoading(false);
        if (res === 401) return;
        toast.warn(res["data"]["message"] + " - " + res["data"]["module_instance_id"], {
          autoClose: false,
          closeOnClick: false,
        });
      })
      .catch((error) => console.debug(error));
  }

  const handleRequest = () => {
    switch (indicator["moduleData"]["name"]) {
      case 0:
        handleMonitoring();
        break;
      case 1:
        handleDetection();
        break;
      case 2:
        handleCorrelation();
        break;
      case 4:
        handleConfigurationAlerte();
        break;
      case 5:
        handleAnalytique();
        break;
      case 6:
        handleIndustrialHisto();
        break;
      case 7:
        handleTimeIndicator();
        break;
      case 8:
        handleOutlier();
        break;
      case 9:
        handleCrosscorrelation();
        break;
      case 10:
        handlePca();
        break;
      case 11:
        handleStatus();
        break;
      case 12:
        handleStatus();
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <div className={"flex justify-center"}>
        <PrimaryButton disabled={isloading} callback={handleRequest} label={label} />
      </div>
    </div>
  );
};
export default IndicatorRecap;
