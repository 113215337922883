import { useEffect, useState } from "react";

import TabGroup from "../../components/Tab/TabGroup";
import TableLogConnection from "../../components/Table/TableLogConnection";
import TableLogAction from "../../components/Table/TableLogAction";
import { COMPONENTS } from "../../constants/component";
import { useSelector } from "react-redux";

const Log = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [panel, setPanel] = useState([]);
  const [headers, setHeaders] = useState([]);
  useEffect(() => {
    const tmpPanel = [];
    const tmpHeaders = [];
    if (authorizedComponent[COMPONENTS.CONNECTION_LOG_DISPLAY] !== undefined) {
      tmpPanel.push({
        id: 1,
        component: <TableLogConnection />,
      });
      tmpHeaders.push("Connection");
    }
    if (authorizedComponent[COMPONENTS.ACTION_LOG_DISPLAY] !== undefined) {
      tmpPanel.push({
        id: 2,
        component: <TableLogAction />,
      });
      tmpHeaders.push("Action");
    }
    setPanel(tmpPanel);
    setHeaders(tmpHeaders);
  }, [authorizedComponent]);
  return (
    headers.length !== 0 && (
      <div>
        <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
          <h2 className="text-3xl font-semibold text-blue-900">Historique</h2>
          <TabGroup headers={headers} current={selectedIndex} changeCurrent={setSelectedIndex} panel={panel} />
        </div>
      </div>
    )
  );
};
export default Log;
