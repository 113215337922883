import { useState } from "react";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import TextInput from "../../components/Selectors/TextInput";
import { useSelector } from "react-redux";
import { makeSelectField } from "../../utils/common";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons/Button";
import store from "../../store";
import { push } from "../../reducers/dataSlice";
import { postUserApi } from "../../axios/UserManagementApi";

const AdminCreateUser = () => {
  const { structures, profiles } = useSelector((state) => state.data);

  const [valid, setValid] = useState({
    email: false,
    lastName: false,
    firstName: false,
  });
  const history = useNavigate();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    id_role: 3, //default: user have the user role
    structure: undefined,
  });
  const onChange = (name, value, isValid) => {
    setData({
      ...data,
      [name]: value,
    });
    if (isValid !== undefined)
      setValid({
        ...valid,
        [name]: isValid,
      });
  };

  const handleAdd = () => {
    setLoading(true);
    const structure_id = sessionStorage.getItem("structure_id");

    postUserApi("management?management_action=adduser&structure_id=" + structure_id, data)
      .then((res) => {
        if (res.data.status === "success") {
          data["id"] = res.data["new_user_id"];
          store.dispatch(
            push({
              type: "users",
              data: data,
            }),
          );
        }
        setLoading(false);
        history("/admin/users");
      })
      .catch((error) => {
        console.debug(error);
        setLoading(false);
      });
  };
  console.debug(data.structure);
  const disabled = Object.values(valid).every((value) => value === true);
  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <div className="flex gap-x-1.5">
        <IconButton bg={true} Icon={<Prev size={40} />} value={""} callback={() => history(-1)} />
        <h2 className="text-3xl font-semibold text-blue-900">Création d&apos;un utilisateur</h2>
      </div>

      <div className={"mt-2"}>
        <div className={"grid grid-cols-1 gap-4 lg:grid-cols-3"}>
          <TextInput required={true} name={"email"} value={data.email} onChange={onChange} label={"Adresse email"} type={"email"} />
          <TextInput required={true} name={"firstName"} value={data.firstName} onChange={onChange} label={"Prénom"} type={"text"} />
          <TextInput required={true} name={"lastName"} value={data.lastName} onChange={onChange} label={"Nom"} type={"text"} />
          <div>
            <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">Profil utilisateur</label>
            <Select
              onChange={(newValue) => onChange("id_role", newValue.value)}
              value={makeSelectField(profiles, ["name"]).find((profile) => profile.value === data.id_role)}
              options={makeSelectField(profiles, ["name"])}
            />
          </div>
          <div>
            <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">Structure</label>
            <Select
              isClearable
              placeholder={"Aucune structure"}
              onChange={(newValue) => onChange("structure", newValue?.value)}
              value={makeSelectField(structures, ["name", "description"]).find((structure) => structure.value === data.structure)}
              options={makeSelectField(structures, ["name", "description"])}
            />
          </div>
        </div>
      </div>
      <div className={"flex justify-end gap-2"}>
        <SecondaryButton label={"Annuler"} disabled={loading} callback={() => history(-1)} />
        <PrimaryButton disabled={!disabled || loading} label={"Ajouter"} isLoading={loading} callback={() => handleAdd()} />
      </div>
    </div>
  );
};
export default AdminCreateUser;
