import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getDataBlocks, variableField } from "./utils";
import { setPredictors, setReconstructionParams } from "../../reducers/indicatorSlice";

import { getMeasureIdentifierFromCrudeSourceId, makeSelectField, makeVariableField } from "../../utils/common";
import LabelTooltip from "../../components/Tooltip/LabelTooltip";

function Predictors() {
  const { crude_source } = useSelector((state) => state.data);
  const { selected_crude_source } = useSelector((state) => state.indicator);
  const { predictors } = useSelector((state) => state.indicator.moduleData.reconstruction_params);
  const dispatch = useDispatch();
  return (
    <div className={""}>
      <div className={"grid grid-cols-2 gap-x-1.5"}>
        <LabelTooltip label={"Predicteur(s) du modèle"} tip={"Défini(s) par le modèle selectionné"} />
        <LabelTooltip label={"Predicteur(s) d'usage"} tip={"Défini(s) par les sources de données selectionnés"} />
      </div>

      {Object.entries(predictors).map((variable, index) => (
        <div key={index} className={"mb-1 grid grid-cols-2 gap-x-1.5"}>
          <Select isDisabled={true} isSearchable={false} value={{ value: variable[0], label: variable[0] }} />
          <Select
            value={{ value: variable[1].usage, label: variable[1].usage }}
            onChange={(newValue) =>
              dispatch(
                setPredictors({
                  key: variable[0],
                  value: { ...variable[1], usage: newValue.value },
                }),
              )
            }
            options={variableField(getDataBlocks(selected_crude_source, crude_source))}
          />
        </div>
      ))}
    </div>
  );
}
export function ReconstructionParams() {
  const { crude_source } = useSelector((state) => state.data);
  const { selected_crude_source, moduleData } = useSelector((state) => state.indicator);
  const { reconstruction_params } = moduleData;
  const { training_model } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const { target, model_id } = reconstruction_params;
  const { origin, usage } = target;
  const handleTargetChange = (key, value) => {
    dispatch(
      setReconstructionParams({
        key: "target",
        value: { ...reconstruction_params.target, [key]: value },
      }),
    );
  };
  const handleDateFieldChange = (newValue) => {
    dispatch(
      setReconstructionParams({
        key: "target",
        value: { ...reconstruction_params.target, time_field: newValue.value, index_measure: getMeasureIdentifierFromCrudeSourceId(newValue.crude_source_id) },
      }),
    );
  };
  const dateFieldOptions = selected_crude_source
    .map((crude_source_id) => makeVariableField(3, crude_source_id, crude_source.find((cs) => cs.id === crude_source_id).measure))
    .flat();
  return (
    <div className={"grid grid-cols-1 gap-2 py-2"}>
      <Select
        value={makeSelectField(training_model, ["model_name", "method_name"]).find((value) => value.value === model_id)}
        onChange={(newValue) => {
          dispatch(setReconstructionParams({ key: "model_id", value: newValue.value }));
          const updates = training_model.find((value) => value.id === newValue.value);
          dispatch(setReconstructionParams({ key: "target", value: { origin: updates.target, usage: updates.target } }));
          const predict = {};
          Object.entries(updates.predictors).forEach((entry) => {
            predict[entry[0]] = { usage: entry[0], orders: entry[1] };
          });
          dispatch(setReconstructionParams({ key: "predictors", value: predict }));
        }}
        options={makeSelectField(training_model, ["model_name", "method_name"])}
      />
      <div className={"grid grid-cols-2 gap-x-1.5"}>
        <div className={"col-span-2"}>
          <LabelTooltip label={"Variable temporelle"} tip={"Variable temporelle utilisée pour réaliser la reconstruction"} />
          <Select onChange={handleDateFieldChange} options={dateFieldOptions} />
        </div>
        <div>
          <LabelTooltip label={"Cible d'origine"} tip={"Cible défini par le modèle selectionné"} />
          <Select isDisabled={true} isSearchable={false} value={{ value: origin, label: origin }} />
        </div>

        <div>
          <LabelTooltip label={"Cible d'usage"} tip={"Cible défini par la source(s) de données selectionné"} />
          <Select
            isSearchable={false}
            menuPosition={"fixed"}
            value={{ value: usage, label: usage }}
            onChange={(newValue) => handleTargetChange("usage", newValue.value)}
            options={variableField(getDataBlocks(selected_crude_source, crude_source))}
          />
        </div>
      </div>
      <Predictors />
    </div>
  );
}
