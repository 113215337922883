import FormulaChart from "../../components/Charts/FormulaChart";

export function FormulaResult({ url, array }) {
  return (
    <div className="flex flex-col pt-4">
      <div className={"grid grid-cols-3 gap-x-1.5"}>
        <iframe className="h-[40vh] w-full " src={url} />

        <div className={"col-span-2"}>
          <FormulaChart data={array} />
        </div>
      </div>
    </div>
  );
}
