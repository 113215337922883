import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isEmptyObject } from "../../utils/common";
import { getDataApi } from "../../axios/DataManagementApi";
import BasicTooltip from "../Tooltip/BasicTooltip";
import Icon from "@mdi/react";
import { mdiEyeOutline } from "@mdi/js";
import { toast } from "react-toastify";

export function ObservedSystemTimeLine() {
  const history = useNavigate();
  const cache = useRef(sessionStorage.getItem("observed_system_id"));
  const { observed_system } = useSelector((state) => state.selected);
  const observed_system_name = useSelector((state) => state.data.observed_system);
  const [url, setUrl] = useState(sessionStorage.getItem("observed_system_status_graph") ?? "");
  console.debug(url);
  useEffect(() => {
    if (isEmptyObject(observed_system) || cache.current === observed_system) return;
    getDataApi("status/" + observed_system + "?active=true&histo=true")
      .then((res) => {
        setUrl(res.data.data.panel_url);
        cache.current = observed_system;
        sessionStorage.setItem("observed_system_status_graph", res.data.data.panel_url);
      })
      .catch(() => {
        toast.error("Pas de status pour ce processus monitoré");
        setUrl("");
        cache.current = observed_system;
        sessionStorage.setItem("observed_system_status_graph", "");
      });
  }, [observed_system]);
  if (isEmptyObject(url)) return;
  return (
    <div className="col-span-2 m-4 h-[450px]  justify-center rounded-lg border border-gray-200 bg-white">
      <div className={"flex justify-between font-semibold text-blue-900"}>
        <span className={"basis-1/4"} />
        <div className={"basis-1/2"}>
          <p className={"float-left mr-1"}>Suivi de l’état de la performance opérationnelle</p>
          <BasicTooltip
            tip={
              <p>
                Evolution dans le temps de l’état de {"<" + observed_system_name.find((obs) => obs.id === observed_system).name + ">"} en fonction de [nom du
                statut]
              </p>
            }
          />
        </div>
        <button onClick={() => history("/observed_system/status/" + observed_system)}>
          <Icon path={mdiEyeOutline} className={"w-10 cursor-pointer px-2 text-blue-900 hover:text-blue-950"} />
        </button>
      </div>
      <iframe className="h-[400px] w-full" src={url} />
    </div>
  );
}
