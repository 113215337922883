import TextInput from "../Selectors/TextInput";
import { handleVisualizationFormatChange } from "../../utils/fileUtils";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { isEmptyString } from "../../utils/common";
import { toast } from "react-toastify";
import { PrimaryButton } from "../Buttons/Button";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import Select from "react-select";

const CategoricalParam = ({ data, setData, id }) => {
  const { t } = useTranslation(["common"]);
  const onVisualizationChange = (name, value) => {
    handleVisualizationFormatChange(value, id, data, setData, name);
  };
  return (
    <div>
      <div className={"grid grid-cols-3 gap-x-4 rounded-t bg-blue-900"}>
        <div className={"group  place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>
          {t("mapping_information")}
        </div>
        <div className={"group col-span-2 place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>
          {t("visualization_format")}
        </div>
      </div>
      <div className={"grid grid-cols-3 gap-x-4 py-0.5 odd:bg-gray-100 even:bg-gray-50"}>
        <div>
          <p className={"pt-6 text-xl"}>-</p>
        </div>
        <div className={"col-span-2 grid grid-cols-2"}>
          <TextInput
            name={"description"}
            label={t("description")}
            onChange={(name, value) => handleVisualizationFormatChange(value, id, data, setData, name)}
            value={data.col_type[id].visualization_format.description}
          />
          <LabelEditor row={data.col_type[id]} onVisualizationChange={onVisualizationChange} />
        </div>
      </div>
    </div>
  );
};
export default CategoricalParam;

const LabelEditor = ({ row, onVisualizationChange }) => {
  const { t } = useTranslation(["common"]);
  const [label, setLabel] = useState("");

  const [description, setDescription] = useState("");
  const addRow = () => {
    const updates = structuredClone(row.visualization_format.label);
    if (isEmptyString(label)) {
      toast.warn(t("EMPTY_LABEL"));
      return;
    }
    updates.push({ label: label, description: description });
    onVisualizationChange("label", updates);
    setDescription("");
    setLabel("");
  };
  const removeRow = (label) => {
    const updates = structuredClone(row.visualization_format.label).filter((value) => value.label !== label);
    onVisualizationChange("label", updates);
  };
  const makeOptions = (label) => {
    return { value: label.label, label: label.label };
  };
  const options = row.visualization_format.label.map((label) => makeOptions(label));
  return (
    <div>
      <label className="text-sm font-bold tracking-wide text-gray-700">{t("label")}</label>
      <div>
        <div className={"flex gap-x-0.5"}>
          <div className={"grid grid-cols-2 gap-x-0.5"}>
            <TextInput placeholder={t("value")} value={label} onChange={(name, value) => setLabel(value)} />
            <TextInput placeholder={t("description")} value={description} onChange={(name, value) => setDescription(value)} />
          </div>
          <PrimaryButton label={<Icon path={mdiPlus} size={1} />} callback={() => addRow()} />
        </div>
        <Select
          placeholder={"-"}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          menuIsOpen={false}
          options={options}
          value={options}
          isMulti={true}
          isSearchable={false}
          isClearable={false}
          onChange={(newValue, actionMeta) => removeRow(actionMeta.removedValue.label)}
        />
      </div>
    </div>
  );
};
