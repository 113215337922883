import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { canRenderComponent, makeSelectField } from "../../utils/common";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import LabelTooltip from "../../components/Tooltip/LabelTooltip";
import { COMPONENTS } from "../../constants/component";
import { PrimaryButton } from "../../components/Buttons/Button";
import { setLink, setPayload } from "../../reducers/graphSlice";
import { postDataApi } from "../../axios/DataManagementApi";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";

const initGraphValue = {
  data_sources: [], // {data_source_id,timestamp_field,variables}
  selectedSource: [],
  link: "",
  graphic_title: "",
  variables: [],
  selectedVariable: [],
  selectedConfiguration: [],
  selectedTimestamp: [],
  timezone: {
    label: Intl.DateTimeFormat().resolvedOptions().timeZone,
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  mode: { label: "linéaire", value: "linear" },
  graph_span: { label: "7 dernières jours", value: "long" },
};
const GraphSimple = () => {
  const [isLoading, setIsLoading] = useState(false);
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [authorizedGraph, setAuthorizedGraph] = useState(canRenderComponent(COMPONENTS.GRAPHIC_CREATION));

  useEffect(
    () => () => {
      setAuthorizedGraph(canRenderComponent(COMPONENTS.GRAPHIC_CREATION));
    },
    [authorizedComponent],
  );
  const dispatch = useDispatch();
  const history = useNavigate();

  const { crude_source } = useSelector((state) => state?.data);
  const [data, setData] = useState(initGraphValue);
  const onChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const makeOptions = (type) => {
    const variableData = data["selectedSource"].length === 0 ? makeSelectField(crude_source, ["name"]) : structuredClone(data["selectedSource"]);
    const group = [];
    variableData.forEach((source) => {
      const options = [];
      if (type === 1)
        options.push({
          label: "timestamp interne",
          value: source.value + "_internal",
          crude_source_id: source.value,
        });
      const to_add = crude_source.find((crudeSource) => crudeSource.id === source.value);
      Object.keys(to_add.measure).forEach((variable) => {
        if (type === 1 && to_add.measure[variable]["visualization_type"] === "date")
          options.push({
            label: variable,
            value: source.value + "_" + variable,
            crude_source_id: source.value,
          });
        else if (
          (type === 0 && to_add.measure[variable]["visualization_type"] === "dynamic_numerical") ||
          to_add.measure[variable]["visualization_type"] === "semistatic_numerical"
        )
          options.push({
            label: variable,
            value: source.value + "_" + variable,
            crude_source_id: source.value,
          });
      });
      group.push({
        label: source.label,
        options: options,
        crude_source_id: source.value,
      });
    });
    return group;
  };
  const defValue = (source, variable) => {
    return {
      name: variable.value.replace(source + "_", ""),
      anomaly_display: false,
      anomaly_parameters: [],
      nocheck_display: true,
      threshold_display: false,
      threshold_parameters: {},
      configured_sources: [],
    };
  };
  const createGraph = () => {
    setIsLoading(true);
    dataLoadingToast({ label: "Création du graphique", isLoading: true });
    const { selectedSource, selectedVariable } = data;
    const data_sources = [];
    const sourceToFilter = selectedSource.length === 0 ? makeSelectField(crude_source, ["name"]) : selectedSource;
    sourceToFilter.forEach((source) => {
      const crude_source_id = source.value;
      const crude_source_name = source.label;
      const crude_source_info = crude_source.find((cs) => cs.id === crude_source_id);
      const measureKeys = Object.keys(crude_source_info.measure);
      const variables = [];
      const variablesToFilter =
        selectedVariable.length === 0 ? makeOptions(0).filter((options) => options.label === crude_source_name)[0].options : selectedVariable;
      variablesToFilter.filter((value) => value.crude_source_id === crude_source_id).forEach((variable) => variables.push(defValue(crude_source_id, variable)));
      const data_source = {
        data_source_id: source.value,
        timestamp_field: measureKeys.find((key) => crude_source_info.measure[key].visualization_type === "date") ?? "internal",
        variables: variables,
      };
      data_sources.push(data_source);
    });
    const payload = {
      data_sources: data_sources,
      graphic_title: "Courbes: " + sourceToFilter.map((value) => value.label).join(" - "),
      scale_distribution: "logarithm",
      graph_span: "long",
      timezone: "Europe/Paris",
    };
    const test = false;
    if (!test)
      postDataApi("graphic/complex_curve/" + sessionStorage.getItem("observed_system_id"), payload)
        .then((res) => {
          setIsLoading(false);
          dispatch(setPayload(payload));
          dispatch(setLink(res.data["creation_data"]["panel_url"]));
          history("/analytics/graph/view");
          dataLoadingToast({ successMessage: "Création terminé", success: true });
        })
        .catch((error) => {
          setIsLoading(false);
          console.debug(error);
          dataLoadingToast({ errorMessage: error.response.data.message, error: true });
        });
    else setIsLoading(false);
  };

  const sourceSelect = (newValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      const updates = [...data.data_sources];
      const { value } = actionMeta.option;
      updates.push({
        data_source_id: value,
        variables: [],
      });
      setData({
        ...data,
        data_sources: updates,
        selectedSource: newValue,
      });
    }
    if (actionMeta.action === "remove-value") {
      const { value } = actionMeta.removedValue;

      const dataSourcesUpdates = data.data_sources.filter((data_source) => data_source.data_source_id !== value);
      const variableUpdates = data.selectedVariable.filter((selected) => selected.crude_source_id !== value);
      const timestampUpdates = data.selectedTimestamp.filter((selected) => selected.crude_source_id !== value);
      setData({
        ...data,
        data_sources: dataSourcesUpdates,
        selectedSource: newValue,
        selectedVariable: variableUpdates,
        selectedTimestamp: timestampUpdates,
      });
    }
    if (actionMeta.action === "clear") {
      setData({
        ...data,
        data_sources: [],
        selectedSource: [],
        selectedVariable: [],
        selectedTimestamp: [],
      });
    }
  };
  return (
    authorizedGraph && (
      <div className="m-8">
        <div className="block rounded border border-gray-200 bg-white px-4 py-2">
          <div className={"py-0.5"}>
            <LabelTooltip
              label={"Source de données"}
              tip={
                <div>
                  <p>Source de données considérées.</p> <p> Une ou plusieurs peuvent être sélectionnées.</p>
                </div>
              }
            />
            <Select
              placeholder={"Toutes..."}
              onChange={(newValue, actionMeta) => sourceSelect(newValue, actionMeta)}
              isMulti
              value={data["selectedSource"]}
              options={makeSelectField(crude_source, ["name"])}
            />
          </div>
          <div className={"py-0.5"}>
            <LabelTooltip
              label={"Variable à considérer"}
              tip={
                <div>
                  <p>Variables qui seront considérées pour réaliser les courbes du graphique. </p> <p> Une ou plusieurs peuvent être sélectionnées.</p>
                </div>
              }
            />
            <Select
              placeholder={"Toutes..."}
              isMulti
              onChange={(newValue) => onChange("selectedVariable", newValue)}
              value={data["selectedVariable"]}
              options={makeOptions(0)}
            />
          </div>
        </div>

        <div className="col-span-5 flex justify-center">
          <PrimaryButton label={"Créer"} disabled={isLoading} callback={createGraph} />
        </div>
      </div>
    )
  );
};
export default GraphSimple;
