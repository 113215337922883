import { useEffect, useState } from "react";

import { checkFileType } from "../../utils/fileUtils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { canRenderComponent } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import FileInformationList from "../../components/Table/FileInformationList";

const FileUpload = ({ data, setData, file, setFile }) => {
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [authorizedFileUpload, setAuthorizedFileUpload] = useState(canRenderComponent(COMPONENTS.FILE_UPLOAD));
  useEffect(
    () => () => {
      setAuthorizedFileUpload(canRenderComponent(COMPONENTS.FILE_UPLOAD));
    },
    [authorizedComponent],
  );

  const handleClear = () => {
    setFile(undefined);
    setData({
      col_type: [],
      error: [],
      preview: [],
      data: [],
    });
  };
  const handlefileUpload = (event) => {
    if (event.target.files[0].size > process.env.REACT_APP_FILE_MAX_SIZE) {
      toast.error("File is too big. \n Size must be less than " + process.env.REACT_APP_FILE_MAX_SIZE);
      setData({ col_type: [], error: [], preview: [], data: [] });
      return;
    }
    setFile(event.target.files[0]);
    setData({ col_type: [], error: [], preview: [], data: [] });
    checkFileType(event.target.files[0], { col_type: [], error: [], preview: [], data: [] }, setData);
  };

  return authorizedFileUpload && <FileInformationList file={file} handleClear={handleClear} data={data} setData={setData} onFileChange={handlefileUpload} />;
};

export default FileUpload;
