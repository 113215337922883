import axios from "axios";
import store from "../store";
import { logout, token } from "../reducers/ApplicationSlice";
import { toast } from "react-toastify";

const axiosClient = axios.create();
const { REACT_APP_INDICATOR_HOST, REACT_APP_API_TOKEN } = process.env;
axiosClient.defaults.baseURL = REACT_APP_INDICATOR_HOST;

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: REACT_APP_API_TOKEN,
};

axiosClient.interceptors.response.use(
  function (response) {
    //updare refresh token
    store.dispatch(token(response.headers["refresh-token"]));
    sessionStorage.setItem("token", response.headers["refresh-token"]);
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      //Add Logic to
      //1. Redirect to login page or
      //2. Request refresh token
      toast.dismiss("loading_data");
      store.dispatch(logout());
      sessionStorage.clear();
    }
    return Promise.reject(error);
  },
);
export function postIndicatorApi(URL, payload) {
  axiosClient.defaults.headers["Authorization"] = "Bearer " + store.getState()["application"]["token"];
  return axiosClient.post(`/${URL}`, payload).then((response) => response);
}
