import { flexRender } from "@tanstack/react-table";

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

export function TableHeader({ table }) {
  return (
    <thead className="bg-blue-700">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th key={header.id} colSpan={header.colSpan} className="group px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">
              {header.isPlaceholder ? null : (
                <>
                  <div
                    {...{
                      className: header.column.getCanSort() ? "cursor-pointer select-none flex items-center justify-between" : "",
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: <ChevronUpIcon className="h-4 w-4 text-gray-400" />,
                      desc: <ChevronDownIcon className="h-4 w-4 text-gray-400" />,
                    }[header.column.getIsSorted()] ?? null}
                  </div>
                </>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}
