import { useEffect, useState } from "react";

import TextInput from "../../components/Selectors/TextInput";

import Checkbox from "../../components/Selectors/Checkbox";
import SourceParametersModal from "../../components/Modals/SourceParametersModal";
import { sourceField } from "../../utils/field";

import { canRenderComponent, capitalizeFirstLetter, isEmptyString, makeSelectField } from "../../utils/common";
import SelectAdd from "../../components/Selectors/SelectAdd";

import { useSelector } from "react-redux";
import Select from "react-select";
import MappingValueModal from "../../components/Modals/MappingValueModal";
import { COMPONENTS } from "../../constants/component";
import TimezoneSelect from "react-timezone-select";
import dayjs from "dayjs";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import { useTranslation } from "react-i18next";
import HorizontalLine from "../../components/HorizontalLine";
import NumericInput from "../../components/Inputs/NumericInput";
import { PrimaryButton } from "../../components/Buttons/Button";
import { postDataApi } from "../../axios/DataManagementApi";

const ConfiguredSourceData = ({
  source,
  setSource,
  mappingValue,
  updateMappingValue,
  sourceParametersValue,
  updateSourceParametersValue,
  isMulti = false,
  selectObs = true,
  selectMappingValues = true,
}) => {
  const { t } = useTranslation(["common", "button"]);

  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [authorizedDataSource, setAuthorizedDataSource] = useState(
    canRenderComponent(COMPONENTS.DATA_SOURCE_CREATION) || canRenderComponent(COMPONENTS.FILE_UPLOAD),
  );
  useEffect(
    () => () => {
      setAuthorizedDataSource(canRenderComponent(COMPONENTS.DATA_SOURCE_CREATION) || canRenderComponent(COMPONENTS.FILE_UPLOAD));
    },
    [authorizedComponent],
  );
  const { storage_system, observed_system } = useSelector((state) => state.data);
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [SpModal, setSpModal] = useState(false);
  const [sameStorageType, setSameStorageType] = useState(true);
  const onSelectChange = (selected, options) => {
    onChange(options.name, selected.value);
  };
  const onChange = (name, value) => {
    setSource({
      ...source,
      [name]: value,
    });
  };
  const onCheckboxChange = (e) => {
    const name = e.target.name;
    setSource({
      ...source,
      [name]: e.target.checked,
    });
  };
  const onMeasureChange = (checked) => {
    setSameStorageType(!sameStorageType);
    if (checked) {
      onChange("storage_system_id", "");
    }
  };

  const handleAdd = () => {
    dataLoadingToast({ isLoading: true, label: "Création de la source de donnée..." });
    setLoading(true);
    const mapping_type = {};
    const mapping_information = {};
    const visualization_format = {};
    const data_source_param = [];
    sourceParametersValue.forEach((param) => {
      data_source_param.push({
        value: param.type === "date" ? dayjs(param.value.date).tz(param.value.name).format("YYYY-MM-DDTHH:mm:ss.SSSZ") : param.value,
        name: param.name,
        type: param.type,
      });
    });
    mappingValue.forEach((val) => {
      if (val.locked) {
        mapping_type[val.name] = val.type;
        mapping_information[val.name] = val.mapping_information;
        visualization_format[val.name] = val.visualization_format;
      }
    });

    const data = {
      name: source.name,
      description: source.description,
      source_type: source.source_type,
      configured_source_description: source.configured_source_description,
      system: source.system,
      mapping_type: mapping_type,
      mapping_information: mapping_information,
      visualization_format: visualization_format,
      is_active: source.is_active,
      alert_delay: parseInt(source.alert_delay),
      source_parameters: data_source_param,
      storage_system_id: source.storage_system_id,
      id: source.id === "" ? null : source.id,
      timezone: source.timezone,
      locale: source.locale,
    };
    console.debug(data);
    postDataApi("configuration/data", data)
      .then(() => {
        dataLoadingToast({ success: true, successMessage: "La source de données " + data.name + " a été créé" });
        setLoading(false);
      })
      .catch((error) => {
        console.debug(error);
        dataLoadingToast({ error: true, errorMessage: "La création a échoué" });
        setLoading(false);
      });
  };
  const canAdd =
    isEmptyString(source["name"]) ||
    isEmptyString(source["description"]) ||
    isEmptyString(source["source_type"]) ||
    isEmptyString(source["configured_source_description"]);
  return (
    authorizedDataSource && (
      <div className="p-8">
        <div className="w-full rounded-lg border-blue-900 bg-white shadow-md">
          <h3 className="px-4 pt-4 text-2xl font-semibold text-blue-900">{capitalizeFirstLetter(t("data_source"))}</h3>
          <h2 className="px-6 pb-4">{capitalizeFirstLetter(t("DATA_SOURCE_DESC"))}</h2>
          <HorizontalLine />
          <div className="grid grid-cols-1 gap-4 p-4 lg:grid-cols-3">
            {selectObs && (
              <div>
                <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("observed_system"))}</label>
                <Select
                  value={makeSelectField(observed_system, ["name", "description"]).find((value) => value.value === source?.system)}
                  options={makeSelectField(observed_system, ["name", "description"])}
                  onChange={onSelectChange}
                  name="system"
                  id="system"
                />
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 gap-4 p-4 lg:grid-cols-3">
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("source_type"))}</label>
              <Select options={sourceField} onChange={onSelectChange} name="source_type" id="source_type" isSearchable={false} />
            </div>
            <div>
              <TextInput name={"name"} label={capitalizeFirstLetter(t("name"))} value={source["name"]} onChange={onChange} />
            </div>
            <div>
              <TextInput name={"description"} value={source["description"]} onChange={onChange} label={capitalizeFirstLetter(t("description"))} />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 p-4 lg:grid-cols-3">
            <div>
              <TextInput name={"id"} label={"id"} value={source["id"]} onChange={onChange} />
            </div>
            <div>
              <TextInput
                name={"configured_source_description"}
                value={source["configured_source_description"]}
                label={capitalizeFirstLetter(t("conf_description"))}
                onChange={onChange}
              />
            </div>
            <div>
              <NumericInput min={0} name={"alert_delay"} label={capitalizeFirstLetter(t("alert_delay"))} value={source["alert_delay"]} onChange={onChange} />
            </div>
            <div>
              <label>
                <Checkbox
                  name="SameStorageType"
                  checked={sameStorageType}
                  label={capitalizeFirstLetter(t("same_storage"))}
                  tooltip={
                    <ul>
                      <li>
                        <span className="font-semibold text-gray-100">Activé:</span> Les mesures sont stockées dans la même instance.
                      </li>
                      <li>
                        <span className="font-semibold text-gray-100">Non activé </span> Les mesures sont stockées dans une autre instance sélectionnable.
                      </li>
                    </ul>
                  }
                  onChange={(e) => {
                    onMeasureChange(e.target.checked);
                  }}
                />
              </label>
              {!sameStorageType && (
                <SelectAdd
                  selectField={makeSelectField(storage_system, ["name", "description", "storage_type"])}
                  callback={onChange}
                  name="storage_system_id"
                  value={makeSelectField(storage_system, ["name", "description"]).find((value) => value.value === source["storage_system_id"])}
                />
              )}
            </div>
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("source_parameters"))}</label>
              <SourceParametersModal
                openModal={SpModal}
                sourceParametersValue={sourceParametersValue}
                updateSourceParametersValue={updateSourceParametersValue}
                callback={() => {
                  setSpModal(!SpModal);
                }}
              />
            </div>
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("state"))}</label>
              <div className={"py-2"}>
                <Checkbox
                  checked={source["is_active"]}
                  label={source["is_active"] ? t("active") : t("inactive")}
                  name={"is_active"}
                  onChange={onCheckboxChange}
                />
              </div>
            </div>
            {selectMappingValues && (
              <div>
                <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("mapping_value"))}</label>
                <MappingValueModal
                  openModal={modal}
                  mappingValue={mappingValue}
                  updateMappingValue={updateMappingValue}
                  callback={() => {
                    setModal(!modal);
                  }}
                />
              </div>
            )}
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("timezone"))}</label>
              <TimezoneSelect
                value={source.timezone}
                onChange={(timezone) => {
                  onChange("timezone", timezone.value);
                }}
              />
            </div>
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("localisation"))}</label>

              <Select
                isSearchable={false}
                isDisabled={true}
                options={[{ value: "fr", label: "FR" }]}
                value={{ value: "fr", label: "FR" }}
                onChange={(newValue) => {
                  onChange("locale", newValue.value);
                }}
              />
            </div>
          </div>
          {!isMulti && (
            <div className="px-4 pb-4 lg:flex lg:justify-end">
              <PrimaryButton isLoading={isLoading} label={t("create", { ns: "button" })} callback={handleAdd} disabled={canAdd} />
            </div>
          )}
        </div>
      </div>
    )
  );
};
export default ConfiguredSourceData;
