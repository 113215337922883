import store from "../store";
import ReactJson from "@microlink/react-json-view";

export const isEmptyString = (string) => {
  if (string) {
    return string === "";
  } else {
    return true;
  }
};

export const makeSelectField = (data, fields, all = false) => {
  if (data === undefined || data.length === 0) return [];
  const options = all ? [{ value: "all", label: "Toutes les options" }] : [];
  data.forEach((value) => {
    const label = [];
    fields.forEach((field) => {
      label.push(value[field]);
    });
    options.push({ value: value["id"], label: label.join(" - ") });
  });
  return options;
};

export function findDateField(measures) {
  for (const key in measures) {
    if (Object.prototype.hasOwnProperty.call(measures, key) && measures[key].visualization_type === "date") {
      return key;
    }
  }
  return null; // Return null if no matching key is found
}

export const makeVariableField = (type, crude_source_id, variables, isGroup = true) => {
  if (crude_source_id === undefined) return [];

  const filterByVisualizationType = (variable) => {
    const visualizationType = variables[variable]["visualization_type"];
    return (
      (type === 5 && (visualizationType === "nominal_categorical" || visualizationType === "ordered_categorical")) ||
      (type === 1 && visualizationType === "date") ||
      (type === 0 && (visualizationType === "dynamic_numerical" || visualizationType === "semistatic_numerical")) ||
      (type === 3 && visualizationType === "date") ||
      type === 4 ||
      type === 6
    );
  };

  const createOption = (variable) => {
    return {
      label: variable,
      value: type === 3 || type === 6 ? variable : crude_source_id + "_" + variable,
      crude_source_id: crude_source_id,
    };
  };

  const options = Object.keys(variables).filter(filterByVisualizationType).map(createOption);

  if (type === 1) {
    options.push({
      label: "internal",
      value: crude_source_id + "_internal",
      crude_source_id: crude_source_id,
    });
  }

  if (!isGroup) return options;

  return [
    {
      label: crude_source_id,
      options: options,
      crude_source_id: crude_source_id,
    },
  ];
};

export const getOptionByValue = (data, value) => {
  for (let obj of data) {
    for (let option of obj.options) {
      if (option.value === value) {
        return option;
      }
    }
  }
  return null;
};

export const parseCategorical = (format, data) => {
  switch (format) {
    case "ordered": {
      const split = data.split(",");
      const trimmed = split.map((x) => x.trim());
      const prep = trimmed.map((x) => x.split(":"));
      const dict = {};
      prep.forEach((x) => {
        dict[parseInt(x[0])] = x[1];
      });
      return dict;
    }
    case "nominal": {
      const split = data.split(",");
      const trimmed = split.map((x) => x.trim());
      const dict = {};
      let index = 0;
      trimmed.sort().forEach((x) => {
        dict[index] = x;
        ++index;
      });

      return dict;
    }
    default:
      break;
  }
};

export const canRenderComponent = (component_id) => {
  const state = store.getState();
  const authorizedComponent = state.application.authorized_components;
  return authorizedComponent[component_id] !== undefined;
};
export const canRenderAction = (action_flags, component_id) => {
  const state = store.getState();
  const authorizedComponent = state.application.authorized_components;
  const action = authorizedComponent[component_id];
  return (action & action_flags) === action_flags;
};
export const renderComponent = (component) => {
  const { component_id } = component.props;
  const state = store.getState();
  const authorizedComponent = state.application.authorized_components;
  if (authorizedComponent[component_id] !== undefined) {
    return component;
  }
};

export const getSourceWithMeasure = (source) => {
  const state = store.getState();
  const sources = state.data[source];
  return sources.filter((value) => value.observed_system_id === sessionStorage.getItem("observed_system_id") && value.measure !== undefined);
};

export const isEmptyObject = (obj) => {
  if (obj === undefined) return true;
  return Object.keys(obj).length === 0;
};
export const hasSeconds = (dateString) => /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(dateString);

export const getConfigurationsFromCrudeSource = (crude_source_id) => {
  const state = store.getState();
  const allConfigurations = state.data.configured_source;
  console.debug(allConfigurations);
  return allConfigurations.filter((configuration) => crude_source_id === configuration.crude_source);
};

export const getPastDate = (seconds) => {
  let date = new Date();
  return new Date(date.getTime() - seconds * 1000);
};

export const transitionClasses = {
  enter: "transition ease-out duration-200",
  enterFrom: "opacity-0 -translate-y-1",
  enterTo: "opacity-100 translate-y-0",
  leave: "transition ease-in duration-150",
  leaveFrom: "opacity-100 translate-y-0",
  leaveTo: "opacity-0 -translate-y-1",
};

export const dataList = (data) => {
  const transformedList = [];
  for (const key in data) {
    const obj = data[key];
    transformedList.push({
      id: key,
      ...obj,
    });
  }
  return transformedList;
};

export const getUserPreferences = () => {
  const state = store.getState();
  return state.application.profile.preference;
};

export function capitalizeFirstLetter(sentence) {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export const DisplayJson = ({ data }) => (
  <div className={"rounded border border-blue-950 bg-white p-1 shadow-lg"}>
    <ReactJson name={false} theme={"shapeshifter:inverted"} src={data} />
  </div>
);

export const getMeasureIdentifierFromCrudeSourceId = (crude_source_id) => {
  const { crude_source } = store.getState().data;
  return crude_source.find((cs) => cs.id === crude_source_id).measure_identifier;
};
