import { useSelector } from "react-redux";
import EventFormulaVerification from "./EventFormulaVerification";

const ComputationParamCreation = () => {
  const histo = useSelector((state) => state.event.histo);

  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <h2 className="text-2xl font-semibold text-blue-900">Paramètres de calcul</h2>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h2 className="text-xl font-semibold text-blue-900">Courant</h2>
          <EventFormulaVerification type={"current"} />
        </div>
        {histo && (
          <div>
            <h2 className="text-xl font-semibold text-blue-900">Historique</h2>
            <EventFormulaVerification type={"historical"} />
          </div>
        )}
      </div>
    </div>
  );
};
export default ComputationParamCreation;
