import { useDispatch, useSelector } from "react-redux";
import TimeInput from "../../components/Inputs/TimeInput";
import { setCrudeSource, setCurrentTimeParams, setModuleData, setReconstructionParams } from "../../reducers/indicatorSlice";
import { PrevButton, PrimaryButton } from "../../components/Buttons/Button";
import HorizontalLine from "../../components/HorizontalLine";
import SimpleTime from "../indicator/Simple/SimpleTime";
import Select from "react-select";
import { useEffect, useState } from "react";
import { makeSelectField, makeVariableField } from "../../utils/common";
import SwitchButton from "../../components/Selectors/SwitchButton";
import { aggregationOptions } from "../../utils/field";
import NumericInput from "../../components/Inputs/NumericInput";
import ConfigurationAlerteFormula from "../indicator/ConfigurationAlerte/ConfigurationAlerteFormula";
import TextInput from "../../components/Selectors/TextInput";
import { formatBackend } from "../../utils/date.utils";
import IndicatorRecurentForm from "../indicator/IndicatorRecurentForm";
import { postIndicatorApi } from "../../axios/IndicatorComputationApi";
import dayjs from "dayjs";
import { putDataApi } from "../../axios/DataManagementApi";
import { toast } from "react-toastify";

export const ReconstructionModelSetup = ({ setResult }) => {
  const { moduleData, selected_crude_source } = useSelector((state) => state.indicator);
  const { current, reconstruction_params, initial_timestamp } = moduleData;
  const { time_params, computation_params } = current;
  const { start_time, end_time, bucket_size, label, time_label } = time_params;
  const { potential_predictors, target, name } = reconstruction_params;
  const dispatch = useDispatch();
  const { crude_source } = useSelector((state) => state.data);
  const [formula, setFormula] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { showLabel } = useSelector((state) => state.application);
  const [recurrent, setRecurrent] = useState(false);
  console.debug(potential_predictors);
  const [targetOptions, setTargetOptions] = useState(
    selected_crude_source.map((value) => makeVariableField(0, value, crude_source.find((source) => source.id === value)["measure"])).flat(),
  );

  function getOptionsFromArray(inputArray, jsonData) {
    const optionsArray = [];

    // Check if jsonData is defined and not empty
    if (jsonData && jsonData.length > 0) {
      // Iterate through the inputArray
      for (const inputValue of inputArray) {
        // Iterate through each item in jsonData
        for (const item of jsonData) {
          // Find the corresponding option in the item's options array
          const option = item.options.find((opt) => opt.label === inputValue);

          // If a matching option is found, push it to the optionsArray
          if (option) {
            optionsArray.push({
              label: option.label,
              value: option.value,
              crude_source_id: option.crude_source_id,
            });
            // Break the inner loop since the option was found
            break;
          }
        }
      }
    } else {
      console.log("jsonData is empty or undefined.");
    }

    return optionsArray;
  }

  useEffect(() => {
    setTargetOptions(selected_crude_source.map((value) => makeVariableField(0, value, crude_source.find((source) => source.id === value)["measure"])).flat());
  }, [crude_source, selected_crude_source]);
  const [aggregation, setAggregation] = useState(false);
  const [aggregationMode, setAggregationMode] = useState("avg");
  const [crudeSourceOptions] = useState(makeSelectField(crude_source, ["name", "description"], true));

  function handleCrudeSource(selected, action) {
    if (action.action === "select-option" && action.option.value === "all") {
      const newSource = crudeSourceOptions.filter((value) => value.value !== "all").map((value) => value.value);
      const newPredictors = newSource
        .map((value) => Object.entries(crude_source.find((source) => source.id === value)["measure"]))
        .flat()
        .filter((pred) => pred[1].visualization_type === "dynamic_numerical" || pred[1].visualization_type === "semistatic_numerical");
      dispatch(
        setReconstructionParams({
          key: "potential_predictors",
          value: newPredictors.map((pred) => pred[0]),
        }),
      );
      dispatch(setCrudeSource(newSource));
    } else {
      const newPredictors = selected
        .map((value) => Object.entries(crude_source.find((source) => source.id === value.value)["measure"]))
        .flat()
        .filter((pred) => pred[1].visualization_type === "dynamic_numerical" || pred[1].visualization_type === "semistatic_numerical");

      dispatch(
        setReconstructionParams({
          key: "potential_predictors",
          value: newPredictors.map((pred) => pred[0]),
        }),
      );
      dispatch(setCrudeSource(selected.map((value) => value.value)));
    }
  }

  function handleTimeParams(name, value) {
    dispatch(setCurrentTimeParams({ key: name, value: value }));
  }

  function handleTarget(newValue) {
    dispatch(setReconstructionParams({ key: "target", value: newValue.label }));
  }

  function handlePredictors(newValue) {
    console.debug(newValue);
    dispatch(setReconstructionParams({ key: "potential_predictors", value: newValue.map((option) => option.label) }));
  }

  function simple() {
    setIsLoading(true);

    function getDataBlocks(source, sourceList, agregation = "identity") {
      const dataBlocks = [];

      for (let i = 0; i < source.length; i++) {
        const sourceId = source[i];
        const sourceData = sourceList.find((item) => item.id === sourceId);

        if (sourceData && sourceData.measure) {
          const measureKeys = Object.keys(sourceData.measure);
          const timeField = measureKeys.find((key) => sourceData.measure[key].visualization_type === "date");

          const preprocess = {};
          measureKeys.forEach((key) => {
            if (key !== timeField) {
              preprocess[key] = agregation;
            }
          });

          const dataBlock = {
            configured_sources: [],
            time_field: timeField ?? "internal",
            preprocess: preprocess,
            measure_identifier: sourceData.measure_identifier,
            status: -1,
          };

          dataBlocks.push(dataBlock);
        }
      }

      return dataBlocks;
    }

    const { reconstruction_params } = structuredClone(moduleData);
    const parseFormulaV1 = (formula, blocks) => {
      let ret = formula;
      blocks.forEach((block) => {
        Object.keys(block.preprocess).forEach((value) => {
          ret = ret.replaceAll(value, "['" + value + "']");
        });
      });
      return ret;
    };
    const obs = sessionStorage.getItem("observed_system_id");
    const payload = {
      current: {
        data_params: getDataBlocks(selected_crude_source, crude_source, aggregation ? aggregationMode : "identity"),
        time_params: {
          start_time: formatBackend(start_time),
          end_time: formatBackend(end_time),
          bucket_size: aggregation ? bucket_size : -1,
          label: label,
        },
        computation_params: structuredClone(computation_params),
      },
      reconstruction_params: reconstruction_params,
    };
    if (showLabel) payload.current.time_params.time_label = time_label;
    payload.current.computation_params.formula = formula ? parseFormulaV1(computation_params.formula, payload.current.data_params) : "";
    if (recurrent) {
      const { script_name, initial_timestamp, frequency, validity_days, time_slot } = structuredClone(moduleData);

      const recurrentPayload = {
        script_name: script_name,
        script_parameters: {
          observed_system: obs,
          parameters: payload,
          initial_timestamp: dayjs(initial_timestamp).tz("Europe/Paris").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          timezone: "Europe/Paris",
        },
        frequency: frequency,
        time_slot: time_slot,
        validity_days: validity_days,
      };
      recurrentPayload.script_parameters.parameters.current.time_params.start_time = "";
      recurrentPayload.script_parameters.parameters.current.time_params.end_time = "";
      putDataApi("configuration/module/" + obs, recurrentPayload)
        .then((res) => {
          setIsLoading(false);
          if (res === 401) return;
          toast.warn(res["data"]["message"] + " - " + res["data"]["module_instance_id"], {
            autoClose: false,
            closeOnClick: false,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          console.debug(error);
        });
    } else
      postIndicatorApi("indicator/reconstructionfitter/" + obs, payload)
        .then((res) => {
          setIsLoading(false);
          setResult(res.data);
          console.debug(res);
        })
        .catch((error) => {
          setIsLoading(false);
          console.debug(error);
        });
  }

  return (
    <div className={"p-8"}>
      <div className="w-full rounded-lg border border-gray-300 bg-white shadow-md">
        <div className={"flex gap-x-0.5 px-4 pt-4"}>
          <PrevButton url={"/analytics/modele"} />
          <h2 className="py-2 text-2xl font-semibold text-blue-800">Construction d&apos;un modèle</h2>
        </div>
        <HorizontalLine />
        <div className="mx-4 my-2">
          <div className={"mb-2 px-8"}>
            <SwitchButton label={"Récurent"} value={recurrent} onChange={(value) => setRecurrent(value)} />
          </div>
          {recurrent ? (
            <div className={"mb-2 grid grid-cols-6 gap-x-4 px-16"}>
              <TimeInput
                name={"initial_timestamp"}
                label={"Date et heure"}
                value={initial_timestamp}
                onChange={(name, value) => dispatch(setModuleData({ key: name, value: value }))}
              />
              <IndicatorRecurentForm />
            </div>
          ) : (
            <div className={"mb-2 flex justify-between gap-x-4 px-16"}>
              <SimpleTime />
            </div>
          )}
        </div>

        <div className={"mb-2 border-b px-8 pb-2"}>
          <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Source(s) de données</label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            placeholder={"Source de données"}
            value={crudeSourceOptions.filter((value) => selected_crude_source.includes(value.value))}
            options={crudeSourceOptions}
            onChange={handleCrudeSource}
          />
        </div>
        <div className={"mb-2 px-8"}>
          <SwitchButton label={"Agrégation"} value={aggregation} onChange={(value) => setAggregation(value)} />
        </div>
        {aggregation && (
          <div className={"mb-2 grid grid-cols-6 gap-x-4 px-16"}>
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">Type</label>
              <Select
                options={aggregationOptions}
                value={aggregationOptions.find((value) => value.value === aggregationMode)}
                onChange={(newValue) => setAggregationMode(newValue.value)}
              />
            </div>
            <NumericInput label={"Intervalle de temps (s)"} value={bucket_size} name={"bucket_size"} onChange={handleTimeParams} />
          </div>
        )}
        <div className={"mb-2 border-b px-8 pb-2"}>
          <SwitchButton label={"Formule"} value={formula} onChange={(value) => setFormula(value)} />
        </div>
        {formula && (
          <div className={"mb-2 w-1/2 px-16"}>
            <ConfigurationAlerteFormula mode={"current"} verify={false} />
          </div>
        )}
        <div className={"mb-2 px-8"}>
          <TextInput
            placeholder={"Nom du modèle"}
            label={"Nom"}
            name={"name"}
            value={name}
            onChange={(name, value) => dispatch(setReconstructionParams({ key: name, value: value }))}
          />
        </div>
        <div className={"mb-2 px-8"}>
          <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Cible</label>
          <Select
            placeholder={"Cible du modèle"}
            getOptionValue={(option) => option.value} // This line is important
            value={targetOptions.reduce((selected, option) => {
              const found = option.options.find((opt) => opt.label === target);
              return found ? found : selected;
            }, null)}
            options={targetOptions}
            onChange={handleTarget}
          />
        </div>
        <div className={"mb-2 px-8"}>
          <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Predicteurs potentiels</label>
          <Select
            value={getOptionsFromArray(potential_predictors, targetOptions)}
            getOptionValue={(option) => option.value} // This line is important
            placeholder={"Prédicteurs de la cible"}
            isMulti
            options={targetOptions}
            onChange={handlePredictors}
          />
        </div>
      </div>
      <div className={"flex justify-center"}>
        <PrimaryButton
          disabled={isLoading || selected_crude_source.length === 0}
          isLoading={isLoading}
          label={recurrent ? "Créer" : "Calculer"}
          callback={() => simple()}
        />
      </div>
    </div>
  );
};
