import { flexRender } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/common";

export function TableBody({ table }) {
  const colSpan = table.getAllColumns().length;
  const { t } = useTranslation(["common"]);
  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      {table.getRowModel().rows.length === 0 && (
        <tr>
          <td className="whitespace-nowrap bg-white px-6 py-4 text-center" colSpan={colSpan}>
            {capitalizeFirstLetter(t("NO_INFO"))}
          </td>
        </tr>
      )}

      {table.getRowModel().rows.map((row) => (
        <tr key={row.id} className={row.getIsSelected() ? "bg-slate-200" : ""}>
          {row.getVisibleCells().map((cell) => {
            if (cell.id.includes("button"))
              return (
                <td key={cell.id} className="w-10">
                  <div className={"flex justify-center"}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                </td>
              );
            return (
              <td key={cell.id} className="whitespace-nowrap px-6 py-4">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
}
