import { getMeasureIdentifierFromCrudeSourceId, isEmptyObject } from "../../utils/common";

export const storageTypeOptions = [
  {
    value: "postgresql",
    label: "PostgreSQL",
  },
  {
    value: "mongodb",
    label: "MongoDB",
  },
  {
    value: "elasticsearch",
    label: "ElasticSearch",
  },
];

export const filtersOptions = [
  { value: "range", label: "Entre deux valeurs" },
  { value: "gt", label: "Supérieur ou égal à" },
  { value: "lt", label: "Inférieur ou égal à" },
  { value: "list", label: "Liste" },
];
export const dynamicFiltersOptions = [
  { value: ">=", label: "Supérieur ou égal à" },
  { value: "<=", label: "Inférieur ou égal à" },
  { value: "=", label: "Egal" },
  { value: "<", label: "Inférieur à" },
  { value: ">", label: "Supérieur à" },
];
export const dynamicTypeOptions = [
  { value: "INTERNAL", label: "INTERNAL" },
  { value: "TIMESTAMP", label: "TIMESTAMP" },
];

export const generateFitlers = (filters, dynamicFilters) => {
  if (isEmptyObject(filters)) return dynamicFilters;
  if (isEmptyObject(dynamicFilters)) return filters;
  const mergedDict = {};
  for (const key in dynamicFilters) {
    if (Object.hasOwn(dynamicFilters, key)) {
      mergedDict[key] = { ...filters[key], ...dynamicFilters[key] };
    } else {
      // If the key is not present in the first dictionary, add it
      mergedDict[key] = dynamicFilters[key];
    }
  }
  return mergedDict;
};
export const generatePayload = (storageType, fieldsToUpload, credentials, filters, id, crudeSourceId, name) => {
  return {
    connexion_to_db: {
      storage_type: storageType,
      credentials: credentials,
    },
    request_parameters: {
      fields_to_upload: fieldsToUpload,
      filters: filters,
      table_name: name,
    },
    upload_parameters: {
      collected_by: id,
      measure_identifier: crudeSourceId !== undefined ? getMeasureIdentifierFromCrudeSourceId(crudeSourceId) : "",
    },
  };
};
