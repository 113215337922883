import { Fragment } from "react";
import { SecondaryButton } from "../../components/Buttons/Button";

const RequestParametersDynamicFiltersDisplay = ({ filters, setFilters }) => (
  <div>
    {Object.keys(filters).map((filter, index) => (
      <div className={"grid grid-cols-4 py-1"} key={index}>
        <p className={"flex items-center justify-center px-1 py-0.5"}>{filter}</p>
        <div className={"col-span-2 grid grid-cols-2"}>
          {Object.entries(filters[filter]).map((entry, index) => (
            <Fragment key={index}>
              <div className={"flex items-center justify-center px-1 py-0.5"}>{entry[0]}</div>
              {Object.entries(entry[1]).map((value, index) => (
                <div key={index} className={"flex items-center justify-center px-1 py-0.5"}>
                  {value[0]} {value[1]}
                </div>
              ))}
            </Fragment>
          ))}
        </div>
        <div className={"flex items-center justify-center gap-x-1"}>
          <SecondaryButton label={"Supprimer"} callback={() => setFilters(filter, null)} />
        </div>
      </div>
    ))}
  </div>
);
export default RequestParametersDynamicFiltersDisplay;
