import MRDRightPanel from "./MRDRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const MRD = () => (
  <div className="m-4 grid gap-4 py-2 lg:grid-cols-2">
    <IndicatorDataConfiguration isDataParam={false} />
    <MRDRightPanel />
  </div>
);
export default MRD; //
