import { useTranslation } from "react-i18next";
import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";

export function TableFilter({ initialValue, onChange }) {
  //Use state
  const { t } = useTranslation(["common"]);

  return (
    <div className="flex w-full">
      <div className="pointer-events-none z-10 flex w-8 items-center justify-center pl-1 text-center">
        <Icon path={mdiMagnify} size={1} className={"text-gray-400"} />
      </div>
      <input
        type="text"
        className="-ml-10 block w-full rounded-lg border-gray-300 bg-gray-200
                py-2 pl-10 shadow-sm outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200/50 "
        value={initialValue}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder={t("table_filter")}
      />
    </div>
  );
}
