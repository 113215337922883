import { handleVisualizationFormatChange } from "../../utils/fileUtils";
import { useTranslation } from "react-i18next";

import TextInput from "../Selectors/TextInput";

const DateParam = ({ data, setData, id }) => {
  const { t } = useTranslation(["common"]);
  return (
    <div>
      <div className={"grid grid-cols-2 gap-x-4 rounded-t bg-blue-900"}>
        <div className={"group  place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>
          {t("mapping_information")}
        </div>
        <div className={"group place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>
          {t("visualization_format")}
        </div>
      </div>
      <div className={"grid grid-cols-2 gap-x-4 py-0.5 odd:bg-gray-100 even:bg-gray-50"}>
        <div>
          <p className={"pt-6 text-xl"}>-</p>
        </div>
        <div>
          <TextInput
            name={"description"}
            label={t("description")}
            onChange={(name, value) => handleVisualizationFormatChange(value, id, data, setData, name)}
            value={data.col_type[id].visualization_format.description}
          />
        </div>
      </div>
    </div>
  );
};
export default DateParam;
