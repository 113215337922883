//React librairies
import { Fragment, useEffect, useId, useState } from "react";
//Icons
import { UserCircleIcon as ProfileIcon } from "@heroicons/react/24/solid";
import Profile from "./Profile";
import { useSelector } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import i18n from "i18next";

const Header = () => {
  const [observedSystem, setObservedSystem] = useState(sessionStorage.getItem("observed_system_name") ?? "");
  const crudeSources = useSelector((state) => state.data);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const handleLanguageToggle = () => {
    const nextLanguage = currentLanguage === "en" ? "fr" : "en";
    i18n.changeLanguage(nextLanguage);
    setCurrentLanguage(nextLanguage);
  };
  useEffect(() => {
    setObservedSystem(sessionStorage.getItem("observed_system_name") ?? "");
  }, [crudeSources]);
  const id = useId();
  return (
    <div className="fixed z-20 flex h-14 w-full items-center justify-between bg-blue-950 text-white">
      {/* TITLE */}
      <div className="flex h-14 w-40 items-center justify-center border-none  md:w-80">
        <span className="hidden text-xl font-bold md:block">PREDITIC</span>
      </div>

      {/* RIGHT SECTION */}
      <div className="flex h-14 w-full items-center  justify-end pl-10">
        <button onClick={handleLanguageToggle}> {currentLanguage === "en" ? "🇬🇧" : "🇫🇷"}</button>

        <div className="pr-2">
          <Popover as="div" className="relative mr-3 inline-block">
            <Popover.Button className="inline-flex h-10 w-10 rounded-full p-1">
              <ProfileIcon id={id} className={"h-full w-full"} />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="absolute right-0 mt-2 w-[16rem] origin-top-right  divide-y divide-gray-100 rounded-md bg-white text-black shadow-lg ring-1 ring-black/5 focus:outline-none dark:bg-[#0d0c18]">
                {({ close }) => <Profile close={close} observedSystem={observedSystem} />}
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default Header;
