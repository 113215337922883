import { isEmptyObject } from "../../utils/common";
import SwitchButton from "../../components/Selectors/SwitchButton";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const ApiParameters = ({ parameters, setNestedParameters, configuredSourceId }) => {
  const { crude_source, configured_source } = useSelector((state) => state.data);
  console.debug(crude_source, configured_source);
  const [measureOptions, setMeasureOptions] = useState([]);
  useEffect(() => {
    const crudeSourceId = configured_source.find((configuration) => configuration.id === configuredSourceId).crude_source;
    const measure = crude_source.find((crude_source) => crude_source.id === crudeSourceId).measure;
    setMeasureOptions(
      Object.keys(measure).map((m) => {
        return { value: m, label: m };
      }),
    );
  }, [configuredSourceId, configured_source, crude_source]);

  const typeOptions = [
    { value: "INTERNAL", label: "INTERNAL" },
    { value: "TIMESTAMP", label: "TIMESTAMP" },
  ];
  const handleSwitch = (key, value) => {
    if (value)
      setNestedParameters(`data_to_upload.api_parameters.parameters.${key}`, {
        value: "DYNAMIC",
        old_value: parameters[key].value,
        status: false,
        value_parameters: {
          DYNAMIC: {
            type: "INTERNAL",
            replacement: "",
          },
        },
      });
    else {
      setNestedParameters(`data_to_upload.api_parameters.parameters.${key}`, {
        value: parameters[key].old_value,
        status: false,
        value_parameters: {},
      });
    }
    console.debug(value);
  };
  const handleTypeChange = (key, newValue) => {
    console.debug(key);
    console.debug(newValue);
    const value = parameters[key].value;
    console.debug(parameters[key].value_parameters[value].type);
    let newType = { type: newValue.value };
    if (newValue.value === "INTERNAL") {
      newType["replacement"] = "";
    }
    if (newValue.value === "TIMESTAMP") {
      newType["replacement"] = "NOW";
      newType["timezone"] = "Europe/Paris";
    }
    setNestedParameters(`data_to_upload.api_parameters.parameters.${key}.value_parameters.${value}`, newType);
  };
  const handleReplacementChange = (key, newValue) => {
    const value = parameters[key].value;
    setNestedParameters(`data_to_upload.api_parameters.parameters.${key}.value_parameters.${value}.replacement`, newValue.value);
  };
  return (
    <div>
      {Object.entries(parameters).map(([key, value]) => (
        <div className={"flex gap-x-4"} key={key}>
          <div>
            <strong>{key}</strong>: {isEmptyObject(value.value_parameters) ? value.value : value.old_value}
          </div>
          <SwitchButton
            value={!isEmptyObject(value.value_parameters)}
            label={isEmptyObject(value.value_parameters) ? "statique" : "dynamique"}
            onChange={(checked) => handleSwitch(key, checked)}
          />
          {!isEmptyObject(value.value_parameters) && (
            <Select
              value={typeOptions.find((type) => value.value_parameters[value.value].type === type.value)}
              options={typeOptions}
              onChange={(newValue) => handleTypeChange(key, newValue)}
            />
          )}
          {!isEmptyObject(value.value_parameters) && value.value_parameters[value.value].type === "INTERNAL" && (
            <Select
              value={measureOptions.find((conf) => conf.value === value.value_parameters[value.value].replacement)}
              onChange={(newValue) => handleReplacementChange(key, newValue)}
              options={measureOptions}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ApiParameters;
