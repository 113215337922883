import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { canRenderAction, getUserPreferences } from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableFilter } from "./TableFilter";
import DeleteModal from "../Selectors/DeleteModal";

import { selectedCrudeSource } from "../../reducers/SelectedSlice";
import { deleteDataApi } from "../../axios/DataManagementApi";
import { remove } from "../../reducers/dataSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Table from "./Table";
import DropdownMenu from "../Selectors/DropDownButton";
import { mdiDelete, mdiExport, mdiEyeOutline, mdiPencil, mdiPlusBox } from "@mdi/js";

const CrudeSourceList = () => {
  const { t } = useTranslation(["common"]);

  const selectedCrudeSourceId = useSelector((state) => state.selected.crude_source);
  const selectedObservedSystemId = useSelector((state) => state.selected.observed_system);
  const [crudeSourceToDelete, setCrudeSourceToDelete] = useState(undefined);

  const [isDeleting, setIsDeleting] = useState(false);

  const [canDelete] = useState(canRenderAction(ACTION_FLAGS.CANDELETE, COMPONENTS.CRUDE_SOURCE_DISPLAY));
  const [canEdit] = useState(canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.CRUDE_SOURCE_DISPLAY));
  const [canCreate] = useState(canRenderAction(ACTION_FLAGS.CANCREATE, COMPONENTS.CONFIGURED_SOURCE_FROM_EXISTING_ONE));

  const [globalFilter, setGlobalFilter] = useState("");
  const crudeSourceList = useSelector((state) => state.data.crude_source);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [rowSelection, setRowSelection] = useState({});
  const handleCrudeSourceChange = (updater) => {
    const newSelected = updater();
    const index = Object.keys(newSelected)[0];
    const crudeSourceId = data[index].id;
    dispatch(selectedCrudeSource(crudeSourceId));
    sessionStorage.setItem("crude_source_id", crudeSourceId);
    setRowSelection(newSelected);
  };
  useEffect(() => {
    const newData = [...crudeSourceList];
    const index = newData.findIndex((crudeSource) => crudeSource.id === selectedCrudeSourceId);
    if (index !== -1) {
      setRowSelection({ [index]: true });
    }
    setData(newData);
  }, [crudeSourceList, selectedCrudeSourceId]);
  const handleView = (configured_source_id) => {
    history("/crude_source/status/" + sessionStorage.getItem("observed_system_id") + "/" + configured_source_id);
  };
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: t("name"),
      cell: (info) => (
        <button className={"font-semibold"} onClick={() => (info.row.getIsSelected() ? null : info.row.toggleSelected(true))}>
          {info.getValue()}
        </button>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: t("description"),
      cell: (info) => <p className={"w-20 truncate"}>{info.getValue()}</p>,
    }),
    columnHelper.display({
      id: "actions_button",
      cell: (info) => {
        const row = info.row.original;
        const options = [];
        if (canEdit) {
          options.push({
            id: 0,
            label: "Exporter les données",
            onClick: () => history("/crude_source/export/" + row.id),
            path: mdiExport,
          });
          options.push({
            id: 3,
            label: "Suivre la performance opérationnelle",
            onClick: () => handleView(row.id),
            path: mdiEyeOutline,
          });
          if (canCreate)
            options.push({
              id: 4,
              label: "Ajouter une nouvelle configuration",
              onClick: () => history("/crude_source/nouvelle-configuration/" + row.id),
              path: mdiPlusBox,
            });
          options.push({
            id: 1,
            label: "Modifier les caractéristiques",
            onClick: () => history("/crude_source/edit/" + row.id),
            path: mdiPencil,
          });
        }
        if (canDelete)
          options.push({
            id: 2,
            label: "Supprimer",
            onClick: () => setCrudeSourceToDelete(row),
            path: mdiDelete,
          });
        return <DropdownMenu options={options} />;
      },
    }),
  ];
  const showId = getUserPreferences().find((preference) => preference.name === "showId")?.value ?? true;

  if (!showId) columns.splice(0, 1);
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      rowSelection,
    },
    enableMultiRowSelection: false,
    enableRowSelection: (row) => !row.getIsSelected(),
    onRowSelectionChange: handleCrudeSourceChange,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div>
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
      </div>
      <Table table={table} />
      {crudeSourceToDelete && (
        <DeleteModal
          callback={() => {
            setIsDeleting(true);
            if (crudeSourceToDelete.id === selectedCrudeSourceId) {
              dispatch(selectedCrudeSource(""));
            }
            deleteDataApi("crude_source?observed_system_id=" + selectedObservedSystemId + "&crude_source_id=" + crudeSourceToDelete.id).then(() => {
              setIsDeleting(false);
              setCrudeSourceToDelete(undefined);
              dispatch(remove({ type: "crude_source", id: crudeSourceToDelete.id }));
              dispatch(selectedCrudeSource(""));
              toast.success("La source de donnée a été supprimé");
            });
          }}
          loading={isDeleting}
          text={t("DELETE") + " " + crudeSourceToDelete.name + " ?"}
          modalVisible={crudeSourceToDelete}
          setModalVisible={setCrudeSourceToDelete}
        />
      )}
    </div>
  );
};
export default CrudeSourceList;
