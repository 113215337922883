import IndicatorAccordion from "../IndicatorAccordion";
import IndicatorRecurentForm from "../IndicatorRecurentForm";
import { useDispatch, useSelector } from "react-redux";
import TimeInput from "../../../components/Inputs/TimeInput";
import { setModuleData } from "../../../reducers/indicatorSlice";
import TimezoneSelect from "react-timezone-select";
import IndicatorDefiningStatus from "../IndicatorDefiningStatus";
import NumericInput from "../../../components/Inputs/NumericInput";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getThreshold } from "../utils";
import { RefreshButton } from "../../../components/Buttons/Button";
import { getDataApi } from "../../../axios/DataManagementApi";
import { formatThreshold } from "../../../network/DataManagementApi";
import IndicatorId from "../TimeIndicator/IndicatorId";
import { useTranslation } from "react-i18next";

function StatusManagerParameters() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useNavigate();

  const { initial_timestamp, timezone, threshold_label, shift, is_active } = useSelector((state) => state.indicator.moduleData);
  const isActiveOptions = [
    { value: true, label: "Actif" },
    { value: false, label: "Inactif" },
  ];
  const obs = sessionStorage.getItem("observed_system_id");

  const [thresholdOptions, setThresholdOptions] = useState(
    getThreshold(obs).map((value) => {
      return { label: value.label, value: value.label };
    }),
  );
  const [thresholdLoading, setThresholdLoading] = useState(false);
  return (
    <div>
      <div className="grid grid-cols-2 gap-x-4">
        <TimeInput
          name={"initial_timestamp"}
          label={t("time")}
          value={initial_timestamp}
          onChange={(name, value) => dispatch(setModuleData({ key: name, value: value }))}
        />
        <div>
          <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{t("timezone")}</label>
          <TimezoneSelect
            value={timezone}
            onChange={(e) => {
              dispatch(setModuleData({ key: "timezone", value: e.value }));
            }}
          />
        </div>
        <NumericInput value={shift} name={"shift"} label={t("shift")} onChange={(name, value) => dispatch(setModuleData({ key: name, value: value }))} />
        <div>
          <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{t("state")}</label>
          <Select
            isSearchable={false}
            isClearable={false}
            value={isActiveOptions.find((value) => value.value === is_active)}
            options={isActiveOptions}
            onChange={(newValue) => dispatch(setModuleData({ key: "is_active", value: newValue.value }))}
          />
        </div>
        <div>
          <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{t("threshold")}</label>
          <div className={"flex gap-x-1.5"}>
            <CreatableSelect
              isClearable={false}
              value={thresholdOptions.find((value) => value.value === threshold_label)}
              options={thresholdOptions}
              className={"w-full"}
              menuPosition={"fixed"}
              onCreateOption={() => history("/analytics/threshold/create")}
              onChange={(newValue) => dispatch(setModuleData({ key: "threshold_label", value: newValue.value }))}
            />
            <RefreshButton
              isLoading={thresholdLoading}
              callback={() => {
                setThresholdLoading(true);
                getDataApi("threshold?structure_id=" + sessionStorage.getItem("structure_id"))
                  .then((res) => {
                    formatThreshold(res.data.data);
                    setThresholdOptions(
                      getThreshold(obs).map((value) => {
                        return { label: value.label, value: value.label };
                      }),
                    );
                    setThresholdLoading(false);
                  })
                  .catch((error) => {
                    setThresholdLoading(false);
                    console.debug(error);
                  });
              }}
            />
          </div>
        </div>
        <div className={"col-span-2"}>
          <IndicatorId />
        </div>
      </div>
    </div>
  );
}

const StatusManager = () => (
  <div className={"mx-4 py-2"}>
    <div className="block rounded border border-gray-200 bg-white">
      <IndicatorAccordion label={"Programmation d'exécution du module"} composant={<IndicatorRecurentForm />} />
      <IndicatorAccordion label={"parameters"} composant={<StatusManagerParameters />} />
      <IndicatorAccordion label={"defining_status"} composant={<IndicatorDefiningStatus />} />
    </div>
  </div>
);
export default StatusManager;
