import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PredictionResult } from "./PredictionResult";
import PredictionSimple from "./PredictionSimple";
import ReconstructionSimple from "./ReconstructionSimple";

const Reconstruction = () => {
  const { execution_mode } = useSelector((state) => state.indicator.moduleData);
  const [result, setResult] = useState({});
  return (
    <Fragment>
      <ReconstructionSimple setResult={setResult} result={result} />
      <PredictionResult name={execution_mode} result={result} />
    </Fragment>
  );
};
export default Reconstruction;
