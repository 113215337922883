import { useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { canRenderAction, getConfigurationsFromCrudeSource, getUserPreferences } from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableFilter } from "./TableFilter";
import { mdiApi, mdiCableData, mdiCheck, mdiClose, mdiDatabase, mdiEyeOutline, mdiFileDelimitedOutline, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";
import Table from "./Table";
import DropdownMenu from "../Selectors/DropDownButton";
import BasicTooltip from "../Tooltip/BasicTooltip";

const ConfiguredSourceList = () => {
  const { t } = useTranslation(["common"]);
  const selectedCrudeSourceId = useSelector((state) => state.selected.crude_source);
  const [canEdit] = useState(canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.CONFIGURED_SOURCE_DISPLAY));
  const [canCreate] = useState(canRenderAction(ACTION_FLAGS.CANCREATE, COMPONENTS.FILE_UPLOAD));

  const [globalFilter, setGlobalFilter] = useState("");
  const crudeSourceList = useSelector((state) => state.data.crude_source);
  const [data, setData] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    setData(getConfigurationsFromCrudeSource(selectedCrudeSourceId));
  }, [crudeSourceList, selectedCrudeSourceId]);
  const handleView = (configured_source_id) => {
    history("/configured_source/status/" + sessionStorage.getItem("observed_system_id") + "/" + configured_source_id);
  };
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("description", {
      id: "desc",
      header: t("description"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "is_active",
      header: () => (
        <div className={"flex"}>
          {t("state_configured_source")}
          {/*<div className={"ml-1"}>*/}
          {/*  <BasicTooltip tip="Suivi de la validité du rythme de remontée de données" />*/}
          {/*</div>*/}
        </div>
      ),
      cell: (info) => {
        const row = info.row.original;
        if (row.is_active) return <Icon path={mdiCheck} className={"h-6 w-6 text-blue-400"} />;
        return <Icon path={mdiClose} className={"h-6 w-6 text-red-600"} />;
      },
    }),
    columnHelper.display({
      id: "actions_button",
      cell: (info) => {
        const row = info.row.original;
        const options = [];
        options.push({
          id: 5,
          label: "Suivre la performance opérationnelle",
          onClick: () => handleView(row.id),
          path: mdiEyeOutline,
        });
        if (canCreate) {
          options.push({
            id: 2,
            label: "Importer des données via un fichier",
            onClick: () => history("/configured_source/upload/" + row.id),
            path: mdiFileDelimitedOutline,
          });
          options.push({
            id: 3,
            label: "Insérer des données via un point d’API",
            onClick: () => history("/configuration/data-feedback/" + row.id),
            path: mdiApi,
          });
          options.push({
            id: 4,
            label: "Insérer des données via une base externe",
            onClick: () => history("/configuration/database/" + row.id),
            path: mdiDatabase,
          });
          options.push({
            id: 5,
            label: "Liste des modulateurs",
            onClick: () => history("/configuration/transformers/" + row.id),
            path: mdiCableData,
          });
        }
        if (canEdit) {
          options.push({
            id: 1,
            label: "Modifier les caractéristiques",
            onClick: () => history("/configured_source/edit/" + row.id),
            path: mdiPencil,
          });
        }

        return <DropdownMenu options={options} />;
      },
    }),
  ];
  const showId = getUserPreferences().find((preference) => preference.name === "showId")?.value ?? true;

  if (!showId) columns.splice(0, 1);
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
      </div>
      <Table table={table} />
    </div>
  );
};
export default ConfiguredSourceList;
