export const COMPONENTS = {
  INSTANCE_DISPLAY: 1,
  OBSERVED_SYSTEM_DISPLAY: 2,
  CRUDE_SOURCE_DISPLAY: 3,
  CONFIGURED_SOURCE_DISPLAY: 4,
  INSTANCE_CREATION: 5,
  OBSERVED_SYSTEM_CREATION: 6,
  DATA_SOURCE_CREATION: 10,
  CONFIGURED_SOURCE_FROM_EXISTING_ONE: 12,
  FILE_UPLOAD: 13,
  INDICATOR_DISPLAY: 17,
  INDICATOR_CREATION: 18,
  INDICATOR_COMPUTATION: 19,
  GRAPHIC_CREATION: 20,
  EVENT_DISPLAY: 21,
  EVENT_CREATION: 22,
  LEARNING_MODULE_CREATION: 23,
  THRESHOLD_DISPLAY: 24,
  THRESHOLD_CREATION: 25,
  STRUCTURE_DISPLAY: 26,
  STRUCTURE_CREATION: 27,
  USER_DISPLAY: 28,
  USER_CREATION: 29,
  CONNECTION_LOG_DISPLAY: 30,
  ACTION_LOG_DISPLAY: 31,
  PROFILE_DISPLAY: 32,
  PROFILE_CREATION: 33,
  PREDICTION_DISPLAY: 35,
  LABEL_DISPLAY: 36,
};
