import { useId } from "react";
import { isEmptyString } from "../../utils/common";

const TimeInput = ({ name, label, value, step = 1, min, max, onChange }) => {
  const id = useId();
  return (
    <div>
      {!isEmptyString(label) && (
        <label htmlFor={id} className="text-sm font-bold tracking-wide text-gray-700">
          {label}
        </label>
      )}
      <input
        onChange={(event) => onChange(event.target.name, event.target.value)}
        name={name}
        step={step}
        min={min}
        max={max}
        type="datetime-local"
        id={id}
        value={value}
        className="block w-full rounded-lg border border-neutral-300 bg-neutral-50 text-sm text-neutral-900 focus:border-blue-500 focus:ring-blue-500"
        placeholder=""
        required
      />
    </div>
  );
};
export default TimeInput;
