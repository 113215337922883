import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";

import { toast } from "react-toastify";

import { capitalizeFirstLetter, isEmptyObject } from "../../utils/common";
import { getHeaderFromCSV } from "../../utils/fileUtils";
import FileUploadList from "../../components/Table/FileUploadList";
import { fileDataApi, getDataApi } from "../../axios/DataManagementApi";
import { PrimaryButton } from "../../components/Buttons/Button";
import { useTranslation } from "react-i18next";

const FileBatchUpload = () => {
  const { t } = useTranslation(["common", "button"]);
  const dataloader = useLoaderData();
  const [file, setFile] = useState(undefined);
  const [columnMap, setColumnMap] = useState(undefined);
  const [fileData, setFileData] = useState({
    header: [],
    delimiter: "",
  });
  const observedSystemId = useRef(useSelector((state) => state.selected.observed_system));
  const redirect = "/";
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const history = useNavigate();
  const [item] = useState(dataloader);
  const [sourceParam, setsourceParam] = useState({});
  console.debug(observedSystemId.current);
  useEffect(() => {
    getDataApi("source_parameters?observed_system_id=" + observedSystemId.current + "&crude_source_id=" + item.crude_source)
      .then((res) => {
        setsourceParam(res.data.data);
      })
      .catch((error) => {
        console.debug(error);
      });
  }, [item.crude_source]);
  const handleClear = () => {
    setColumnMap(undefined);
    setFile(undefined);
    setFileData({
      header: [],
      delimiter: "",
    });
  };
  const handlefileUpload = (event) => {
    console.log(event.target.files[0].size);
    if (event.target.files[0].size > process.env.REACT_APP_FILE_MAX_SIZE) {
      toast.error("File is too big. \n Size must be less than " + process.env.REACT_APP_FILE_MAX_SIZE);
      setFileData({
        header: null,
        delimiter: "",
      });
      return;
    }

    setFile(event.target.files[0]);
    getHeaderFromCSV(event.target.files[0], Object.keys(sourceParam.values)).then((value) => {
      setFileData({ header: value.header, delimiter: value.delimiter });
      setColumnMap(value.columnMap);
    });
  };
  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <div className="flex gap-x-1.5">
        <IconButton bg={true} Icon={<Prev size={40} />} value={""} callback={() => history(redirect)} />
        <h2 className="text-3xl font-semibold text-blue-900">{capitalizeFirstLetter(t("configured_source"))}</h2>
      </div>
      <FileUploadList
        data={isEmptyObject(sourceParam) ? [] : Object.entries(sourceParam.values)}
        header={fileData.header}
        columnMap={columnMap}
        setColumnMap={setColumnMap}
        onFileChange={handlefileUpload}
        selectedFile={file}
        handleClear={handleClear}
      />

      <div className={"flex justify-end gap-2 pt-2 "}>
        <PrimaryButton
          isLoading={isLoading}
          disabled={isLoading || file === undefined}
          label={t("add", { ns: "button" })}
          callback={() => {
            setIsLoading(true);
            const data = {
              columnMap: columnMap,
              delimiter: fileData.delimiter,
            };
            fileDataApi(
              "upload?configured_source_id=" + id + "&observed_system_id=" + observedSystemId.current + "&measure_identifier=" + item.measure_identifier,
              file,
              data,
            )
              .then((res) => {
                toast.success(res.data.message);
                setIsLoading(false);
              })
              .catch((error) => {
                console.debug(error);
                toast.error(error.response.data.message);
                setIsLoading(false);
              });
          }}
        />
      </div>
    </div>
  );
};
export default FileBatchUpload;
