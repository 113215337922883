import { useState } from "react";

import ConfiguredSourceData from "../multi/ConfiguredSourceData";

const Data = () => {
  const [mappingValue, updateMappingValue] = useState([]);
  const [sourceParametersValue, updateSourceParametersValue] = useState([]);
  const [source, setSource] = useState({
    system: sessionStorage.getItem("observed_system_id"),
    source_type: "",
    description: "",
    name: "",
    alert_delay: 0,
    is_active: false,
    configured_source_description: "",
    storage_system_id: "",
    id: "",
    timezone: "Europe/Paris",
    locale: "fr",
  });

  return (
    <ConfiguredSourceData
      source={source}
      mappingValue={mappingValue}
      updateMappingValue={updateMappingValue}
      updateSourceParametersValue={updateSourceParametersValue}
      setSource={setSource}
      sourceParametersValue={sourceParametersValue}
    />
  );
};
export default Data;
