import { useEffect, useRef } from "react";

export const TableSelector = ({ indeterminate, className = "", ...rest }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return <input type="checkbox" ref={ref} className={className + " cursor-pointer"} {...rest} />;
};
