import Checkbox from "../components/Selectors/Checkbox";
import DateTimezoneCS from "../components/Selectors/DateTimezoneConfiguredSource";
import NumericDataCS from "../components/Selectors/NumericDataConfiguredSource";
import TextInput from "../components/Selectors/TextInput";
import NumericInput from "../components/Inputs/NumericInput";
import { capitalizeFirstLetter } from "./common";

const onChangeRangeMapping = (sourceParam, name, value, callback, arg = null) => {
  const to_update = structuredClone(sourceParam);
  if (arg) to_update["values"][name]["arg"][arg] = value;
  else to_update["values"][name]["arg"] = value;
  callback("values", to_update.values);
};
const onChangeSourceParam = (sourceParam, name, value, callback) => {
  const to_update = structuredClone(sourceParam);
  to_update["source_parameters_values"][name]["value"] = value;
  callback("source_parameters_values", to_update["source_parameters_values"]);
};
export const handleType = (name, value, onChange, t) => {
  switch (name) {
    case "conf_id":
      return (
        <div>
          <label className="flex gap-x-0.5 text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("id"))}</label>
          <TextInput name={name} value={value} onChange={onChange} />{" "}
        </div>
      );

    case "is_active":
      return (
        <div>
          <label className="flex gap-x-0.5 text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("state"))}</label>
          <div className={"py-2"}>
            <Checkbox
              label={value ? t("active") : t("inactive")}
              name={"is_active"}
              checked={value}
              onChange={(e) => onChange("is_active", e.target.checked)}
            />
          </div>
        </div>
      );
    case "description":
      return (
        <div>
          <label className="flex gap-x-0.5 text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("description"))}</label>
          <TextInput name={name} value={value} onChange={onChange} />
        </div>
      );

    default:
      break;
  }
};

export const handleRangeMapping = (value, name, setIds, ids, onChange, t) => {
  const arg = value.arg;
  switch (value["type"]) {
    case "int":
      return (
        <div>
          <label className="flex gap-x-0.5 text-sm font-bold tracking-wide text-gray-700">{name}</label>
          <NumericDataCS onChange={(aname, value) => onChangeRangeMapping(ids, name, value, onChange, aname)} value={arg} step={"1"} />
        </div>
      );
    case "float":
      return (
        <div>
          <label className="flex gap-x-0.5 text-sm font-bold tracking-wide text-gray-700">{name}</label>
          <NumericDataCS onChange={(aname, value) => onChangeRangeMapping(ids, name, value, onChange, aname)} value={arg} step={"0.01"} />{" "}
        </div>
      );
    case "date":
      return (
        <div>
          <TextInput label={name} disabled={true} value={capitalizeFirstLetter(t("NO_UPDATE"))} />
        </div>
      );
    case "string":
      return (
        <div>
          <label className="flex gap-x-0.5 text-sm font-bold tracking-wide text-gray-700">{name}</label>
          <NumericInput step={1} min={0} onChange={(name, value) => onChangeRangeMapping(ids, name, value, onChange)} name={name} value={value} />
        </div>
      );
    case "categorical":
      return (
        <div>
          <TextInput label={name} onChange={(name, value) => onChangeRangeMapping(ids, name, value, onChange)} name={name} value={arg} />
        </div>
      );
    default:
      return (
        <div>
          <label className="flex gap-x-0.5 text-sm font-bold tracking-wide text-gray-700">{name}</label>
          {value.type}
        </div>
      );
  }
};

export const handleSourceParameters = (value, name, setIds, ids, onChange) => {
  const arg = value["value"];
  const type = value["type"];
  switch (type) {
    case "date":
      return (
        <div>
          <label className="flex gap-x-0.5 text-sm font-bold tracking-wide text-gray-700">{name}</label>
          <DateTimezoneCS callback={(name, value) => onChangeSourceParam(ids, name, value, onChange)} date={arg} name={name} />
        </div>
      );
    case "string":
      return (
        <div>
          <TextInput label={name} onChange={(name, value) => onChangeSourceParam(ids, name, value, onChange)} name={name} value={arg} />
        </div>
      );

    case "int":
      return (
        <div>
          <NumericInput label={name} step={1} onChange={(name, value) => onChangeSourceParam(ids, name, parseInt(value), onChange)} name={name} value={arg} />{" "}
        </div>
      );
    case "float":
      return (
        <div>
          <NumericInput
            label={name}
            step={0.01}
            onChange={(name, value) => onChangeSourceParam(ids, name, parseFloat(value), onChange)}
            name={name}
            value={arg}
          />
        </div>
      );
    default:
      return (
        <div>
          <label className="flex gap-x-0.5 text-sm font-bold tracking-wide text-gray-700">{name}</label>
          <p>-</p>
        </div>
      );
  }
};
