import IndicatorAccordion from "../IndicatorAccordion";
import IndustrialCurrent from "../IndicatorIndustrialHisto/IndustrialCurrent";
import IndustrialParam from "./IndustrialParam";
import DetectionAnomalieHistorical from "../DetectionAnomalie/DetectionAnomalieHistorical";
import { useSelector } from "react-redux";

const IndustrialRightPanel = () => {
  const { mode } = useSelector((state) => state.indicator.moduleData);

  return (
    <div className=" grid min-h-[80vh] grid-flow-row auto-rows-max bg-white  shadow-lg">
      {mode === 0 && <IndicatorAccordion label={"paramétrage des blocs historiques"} composant={<DetectionAnomalieHistorical />} />}
      <IndicatorAccordion label={"paramétrage des blocs courants"} composant={<IndustrialCurrent time={true} />} />
      <IndicatorAccordion label={"Paramètre d'indicateur"} composant={<IndustrialParam />} />
    </div>
  );
};
export default IndustrialRightPanel;
