import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import TextInput from "../../components/Selectors/TextInput";
import IconButton from "../../components/IconButton";
import { toast } from "react-toastify";
import { PrimaryButton } from "../Buttons/Button";
import { putUserApi } from "../../axios/UserManagementApi";
import { useDispatch } from "react-redux";
import { edit } from "../../reducers/dataSlice";
import { isEmptyString } from "../../utils/common";
import { mdiMenuLeft } from "@mdi/js";
import Icon from "@mdi/react";

const ProfileEdit = () => {
  const dispatch = useDispatch();
  const dataloaded = useLoaderData();
  const redirect = "/admin/profile";
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const history = useNavigate();
  const [item, setItem] = useState(dataloaded);
  const onChange = (name, value) => {
    setItem((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <div className="flex gap-x-1.5">
        <IconButton bg={true} Icon={<Icon path={mdiMenuLeft} size={1.5} />} value={""} callback={() => history(redirect)} />
        <h2 className="text-3xl font-semibold text-blue-900">Profil</h2>
      </div>
      <div className={"grid gap-4 border-b  pb-2 lg:grid-cols-2"}>
        <TextInput name={"name"} value={item?.name} onChange={onChange} label={"Nom"} />
        <TextInput name={"description"} value={item?.description} onChange={onChange} label={"Description"} />
      </div>
      <div className={"flex justify-end gap-2 pt-2 "}>
        <PrimaryButton
          isLoading={isLoading}
          disabled={isLoading || isEmptyString(item.description) || isEmptyString(item.name)}
          label={"Modifier"}
          callback={() => {
            setIsLoading(true);
            const structure_id = sessionStorage.getItem("structure_id");
            putUserApi("profile?profile_id=" + id + "&structure_id=" + structure_id, item)
              .then((res) => {
                console.debug(res);
                toast.success("Le profil a été modifié avec succès.");
                dispatch(edit({ type: "profiles", updates: item, id: id }));
                setIsLoading(false);
                history(redirect);
              })
              .catch((error) => {
                setIsLoading(false);
                console.debug(error);
              });
          }}
        />
      </div>
    </div>
  );
};
export default ProfileEdit;
