import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  structure: sessionStorage.getItem("structure_id") ?? "",
  observed_system: sessionStorage.getItem("observed_system_id") ?? "",
  crude_source: sessionStorage.getItem("crude_source_id") ?? "",
  status: { type: sessionStorage.getItem("status_type") ?? "", id: sessionStorage.getItem("status_id") ?? "" },
  virtual_crude_source: sessionStorage.getItem("virtual_crude_source") ?? "",
  page: sessionStorage.getItem("selectedSidebar") ?? "",
};
const emptyState = {
  structure: "",
  observed_system: "",
  crude_source: "",
  status: { type: "", id: "" },
  virtual_crude_source: "",
};
export const selectedSlice = createSlice({
  name: "selected",
  initialState,
  reducers: {
    selectedStructure: (state, action) => {
      state.structure = action.payload;
      state.observed_system = "";
      state.crude_source = "";
    },

    selectedObservedSystem: (state, action) => {
      state.observed_system = action.payload;
    },

    selectedCrudeSource: (state, action) => {
      state.crude_source = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setSelectedVirtualCrudeSource: (state, action) => {
      state.virtual_crude_source = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    reset: () => emptyState,
  },
});
export const { selectedStructure, selectedObservedSystem, selectedCrudeSource, setStatus, setSelectedVirtualCrudeSource, setPage } = selectedSlice.actions;
export default selectedSlice.reducer;
