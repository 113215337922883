import { useSelector } from "react-redux";
import IndustrialRightPanel from "../IndicatorIndustrial/IndustrialRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const IndustrialHisto = () => {
  const { mode } = useSelector((state) => state.indicator.moduleData);
  console.debug(mode);
  return (
    <div className="mx-4 grid gap-4 py-2 lg:grid-cols-2">
      <IndicatorDataConfiguration isTwoBloc={true} />
      <IndustrialRightPanel />
    </div>
  );
};
export default IndustrialHisto;
