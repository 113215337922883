import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { current, historical } from "../event/EventUtils";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import Select from "react-select";
import { getPastDate, getSourceWithMeasure, makeSelectField } from "../../utils/common";
import { initEvent, removeDataParams, setAutoRegressiveParams, setBase } from "../../reducers/EventSlice";
import DataParamCreation from "../event/DataParamCreation";
import TimeParamCreation from "../event/TimeParamCreation";
import ComputationParamCreation from "../event/ComputationParamCreation";
import AutoRegressiveParam from "./AutoRegressiveParam";
import TextInput from "../../components/Selectors/TextInput";

import TimezoneSelect from "react-timezone-select";

import CronDualRange from "../../components/Selectors/CronDualRange";
import { formatOffset } from "../indicator/utils";
import NumericInput from "../../components/Inputs/NumericInput";
import { PrimaryButton } from "../../components/Buttons/Button";
import { postDataApi } from "../../axios/DataManagementApi";

const RegressionModelCreator = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const obs = sessionStorage.getItem("observed_system_id");
  const [dataLoaded, setDataLoaded] = useState(false);
  const dispatch = useDispatch();
  //date handling
  let date = new Date();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let offset = formatOffset(-date.getTimezoneOffset() / 60);
  const start_time = getPastDate(5 * 60)
    .toISOString()
    .split(".")[0];
  const init = {
    current: structuredClone(current),
    historical: structuredClone(historical),
    autoregressive_params: {
      predictor_variables: [],
      target_variable: "",
      order: 3,
      training_data_ratio: 0.8,
      model_name: "",
    },
  };
  const selectedSource = useSelector((state) => state.event.selectedSource);
  const data = useSelector((state) => state.event);
  const source = useSelector((state) => state.event.source);
  const sourceId = useSelector((state) => state.event.sourceId);
  const configurations = useSelector((state) => state.data.configured_source);
  useEffect(() => {
    dispatch(
      initEvent({
        data: structuredClone(init),
        source: getSourceWithMeasure("crude_source"),
        selectedSource: null,
        histo: false,
        count: true,
        sourceId: false,
        offset: offset,
        timeZone: timeZone,
        lockedHisto: true,
        loaded: true,
        model_name: "",
        initial_timestamp: start_time,
        frequency: "5",
        validity_days: "1-5",
        time_slot: "9-18",
      }),
    );
    setDataLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const create = () => {
    setLoading(true);
    const state = structuredClone(data);
    const to_send = {};
    to_send["script_name"] = "autoregressive_module";
    to_send["frequency"] = parseInt(state["frequency"]);
    to_send["validity_days"] = state["validity_days"];
    to_send["time_slot"] = state["time_slot"];
    to_send["script_parameters"] = {};
    to_send["script_parameters"]["observed_system"] = obs;
    to_send["script_parameters"]["parameters"] = state.data;
    to_send["script_parameters"]["initial_timestamp"] = state.initial_timestamp + state.offset;
    to_send["script_parameters"]["timezone"] = state.timeZone;
    to_send["script_parameters"]["parameters"]["autoregressive_params"]["predictor_variables"] = to_send["script_parameters"]["parameters"][
      "autoregressive_params"
    ]["predictor_variables"].map((value) => value.label);

    delete to_send["script_parameters"]["parameters"]["historical"];
    console.debug("data", to_send);
    postDataApi("configuration/module/" + obs, to_send)
      .then((res) => {
        console.log(res); //TODO handle res
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.debug(error);
      });
  };
  return (
    <div>
      <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
        <div className="flex gap-x-1.5">
          <IconButton bg={true} Icon={<Prev size={40} />} value={""} callback={() => history("/analytics/event")} />
          <h2 className="text-3xl font-semibold text-blue-900">Création d&apos;un modèle d&apos;apprentissage</h2>
        </div>
      </div>
      <div>
        {dataLoaded && (
          <div>
            <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
              <h2 className="text-2xl font-semibold text-blue-900">data_params</h2>
              <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Source de données</label>
              <Select
                placeholder={"Source de données..."}
                openMenuOnClick={false}
                isClearable={true}
                isMulti={true}
                value={selectedSource}
                options={makeSelectField(source, ["name"])}
                isDisabled={sourceId}
                isLoading={sourceId}
                onChange={(option, action) => {
                  if (action.action === "remove-value") {
                    const measure_identifier = configurations[source[action.removedValue.value].configuration[0]].measure_identifier;
                    dispatch(removeDataParams(measure_identifier));
                    dispatch(setBase({ type: "selectedSource", value: option }));
                  }
                  if (action.action === "select-option") {
                    dispatch(setBase({ type: "selectedSource", value: option }));
                    dispatch(
                      setBase({
                        type: "sourceId",
                        value: action.option.value,
                      }),
                    );
                  }
                }}
              />

              {sourceId && <DataParamCreation />}
            </div>
            <TimeParamCreation />
            <ComputationParamCreation />
            <AutoRegressiveParam />
            <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
              <h2 className="text-2xl font-semibold text-blue-900">Autres paramètres</h2>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
                <TextInput
                  name={"model_name"}
                  label={"model_name"}
                  value={data.data.autoregressive_params.model_name}
                  onChange={(name, value) => dispatch(setAutoRegressiveParams({ type: name, value: value }))}
                />

                <div className="grid grid-cols-2 gap-2">
                  <TextInput
                    name={"initial_timestamp"}
                    label={"Temps"}
                    step={"1"}
                    value={data.initial_timestamp}
                    type={"datetime-local"}
                    onChange={(name, value) => {
                      dispatch(setBase({ type: name, value: value }));
                    }}
                  />
                  <div>
                    <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">Timezone</label>
                    <TimezoneSelect
                      value={data.timeZone}
                      onChange={(e) => {
                        dispatch(setBase({ type: "timeZone", value: e.value }));
                        dispatch(
                          setBase({
                            type: "offset",
                            value: e.label.substring(4, 9),
                          }),
                        );
                      }}
                    />
                  </div>
                </div>
                <NumericInput
                  name={"frequency"}
                  label={"Frequence"}
                  step={1}
                  value={data.frequency}
                  min={1}
                  onChange={(name, value) => dispatch(setBase({ type: name, value: value }))}
                />
                <div className={"grid grid-cols-2 gap-2"}>
                  <CronDualRange model={"time_slot"} name={"Plage Horaire"} max={23} min={0} />
                  <CronDualRange model={"validity_days"} name={"Jour(s) d'activité"} max={6} min={0} />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <PrimaryButton label={"Créer"} disabled={loading} isLoading={loading} callback={create} />
        </div>
      </div>
    </div>
  );
};
export default RegressionModelCreator;
