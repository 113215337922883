import Select from "react-select";

const SelectMulti = ({
  options,
  onChange,
  style = "w-full py-2 rounded-lg outline-none",
  id,
  loading = false,
  disabled = false,
  placement = "auto",
  value,
  closeMenuOnSelect = false,
}) => {
  const onSelect = (e) => {
    if (e === null) onChange([]);
    else onChange(e);
  };
  return (
    <Select
      value={value}
      isMulti
      closeMenuOnSelect={closeMenuOnSelect}
      menuPlacement={placement}
      hideSelectedOptions={true}
      isClearable={true}
      isDisabled={disabled}
      name={id}
      className={style}
      isLoading={loading}
      onChange={(e) => {
        onSelect(e);
      }}
      isSearchable={true}
      options={options}
    />
  );
};
export default SelectMulti;
