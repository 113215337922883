//React librairies
import { Fragment } from "react";
import { RouterProvider } from "react-router-dom";
import "dayjs/locale/fr"; // ES 2015
//routing
import { router } from "./routing/Routing";
import { usePolling } from "./utils/Polling";
import { getUpperLevel } from "./network/DataManagementApi";

import ToastWrapper from "./components/Toasts/ToastWrapper";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";

import timezone from "dayjs/plugin/timezone";
import { useTranslation } from "react-i18next"; // dependent on utc plugin
const App = () => {
  const { i18n } = useTranslation();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  var duration = require("dayjs/plugin/duration");
  dayjs.extend(duration);

  dayjs.locale(i18n.language);
  usePolling(async () => {
    getUpperLevel();
  }, 1000 * process.env.REACT_APP_POLLING);

  return (
    <Fragment>
      <ToastWrapper />
      <RouterProvider router={router} />
    </Fragment>
  );
};

export default App;
