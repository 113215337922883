import { Fragment } from "react";
import Select from "react-select";
import { Dialog, Transition } from "@headlessui/react";
import { PrimaryButton } from "../Buttons/Button";
import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";

const Modal = ({ name, optionsLabel, columns, callback, openModal, values, updateValues, lockValues, header }) => {
  const { t } = useTranslation(["button"]);
  const handleLock = () => {
    const tmpArray = [...values];

    Object.keys(tmpArray).forEach((key) => {
      lockValues(tmpArray, key);
    });
    updateValues(tmpArray);
    callback();
  };

  return (
    <div>
      <Transition appear show={openModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={callback}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          </Transition.Child>

          <div className="fixed inset-0 ">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className=" h-auto w-2/3 rounded-2xl  bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {header}
                  <div className={"max-h-[50vh] w-full overflow-auto shadow"}>{columns}</div>
                  <div className="mt-4 flex justify-end">
                    <PrimaryButton label={t("add")} callback={handleLock} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className={"flex"}>
        <Select
          isClearable={false}
          menuIsOpen={false}
          className={"w-full"}
          isMulti
          isSearchable={false}
          name={name}
          placeholder={"-"}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(value, action) => updateValues(values.filter((val) => val.id !== action.removedValue.id))}
          value={optionsLabel}
        />
        <PrimaryButton label={<Icon path={mdiPlus} size={1} />} callback={callback} />
      </div>
    </div>
  );
};
export default Modal;
