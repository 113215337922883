import { Fragment, useEffect, useState } from "react";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons/Button";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { dataList, isEmptyObject, makeSelectField } from "../../utils/common";
import Select from "react-select";
import TextInput from "../../components/Selectors/TextInput";
import TransformerVariable from "./TransformerVariable";
import { getDataApi, postDataApi } from "../../axios/DataManagementApi";
import { useTranslation } from "react-i18next";

const TransformerCreatorModal = ({ configuredSourceId, setData }) => {
  const { t } = useTranslation(["transformers"]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { configured_source, crude_source } = useSelector((state) => state.data);
  const [configuredSourceOptions, setConfiguredSourceOptions] = useState([]);
  useEffect(() => {
    setConfiguredSourceOptions(makeSelectField(configured_source, ["description"]));
  }, [configured_source]);
  //data to modify
  const [collectedBy, setCollectedBy] = useState(configuredSourceId);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [parameters, setParameters] = useState({});
  const transformersOptions = [
    {
      value: "SIMPLE",
      label: "Simple",
    },
    {
      value: "DELETION",
      label: "Suppression",
    },
    {
      value: "FORMULA",
      label: "Formule",
    },
  ];
  const [crudeSourceId, setCrudeSourceId] = useState();
  useEffect(() => {
    setCrudeSourceId(configured_source.find((configuration) => configuration.id === configuredSourceId).crude_source);
  }, [configured_source, configuredSourceId]);
  const [measures, setMeasures] = useState([]);
  useEffect(() => {
    const measureOjbect = crude_source.find((crude_source) => crude_source.id === crudeSourceId)?.measure;
    if (isEmptyObject(measureOjbect)) return;
    const measureListWithType = Object.entries(measureOjbect).map((measure) => [measure[0], measure[1].visualization_type]);
    //store an array of [measure, type]
    setMeasures(measureListWithType);
  }, [crudeSourceId, crude_source]);
  const handleCollectedByChange = (newValue) => {
    setCollectedBy(newValue.value);
  };
  const handleFormulaFormat = (formula) => {
    let formulaFormatted = formula;
    measures.forEach((measure) => (formulaFormatted = formulaFormatted.replaceAll(measure[0], `['${measure[0]}']`)));
    return formulaFormatted;
  };
  const handleAdd = () => {
    const parametersToFormat = structuredClone(parameters);
    Object.entries(parametersToFormat).forEach((param) => {
      if (param[1].type === "FORMULA") parametersToFormat[param[0]].value = handleFormulaFormat(param[1].value);
    });
    const payload = {
      observed_system_id: sessionStorage.getItem("observed_system_id"),
      configured_source_id: configuredSourceId,
      transformer_parameters: {
        name: name,
        description: description,
        parameters: {
          data: parametersToFormat,
        },
      },
    };
    if (configuredSourceId !== collectedBy) payload.transformer_parameters.parameters["collected_by"] = collectedBy;
    postDataApi("transformer/add_transformer", payload).then((res) => {
      console.debug(res);
      getDataApi(`transformer/get_transformers?observed_system_id=${sessionStorage.getItem("observed_system_id")}&configured_source_id=${configuredSourceId}`)
        .then((res) => setData(dataList(res.data.data)))
        .catch(() => setData([]));
    });
  };
  const handleCancel = () => {
    setDescription("");
    setCollectedBy(configuredSourceId);
    setName("");
    setModalIsOpen(false);
    setParameters({});
  };
  const handleValue = (value, type) => {
    if (value === "SIMPLE") if (type === "dynamic_numerical") return { type: value, value: 0 };
    if (value === "DELETION") return { type: value };
    if (value === "FORMULA") return { type: value, value: "" };
  };
  const handleSelect = (newValue, actionMeta) => {
    const updates = structuredClone(parameters);
    const index = actionMeta.name;
    const key = measures[index][0];
    const type = measures[index][1];
    const value = actionMeta.action === "select-option" ? newValue.value : null;
    if (value === null) delete updates[key];
    else updates[key] = handleValue(value, type);
    setParameters(updates);
  };
  const handleValueChange = (name, value) => {
    console.debug(name, value);
    const updates = structuredClone(parameters);
    updates[name].value = value;
    setParameters(updates);
  };

  if (configured_source === [] || measures === []) return;
  return (
    <div>
      <PrimaryButton label={t("create_button")} callback={() => setModalIsOpen(true)} />
      <div>
        <Transition appear show={modalIsOpen} as={Fragment}>
          <Dialog as="div" className={"relative z-50"} onClose={handleCancel}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            </Transition.Child>
            <div className="fixed inset-0 ">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className=" h-auto w-2/3 rounded-2xl  bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <div className={"flex flex-col gap-2"}>
                      <div className={"grid grid-cols-2 gap-x-4"}>
                        <TextInput label={"Nom"} value={name} onChange={(name, value) => setName(value)} />
                        <TextInput label={"Description"} value={description} onChange={(name, value) => setDescription(value)} />
                      </div>
                      <div>
                        <label className="text-sm font-bold tracking-wide text-gray-700">Collecté par</label>
                        <Select
                          value={configuredSourceOptions.find((cs) => cs.value === collectedBy)}
                          options={configuredSourceOptions}
                          onChange={handleCollectedByChange}
                        />
                      </div>

                      <div className={"flex flex-col gap-1 "}>
                        <div className={"grid grid-cols-3 bg-blue-900 font-semibold text-white"}>
                          <p className={"flex items-center justify-center"}>{t("variable")}</p>
                          <p className={"flex items-center justify-center"}>{t("mode")}</p>
                          <p className={"flex items-center justify-center"}>{t("value")}</p>
                        </div>
                        {measures.map((measure, index) => (
                          <div className={"grid grid-cols-3"} key={index}>
                            <p className={"flex items-center justify-center px-1 py-0.5"}>{measure[0]}</p>
                            <div className={"flex  items-center justify-center px-1 py-0.5"}>
                              <Select
                                isClearable
                                className={"w-52"}
                                placeholder={"Non considéré"}
                                options={transformersOptions}
                                name={index}
                                onChange={handleSelect}
                              />
                            </div>
                            <TransformerVariable parameters={parameters} variable={measure} handleValueChange={handleValueChange} />
                          </div>
                        ))}
                      </div>
                      <div className={"flex justify-end gap-x-4"}>
                        <SecondaryButton label={"Annuler"} callback={handleCancel} />
                        <PrimaryButton label={"Ajouter"} callback={handleAdd} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
};
export default TransformerCreatorModal;
