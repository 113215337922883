import IndicatorRecurentForm from "../IndicatorRecurentForm";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setExecutionMode } from "../../../reducers/indicatorSlice";

const IndicatorExecutionMode = () => {
  const dispatch = useDispatch();
  const { execution_mode } = useSelector((state) => state.indicator.moduleData);
  const options = [
    { value: 0, label: "Simple" },
    { value: 1, label: "Récurrent" },
  ];
  return (
    <>
      <label className="mb-1 block text-sm font-bold tracking-wide text-gray-900">Mode</label>
      <div className="w-72">
        <Select
          isSearchable={false}
          className="w-full rounded-lg py-2 outline-none"
          onChange={(e) => dispatch(setExecutionMode(e.value))}
          value={options[execution_mode]}
          options={options}
        />
      </div>
      {execution_mode === 1 && <IndicatorRecurentForm />}
    </>
  );
};
export default IndicatorExecutionMode;
