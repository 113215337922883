import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { canRenderComponent, isEmptyString, makeSelectField } from "../../utils/common";
import Select from "react-select";
import { COMPONENTS } from "../../constants/component";
import { PrimaryButton } from "../../components/Buttons/Button";
import { postDataApi } from "../../axios/DataManagementApi";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import { formatBackend } from "../../utils/date.utils";
import dayjs from "dayjs";
import TimeSelector from "../../components/TimeSelector";

const GraphHeatMap = () => {
  const initGraphValue = {
    start_time: dayjs().subtract(7, "day").format("YYYY-MM-DDTHH:mm:ss"),
    end_time: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
    crude_source_id: "",
    target: "",
    timestamp_field: "",
    horizontal_aggregation: "HOURLY",
    vertical_aggregation: "DAILY",
  };
  const [isLoading, setIsLoading] = useState(false);
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [authorizedGraph, setAuthorizedGraph] = useState(canRenderComponent(COMPONENTS.GRAPHIC_CREATION));
  const horizontalOptions = [
    {
      value: "HOURLY",
      label: "horaire",
    },
    {
      value: "DAILY",
      label: "journalier",
    },
  ];
  const verticalOptions = [
    {
      value: "DAILY",
      label: "journalier",
    },
    {
      value: "MONTHLY",
      label: "mensuel",
    },
    {
      value: "YEARLY",
      label: "annuel",
    },
  ];
  useEffect(
    () => () => {
      setAuthorizedGraph(canRenderComponent(COMPONENTS.GRAPHIC_CREATION));
    },
    [authorizedComponent],
  );
  const [graphUrl, setGraphUrl] = useState("");
  const { crude_source } = useSelector((state) => state?.data);
  const [data, setData] = useState(initGraphValue);

  const makeOptions = () => {
    const options = [];
    if (isEmptyString(data.crude_source_id)) return options;
    const variableData = crude_source.find((crudeSource) => crudeSource.id === data.crude_source_id).measure;
    const filter = Object.entries(variableData).filter(
      (variable) => variable[1].visualization_type === "dynamic_numerical" || variable[1].visualization_type === "semistatic_numerical",
    );
    filter.forEach((value) =>
      options.push({
        value: value[0],
        label: value[0],
      }),
    );
    return options;
  };
  console.debug(data);
  const createGraph = () => {
    setGraphUrl("");

    function getDataBlocks(source, sourceList, agregation = "identity") {
      const dataBlocks = [];

      for (let i = 0; i < source.length; i++) {
        const sourceId = source[i];
        const sourceData = sourceList.find((item) => item.id === sourceId);
        console.debug(sourceData);
        if (sourceData && sourceData.measure) {
          const measureKeys = Object.keys(sourceData.measure);
          const timeField = measureKeys.find((key) => sourceData.measure[key].visualization_type === "date");

          const preprocess = {};
          measureKeys.forEach((key) => {
            if (key !== timeField) {
              preprocess[key] = agregation;
            }
          });

          const dataBlock = {
            configured_sources: [],
            time_field: timeField ?? "internal",
            preprocess: preprocess,
            measure_identifier: sourceData.measure_identifier,
            status: -1,
          };

          dataBlocks.push(dataBlock);
        }
      }

      return dataBlocks;
    }

    setIsLoading(true);
    dataLoadingToast({ label: "Création du graphique", isLoading: true });
    const payload = {
      current: {
        data_params: getDataBlocks([data.crude_source_id], crude_source),
        time_params: {
          start_time: formatBackend(data.start_time),
          end_time: formatBackend(data.end_time),
          bucket_size: -1,
        },
        computation_params: {
          formula: "",
          function: {
            name: "identity",
            variables: {},
          },
        },
      },
      visualization_params: {
        target: data.target,
        time_field: data.timestamp_field,
        horizontal_aggregation: data.horizontal_aggregation,
        vertical_aggregation: data.vertical_aggregation,
      },
    };
    postDataApi("status/" + sessionStorage.getItem("observed_system_id"), payload)
      .then((res) => {
        setIsLoading(false);
        setGraphUrl(res.data["creation_data"]["panel_url"]);
        dataLoadingToast({ successMessage: "Création terminé", success: true });
      })
      .catch((error) => {
        setIsLoading(false);
        console.debug(error);
        dataLoadingToast({ errorMessage: error.response.data.message, error: true });
      });
  };

  const sourceSelect = (newValue) => {
    const newData = structuredClone(data);
    newData.crude_source_id = newValue.value;
    newData.target = "";
    const variableData = crude_source.find((crudeSource) => crudeSource.id === newValue.value).measure;
    const filter = Object.entries(variableData).filter((variable) => variable[1].visualization_type === "date");
    newData.timestamp_field = filter[0][0] ?? "internal";
    setData(newData);
  };
  const variableSelect = (newValue) => {
    const newData = structuredClone(data);
    newData.target = newValue.value;
    setData(newData);
  };
  const handleHorizontal = (newValue) => {
    const newData = structuredClone(data);
    newData.horizontal_aggregation = newValue.value;
    setData(newData);
  };
  const handleVertical = (newValue) => {
    const newData = structuredClone(data);
    newData.vertical_aggregation = newValue.value;
    setData(newData);
  };
  const onCreate = (start, end) => {
    const newData = structuredClone(data);
    newData.start_time = start;
    newData.end_time = end;
    setData(newData);
  };
  return (
    authorizedGraph && (
      <div className="m-8">
        <div className="block rounded border border-gray-200 bg-white px-4 py-2">
          <div className={"py-0.5"}>
            <label className="font-bold tracking-wide text-gray-700 ">Source de données</label>
            <Select
              isSearchable={false}
              placeholder={"..."}
              onChange={sourceSelect}
              value={makeSelectField(crude_source, ["name"]).find((value) => value.value === data.crude_source_id)}
              options={makeSelectField(crude_source, ["name"])}
            />
          </div>
          <div className={"py-0.5"}>
            <label className="font-bold tracking-wide text-gray-700 ">Variable à considérer</label>
            <Select
              isSearchable={false}
              placeholder={"..."}
              onChange={variableSelect}
              value={makeOptions().find((value) => value.value === data.target) ?? ""}
              options={makeOptions()}
            />
          </div>
          <div className={"py-0.5"}>
            <label className="font-bold tracking-wide text-gray-700 ">Plage de temps</label>
            <div className={"flex"}>
              <TimeSelector start_time={data.start_time} end_time={data.end_time} callback={onCreate} />
            </div>
          </div>
          <div className={"grid grid-cols-2 gap-x-0.5 py-0.5"}>
            <div>
              <label className="font-bold tracking-wide text-gray-700 ">Axe horizontal</label>
              <Select
                isSearchable={false}
                placeholder={"..."}
                onChange={handleHorizontal}
                value={horizontalOptions.find((value) => value.value === data.horizontal_aggregation) ?? ""}
                options={horizontalOptions}
              />
            </div>
            <div>
              <label className="font-bold tracking-wide text-gray-700 ">Axe vertical</label>
              <Select
                isSearchable={false}
                placeholder={"..."}
                onChange={handleVertical}
                value={verticalOptions.find((value) => value.value === data.vertical_aggregation) ?? ""}
                options={verticalOptions}
              />
            </div>
          </div>
        </div>

        <div className="col-span-5 flex justify-center">
          <PrimaryButton label={"Créer"} disabled={isLoading} callback={createGraph} />
        </div>
        {!isEmptyString(graphUrl) && (
          <div className="m-8 block h-[80vh] rounded border border-gray-200 bg-white p-4">
            <iframe className="h-full  w-full " src={graphUrl} />
          </div>
        )}
      </div>
    )
  );
};
export default GraphHeatMap;
