import { useDispatch, useSelector } from "react-redux";
import { setModuleData, setParameters } from "../../../reducers/indicatorSlice";
import TimezoneSelect from "react-timezone-select";
import TimeInput from "../../../components/Inputs/TimeInput";
import { IndicatorEvent } from "../IndicatorEvent";

const OutlierValidationParameter = () => {
  const dispatch = useDispatch();
  const selectedEvent = useSelector((state) => state.indicator.moduleData.parameters.event_id);
  const { initial_timestamp, timezone } = useSelector((state) => state.indicator.moduleData);

  return (
    <div>
      <IndicatorEvent selectedEvent={selectedEvent} setEvent={setParameters} keyName={"event_id"} />
      <div className="flex gap-x-0.5">
        <TimeInput
          name={"initial_timestamp"}
          label={"Date et heure"}
          value={initial_timestamp}
          onChange={(name, value) => dispatch(setModuleData({ key: name, value: value }))}
        />
        <div>
          <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"fuseau horaire"}</label>
          <TimezoneSelect
            value={timezone}
            onChange={(e) => {
              dispatch(setModuleData({ key: "timezone", value: e.value }));
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default OutlierValidationParameter;
