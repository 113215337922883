import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { canRenderComponent, dataList, getUserPreferences } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableFilter } from "../../components/Table/TableFilter";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons/Button";
import { getDataApi } from "../../axios/DataManagementApi";
import { set } from "../../reducers/dataSlice";
import { TableHeader } from "../../components/Table/TableHeader";
import { TableBody } from "../../components/Table/TableBody";
import { TablePagination } from "../../components/Table/TablePagination";
import BasicTooltip from "../../components/Tooltip/BasicTooltip";

const DateInfo = ({ date }) => {
  const { format, value } = date;

  return (
    <div>
      <h2 className={"text-lg"}> Information de la date</h2>
      <div className={"grid grid-cols-2 border-l border-blue-900 px-2"}>
        <p className={"font-semibold"}>Format: </p>
        <p> {format}</p>
        <p className={"font-semibold"}>Valeur: </p>
        <p>{value.join(", ").replaceAll("*", "→")}</p>
      </div>
    </div>
  );
};
const LabelList = () => {
  const dispatch = useDispatch();
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const showId = getUserPreferences().find((preference) => preference.name === "showId")?.value ?? true;

  const [authorizedEventCreation, setAuthorizedEventCreation] = useState(canRenderComponent(COMPONENTS.EVENT_CREATION));
  useEffect(
    () => () => {
      setAuthorizedEventCreation(canRenderComponent(COMPONENTS.EVENT_CREATION));
    },
    [authorizedComponent],
  );
  const [isLoading, setIsLoading] = useState(false);

  const data = useSelector((state) => state.data.labels);
  const [globalFilter, setGlobalFilter] = useState("");
  const history = useNavigate();
  const createLabel = () => history("/analytics/labels/create");

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("label", {
      id: "label",
      header: "Label",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: "Description",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "date",
      cell: (info) => <BasicTooltip tip={<DateInfo date={info.row.original.value.date} />} />,
    }),
  ];
  console.debug(data);
  if (!showId) columns.splice(0, 1);
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>

        <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
          <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
            <SecondaryButton
              isLoading={isLoading}
              disabled={isLoading}
              callback={() => {
                setIsLoading(true);
                getDataApi("label?observed_system_id=" + sessionStorage.getItem("observed_system_id"))
                  .then((res) => {
                    console.debug(dataList(res.data));
                    const labels = res.data;
                    const labelList = labels.id.map((value, index) => {
                      return {
                        id: value,
                        description: labels.description[index],
                        label: labels.label[index],
                        value: labels.value[index],
                      };
                    });
                    dispatch(set({ type: "labels", data: labelList }));
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    console.debug(error);
                  });
              }}
              label="Mettre à jour"
            />
          </div>
          {authorizedEventCreation && (
            <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
              <PrimaryButton disabled={true} callback={createLabel} label="Créer un label" />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};

export default LabelList;
