import { sourceField } from "../../utils/field";
import TableSP from "../../components/Selectors/TableSP";
import TableMV from "../../components/Selectors/TableMV";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/common";

const Recap = ({ obs_info, conf_info, mv_info, sp_info, storage_info, os_info }) => {
  const { t } = useTranslation(["common"]);
  const handle_mv = (data) => {
    const ret = [];
    console.debug(data);
    data.forEach((elem) => {
      console.debug(elem);
      let param = "/";
      switch (elem.type) {
        case "nominal":
        case "ordered":
          param = elem.visualization_format.label.map((label) => `'${label.label}' => ${label.description}`).join(" - ");
          break;
        case "string":
          param = elem.mapping_information;
          break;
        case "date":
          break;
        case "int":
        case "float":
          param = elem.mapping_information.min + "-" + elem.mapping_information.max;
          break;
        default:
          break;
      }
      if (elem.locked) ret.push({ name: elem.name, type: elem.type, param: param });
    });
    console.debug(ret);
    return ret;
  };

  const handle_owner_ids = (owner) => {
    const stringBuilder = [];
    owner.forEach((elem) => {
      console.debug(elem);
      const system = os_info[elem.value];
      console.debug(system);
      stringBuilder.push(system.name);
    });
    return stringBuilder.join(" - ");
  };
  const obs_render = () => {
    const storage = storage_info.find((value) => value.id === obs_info.storage_system_id);
    return (
      <div>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-semibold leading-6 text-blue-900">{capitalizeFirstLetter(t("observed_system"))}</h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="border-x border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("storage"))}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{storage === undefined ? "" : storage.name}</dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("name"))}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{obs_info.name}</dd>
            </div>
            <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("description"))}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{obs_info.description}</dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("link"))}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{obs_info.membership}</dd>
            </div>
            <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("link_obs"))}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{handle_owner_ids(obs_info.owner_ids)}</dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };
  const source_render = () => {
    const source_type = sourceField.find((e) => e.value === conf_info.source_type);
    const storage = conf_info.storage_system_id === "" ? storage_info[obs_info.storage_system_id] : storage_info[conf_info.storage_system_id];
    return (
      <div className={"col-span-2 lg:col-span-1"}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-semibold leading-6 text-blue-900">{capitalizeFirstLetter(t("data_source"))}</h3>
        </div>
        <div className="border-x border-t border-gray-200">
          <dl>
            <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("name"))}</dt>
              <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conf_info.name}</dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("description"))}</dt>
              <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conf_info.description}</dd>
            </div>
            <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("type"))}</dt>
              <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{source_type?.label ?? ""}</dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("same_storage")).replace("?", "")}</dt>
              <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {conf_info.is_active ? <CheckCircleIcon className={"h-4 w-4 text-green-600"} /> : <XCircleIcon className={"h-4 w-4 text-red-700"} />}
              </dd>
            </div>
            {conf_info.storage_system_id !== "" && (
              <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("storage"))}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{storage.name ?? ""}</dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    );
  };
  const conf_render = () => (
    <div className={"col-span-2 lg:col-span-1"}>
      <div className="px-4 py-5">
        <h3 className="text-lg font-semibold leading-6 text-blue-900">{capitalizeFirstLetter(t("configured_source"))}</h3>
      </div>
      <div className="border-x border-t border-gray-200">
        <dl>
          <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("description"))}</dt>
            <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conf_info.configured_source_description}</dd>
          </div>
          <div className="border-t  border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">ID</dt>
            <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conf_info.id}</dd>
          </div>
          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("active"))}</dt>
            <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {conf_info.is_active ? <CheckCircleIcon className={"h-4 w-4 text-green-600"} /> : <XCircleIcon className={"h-4 w-4 text-red-700"} />}
            </dd>
          </div>

          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("alert_delay"))}</dt>
            <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conf_info.alert_delay}</dd>
          </div>
        </dl>
      </div>
    </div>
  );

  const sp_render = () => <TableSP name={capitalizeFirstLetter(t("source_parameters"))} data={sp_info} />;
  const mv_render = () => <TableMV name={capitalizeFirstLetter(t("mapping_value"))} data={handle_mv(mv_info)} />;
  return (
    <div className={"bg-white p-2"}>
      {obs_render()}
      <div className="grid grid-cols-2 gap-2">
        {source_render()}
        {conf_render()}
        {sp_render()}
        {mv_render()}
      </div>
    </div>
  );
};
export default Recap;
