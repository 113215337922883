import MailEvent from "./MailEvent";
import AnalyticalFormulaEvent from "./AnalyticalFormulaEvent";
import KalmanEvent from "./KalmanEvent";
import AutoregressivepredictionEvent from "./AutoregressivepredictionEvent";
import CorrelationEvent from "./CorrelationEvent";
import dayjs from "dayjs";

// export const discover = (mapping, set) => {
//   Object.keys(mapping).forEach((property) => getItem(property, mapping[property], set));
// };
// const getItem = (name, property, set) => {
//   switch (property.type) {
//     case "object":
//       if (property.properties) {
//         set((oldArray) => [...oldArray, <h1> {name} OBJECT</h1>]);
//         return discover(property.properties, set);
//       } else {
//         set((oldArray) => [...oldArray, <p> {name} - new specific component </p>]);
//         break;
//       }
//
//     case "string":
//       set((oldArray) => [...oldArray, <TextInput label={name} type={"text"} />]);
//       break;
//     case "number":
//       set((oldArray) => [...oldArray, <TextInput label={name} type={"number"} />]);
//       break;
//     case "array":
//       set((oldArray) => [...oldArray, <p> {name} IS ARRAY</p>]);
//       return getItem(name, property.items, set);
//
//     default:
//       set((oldArray) => [...oldArray, <p> default case - ??? </p>]);
//   }
// };

export const mailEvent = {
  module_name: "Email",
  parameters: {
    sender: "notifications-retd@preditic.com",
    receivers: [],
    subject: "",
    message: "",
    server_param: {
      smtp_password: "3011aa9335d91de2b0f7780b55d29f80",
      smtp_server: "in-v3.mailjet.com",
      smtp_login: "8e3e8a8be2d6e67d77da73f927dd0bf6",
      port: 587,
    },
  },
  function_name: "send_notification",
};
// export const analyticalFormulaEvent = {
//   module_name: "Analyticalformula",
//   function_name: "??",
//   parameters: {
//     data_params: [],
//   },
// };
export const data_params = {
  status: -1,
  measure_identifier: "",
  time_field: "internal",
  preprocess: {},
  configured_sources: [],
};
export const time_params = {
  start_time: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
  end_time: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
  bucket_size: 3600,
  label: "",
};
export const computation_params = {
  function: {
    variables: {},
    name: "identity",
  },
  formula: "",
};
export const current = {
  data_params: [],
  time_params: structuredClone(time_params),
  computation_params: structuredClone(computation_params),
};
export const historical = {
  data_params: [],
  time_params: {
    count: {
      delay: 1,
      length: 5,
    },
    time: {
      delay: 1,
      length: 5,
    },
    bucket_size: -1,
  },
  computation_params: structuredClone(computation_params),
};
export const renderEvent = (event) => {
  switch (event) {
    case "Analyticalformula":
      return <AnalyticalFormulaEvent />;
    case "Email":
      return <MailEvent />;
    case "Kalmanprediction":
      return <KalmanEvent />;
    case "Autoregressiveprediction":
      return <AutoregressivepredictionEvent />;
    case "Correlation":
      return <CorrelationEvent />;
    case "Kalmanvalidation":
      return <KalmanEvent />;

    // case "IndicatorCreator":
    //     return <IndicatorEvent />
    default:
      return <p>{event} -WIP</p>;
  }
};

export const getValueFromSelect = (options) => options.map((option) => option.value);
export const makeOptions = (options) => {
  const formattedOptions = [];
  for (let i = 0; i < options.length; i++) {
    formattedOptions.push({ value: i, label: options[i] });
  }
  return formattedOptions;
};

export const redirect = "/analytics/event";
