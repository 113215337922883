import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { renderEvent } from "./EventUtils";
import { makeSelectField } from "../../utils/common";

const makeOptions = (options) => {
  const formattedOptions = [];
  for (let i = 0; i < options.length; i++) {
    formattedOptions.push({ value: i, label: options[i] });
  }
  return formattedOptions;
};
const EventCreator2 = () => {
  const history = useNavigate();
  const [event, setEvent] = useState(null);
  const [obs, setObs] = useState(undefined);
  const moduleName = useSelector((state) => state.data.moduleName);
  const systems = useSelector((state) => state.data.observed_system);
  const options = makeOptions(moduleName);
  const [eventRendered, setEventRendered] = useState();
  useEffect(() => {
    if (event !== null) setEventRendered(renderEvent(event.label, obs?.value));
  }, [event, obs]);

  return (
    <div>
      <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
        <div className="flex gap-x-1.5">
          <IconButton bg={true} Icon={<Prev size={40} />} value={""} callback={() => history("/analytics/indicator")} />
          <h2 className="text-3xl font-semibold text-blue-900">Création d&apos;une action</h2>
        </div>
        <div className="mx-20 grid grid-cols-2 gap-2">
          <div>
            <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"action"}</label>
            <Select
              isSearchable={true}
              onChange={(option) => {
                setEvent(option);
              }}
              value={event}
              options={options}
            />
          </div>
          <div>
            <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"Système observé (optional ?)"}</label>
            <Select
              isSearchable={true}
              onChange={(option) => {
                setObs(option);
              }}
              value={obs}
              options={makeSelectField(systems, ["description"])}
            />
          </div>
        </div>
      </div>
      {eventRendered}
    </div>
  );
};
export default EventCreator2;
