import { Fragment, useState } from "react";
import Select from "react-select";
import GraphSimple from "./GraphSimple";
import GraphStateTimeline from "./GraphStateTimeline";
import GraphHeatMap from "./GraphHeatMap";

const GraphList = () => {
  const [graph, setGraph] = useState(0);
  const graphOptions = [
    {
      value: 0,
      label: "Courbe",
    },
    { value: 1, label: "Diagramme catégoriel" },
    { value: 2, label: "Heatmap temporelle" },
  ];
  const handleChange = (newValue) => {
    setGraph(newValue.value);
  };
  const handleDescription = () => {
    switch (graph) {
      case 0:
        return "Représentation graphique de données numériques sur une échelle temporelle";
      case 1:
        return "Evolution des différentes catégories au fil du temps";
      case 2:
        return "Graphique qui représente l'évolution des données sur une échelle temporelle sous la forme d'une carte thermique";
    }
  };
  return (
    <Fragment>
      <div className={"mx-8 mt-8 rounded-lg border border-gray-200 bg-white px-8 py-4 shadow"}>
        <div className="flex justify-between gap-x-1.5">
          <div>
            <h2 className="text-3xl font-semibold text-blue-900">{"Visualisation des données"}</h2>
            <div className={"mx-12 w-72 py-2"}>
              <label className="font-bold tracking-wide text-gray-700 ">Mode de visualisation</label>
              <Select isSearchable={false} options={graphOptions} onChange={handleChange} value={graphOptions.find((value) => value.value === graph)} />
            </div>
            <div className={"mx-12 py-2 text-gray-700"}>
              <p>{handleDescription()}</p>
            </div>
          </div>
        </div>
      </div>
      {graph === 0 && <GraphSimple />}
      {graph === 1 && <GraphStateTimeline />}
      {graph === 2 && <GraphHeatMap />}
    </Fragment>
  );
};
export default GraphList;
