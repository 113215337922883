import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  indicators: {},
  selIndicator: null,
  selSystem: sessionStorage.getItem("observed_system_id") ?? "",
  selSource: "",
  selConfiguration: [],
  moduleData: {},
  selected_crude_source: [],
};

export const indicatorSlice = createSlice({
  name: "indicator",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setCurrentTimeParams: (state, action) => {
      state.moduleData.current.time_params[action.payload.key] = action.payload.value;
    },
    setCurrentComputationParams: (state, action) => {
      state.moduleData.current.computation_params[action.payload.key] = action.payload.value;
    },
    setHistoricalComputationParams: (state, action) => {
      state.moduleData.historical.computation_params[action.payload.key] = action.payload.value;
    },
    setHistoricalTimeParams: (state, action) => {
      state.moduleData.historical.time_params[action.payload.key] = action.payload.value;
    },
    setScriptParameters: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.script_parameters[key] = value;
    },
    setModuleParams: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.module_params[key] = value;
    },
    set: (state, action) => {
      state.moduleData = action.payload;
      state.selSource = sessionStorage.getItem("crude_source_id") ?? "";
      state.selConfiguration = [];
      state.selVariable = [];
      state.selected_crude_source = [];
    },
    setMode: (state, action) => {
      state.moduleData.mode = action.payload;
      if (action.payload === "1") state.moduleData.historical.data_params = [];
      state.moduleData.current.data_params = [];
      state.selected_crude_source = [];
    },
    setExecutionMode: (state, action) => {
      //0 or 1 => linked to simple/recurent
      state.moduleData.execution_mode = action.payload;
    },
    setHistoricalMode: (state, action) => {
      state.moduleData.historicalMode = action.payload;
    },
    setAlertParams: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.alert_params[key] = value;
    },

    setIndicatorParams: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.indicator_params[key] = value;
    },
    setAlertSettings: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.alert_settings[key] = value;
    },
    setModuleData: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData[key] = value;
    },

    setParameters: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.parameters[key] = value;
    },
    setOne: (state, action) => {
      state[action.payload.type] = action.payload.data;
      if (action.payload.type === "selSystem") {
        state.configurations = [];
        state.selConfiguration = [];
        state.selSource = [];
        state.variables = [];
        state.selVariable = [];
        state.sources = {};
      }
    },
    setModelParams: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.model_params[key] = value;
    },
    setPredictionParams: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.prediction_params[key] = value;
    },
    setReconstructionParams: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.reconstruction_params[key] = value;
    },
    setDataParam: (state, action) => {
      if (!state.moduleData.current.data_params.some((value) => value.measure_identifier === action.payload.cur.measure_identifier)) {
        state.moduleData.current.data_params.push(action.payload.cur);
        if (action.payload.hist !== null) state.moduleData.historical.data_params.push(action.payload.hist);
      } else {
        toast.warn("Source de données déjà ajoutée");
      }
    },
    removeDataParam: (state, action) => {
      if (state.moduleData.mode === 0)
        // case where we got a historical block
        state.moduleData.historical.data_params = state.moduleData.historical.data_params.filter((bloc) => bloc.measure_identifier !== action.payload);
      state.moduleData.current.data_params = state.moduleData.current.data_params.filter((bloc) => bloc.measure_identifier !== action.payload);
    },
    setPredictors: (state, action) => {
      state.moduleData.prediction_params.predictors[action.payload.key] = action.payload.value;
    },
    setTargetVariables: (state, action) => {
      state.moduleData.target_variables = action.payload;
    },
    setPcaParams: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.pca_params[key] = value;
    },
    setCharacterizationParams: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.characterization_params[key] = value;
    },
    setCrudeSource: (state, action) => {
      state.selected_crude_source = action.payload;
      if (state.moduleData.target_variables)
        state.moduleData.target_variables = state.moduleData.target_variables.filter((value) => state.selected_crude_source.includes(value.crude_source_id));
    },
    setMultipleSources: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.multiple_sources[key] = value;
    },
    setMultipleSourcesTimeField: (state, action) => {
      const { key, value } = action.payload;
      state.moduleData.multiple_sources[key].time_field = value;
    },
    removeMultipleSources: (state, action) => {
      delete state.moduleData.multiple_sources[action.payload];
    },
    setDefiningStatus: (state, action) => {
      const { key, value } = action.payload;
      if (key === "type") state.moduleData.defining_status.id = "";
      if (key === "type" && value === "OBSERVED_SYSTEM") state.moduleData.defining_status.id = sessionStorage.getItem("observed_system_id");
      state.moduleData.indicator_id = {};
      state.moduleData.defining_status[key] = value;
    },
  },
});

export const {
  reset,
  set,
  setDataParam,
  removeDataParam,
  setModuleData,
  setCurrentTimeParams,
  setCurrentComputationParams,
  setMode,
  setHistoricalTimeParams,
  setHistoricalComputationParams,
  setHistoricalMode,
  setScriptParameters,
  setAlertParams,
  setModuleParams,
  setAlertSettings,
  setIndicatorParams,
  setParameters,
  setModelParams,
  setPredictionParams,
  setPredictors,
  setExecutionMode,
  setTargetVariables,
  setPcaParams,
  setCrudeSource,
  setMultipleSources,
  removeMultipleSources,
  setCharacterizationParams,
  setDefiningStatus,
  setReconstructionParams,
} = indicatorSlice.actions;
export default indicatorSlice.reducer;
