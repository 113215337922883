import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { variableField } from "./utils";
import { removeMultipleSources, setMultipleSources, setPredictionParams, setPredictors } from "../../reducers/indicatorSlice";
import SwitchButton from "../../components/Selectors/SwitchButton";

import { findDateField, makeSelectField } from "../../utils/common";
import LabelTooltip from "../../components/Tooltip/LabelTooltip";

function Predictors() {
  const { data_params } = useSelector((state) => state.indicator.moduleData.current);
  const { predictors } = useSelector((state) => state.indicator.moduleData.prediction_params);
  const dispatch = useDispatch();
  return (
    <div className={""}>
      <div className={"grid grid-cols-2 gap-x-1.5"}>
        <LabelTooltip label={"Predicteur(s) du modèle"} tip={"Défini(s) par le modèle selectionné"} />
        <LabelTooltip label={"Predicteur(s) d'usage"} tip={"Défini(s) par les sources de données selectionnés"} />
      </div>

      {Object.entries(predictors).map((variable, index) => (
        <div key={index} className={"mb-1 grid grid-cols-2 gap-x-1.5"}>
          <Select isDisabled={true} isSearchable={false} value={{ value: variable[0], label: variable[0] }} />
          <Select
            value={{ value: variable[1].usage, label: variable[1].usage }}
            onChange={(newValue) =>
              dispatch(
                setPredictors({
                  key: variable[0],
                  value: { ...variable[1], usage: newValue.value },
                }),
              )
            }
            options={variableField(data_params)}
          />
        </div>
      ))}
    </div>
  );
}

function MultiSource() {
  const { prediction_params, multiple_sources } = useSelector((state) => state.indicator.moduleData);
  const { predictors } = prediction_params;
  const { crude_source } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const handleCrudeSource = (key, newValue, actionMeta) => {
    const timefield = findDateField(crude_source.find((cs) => cs.id === newValue.value).measure);
    if (actionMeta.action === "select-option") {
      dispatch(
        setMultipleSources({
          key: key,
          value: {
            measure_identifier: newValue.value,
            time_field: { value: newValue.value + "_" + timefield, label: timefield, crude_source_id: newValue.value },
          },
        }),
      );
    } else if (actionMeta.action === "clear") {
      dispatch(removeMultipleSources(key));
    }
  };

  return (
    <div className={"px-8"}>
      {Object.values(predictors).map((value, index) => {
        const measure_identifier = multiple_sources[value.usage]?.measure_identifier;
        return (
          <div key={index} className={"mb-1 grid grid-cols-2 gap-x-1.5"}>
            <Select isDisabled={true} isSearchable={false} value={{ value: value.usage, label: value.usage }} />
            <Select
              isClearable={false}
              placeholder={"Source brute"}
              options={makeSelectField(crude_source, ["name", "description"])}
              value={makeSelectField(crude_source, ["name", "description"]).find((cs) => cs.value === measure_identifier)}
              onChange={(newValue, actionMeta) => handleCrudeSource(value.usage, newValue, actionMeta)}
            />
          </div>
        );
      })}
    </div>
  );
}

export function PredictionParams({ multiSource, setMultiSource }) {
  const { prediction_params, current } = useSelector((state) => state.indicator.moduleData);
  const { training_model } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const { target, model_id } = prediction_params;
  const { origin, usage } = target;
  const handleTargetChange = (key, value) => {
    dispatch(
      setPredictionParams({
        key: "target",
        value: { ...prediction_params.target, [key]: value },
      }),
    );
  };
  return (
    <div className={"grid grid-cols-1 gap-2 py-2"}>
      <Select
        value={makeSelectField(training_model, ["model_name", "method_name"]).find((value) => value.value === model_id)}
        onChange={(newValue) => {
          dispatch(setPredictionParams({ key: "model_id", value: newValue.value }));
          const updates = training_model.find((value) => value.id === newValue.value);
          dispatch(setPredictionParams({ key: "target", value: { origin: updates.target, usage: updates.target } }));
          const predict = {};
          Object.entries(updates.predictors).forEach((entry) => {
            predict[entry[0]] = { usage: entry[0], orders: entry[1] };
          });
          dispatch(setPredictionParams({ key: "predictors", value: predict }));
        }}
        options={makeSelectField(training_model, ["model_name", "method_name"])}
      />
      <div className={"grid grid-cols-2 gap-x-1.5"}>
        <div>
          <LabelTooltip label={"Cible d'origine"} tip={"Cible défini par le modèle selectionné"} />
          <Select isDisabled={true} isSearchable={false} value={{ value: origin, label: origin }} />
        </div>

        <div>
          <LabelTooltip label={"Cible d'usage"} tip={"Cible défini par la source(s) de données selectionné"} />
          <Select
            isSearchable={false}
            menuPosition={"fixed"}
            value={{ value: usage, label: usage }}
            onChange={(newValue) => handleTargetChange("usage", newValue.value)}
            options={variableField(current.data_params)}
          />
        </div>
      </div>
      <Predictors />
      <SwitchButton label={"Source externe pour les données historiques"} value={multiSource} onChange={(value) => setMultiSource(value)} />
      {multiSource && <MultiSource />}
    </div>
  );
}
