import { DeleteButton, PrimaryButton } from "../../components/Buttons/Button";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import { filtersOptions } from "./databaseFeedback.utils";
import dayjs from "dayjs";
import RequestParametersFiltersDisplay from "./RequestParametersFiltersDisplay";
import { DisplayValue } from "./DisplayValue";

const RequestParametersFiltersEditor = ({ mapping, mappingWithType, setFilter, filters, isElasticSearch = false }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [columnUsed, setColumnUsed] = useState(null);
  const [operator, setOperator] = useState(null);
  const [value, setValue] = useState(null);
  const [type, setType] = useState(null);
  const handleAdd = () => {
    setFilter(columnUsed, value);
    handleCancel();
  };
  const handleCancel = () => {
    setColumnUsed(null);
    setOperator(null);
    setValue(null);
    setType(null);
    setModalIsOpen(false);
  };

  const [options, setOptions] = useState([]);
  useEffect(() => {
    const options = mapping
      .filter((res) => mappingWithType[res] !== "NoneType" && !Object.keys(filters).includes(res))
      .map((res) => {
        return { label: res, value: res };
      });
    setOptions(options);
  }, [filters, mapping, mappingWithType]);
  const handleColumnUsedChange = (newValue) => {
    setColumnUsed(newValue.value);
    const type = mappingWithType[newValue.value];
    setOperator(type === "str" ? "list" : null);
    setValue(type === "str" ? { in: [] } : null);
    setType(mappingWithType[newValue.value]);
  };
  const handleOperatorChange = (newValue) => {
    setOperator(newValue.value);
    if (newValue.value === "list") setValue({ in: [] });
    if (newValue.value === "range") setValue({ "<=": setValueByType(type), ">=": setValueByType(type) });
    if (newValue.value === "gt") setValue({ ">=": setValueByType(type) });
    if (newValue.value === "lt") setValue({ "<=": setValueByType(type) });
  };
  return (
    <div>
      <div className={"grid grid-cols-4 bg-blue-900 font-semibold text-white"}>
        <p className={"flex items-center justify-center"}>Nom</p>
        <p className={"flex items-center justify-center"}>Type</p>
        <p className={"flex items-center justify-center"}>Valeur</p>
        <div className={"flex items-center justify-center"}>
          <PrimaryButton label={"Ajouter un filtre"} callback={() => setModalIsOpen(true)} />
        </div>
      </div>
      <RequestParametersFiltersDisplay setFilters={setFilter} filters={filters} mappingWithType={mappingWithType} />

      <div>
        <Transition appear show={modalIsOpen} as={Fragment}>
          <Dialog as="div" className={"relative z-50"} onClose={setModalIsOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            </Transition.Child>
            <div className="fixed inset-0 ">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className=" h-auto w-2/3 rounded-2xl  bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <div className={"flex flex-col gap-2"}>
                      <div className={"grid grid-cols-4 gap-x-1"}>
                        <div>
                          <label className="font-bold tracking-wide text-gray-700">Colonne utilisée</label>
                          <Select options={options} value={options.find((option) => option.value === columnUsed)} onChange={handleColumnUsedChange} />
                        </div>
                        <div>
                          <label className="font-bold tracking-wide text-gray-700">Type</label>
                          <Select
                            hideSelectedOptions
                            options={type === "str" && !isElasticSearch ? filtersOptions.filter((filter) => filter.value === "list") : filtersOptions}
                            value={filtersOptions.find((option) => option.value === operator) ?? null}
                            onChange={handleOperatorChange}
                          />
                        </div>
                        <div className={"col-span-2 gap-x-0.5"}>
                          <DisplayValue type={type} value={value} setValue={setValue} operator={operator} />
                        </div>
                      </div>
                      <div className={"flex justify-end gap-x-0.5"}>
                        <DeleteButton label={"Annuler"} callback={handleCancel} />
                        <PrimaryButton label={"Ajouter"} callback={handleAdd} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
};
export default RequestParametersFiltersEditor;

const setValueByType = (type) => {
  if (type === "str") return "";
  if (type === "datetime") return dayjs().format("YYYY-MM-DDTHH:mm:ss");
  if (type === "int") return 0;
  if (type === "float") return 0;
};
