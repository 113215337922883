import TextInput from "../../components/Selectors/TextInput";
import TimeInput from "../../components/Inputs/TimeInput";
import NumericInput from "../../components/Inputs/NumericInput";

export const DisplayType = ({ value, type, setValue, label }) => {
  if (type === "str") return <TextInput label={label} value={value} onChange={(name, val) => setValue(val)} />;
  if (type === "datetime") return <TimeInput label={label} value={value} onChange={(name, val) => setValue(val)} />;
  if (type === "int") return <NumericInput label={label} value={value} onChange={(name, val) => setValue(val)} />;
  if (type === "float") return <NumericInput label={label} value={value} onChange={(name, val) => setValue(val)} step={0.1} />;
};
