import { Fragment, useEffect, useState } from "react";
import { storageField } from "../../utils/field";
import TextInput from "../Selectors/TextInput";

import { Dialog, Transition } from "@headlessui/react";
import { isEmptyObject, isEmptyString } from "../../utils/common";
import DataForm from "../DataForm/DataForm";
import Select from "react-select";
import { postDataApi } from "../../axios/DataManagementApi";
import { toast } from "react-toastify";
import { push } from "../../reducers/dataSlice";
import { useDispatch } from "react-redux";
import { PrimaryButton, SecondaryButton } from "../Buttons/Button";

const StorageModal = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const [storageSystem, setStorageSystem] = useState(-1);
  const [name, setName] = useState(isOpen);
  useEffect(() => {
    setName(isOpen);
  }, [isOpen]);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const cancel = () => {
    setIsOpen("");
  };
  const canAdd = isEmptyObject(storageSystem) || isEmptyString(name) || isEmptyString(description);
  const add = () => {
    setLoading(true);
    const structure = sessionStorage.getItem("structure_id");
    const data = {
      structure_id: structure,
      name: name,
      description: description,
      storage_type: storageSystem.value,
    };
    postDataApi("storage_system?structure_id=" + sessionStorage.getItem("structure_id"), data).then((res) => {
      const index = res.data["id"];
      toast.success("Système de stockage créé");
      dispatch(
        push({
          data: res.data.data[index],
          type: "storage_system",
        }),
      );
      setIsOpen("");
      setLoading(false);
    });
  };
  const field = [
    {
      id: 0,
      name: "Stockage",
      type: <Select isSearchable={false} options={storageField} onChange={(e) => setStorageSystem(e)} name="storage_type" value={storageSystem} />,
      tooltip: false,
    },
    {
      id: 1,
      name: "nom",
      type: <TextInput placeholder={"Nom de l'instance - système de stockage"} name={"name"} value={name} onChange={(e, v) => setName(v)} />,
      tooltip: false,
    },
    {
      id: 2,
      name: "Description",
      type: <TextInput placeholder={"Description de l'instance"} name={"description"} value={description} onChange={(e, v) => setDescription(v)} />,
      tooltip: false,
    },
    {
      id: 3,
      type: (
        <div className="flex justify-center  gap-x-1.5">
          <SecondaryButton callback={cancel} label={"Annuler"} />
          <PrimaryButton label={"Ajouter"} isLoading={loading} disabled={canAdd} callback={add} />
        </div>
      ),
      tooltip: false,
    },
  ];

  return (
    <Transition appear show={!isEmptyString(isOpen)} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={cancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="ml-64 w-1/2 overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <DataForm
                  isMulti={true}
                  isLoading={false}
                  disabled={true}
                  information={"Instance"}
                  description={"formulaire pour créer une nouvelle instance en fonction du type de stockage choisit."}
                  field={field}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default StorageModal;
