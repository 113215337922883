import classNames from "classnames";

const TextInput = ({
  width = "w-full",
  disabled = false,
  label = "",
  name,
  type = "text",
  onChange,
  step = "any",
  placeholder,
  value,
  max,
  min,
  required = false,
}) => (
  <div>
    <label className="text-sm font-bold tracking-wide text-gray-700">{label}</label>
    <input
      step={step}
      max={max}
      min={min}
      type={type}
      disabled={disabled}
      required={required}
      className={classNames(
        width,
        "block rounded-lg border border-neutral-300 bg-neutral-50 text-sm text-neutral-900 invalid:border-pink-500 invalid:text-pink-600 focus:border-blue-500 focus:ring-blue-500 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 disabled:cursor-not-allowed disabled:opacity-75",
      )}
      placeholder={placeholder}
      name={name}
      onChange={(e) => {
        onChange(e.target.name, e.target.value, e.target.checkValidity());
      }}
      value={value}
    />
  </div>
);

export default TextInput;
