import TextInput from "../Selectors/TextInput";
import { typeField } from "../../utils/field";

import uniqueId from "lodash.uniqueid";
import { isEmptyString } from "../../utils/common";
import DateTimezone from "../Selectors/DateTimezone";

import Modal from "./Modal";
import Select from "react-select";
import dayjs from "dayjs";
import { DeleteButton, PrimaryButton } from "../Buttons/Button";
import { mdiTableRowPlusAfter, mdiTableRowRemove } from "@mdi/js";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";
import NumericInput from "../Inputs/NumericInput";

const SourceParametersModal = ({ callback, openModal, sourceParametersValue, updateSourceParametersValue }) => {
  const { t } = useTranslation(["common"]);

  const addRow = () => {
    updateSourceParametersValue([
      ...sourceParametersValue,
      {
        id: uniqueId(),
        locked: false,
        value: "",
        name: "",
        type: "",
      },
    ]);
  };
  const removeRow = (id) => {
    updateSourceParametersValue(sourceParametersValue.filter((val) => val.id !== id));
  };
  const handleLock = () => {
    const tmpArray = [...sourceParametersValue];
    Object.keys(tmpArray).forEach((key) => {
      const newValue = tmpArray[key];

      newValue["locked"] = !isEmptyString(newValue["value"]) && !isEmptyString(newValue["type"]) && !isEmptyString(newValue["name"]);
      if (newValue.type === "date") {
        newValue["locked"] = !isEmptyString(newValue.value.date);
      }
    });
    updateSourceParametersValue(tmpArray);
    callback();
  };
  const handleTextInput = (row, value, type) => {
    const arrayIndex = sourceParametersValue.findIndex((elem) => elem.id === row.id);
    const tmpArray = [...sourceParametersValue];
    row[type] = value;
    tmpArray[arrayIndex] = row;
    updateSourceParametersValue(tmpArray);
  };

  const switchType = (field) => {
    switch (field.type) {
      case "integer":
        return (
          <NumericInput
            onChange={(id, value) => {
              handleTextInput(field, value, "value");
            }}
            value={field.value}
            name={field.id}
            type={"number"}
            step={"1"}
            placeholder={"value"}
          />
        );
      case "date":
        return <DateTimezone handleTextInput={handleTextInput} field={field} />;

      case "string":
        return (
          <TextInput
            onChange={(id, value) => {
              handleTextInput(field, value, "value");
            }}
            value={field.value}
            name={field.id}
            type={"text"}
          />
        );
      case "float":
        return (
          <NumericInput
            onChange={(id, value) => {
              handleTextInput(field, value, "value");
            }}
            value={field.value}
            name={field.id}
            type={"number"}
            step={"0.001"}
          />
        );
      default:
        break;
    }
  };

  const onTypeChange = (row, value) => {
    const tmpArray = [...sourceParametersValue];
    const arrayIndex = sourceParametersValue.findIndex((elem) => elem.id === row.id);
    if (value === "date") row["value"] = { date: dayjs().format("YYYY-MM-DDTHH:mm:ss"), name: "Europe/Brussels" };
    else row["value"] = "";
    row["type"] = value;
    tmpArray[arrayIndex] = row;
    updateSourceParametersValue(tmpArray);
  };
  const styleOption = (option) => (
    <div className="flex gap-0.5">
      <p className="font-bold">Nom:</p>
      <p>{option.name}</p>
      <p className="font-bold">Type:</p>
      <p>{option.type}</p>
    </div>
  );
  const columns = sourceParametersValue.map((row, index) => (
    <div key={index} className={"grid grid-cols-7 py-0.5 even:bg-gray-50"}>
      <div className={"col-span-2 place-self-center"}>
        {
          <TextInput
            value={row["name"]}
            name={row.id}
            onChange={(id, value) => {
              handleTextInput(row, value, "name");
            }}
          />
        }
      </div>
      <div className={"col-span-2 place-self-center"}>
        <Select
          className={"w-40"}
          value={typeField.find((value) => value.value === row.type)}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => {
              return { ...base, zIndex: 9999 };
            },
          }}
          onChange={(option) => onTypeChange(row, option.value)}
          options={typeField}
          id={row.id}
        />
      </div>
      <div className={"col-span-2 place-self-center"}>{switchType(row)} </div>
      <div className={"place-self-center"}>
        <DeleteButton callback={() => removeRow(row.id)} label={<Icon path={mdiTableRowRemove} size={1} />} />
      </div>
    </div>
  ));
  const Header = () => (
    <div>
      <div className={"grid grid-cols-7 rounded-t bg-blue-900"}>
        <div className={"group col-span-2 place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>{t("name")}</div>
        <div className={"group col-span-2 place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}> {t("type")}</div>
        <div className={"group col-span-2 place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>{t("value")}</div>
        <div className={"group place-self-center px-6 py-3 text-center  text-xs font-medium uppercase tracking-wider text-white"}>
          <PrimaryButton callback={addRow} label={<Icon path={mdiTableRowPlusAfter} size={1} />} />{" "}
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      header={<Header />}
      name={"mappingValue"}
      columns={columns}
      addRow={addRow}
      lockValues={handleLock}
      callback={callback}
      values={sourceParametersValue}
      updateValues={updateSourceParametersValue}
      openModal={openModal}
      optionsLabel={sourceParametersValue
        .filter((value) => value.locked)
        .map((value) => {
          return {
            id: value.id,
            value: value.name,
            label: styleOption(value),
          };
        })}
    />
  );
};
export default SourceParametersModal;
