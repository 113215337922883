import { sourceField } from "../../utils/field";

import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import TableSP from "../../components/Selectors/TableSP";
import TableMV from "../../components/Selectors/TableMV";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/common";

const RecapCsv = ({ conf_info, sp_info, storage_info, file_data_info }) => {
  const { t } = useTranslation(["common"]);
  const handle_mapping = (data) => {
    const ret = [];
    data.forEach((elem) => {
      let param = elem.mapping_information;
      switch (elem.type) {
        case "string":
          param = param.length;
          break;
        case "int":
        case "float":
          param = param.min + "-" + param.max;
          break;
        default:
          param = "-";
          break;
      }
      ret.push({ name: elem.column, type: elem.type, param: param });
    });
    return ret;
  };
  const sp_render = () => <TableSP name={capitalizeFirstLetter(t("source_parameters"))} data={sp_info} />;
  const source_render = () => {
    const source_type = sourceField.find((e) => e.value === conf_info.source_type);
    const storage = conf_info.storage_system_id === "" ? storage_info[sessionStorage.getItem("storageSystemId")] : storage_info[conf_info.storage_system_id];
    return (
      <div className={"col-span-2 lg:col-span-1"}>
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-center  text-lg font-medium leading-6 text-gray-900">{capitalizeFirstLetter(t("crude_source"))}</h3>
        </div>
        <div className="border-x border-t border-gray-200">
          <dl>
            <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("name"))}</dt>
              <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conf_info.name}</dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("description"))}</dt>
              <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conf_info.description}</dd>
            </div>
            <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("type"))}</dt>
              <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{source_type?.label ?? ""}</dd>
            </div>
            <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("same_storage")).replace("?", "")}</dt>
              <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {conf_info.storage_system_id !== "" ? (
                  <CheckCircleIcon className={"h-4 w-4 text-red-700"} />
                ) : (
                  <XCircleIcon className={"h-4 w-4 text-red-700"} />
                )}
              </dd>
            </div>
            {conf_info.storage_system_id !== "" && (
              <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("storage"))}</dt>
                <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{storage.name ?? ""}</dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    );
  };
  const conf_render = () => (
    <div className={"col-span-2 lg:col-span-1"}>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-center text-lg font-medium leading-6 text-gray-900">{capitalizeFirstLetter(t("configuration"))}</h3>
      </div>
      <div className="border-x border-t border-gray-200">
        <dl>
          <div className="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("description"))}</dt>
            <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conf_info.configured_source_description}</dd>
          </div>
          <div className="border-t  border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">ID</dt>
            <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conf_info.id}</dd>
          </div>
          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("state"))}</dt>
            <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {conf_info.is_active ? <CheckCircleIcon className={"h-4 w-4 text-green-600"} /> : <XCircleIcon className={"h-4 w-4 text-red-700"} />}
            </dd>
          </div>

          <div className="border-t border-gray-200 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">{capitalizeFirstLetter(t("alert_delay"))}</dt>
            <dd className="mt-1 flex justify-center text-sm text-gray-900 sm:col-span-2 sm:mt-0">{conf_info.alert_delay}</dd>
          </div>
        </dl>
      </div>
    </div>
  );

  const mv_render = () => <TableMV name={capitalizeFirstLetter(t("mapping_value"))} data={handle_mapping(file_data_info.col_type)} />;
  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        {source_render()}
        {conf_render()}
        {sp_render()}
        {mv_render()}
      </div>
    </div>
  );
};
export default RecapCsv;
