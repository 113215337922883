import Select from "react-select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/common";
import { PrevButton } from "../../components/Buttons/Button";

const IndicatorHeader = ({ set, title, field }) => {
  const { t } = useTranslation(["common"]);
  const indicator = useSelector((state) => state.indicator);

  return (
    <div className="m-4  rounded-lg bg-white px-8 pb-8  pt-2 shadow-lg">
      <div className="flex gap-x-1.5">
        <PrevButton url={"/analytics/indicator"} />
        <h2 className="text-3xl font-semibold text-blue-900">{title}</h2>
      </div>
      <div className="mx-20 grid grid-cols-2 gap-2">
        <div>
          <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{capitalizeFirstLetter(t("indicator_name"))}</label>
          <Select
            placeholder={"..."}
            id="name"
            value={field.find((field) => field.value === indicator?.moduleData?.name)}
            onChange={(e) => set(e)}
            options={field}
          />
        </div>
      </div>
    </div>
  );
};
export default IndicatorHeader;
