//React librairies
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { isEmptyString } from "../utils/common";
import { toast } from "react-toastify";
import { postUserApi } from "../axios/UserManagementApi";
import { useDispatch } from "react-redux";
import { login as loginState } from "../reducers/ApplicationSlice";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { selectedStructure } from "../reducers/SelectedSlice";
import { dataLoadingToast } from "../components/Toasts/DataLoadingToast";

const Login = () => {
  //Use navigate
  const history = useNavigate();
  const dispatch = useDispatch();
  //Use state
  const [isLoading, setIsLoading] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [changePwd, setChangePwd] = useState(false);
  const onChange = (name, value) => {
    setLogin({
      ...login,
      [name]: value,
    });
  };
  const authenticate = () => {
    setIsLoading(true);
    if (changePwd) {
      postUserApi("regenerate?username=" + login["email"], {})
        .then((res) => {
          console.debug(res);
          setIsLoading(false);
        })
        .catch((error) => {
          console.debug(error);
          setIsLoading(false);
          toast.error(error.response.data.message);
        });
      return;
    }
    const data = {
      username: login["email"],
      password: login["password"],
    };
    dataLoadingToast({ label: "Connection..", isLoading: true, id: "login-toast" });
    postUserApi("user/login", data)
      .then((res) => {
        dataLoadingToast({ successMessage: "Connecté", success: true, id: "login-toast" });
        console.debug(res);
        setIsLoading(false);
        sessionStorage.setItem("isAuthenticated", "true");
        sessionStorage.setItem("profile", JSON.stringify(res.data.profile));
        sessionStorage.setItem("authorized_pages", JSON.stringify(res.data.authorized_pages));
        sessionStorage.setItem("authorized_components", JSON.stringify(res.data.authorized_components));
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("showSidebar", "true");
        dispatch(loginState(res.data));
        if (res.data.profile["id_role"] !== 1) {
          sessionStorage.setItem("structure_id", res.data.profile.structure_id);
          dispatch(selectedStructure(res.data.profile.structure_id));
        }
        //TODO serge hotfix
        if (res.data.profile.id_keycloak === "af95904e-dc91-4e6b-8a8a-ca30adb6e47f") {
          sessionStorage.setItem("structure_id", res.data.profile.structure_id);
          dispatch(selectedStructure(res.data.profile.structure_id));
          history("/");
        } else res.data.profile["id_role"] !== 1 ? history("/") : history("/admin/structures");
      })
      .catch((error) => {
        setIsLoading(false);
        dataLoadingToast({
          errorMessage: error?.response?.data?.message ?? "Un problème est survenu",
          error: true,
          id: "login-toast",
        });
        console.debug(error?.response?.data);
      });
  };

  const [showPwd, setShowPwd] = useState(false);
  const canLogin = (!changePwd && (isEmptyString(login.email) || isEmptyString(login.password) || isLoading)) || (changePwd && isEmptyString(login.email));
  return (
    <section className="bg-gray-50 bg-cover bg-no-repeat dark:bg-gray-900" style={{ backgroundImage: "url(./login.jpg)" }}>
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0">
        <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0">
          <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
            <h1 className="text-xl font-bold leading-tight tracking-wide text-gray-900 dark:text-white md:text-2xl">PREDITIC</h1>
            <form className="space-y-4 md:space-y-6">
              <div>
                <label htmlFor="email" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                  Adresse email
                </label>
                <input
                  onChange={(event) => onChange(event.target.name, event.target.value)}
                  type="email"
                  autoComplete={"username"}
                  name="email"
                  id="email"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                  required=""
                />
              </div>
              {!changePwd && (
                <div>
                  <label htmlFor="password" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                    Mot de passe
                  </label>
                  <div className={"flex"}>
                    <button
                      type={"button"}
                      onClick={() => setShowPwd(!showPwd)}
                      className={"z-10 flex w-10 cursor-pointer items-center justify-center pl-1 text-center"}
                    >
                      {!showPwd ? <EyeIcon className={"h-6 w-6"} /> : <EyeSlashIcon className={"h-6 w-6"} />}
                    </button>
                    <input
                      onChange={(event) => onChange(event.target.name, event.target.value)}
                      type={showPwd ? "text" : "password"}
                      autoComplete={"current-password"}
                      name="password"
                      id="password"
                      className="-ml-10 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-gray-900 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                      required=""
                    />
                  </div>
                </div>
              )}
              <div className="flex justify-end">
                <button type={"button"} onClick={() => setChangePwd(!changePwd)} className="text-sm font-medium text-blue-600 hover:underline">
                  {changePwd ? "Je m'en souviens !" : "Mot de passe oublié ?"}
                </button>
              </div>
              <button
                disabled={canLogin}
                type="button"
                onClick={() => authenticate()}
                className="w-full rounded-lg bg-blue-800 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-4 focus:ring-blue-700 disabled:opacity-50"
              >
                {changePwd ? "réinitialiser le mot de passe" : "Se connecter"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
