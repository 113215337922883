import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import { useEffect, useId, useState } from "react";
import { useSelector } from "react-redux";
import { canRenderComponent, isEmptyObject } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";

import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { formatThreshold } from "../../network/DataManagementApi";
import { Tooltip } from "react-tooltip";
import { MdInfoOutline as Info } from "react-icons/md";
import { TableFilter } from "./TableFilter";
import { getDataApi } from "../../axios/DataManagementApi";
import { PrimaryButton, SecondaryButton } from "../Buttons/Button";
import { useTranslation } from "react-i18next";

const ThresholdList = () => {
  const { t } = useTranslation(["button", "common"]);
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  // const [canView] = useState(canRenderAction(ACTION_FLAGS.CANVIEW, COMPONENTS.THRESHOLD_DISPLAY));
  const [authorizedThresholdCreation, setAuthorizedThresholdCreation] = useState(canRenderComponent(COMPONENTS.THRESHOLD_CREATION));

  useEffect(
    () => () => {
      setAuthorizedThresholdCreation(canRenderComponent(COMPONENTS.THRESHOLD_CREATION));
    },
    [authorizedComponent],
  );

  const thresholdList = useSelector((state) => state.data.threshold);
  const updateThresold = () => {
    getDataApi("threshold?structure_id=" + sessionStorage.getItem("structure_id"))
      .then((res) => {
        formatThreshold(res.data.data);
        setRefresh(false);
      })
      .catch((error) => {
        console.debug(error);
        setRefresh(false);
      });
  };
  const id = useId();

  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);
  const history = useNavigate();
  useEffect(() => {
    setData(thresholdList);
  }, [thresholdList]);
  const createThreshold = () => history("/analytics/threshold/create");
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("label", {
      id: "label",
      header: t("name", { ns: "common" }),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: t("description", { ns: "common" }),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.creation_timestamp, {
      id: "creation_timestamp",
      header: t("creation_time", { ns: "common" }),
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "type",
      header: t("type", { ns: "common" }),
      cell: (info) => (
        <div>
          <Tooltip place={"left"} id={id + info.row.original.creation_timestamp}>
            <div>
              <ul>
                {Object.entries(info.row.original.formula).map((value, index) => (
                  <li key={index}>
                    {value[0]} : {value[1]}
                  </li>
                ))}
              </ul>
            </div>
          </Tooltip>
          <div data-tooltip-id={id + info.row.original.creation_timestamp} className="flex items-center gap-2 hover:text-blue-900">
            <Info size={20} />
            {isEmptyObject(info.row.original.formula) ? "fonction" : "formule"}
          </div>
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className=" m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className=" flex flex-row px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
        <div className=" mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
          <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
            <SecondaryButton
              isLoading={refresh}
              disabled={refresh}
              callback={() => {
                setRefresh(true);
                updateThresold();
              }}
              label={t("update")}
            />
          </div>
          {authorizedThresholdCreation && (
            <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
              <PrimaryButton callback={createThreshold} label={t("create_threshold")} />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};
export default ThresholdList;
