import Icon from "@mdi/react";
import { mdiDelete, mdiExport, mdiEyeOutline, mdiPencil, mdiPlusCircle } from "@mdi/js";

const ActionButtons = ({
  canExport = false,
  canView = false,
  canDelete = false,
  canEdit = false,
  canCreate = false,
  handleExport,
  handleView,
  handleEdit,
  handleDelete,
  handleCreate,
}) => (
  <div className="flex">
    {canExport && (
      <div onClick={handleExport}>
        <Icon path={mdiExport} size={1} className={"cursor-pointer text-blue-900 hover:text-blue-950"} />{" "}
      </div>
    )}
    {canCreate && (
      <div onClick={handleCreate}>
        <Icon path={mdiPlusCircle} size={1} className={"cursor-pointer text-blue-900 hover:text-blue-950"} />{" "}
      </div>
    )}
    {canView && (
      <div onClick={handleView}>
        <Icon path={mdiEyeOutline} size={1} className={"cursor-pointer text-blue-900 hover:text-blue-950"} />
      </div>
    )}
    {canEdit && (
      <div onClick={handleEdit}>
        <Icon path={mdiPencil} size={1} className={"cursor-pointer text-blue-900 hover:text-blue-950"} />{" "}
      </div>
    )}
    {canDelete && (
      <div onClick={handleDelete}>
        <Icon path={mdiDelete} size={1} className={"cursor-pointer text-blue-900 hover:text-blue-950"} />
      </div>
    )}
  </div>
);
export default ActionButtons;
