import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import Select from "react-select";
import { isEmptyString, makeSelectField } from "../../utils/common";
import { useEffect, useState } from "react";
import TextInput from "../../components/Selectors/TextInput";
import CreatableSelect from "react-select/creatable";
import { parser } from "mathjs";
import { toast } from "react-toastify";

import LabelTooltip from "../../components/Tooltip/LabelTooltip";
import TableWrapper from "../../components/Table/TableWrapper";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableHeader } from "../../components/Table/TableHeader";
import { TableBody } from "../../components/Table/TableBody";
import { TablePagination } from "../../components/Table/TablePagination";
import { PrimaryButton } from "../../components/Buttons/Button";
import { postDataApi } from "../../axios/DataManagementApi";
import { push } from "../../reducers/dataSlice";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";

const ThresholdCreator = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const obs = sessionStorage.getItem("observed_system_id");
  const [dataSource, setDataSource] = useState(undefined);
  const dataSources = useSelector((state) => state.data.crude_source);
  const [threshold, setThreshold] = useState({
    description: "",
    label: "",
    formula: [],
  });
  console.debug(dataSource);
  useEffect(() => {
    setDisabled(false);
    if (isEmptyString(threshold.description) || isEmptyString(threshold.label) || threshold.formula.length === 0) setDisabled(true);
  }, [threshold]);
  const handleThresholdChange = (name, value) =>
    setThreshold({
      ...threshold,
      [name]: value,
    });
  const create = () => {
    dataLoadingToast({ label: "Création du seuil", isLoading: true });

    setIsLoading(true);
    const formula = {};
    threshold.formula.forEach((value) => (formula[value.value] = value.formula));
    const data = {
      label: threshold.label,
      description: threshold.description,
      formula: formula,
      structure_id: sessionStorage.getItem("structure_id"),
    };
    postDataApi("threshold?observed_system_id=" + obs, data)
      .then((res) => {
        dataLoadingToast({ success: true, successMessage: "Seuil créé" });

        console.debug(res);
        dispatch(
          push({
            type: "threshold",
            data: {
              label: data.label,
              observed_system_id: obs,
              description: data.description,
              formula: data.formula,
              function: {},
              creation_timestamp: new Date(Date.now()).toLocaleString(),
            },
          }),
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.debug(error);
        setIsLoading(false);
      });
  };
  console.debug(dataSource === undefined ? [] : dataSources.find((value) => value.id === dataSource.value).measure);
  return (
    <div>
      <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
        <div className="flex gap-x-1.5">
          <IconButton bg={true} Icon={<Prev size={40} />} value={""} callback={() => history(-1)} />
          <h2 className="text-3xl font-semibold text-blue-900">Création d&apos;un seuil</h2>
        </div>
        <div className=" text-sm font-semibold text-gray-700 dark:text-white ">
          <p>Permet la création d&apos;un nouveau seuil utilisable par les indicateurs ou les évenements.</p>
          <p>Il est nécéssaire de sélectionner une source de données pour considérer les variables de la formule.</p>
        </div>
        <div className="mx-20 ">
          <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"Source(s) de données"}</label>
          <Select
            isSearchable={true}
            onChange={(option) => {
              console.debug(dataSources.find((value) => value.id === option.value));
              setDataSource(option);
            }}
            value={dataSource}
            options={makeSelectField(dataSources, ["name", "description"])}
          />
        </div>
      </div>
      <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
        <TableWrapper
          label={"Liste des variables"}
          component={<VariableList data={dataSource === undefined ? [] : Object.entries(dataSources.find((value) => value.id === dataSource.value).measure)} />}
        />
      </div>
      <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
        <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"Paramètre du seuil"}</label>
        <div className={"grid grid-cols-2 gap-4"}>
          <TextInput name={"label"} label={"Nom"} value={threshold.label} type={"text"} onChange={handleThresholdChange} />
          <TextInput name={"description"} label={"Description"} value={threshold.description} type={"text"} onChange={handleThresholdChange} />
          <div className="col-span-2">
            <LabelTooltip label={"Formule"} tip={"usage: state:formula - Exemple: BON:CHAUD >  FROID"} />
            <CreatableSelect
              placeholder={"usage: state:formula - Exemple: BON:CHAUD >  FROID"}
              openMenuOnClick={false}
              openMenuOnFocus={false}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              isClearable={true}
              value={threshold.formula}
              onChange={(option, actionMeta) => {
                if (actionMeta.action === "create-option") {
                  const parse = parser();
                  const variables = dataSource === undefined ? [] : dataSources.find((value) => value.id === dataSource.value).measure;
                  const splittedOption = actionMeta.option.value.split(":");
                  if (splittedOption.length !== 2) {
                    toast.error("Usage: Etat:Formule");
                    return;
                  }
                  let formula = splittedOption[1];
                  Object.entries(variables).forEach((variable) => {
                    formula = formula.replaceAll(variable[0], "['" + variable[0] + "']");

                    parse.set(variable[0], 1);
                  });
                  formula = formula.replaceAll("(?<!>)=(?!>)", "==").replaceAll("c_[", "c[").replaceAll("h_[", "h[");

                  try {
                    parse.evaluate(splittedOption[1].replaceAll("c_", "").replaceAll("h_", ""));
                    const formulas = threshold.formula;
                    formulas.push({
                      value: splittedOption[0],
                      label: splittedOption[0] + " : " + formula,
                      formula: formula,
                    });
                    handleThresholdChange("formula", formulas);
                  } catch (e) {
                    if (e instanceof SyntaxError) {
                      toast.error("Formule incorrect");
                    } else {
                      const wrongVariable = e.message.split(" ")[2];
                      toast.error("Variable incorrect: " + wrongVariable + " Formule: " + splittedOption[1]);
                    }
                  }
                } else handleThresholdChange("formula", option);
              }}
              isMulti={true}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <PrimaryButton label={"Créer"} disabled={disabled} isLoading={isLoading} callback={create} />
        </div>
      </div>
    </div>
  );
};
export default ThresholdCreator;

const VariableList = ({ data }) => {
  console.debug(data);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor(
      (row) => {
        console.debug(row);
        return row[0];
      },
      {
        id: "name",
        header: "Nom",
        cell: (info) => info.getValue(),
      },
    ),
    columnHelper.accessor((row) => row[1].type, {
      id: "type",
      header: "Type",
      cell: (info) => info.getValue(),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};
