import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { makeSelectField, makeVariableField } from "../../../utils/common";
import { useEffect, useState } from "react";
import { setCrudeSource, setCurrentTimeParams, setTargetVariables } from "../../../reducers/indicatorSlice";
import SwitchButton from "../../../components/Selectors/SwitchButton";
import { aggregationOptions } from "../../../utils/field";
import NumericInput from "../../../components/Inputs/NumericInput";
import ConfigurationAlerteFormula from "../ConfigurationAlerte/ConfigurationAlerteFormula";
import { PrimaryButton } from "../../../components/Buttons/Button";
import { formatBackend } from "../../../utils/date.utils";
import IndicatorResult from "../IndicatorResult";
import { postIndicatorApi } from "../../../axios/IndicatorComputationApi";
import SimpleTime from "./SimpleTime";
import { dataLoadingToast } from "../../../components/Toasts/DataLoadingToast";

const CrossCorrelationSimple = () => {
  const { moduleData, selected_crude_source } = useSelector((state) => state.indicator);
  const { current, target_variables } = moduleData;
  const { time_params, computation_params } = current;
  const { start_time, end_time, bucket_size, label, time_label } = time_params;
  const { crude_source } = useSelector((state) => state.data);
  const [crudeSourceOptions] = useState(makeSelectField(crude_source, ["name", "description"], true));
  const [targetOptions, setTargetOptions] = useState(
    selected_crude_source.map((value) => makeVariableField(0, value, crude_source.find((source) => source.id === value)["measure"])).flat(),
  );
  useEffect(() => {
    setTargetOptions(selected_crude_source.map((value) => makeVariableField(0, value, crude_source.find((source) => source.id === value)["measure"])).flat());
  }, [crude_source, selected_crude_source]);
  const dispatch = useDispatch();
  const [aggregation, setAggregation] = useState(false);
  const [aggregationMode, setAggregationMode] = useState("avg");
  const [formula, setFormula] = useState(false);
  const [mainSeries, setMainSeries] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState({});
  const { showLabel } = useSelector((state) => state.application);

  function handleCrudeSource(selected, action) {
    if (action.action === "select-option" && action.option.value === "all")
      dispatch(setCrudeSource(crudeSourceOptions.filter((value) => value.value !== "all").map((value) => value.value)));
    else dispatch(setCrudeSource(selected.map((value) => value.value)));
  }

  function handleTimeParams(name, value) {
    dispatch(setCurrentTimeParams({ key: name, value: value }));
  }

  function handleTargetVariables(selected, action) {
    console.debug(selected, action);
    dispatch(setTargetVariables(selected));
  }

  const parseFormulaV1 = (formula, blocks) => {
    let ret = formula;
    blocks.forEach((block) => {
      Object.keys(block.preprocess).forEach((value) => {
        ret = ret.replaceAll(value, "['" + value + "']");
      });
    });
    return ret;
  };

  function getDataBlocks(source, sourceList, agregation = "identity") {
    const dataBlocks = [];

    for (let i = 0; i < source.length; i++) {
      const sourceId = source[i];
      const sourceData = sourceList.find((item) => item.id === sourceId);

      if (sourceData && sourceData.measure) {
        const measureKeys = Object.keys(sourceData.measure);
        const timeField = measureKeys.find((key) => sourceData.measure[key].visualization_type === "date");

        const preprocess = {};
        measureKeys.forEach((key) => {
          if (key !== timeField) {
            preprocess[key] = agregation;
          }
        });

        const dataBlock = {
          configured_sources: [],
          time_field: timeField ?? "internal",
          preprocess: preprocess,
          measure_identifier: sourceData.measure_identifier,
          status: -1,
        };

        dataBlocks.push(dataBlock);
      }
    }

    return dataBlocks;
  }

  function compute() {
    setIsLoading(true);
    setResult({});
    const obs = sessionStorage.getItem("observed_system_id");
    const target = structuredClone(target_variables).map((value) => value.label);
    if (formula) target.push("computed_field");
    if (aggregation && bucket_size === -1) {
      setIsLoading(false);
      dataLoadingToast({ label: "La taille du bucket doit être positive" });
    }
    const payload = {
      current: {
        data_params: getDataBlocks(selected_crude_source, crude_source, aggregation ? aggregationMode : "identity"),
        time_params: {
          start_time: formatBackend(start_time),
          end_time: formatBackend(end_time),
          bucket_size: aggregation ? bucket_size : -1,
          label: label,
        },
        computation_params: structuredClone(computation_params),
      },
      cross_correlation_parameters: {
        main_series: mainSeries,
        comparison_series: target,
      },
    };
    if (showLabel) payload.current.time_params.time_label = time_label;

    payload.current.computation_params.formula = formula ? parseFormulaV1(computation_params.formula, payload.current.data_params) : "";
    console.debug(payload);
    postIndicatorApi("indicator/crosscorrelation/" + obs, payload)
      .then((res) => {
        setIsLoading(false);
        console.debug(res);
        setResult(res.data);
      })
      .then((error) => {
        setIsLoading(false);
        console.debug(error);
      });
  }

  return (
    <div className="mx-4 my-2">
      <div className="block rounded border border-gray-200 bg-white px-4">
        <h2 className="text-2xl font-semibold text-blue-900">Paramètres des données</h2>
        <div className={"mb-2 flex gap-x-4 px-8"}>
          <SimpleTime />
        </div>
        <div className={"mb-2 border-b px-8 pb-2"}>
          <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Source de données</label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            placeholder={"Source(s) de données"}
            value={crudeSourceOptions.filter((value) => selected_crude_source.includes(value.value))}
            options={crudeSourceOptions}
            onChange={handleCrudeSource}
          />
        </div>
        <div className={"mb-2 px-8"}>
          <SwitchButton label={"Agrégation"} value={aggregation} onChange={(value) => setAggregation(value)} />
        </div>
        {aggregation && (
          <div className={"mb-2 grid grid-cols-6 gap-x-4 px-16"}>
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Type</label>
              <Select
                options={aggregationOptions}
                value={aggregationOptions.find((value) => value.value === aggregationMode)}
                onChange={(newValue) => setAggregationMode(newValue.value)}
              />
            </div>
            <NumericInput label={"Intervalle de temps (s)"} value={bucket_size} name={"bucket_size"} onChange={handleTimeParams} />
          </div>
        )}
        <div className={"mb-2 px-8"}>
          <SwitchButton label={"Formule"} value={formula} onChange={(value) => setFormula(value)} />
        </div>
        {formula && (
          <div className={"mb-2 w-1/2 px-16"}>
            <ConfigurationAlerteFormula mode={"current"} verify={false} />
          </div>
        )}
        <div className={"flex"}>
          <div className={"mb-2 w-2/5 border-b px-8 pb-2"}>
            <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Variable de référence</label>
            <Select
              options={targetOptions}
              // value={aggregationOptions.find((value) => value.value === aggregationMode)}
              onChange={(newValue) => setMainSeries(newValue.label)}
            />
          </div>
          <div className={"mb-2 w-2/5 border-b px-8 pb-2"}>
            <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Variables à comparer</label>
            <Select
              isMulti
              value={target_variables}
              closeMenuOnSelect={false}
              placeholder={"Toute les données"}
              options={targetOptions}
              onChange={handleTargetVariables}
            />
          </div>
        </div>
      </div>
      <div className={"flex justify-center"}>
        <PrimaryButton disabled={isLoading || selected_crude_source.length === 0} isLoading={isLoading} label={"Calculer"} callback={() => compute()} />
      </div>
      <IndicatorResult type={9} result={result} />
    </div>
  );
};
export default CrossCorrelationSimple;
