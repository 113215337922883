import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Checkbox from "../../components/Selectors/Checkbox";

import { canRenderComponent, capitalizeFirstLetter } from "../../utils/common";
import { handleRangeMapping, handleSourceParameters, handleType } from "../../utils/configuredSourceUtils";
import { useDispatch, useSelector } from "react-redux";
import { COMPONENTS } from "../../constants/component";
import { postDataApi } from "../../axios/DataManagementApi";
import { useTranslation } from "react-i18next";
import HorizontalLine from "../../components/HorizontalLine";
import { PrevButton, PrimaryButton } from "../../components/Buttons/Button";
import { useLoaderData, useParams } from "react-router-dom";
import { push } from "../../reducers/dataSlice";

const ConfiguredSource = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const dataloaded = useLoaderData();
  const { t } = useTranslation(["common"]);
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [authorizedDataSource, setAuthorizedDataSource] = useState(canRenderComponent(COMPONENTS.CONFIGURED_SOURCE_FROM_EXISTING_ONE));
  useEffect(
    () => () => {
      setAuthorizedDataSource(canRenderComponent(COMPONENTS.CONFIGURED_SOURCE_FROM_EXISTING_ONE));
    },
    [authorizedComponent],
  );

  const observedSystem = sessionStorage.getItem("observed_system_id");

  const [sourceParameter, setSourceParameter] = useState(dataloaded);

  const [loading, setLoading] = useState(false);
  const disabled = observedSystem === null || sourceParameter.description === "";
  const [modifySourceParameter, setModifySourceParameter] = useState(false);
  const [modifyRangeMapping, setModifyRangeMapping] = useState(false);
  const handleSend = () => {
    setLoading(true);
    let send_data = {
      description: sourceParameter.description,
      alert_delay: sourceParameter.alert_delay,
      is_active: sourceParameter.is_active,
      crude_source_id: id,
      measure_index: sourceParameter["source_parameters_info"].measure_identifier,
      source_parameters_identifier: sourceParameter["source_parameters_info"].identifier,
      source_parameters_id: sourceParameter["source_parameters_info"].id,
      id: sourceParameter["conf_id"] === "" ? null : sourceParameter["conf_id"],
    };

    if (modifyRangeMapping || modifySourceParameter) {
      send_data["source_parameters_data"] = {
        source_parameters_values: sourceParameter.source_parameters_values,
        values: sourceParameter.values,
      };
      send_data["source_parameters_id"] = sourceParameter["source_parameters_info"].id;
      postDataApi("source_parameters?observed_system_id=" + observedSystem, send_data)
        .then((res) => {
          toast.success(res.data.message.toString());
          setLoading(false);
        })
        .catch((error) => {
          console.debug(error);
          setLoading(false);

          toast.error("Une erreur est survenue.");
        });
    } else
      postDataApi("configured_source?observed_system_id=" + observedSystem, send_data)
        .then((res) => {
          const newData = {
            alert_delay: send_data.alert_delay,
            crude_source: send_data.crude_source_id,
            description: send_data.description,
            id: res.data.id,
            is_active: send_data.is_active,
            measure_identifier: send_data.measure_index,
            observed_system_id: observedSystem,
          };
          toast.success("Nouvelle configuration créée");
          dispatch(push({ type: "configured_source", data: newData }));
          setLoading(false);
        })
        .catch((error) => {
          console.debug(error);
          setLoading(false);
          toast.error(error.response.data.message);
        });
  };
  const onChange = (name, value) => {
    setSourceParameter((sourceParameter) => {
      return {
        ...sourceParameter,
        [name]: value,
      };
    });
  };
  const renderRangeMapping = () => {
    if (sourceParameter) {
      if (modifyRangeMapping) {
        return Object.entries(sourceParameter["values"]).map((value) =>
          handleRangeMapping(value[1], value[0], setSourceParameter, sourceParameter, onChange, t),
        );
      }
    }
  };
  const renderSourceParameters = () => {
    if (sourceParameter) {
      if (modifySourceParameter) {
        return Object.entries(sourceParameter["source_parameters_values"]).map((value) =>
          handleSourceParameters(value[1], value[0], setSourceParameter, sourceParameter, onChange),
        );
      }
    }
  };
  return (
    authorizedDataSource && (
      <div className="p-8">
        <div className="w-full rounded-lg border border-gray-300 bg-white shadow-md">
          <div className={"flex gap-x-0.5 px-4 pt-4"}>
            <PrevButton url={"/"} />
            <h2 className="text-3xl font-semibold text-blue-800">{capitalizeFirstLetter(t("configured_source"))}</h2>
          </div>
          <h2 className="px-6 pb-4">{capitalizeFirstLetter(t("CONF_DESC"))}</h2>
          <HorizontalLine />
          <div className="px-4 ">{sourceParameter && <h2 className="font-semibold text-blue-900">Configuration</h2>}</div>
          {sourceParameter && (
            <div className="mx-4 grid grid-cols-1 gap-4 border-l border-blue-900 px-4 lg:grid-cols-3">
              {handleType("is_active", sourceParameter.is_active, onChange, t)}
              {handleType("conf_id", sourceParameter.conf_id, onChange, t)}
              {handleType("description", sourceParameter.description, onChange, t)}
            </div>
          )}
          <div className="grid grid-cols-1 gap-4 px-4 pt-4 lg:grid-cols-3">
            <Checkbox
              label={t("MODIFY_RANGE_MAPPING")}
              name={"rm_modify"}
              checked={modifyRangeMapping}
              onChange={() => {
                setModifyRangeMapping(!modifyRangeMapping);
              }}
            />
          </div>
          <div className="px-4 ">
            {sourceParameter && modifyRangeMapping && <h2 className="font-semibold text-blue-900">{capitalizeFirstLetter(t("variable"))}</h2>}
          </div>
          {<div className="mx-4 grid grid-cols-1 gap-4 border-l border-blue-900 px-4 lg:grid-cols-3">{renderRangeMapping()}</div>}
          <div className="grid grid-cols-1 gap-4 p-4 lg:grid-cols-3">
            <Checkbox
              label={t("MODIFY_SOURCE_PARAM")}
              name={"sp_modify"}
              checked={modifySourceParameter}
              onChange={() => {
                setModifySourceParameter(!modifySourceParameter);
              }}
            />
          </div>
          <div className="px-4 ">{sourceParameter && modifySourceParameter && <h2 className="font-semibold text-blue-900">Paramètres de source</h2>}</div>
          {<div className="mx-4 grid grid-cols-1 gap-4 border-l border-blue-900 px-4 lg:grid-cols-3">{renderSourceParameters()}</div>}

          <div className="p-4 lg:flex lg:justify-end ">
            <PrimaryButton isLoading={loading} label={t("create", { ns: "button" })} callback={handleSend} disabled={disabled} />
          </div>
        </div>
      </div>
    )
  );
};
export default ConfiguredSource;
