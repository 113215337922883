import TextInput from "../../../components/Selectors/TextInput";
import Select from "react-select";

const MailView = ({ data }) => (
  <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
    <h2 className="text-2xl font-semibold text-blue-900">Paramètre de l&apos;email</h2>
    <TextInput
      extraStyle="w-full py-2 rounded-lg outline-none border border-2px opacity-50"
      disabled={true}
      value={data["parameters"].sender}
      label={"Expediteur"}
    />
    <div>
      <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Destinataires</label>
      <Select
        isDisabled={true}
        isMulti={true}
        value={data["parameters"].receivers.map((value) => {
          return { value: value, label: value };
        })}
      />
    </div>
    <TextInput disabled={true} value={data["parameters"].subject} label={"Sujet"} name="subject" />
    <TextInput disabled={true} value={data["parameters"].message} label={"Message"} name="message" />
  </div>
);
export default MailView;
