import { useTranslation } from "react-i18next";
import { formatDisplay } from "../../utils/date.utils";

export const ActiveDisplay = ({ active }) => {
  const { t } = useTranslation(["common"]);
  const formatDate = () => {
    if (active?.timestamp === undefined) return "-";
    return formatDisplay(active.timestamp);
  };
  formatDate();
  return (
    <div className={"rounded border border-gray-300 bg-gray-200 px-2"}>
      <div className={"flex gap-x-1.5 "}>
        <label className="text-right font-bold tracking-wide text-gray-700">{t("last_active") + ":"}</label>
        <p>{active?.status ?? "-"}</p>
      </div>
      <div className={"flex gap-x-1.5 "}>
        <label className="text-right font-bold tracking-wide text-gray-700">{t("timestamp") + ":"}</label>
        <p>{formatDate()}</p>
      </div>
    </div>
  );
};
