import { useDispatch, useSelector } from "react-redux";
import { setHistoricalMode, setHistoricalTimeParams } from "../../../reducers/indicatorSlice";
import Select from "react-select";
import ConfigurationAlerteFormula from "../ConfigurationAlerte/ConfigurationAlerteFormula";
import NumericInput from "../../../components/Inputs/NumericInput";
import { historicalModeOptions } from "../../../utils/field";

const DetectionAnomalieHistorical = () => {
  const dispatch = useDispatch();
  const historical = useSelector((state) => state.indicator.moduleData.historical);
  const historicalMode = useSelector((state) => state.indicator.moduleData.historicalMode);

  return (
    <div className="py-2">
      <div className="">
        <ConfigurationAlerteFormula mode={"historical"} />
      </div>
      <NumericInput
        name={"bucket_size"}
        value={historical.time_params.bucket_size}
        min={-1}
        label={"Taille du bucket"}
        step={1}
        onChange={(name, value) => dispatch(setHistoricalTimeParams({ key: name, value: value }))}
      />
      <div className={"grid  grid-cols-3 gap-1.5 pt-2"}>
        <div>
          <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"Type"}</label>
          <Select
            isSearchable={false}
            value={historicalModeOptions.find((option) => option.value === historicalMode)}
            options={historicalModeOptions}
            onChange={(newValue) => dispatch(setHistoricalMode(newValue.value))}
          />
        </div>
        <NumericInput
          name={"delay"}
          value={historicalMode === "count" ? historical.time_params.count.delay : historical.time_params.time.delay}
          label={"Délai"}
          type={"number"}
          step={1}
          onChange={(name, value) => {
            const key = historicalMode === "count" ? "count" : "time";
            const updates = { ...historical.time_params[key] };
            updates.delay = value;
            dispatch(
              setHistoricalTimeParams({
                key: key,
                value: updates,
              }),
            );
          }}
        />
        <NumericInput
          name={"length"}
          value={historicalMode === "count" ? historical.time_params.count.length : historical.time_params.time.length}
          label={"Taille"}
          type={"number"}
          step={1}
          onChange={(name, value) => {
            const key = historicalMode === "count" ? "count" : "time";
            const updates = { ...historical.time_params[key] };
            updates.length = value;
            dispatch(
              setHistoricalTimeParams({
                key: key,
                value: updates,
              }),
            );
          }}
        />
      </div>
    </div>
  );
};
export default DetectionAnomalieHistorical;
