import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { canRenderAction, capitalizeFirstLetter, dataList, isEmptyString, makeSelectField } from "../../utils/common";

import DeleteModal from "../Selectors/DeleteModal";
import { reset } from "../../reducers/indicatorSlice";
import { remove as removeData, set } from "../../reducers/dataSlice";
import { selectedCrudeSource, selectedObservedSystem } from "../../reducers/SelectedSlice";
import { deleteDataApi, getDataApi } from "../../axios/DataManagementApi";
import store from "../../store";
import { toast } from "react-toastify";
import { dataLoadingToast } from "../Toasts/DataLoadingToast";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import classNames from "classnames";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import { mdiDelete, mdiEyeCheckOutline, mdiEyeRemoveOutline, mdiPencil, mdiRefresh } from "@mdi/js";
import DropdownMenu from "../Selectors/DropDownButton";
import { getUpperLevel } from "../../network/DataManagementApi";

const ObserveSystemSelect = ({ instanceDisplay, setInstanceDisplay }) => {
  const { storage_system } = useSelector((state) => state.data);
  const history = useNavigate();
  const selected = useSelector((state) => state.selected.observed_system);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["common"]);
  const [observedSystemToDelete, setObservedSystemToDelete] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const { observed_system } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const observedSystemData = observed_system.find((obs) => obs.id === selected);
  const handleObservedSystemChange = (newValue) => {
    setLoading(true);
    dataLoadingToast({ isLoading: true, id: "observed-system-toast" });
    const observedSystemId = newValue.value;
    const observedSystemData = observed_system.find((value) => value.id === observedSystemId);
    dispatch(reset());
    dispatch(set({ type: "crude_source", data: [] }));
    dispatch(set({ type: "configured_source", data: [] }));
    dispatch(selectedObservedSystem(observedSystemId));
    dispatch(selectedCrudeSource("")); // clear previously selected crude source
    sessionStorage.removeItem("crude_source_id");

    sessionStorage.setItem("observed_system_id", observedSystemId);
    sessionStorage.setItem("observed_system_desc", observedSystemData.description);
    sessionStorage.setItem("observed_system_name", observedSystemData.name);
    getDataApi("crude_source?stat=true&observed_system_id=" + observedSystemId)
      .then((res) => {
        store.dispatch(set({ type: "crude_source", data: dataList(res["data"]["data"]) }));
        getDataApi("configured_source?observed_system_id=" + observedSystemId)
          .then((res) => {
            store.dispatch(set({ type: "configured_source", data: dataList(res["data"]["data"]) }));
            dataLoadingToast({ success: true, id: "observed-system-toast" });
            setLoading(false);
          })
          .catch((error) => {
            dataLoadingToast({ error: true, id: "observed-system-toast" });
            setLoading(false);
            console.debug(error);
          });
      })
      .catch((error) => {
        console.debug(error);
        dataLoadingToast({ error: true, id: "observed-system-toast" });
        setLoading(false);
      });
  };

  const makeDropDownOptions = () => {
    const options = [];
    if (isEmptyString(selected) || loading) return options;
    const canDelete = canRenderAction(ACTION_FLAGS.CANDELETE, COMPONENTS.OBSERVED_SYSTEM_DISPLAY);
    const canEdit = canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.OBSERVED_SYSTEM_DISPLAY);
    options.push({
      id: 3,
      label: "Rafraichir les données",
      onClick: () => getUpperLevel(),
      path: mdiRefresh,
    });
    options.push({
      id: 2,
      label: instanceDisplay ? "Masquer les informations des instances" : "Afficher les informations des instances",
      onClick: () => setInstanceDisplay(!instanceDisplay),
      path: instanceDisplay ? mdiEyeRemoveOutline : mdiEyeCheckOutline,
    });
    if (canEdit)
      options.push({
        id: 0,
        label: "Modifier",
        onClick: () => history("/observed_system/edit/" + selected),
        path: mdiPencil,
      });
    if (canDelete)
      options.push({
        id: 1,
        label: "Supprimer",
        onClick: () => setObservedSystemToDelete(observedSystemData),
        path: mdiDelete,
      });

    return options;
  };
  return (
    <div className={"flex w-full"}>
      <div className="flex  w-full gap-x-4 ">
        <div>
          <label className="font-bold text-blue-900 ">{capitalizeFirstLetter(t("observed_system"))}</label>
          <Select
            placeholder={t("placeholder_observed_system")}
            value={makeSelectField(observed_system, ["name"]).find((value) => value.value === selected)}
            isDisabled={loading}
            isLoading={loading}
            classNames={{
              container: () => "relative",
              control: () => "w-80 rounded-lg border border-neutral-300 bg-neutral-50 text-sm font-medium text-neutral-900 hover:bg-gray-200",
              menu: () => "w-80 absolute right-0 z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
              option: (state) => classNames(state.isFocused ? "w-full bg-gray-100" : "", "block w-full py-2 text-sm text-gray-700"),
            }}
            onChange={handleObservedSystemChange}
            options={makeSelectField(observed_system, ["name"])}
          />
        </div>
        <div>
          <label className="font-bold text-blue-900 ">{capitalizeFirstLetter(t("description"))}</label>
          <p className={"py-1"}>{observedSystemData?.description ?? "-"}</p>
        </div>
        <div>
          <label className="font-bold text-blue-900"> {capitalizeFirstLetter(t("storage"))}</label>
          <p className={"py-1"}>{storage_system.find((storage) => storage.id === observedSystemData?.storage_system_id)?.name ?? "-"}</p>
        </div>
      </div>
      <div className={"pt-2.5"}>
        <DropdownMenu options={makeDropDownOptions()} />
      </div>
      {observedSystemToDelete && (
        <DeleteModal
          callback={() => {
            setIsDeleting(true);
            deleteDataApi("observed_system?observed_system_id=" + observedSystemToDelete.id)
              .then(() => {
                dispatch(removeData({ type: "observed_system", id: observedSystemToDelete.id }));
                toast.success("Le système observé a été supprimé");
                dispatch(selectedObservedSystem(""));
                dispatch(selectedCrudeSource(""));
                setIsDeleting(false);
                setObservedSystemToDelete(undefined);
              })
              .catch((res) => {
                console.debug(res);
                toast.error(res?.response?.data?.error);
                setIsDeleting(false);
              });
          }}
          loading={isDeleting}
          text={t("DELETE") + " " + observedSystemToDelete.name + " ?"}
          modalVisible={observedSystemToDelete}
          setModalVisible={setObservedSystemToDelete}
        />
      )}
    </div>
  );
};
export default ObserveSystemSelect;
