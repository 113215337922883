import { useState } from "react";
import ObservedSystemData from "../multi/ObservedSystemData";

const ObservedSystem = () => {
  const [observedSystem, setObservedSystem] = useState({
    storage_system_id: "",
    membership: "structure",
    description: "",
    name: "",
    owner_ids: "",
  });
  return <ObservedSystemData observedSystem={observedSystem} setObservedSystem={setObservedSystem} />;
};

export default ObservedSystem;
