import { useState } from "react";
import ObservedSystemData from "../multi/ObservedSystemData";
import MultiFormFile from "../multi/MultistepFormCSV";
import { fileDataApi, postDataApi } from "../../axios/DataManagementApi";
import { push } from "../../reducers/dataSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const SystemAndFileUpload = () => {
  const dispatch = useDispatch();
  const [observedSystemInfo, setObservedSystemInfo] = useState({
    storage_system_id: sessionStorage.getItem("storageSystemId"),
    membership: "",
    description: "",
    name: "",
    owner_ids: [],
  });
  const handleAdd = (file, data_conf, setLoading) => {
    const data = {
      structure_id: sessionStorage.getItem("structure_id"),
      storage_system_id: observedSystemInfo["storage_system_id"],
      name: observedSystemInfo["name"],
      description: observedSystemInfo["description"],
      membership: observedSystemInfo["membership"],
      owner_ids: observedSystemInfo["membership"] === "structure" ? [] : [observedSystemInfo["owner_ids"]],
    };
    postDataApi("observed_system?structure_id=" + sessionStorage.getItem("structure_id"), data)
      .then((res) => {
        dispatch(
          push({
            data: {
              name: data.name,
              description: data.description,
              storage_system_id: data.storage_system_id,
            },
            type: "observed_system",
          }),
        );
        toast.success("Le système observé " + data.name + " a été créé");
        fileDataApi("upload?observed_system_id=" + res.data.id, file, data_conf)
          .then((res) => {
            console.debug(res);
            setLoading(false);
          })
          .catch((error) => {
            console.debug(error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.debug(error);
        setLoading(false);
      });
  };
  return (
    <div>
      <ObservedSystemData observedSystem={observedSystemInfo} setObservedSystem={setObservedSystemInfo} isMulti={true} />
      <MultiFormFile selectObs={false} callback={handleAdd} />
    </div>
  );
};
export default SystemAndFileUpload;
