import { Fragment, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableFilter } from "./TableFilter";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import { toast } from "react-toastify";
import uniqueId from "lodash.uniqueid";
import { BarsArrowUpIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import { getDataApi } from "../../axios/DataManagementApi";
import { useSelector } from "react-redux";
import { PrimaryButton } from "../Buttons/Button";

const TableLogAction = () => {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state) => state.application.profile);
  const update = () => {
    setLoading(true);
    getDataApi("log/" + profile["structure_id"])
      .then((res) => {
        const formatted = JSON.parse(res.data.data);
        toast.success("Informations mises à jour.");
        setData(formatted);
        setLoading(false);
      })
      .catch((error) => {
        console.debug(error);
        setLoading(false);
      });
  };
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("user_email", {
      id: "email",
      header: "Utilisateur",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("action_type", {
      id: "action_type",
      header: "Action",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("action_name", {
      id: "action_name",
      header: "Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("timestamp", {
      id: "timestamp",
      header: "Date",
      cell: (info) => new Date(info.getValue() * 1000).toLocaleString(),
    }),
    columnHelper.display({
      id: "param",
      header: "Paramètre",
      cell: (info) => {
        const row = info.row.original;
        const id = info.cell.id;
        const action = row.display?.action;
        return (
          <div className={"flex justify-center gap-x-1.5"}>
            <Tooltip className="z-40 border border-gray-200" id={id} variant={"light"}>
              {paramHandling(action)}
            </Tooltip>
            <BarsArrowUpIcon
              data-tooltip-id={id}
              className={"h-8 w-8 cursor-pointer text-blue-900 transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110"}
            />
          </div>
        );
      },
    }),
    columnHelper.display({
      id: "status",
      cell: (info) => {
        const row = info.row.original;

        const message = row.display?.message ?? "N/A";
        const id_output = "output_" + info.cell.id;
        const status = row.display?.status ?? "N/A";
        return status === "ERROR" ? (
          <Fragment>
            <Tooltip className=" z-40 border border-gray-200" id={id_output} variant={"light"}>
              {message}
            </Tooltip>
            <ExclamationTriangleIcon
              data-tooltip-id={id_output}
              className={"h-8 w-8 cursor-pointer text-red-600 transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110"}
            />
          </Fragment>
        ) : (
          <span className={"h-8 w-8"} />
        );
      },
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className=" m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className=" flex flex-row px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
        <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
          <PrimaryButton isLoading={loading} disabled={loading} callback={update} label="Mettre à jour" />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};
export default TableLogAction;

export const paramHandling = (param) => {
  if (param === undefined) return "NO PARAM";

  return Object.entries(param).map((value) => (
    <li key={uniqueId()} className={"flex gap-x-1.5"}>
      <ul className={"font-semibold"}>{value[0]}:</ul>
      <ul>{typeof value[1] === "object" && value[1] !== null ? paramHandling(value[1]) : value[1] !== null ? value[1].toString() : ""}</ul>
    </li>
  ));
};
