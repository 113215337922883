import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { makeSelectField } from "../../../utils/common";
import { useState } from "react";
import { setCrudeSource, setCurrentTimeParams } from "../../../reducers/indicatorSlice";
import SwitchButton from "../../../components/Selectors/SwitchButton";
import { aggregationOptions } from "../../../utils/field";
import NumericInput from "../../../components/Inputs/NumericInput";
import ConfigurationAlerteFormula from "../ConfigurationAlerte/ConfigurationAlerteFormula";
import { PrimaryButton } from "../../../components/Buttons/Button";
import { formatBackend } from "../../../utils/date.utils";
import { postIndicatorApi } from "../../../axios/IndicatorComputationApi";
import IndicatorResult from "../IndicatorResult";
import LabelTooltip from "../../../components/Tooltip/LabelTooltip";
import SimpleTime from "./SimpleTime";

const FormulaSimple = () => {
  const { moduleData, selected_crude_source } = useSelector((state) => state.indicator);
  const { current } = moduleData;
  const { time_params, computation_params } = current;
  const { start_time, end_time, bucket_size, label, time_label } = time_params;
  const { crude_source } = useSelector((state) => state.data);
  const [crudeSourceOptions] = useState(makeSelectField(crude_source, ["name", "description"], true));
  const dispatch = useDispatch();
  const [aggregation, setAggregation] = useState(false);
  const [aggregationMode, setAggregationMode] = useState("avg");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState({});
  const { showLabel } = useSelector((state) => state.application);

  function handleCrudeSource(selected, action) {
    if (action.action === "select-option" && action.option.value === "all") dispatch(setCrudeSource(crudeSourceOptions.map((value) => value.value)));
    else dispatch(setCrudeSource(selected.map((value) => value.value)));
  }

  function handleTimeParams(name, value) {
    dispatch(setCurrentTimeParams({ key: name, value: value }));
  }

  const parseFormulaV1 = (formula, blocks) => {
    let ret = formula;
    blocks.forEach((block) => {
      Object.keys(block.preprocess).forEach((value) => {
        ret = ret.replaceAll(value, "['" + value + "']");
      });
    });
    return ret;
  };

  function getDataBlocks(source, sourceList, agregation = "identity") {
    const dataBlocks = [];

    for (let i = 0; i < source.length; i++) {
      const sourceId = source[i];
      const sourceData = sourceList.find((item) => item.id === sourceId);

      if (sourceData && sourceData.measure) {
        const measureKeys = Object.keys(sourceData.measure);
        const timeField = measureKeys.find((key) => sourceData.measure[key].visualization_type === "date");

        const preprocess = {};
        measureKeys.forEach((key) => {
          if (key !== timeField) {
            preprocess[key] = agregation;
          }
        });

        const dataBlock = {
          configured_sources: [],
          time_field: timeField ?? "internal",
          preprocess: preprocess,
          measure_identifier: sourceData.measure_identifier,
          status: -1,
        };

        dataBlocks.push(dataBlock);
      }
    }

    return dataBlocks;
  }

  function compute() {
    setIsLoading(true);
    const obs = sessionStorage.getItem("observed_system_id");

    const payload = {
      current: {
        data_params: getDataBlocks(selected_crude_source, crude_source, aggregation ? aggregationMode : "identity"),
        time_params: {
          start_time: formatBackend(start_time),
          end_time: formatBackend(end_time),
          bucket_size: aggregation ? bucket_size : -1,
          label: label,
        },
        computation_params: structuredClone(computation_params),
      },
    };
    if (showLabel) payload.current.time_params.time_label = time_label;

    payload.current.computation_params.formula = parseFormulaV1(computation_params.formula, payload.current.data_params);
    console.debug(payload);
    postIndicatorApi("indicator/analyticalformula/" + obs, payload)
      .then((res) => {
        setIsLoading(false);
        console.debug(res);
        setResult(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.debug(error);
      });
  }

  return (
    <div className="mx-4 my-2">
      <div className="block rounded border border-gray-200 bg-white px-4 dark:border-gray-600 dark:bg-gray-800">
        <h2 className="text-2xl font-semibold text-blue-900">Paramètres des données</h2>
        <div className={"mb-2 flex gap-x-4 px-8"}>
          <SimpleTime />
        </div>
        <div className={"mb-2 border-b px-8 pb-2"}>
          <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Source(s) de données</label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            placeholder={"Source(s) de données"}
            value={crudeSourceOptions.filter((value) => selected_crude_source.includes(value.value))}
            options={crudeSourceOptions}
            onChange={handleCrudeSource}
          />
        </div>
        <div className={"mb-2 px-8"}>
          <SwitchButton label={"Agrégation"} value={aggregation} onChange={(value) => setAggregation(value)} />
        </div>
        {aggregation && (
          <div className={"mb-2 grid grid-cols-6 gap-x-4 px-16"}>
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Type</label>
              <Select
                options={aggregationOptions}
                value={aggregationOptions.find((value) => value.value === aggregationMode)}
                onChange={(newValue) => setAggregationMode(newValue.value)}
              />
            </div>
            <NumericInput label={"Intervalle de temps (s)"} value={bucket_size} name={"bucket_size"} onChange={handleTimeParams} />
          </div>
        )}
        <div className={"mb-2 w-1/2 px-8"}>
          <LabelTooltip
            label={"Formule"}
            tip={crude_source
              .filter((value) => selected_crude_source.includes(value.id))
              .map((value) => Object.keys(value.measure))
              .flat()
              .map((value, index) => (
                <p key={index}>{value}</p>
              ))}
          />
          <ConfigurationAlerteFormula mode={"current"} verify={false} />
        </div>
      </div>
      <div className={"flex justify-center"}>
        <PrimaryButton disabled={isLoading} isLoading={isLoading} label={"Calculer"} callback={() => compute()} />
      </div>
      <IndicatorResult type={5} result={result} />
    </div>
  );
};
export default FormulaSimple;
