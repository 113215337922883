import { Fragment, useEffect, useState } from "react";
import { makeSelectField } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import { getDataApi } from "../../axios/DataManagementApi";
import { toast } from "react-toastify";
import { setModuleData } from "../../reducers/indicatorSlice";
import { PrimaryButton } from "../Buttons/Button";

const StatusModal = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  const { configured_source, crude_source } = useSelector((state) => state.data);
  const { indicator_id, defining_status } = useSelector((state) => state.indicator.moduleData);
  const [elementOption, setElementOption] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [statusListByElement, setStatusListByElement] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleElementChange = (newValue) => {
    setIsLoading(true);
    getDataApi(`status/${sessionStorage.getItem("observed_system_id")}/${newValue.value}`)
      .then((response) => {
        setIsLoading(false);
        const statusList = Object.values(response.data.data).map((status) => {
          return { label: status.status_id, value: status.status_id };
        });
        if (statusList.length === 0) {
          toast.warn("Pas de status pour la configuration selectionnée.");
          return;
        }
        setSelectedStatus(null);
        setSelectedElement(newValue);
        setStatusListByElement(statusList);
      })
      .catch(() => setIsLoading(false));
  };
  const handleStatusChange = (newValue) => {
    setSelectedStatus(newValue);
  };
  useEffect(() => {
    if (defining_status.type === "CRUDE_SOURCE")
      setElementOption(
        makeSelectField(
          configured_source.filter((conf) => conf.crude_source === defining_status.id),
          ["description"],
        ),
      );
    if (defining_status.type === "OBSERVED_SYSTEM")
      setElementOption(
        makeSelectField(
          crude_source.filter((cs) => cs.observed_system_id === defining_status.id),
          ["name"],
        ),
      );
  }, [configured_source, crude_source, defining_status]);
  const labelFromDefiningStatusType = () => {
    if (defining_status.type === "CRUDE_SOURCE") return "Configuration";
    if (defining_status.type === "OBSERVED_SYSTEM") return "Source de données";
  };
  const handleAdd = () => {
    dispatch(setModuleData({ key: "indicator_id", value: { ...indicator_id, [selectedElement.value]: selectedStatus.value } }));
    setIsOpen(false);
    setSelectedStatus(null);
    setStatusListByElement([]);
    setSelectedElement(null);
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>
        <div className="fixed inset-0 ">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className=" h-auto w-2/3 rounded-2xl  bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className={"grid grid-cols-2 gap-x-2"}>
                  <div>
                    <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{labelFromDefiningStatusType()}</label>

                    <Select value={selectedElement} isLoading={isLoading} isDisabled={isLoading} options={elementOption} onChange={handleElementChange} />
                  </div>
                  <div>
                    <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">Status</label>
                    {selectedElement !== null && <Select value={selectedStatus} options={statusListByElement} onChange={handleStatusChange} />}
                  </div>
                </div>
                <div className={"flex justify-end"}>
                  <PrimaryButton label={"ajouter"} callback={handleAdd} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default StatusModal;
