import IndicatorMailingForm from "../IndicatorMailingForm";
import IndicatorAccordion from "../IndicatorAccordion";
import MRDParameters from "./MRDParameters";
import IndicatorExecutionMode from "./IndicatorExecutionMode";

const MRDRightPanel = () => (
  <div className="grid h-[80vh] grid-flow-row auto-rows-max overflow-auto bg-white shadow-lg">
    <IndicatorAccordion label={"Programmation d'exécution du module"} composant={<IndicatorExecutionMode />} open={false} />
    <IndicatorAccordion label={"Notification via courier électronique"} composant={<IndicatorMailingForm />} />
    <IndicatorAccordion label={"Paramètres du suivi de la remontée de données"} composant={<MRDParameters />} />
  </div>
);
export default MRDRightPanel;
