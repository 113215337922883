import Select from "react-select";
import { makeSelectField, makeVariableField } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { removeDataParam, setCrudeSource, setDataParam, setMode, setModuleData } from "../../reducers/indicatorSlice";
import { DetectionAnomalieModuleParam } from "./utils";
import { configurationAlerteMode, dataStatusOptions, DetectionAnomalieMode } from "../../utils/field";
import { Fragment, useState } from "react";
import { data_params } from "../event/EventUtils";
import VariableTableHisto from "../event/VariableTableHisto";
import VariableTable from "../event/VariableTable";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons/Button";
import { Tooltip } from "react-tooltip";
import VariableRecap from "./VariableRecap";

const IndicatorDataConfiguration = ({ isMethod = false, isTwoBloc = false, isVariable = true }) => {
  const dispatch = useDispatch();
  const crudeSources = useSelector((state) => state.data.crude_source);
  const configurations = useSelector((state) => state.data.configured_source);
  const mode = useSelector((state) => state.indicator.moduleData.mode);
  const moduleName = useSelector((state) => state.indicator.moduleData.module_name) ?? "";
  const { selected_crude_source } = useSelector((state) => state.indicator);
  const [sourceId, setSourceId] = useState(false);
  const handleMethod = () => (
    <div className=" border-b  border-blue-900 py-2">
      <h1 className="w-fit py-2 text-sm font-bold tracking-wide text-gray-700 dark:text-white">Méthode de détection</h1>
      <Select
        isSearchable={false}
        value={DetectionAnomalieMode.find((value) => value.value === moduleName)}
        className="w-full  rounded-lg outline-none"
        onChange={(newValue) => {
          dispatch(setModuleData({ key: "module_name", value: newValue["value"] }));
          dispatch(
            setModuleData({
              key: "module_params",
              value: DetectionAnomalieModuleParam(newValue["value"]),
            }),
          );
        }}
        options={DetectionAnomalieMode}
      />
    </div>
  );

  const handleTwoBlocs = () => (
    <div className=" border-b  border-blue-900 py-2">
      <label className=" py-2 text-sm font-bold tracking-wide text-gray-700 dark:text-white">Méthode</label>
      <Select
        isSearchable={false}
        className="w-full  rounded-lg outline-none"
        onChange={(newValue) => {
          dispatch(setMode(newValue["value"]));
        }}
        value={configurationAlerteMode.find((value) => value.value === mode)}
        options={configurationAlerteMode}
      />
    </div>
  );
  const MultiValueLabel = (props) => {
    console.debug(props);
    return (
      <Fragment>
        <a data-tooltip-id={props.data.value}>{props.data.label}</a>
        <Tooltip variant={"light"} className={"z-40 bg-gray-200 p-0 opacity-100"} noArrow positionStrategy={"fixed"} id={props.data.value}>
          <VariableRecap crude_source_id={props.data.value} />
        </Tooltip>
      </Fragment>
    );
  };
  return (
    <div className="block rounded border border-gray-200 bg-white px-4 dark:border-gray-600 dark:bg-gray-800">
      <div>
        {isVariable ? (
          <div>
            {isMethod && handleMethod()}
            {isTwoBloc && !isMethod && handleTwoBlocs()}
            <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Source(s) de données</label>
            <Select
              components={{ MultiValueLabel }}
              styles={{
                multiValueLabel: (base) => {
                  return {
                    ...base,
                    color: "white",
                  };
                },
              }}
              placeholder={"Source(s) de données..."}
              isClearable={true}
              isMulti
              onFocus={(event) => console.debug(event)}
              value={selected_crude_source}
              options={makeSelectField(crudeSources, ["description"])}
              isDisabled={sourceId}
              isLoading={sourceId}
              onChange={(options, action) => {
                if (action.action === "remove-value") {
                  const configured_source_id = crudeSources.find((value) => value.id === action.removedValue.value).configuration[0];
                  const measure_identifier = configurations.find((value) => value.id === configured_source_id).measure_identifier;
                  dispatch(removeDataParam(measure_identifier));
                  dispatch(setCrudeSource(options));
                }
                if (action.action === "select-option") {
                  dispatch(setCrudeSource(options));
                  setSourceId(action.option.value);
                }
              }}
            />
            {sourceId && <DataParamCreation sourceId={sourceId} setSourceId={setSourceId} />}
          </div>
        ) : (
          "WIP"
        )}
      </div>
    </div>
  );
};
export default IndicatorDataConfiguration;

const DataParamCreation = ({ sourceId, setSourceId }) => {
  const { configured_source, crude_source } = useSelector((state) => state.data);
  const source = crude_source.find((value) => value.id === sourceId);
  const { selected_crude_source } = useSelector((state) => state.indicator);
  const { mode } = useSelector((state) => state.indicator.moduleData) ?? 1;
  console.debug(mode);
  const dispatch = useDispatch();
  const [rowSelection, setRowSelection] = useState({});
  const preprocesses = (variables) => {
    const preprocessesList = structuredClone(variables.filter((variable) => variable[1].type !== "date"));
    preprocessesList.forEach((preprocess) => {
      preprocess[1]["current"] = "identity";
      preprocess[1]["historical"] = "identity";
      preprocess[2] = false;
    });
    return preprocessesList;
  };
  const [variables, setVariables] = useState(preprocesses(Object.entries(source.measure)));
  const [data, setData] = useState(structuredClone(data_params));
  const updateData = (name, value) => {
    setData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const create = () => {
    const current = structuredClone(data);
    current.measure_identifier = source.measure_identifier;
    current.configured_sources = current.configured_sources.map((value) => value.value);
    current.time_field = current.time_field.replace(sourceId + "_", "");
    const historical = structuredClone(current);
    Object.keys(rowSelection).forEach((row) => {
      const variable = variables[row];
      console.debug(variable[0], variable[1]["current"]);
      current.preprocess[variable[0]] = variable[1]["current"];
      if (!mode) {
        historical.preprocess[variable[0]] = variable[1]["historical"];
      }
    });
    dispatch(setDataParam({ cur: current, hist: mode ? null : historical }));
    setSourceId(false);
  };
  return (
    <div>
      <div>
        <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Variable temporelle</label>
        <Select
          isSearchable={false}
          isClearable={false}
          options={makeVariableField(1, sourceId, source.measure, false)}
          value={makeVariableField(1, sourceId, source.measure, false).find((value) => value.label === data.time_field)}
          onChange={(newValue) => updateData("time_field", newValue["value"])}
          placeholder={"Index de temps..."}
        />
      </div>
      <div>
        <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Configuration</label>
        <Select
          isMulti
          value={data.configured_sources}
          onChange={(options) => updateData("configured_sources", options)}
          options={makeSelectField(
            configured_source.filter((value) => value.crude_source === sourceId),
            ["description"],
          )}
          placeholder={"Toute les configurations"}
        />
      </div>
      <div className={"pb-2"}>
        <h1 className="py-2 text-sm font-bold tracking-wide text-gray-700 dark:text-white">Données à considérer</h1>
        <Select
          options={dataStatusOptions}
          onChange={(newValue) => {
            updateData("status", newValue["value"]);
          }}
          value={dataStatusOptions.find((value) => value.value === data["status"])}
        />
      </div>
      {mode ? (
        <VariableTable row={rowSelection} setRow={setRowSelection} data={variables} setData={setVariables} />
      ) : (
        <VariableTableHisto row={rowSelection} setRow={setRowSelection} data={variables} setData={setVariables} />
      )}

      <div className="flex justify-center gap-x-0.5">
        <SecondaryButton
          label={"Annuler"}
          callback={() => {
            dispatch(setCrudeSource(selected_crude_source.filter((value) => value.value !== sourceId)));
            setSourceId(false);
          }}
        />
        <PrimaryButton label={"ajouter"} callback={create} />
      </div>
    </div>
  );
};
