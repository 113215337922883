import uniqueId from "lodash.uniqueid";

export const DetectionAnomalieMode = [{ value: "kalmanvalidation", label: "Kalman" }];
export const configurationAlerteMode = [
  { value: 0, label: "Historique" },
  { value: 1, label: "Courant" },
];
export const historicalModeOptions = [
  { label: "count", value: "count" },
  { label: "time", value: "time" },
];
export const indicatorField = [
  // {
  //   value: 0,
  //   script: "indicator_1_module",
  //   label: "Monitoring de remontée de données",
  //   multi: false,
  // },
  {
    value: 1,
    script: "outliers_detection_module",
    label: "Détection d'anomalie",
    multi: false,
  },

  {
    value: 4,
    script: "alert_monitoring",
    label: "Configuration d'alerte",
    multi: true,
  },
  {
    value: 7,
    script: "time_indicator",
    label: "Indicateur temporel",
    multi: true,
  },
  {
    value: 8,
    script: "outliers_validation_module",
    label: "Validation d'anomalie",
    multi: true,
  },
  {
    value: 11,
    script: "statusmanager",
    label: "Gestion des états",
  },
  {
    value: 12,
    script: "statusmanager",
    label: "statusmanager",
  },
];
export const computationField = [
  // {
  //   value: 2,
  //   label: "Corrélation",
  //   multi: true,
  // },
  //
  // {
  //   value: 5,
  //   label: "Formule analytique",
  //   multi: true,
  // },
  // {
  //   value: 6,
  //   label: "Indicateur industriel",
  //   multi: true,
  // },
  // {
  //   value: 9,
  //   label: "Cross correlation",
  //   multi: true,
  // },
  // {
  //   value: 10,
  //   label: "ACP",
  //   multi: true,
  // },
  {
    value: 20,
    label: "Corrélation",
  },
  {
    value: 21,
    label: "Formule analytique",
  },
  {
    value: 22,
    label: "Corrélation croisée",
  },
  {
    value: 23,
    label: "ACP",
  },
  {
    value: 24,
    label: "Indicateur mode industriel",
  },
  {
    value: 25,
    label: "Caractérisation",
  },
];
export const aggregationOptions = [
  { value: "min", label: "minimum" },
  { value: "max", label: "maximum" },
  { value: "avg", label: "mean" },
];
export const aggregationOptions2 = [{ value: "mode", label: "mode" }];
export const ownerField = [
  { id: 1, value: "structure", label: "Structure" },
  { id: 2, value: "observed_system", label: "Système observé" },
];
export const storageField = [
  { id: uniqueId(), value: 1, label: "Postgresql" },
  { id: uniqueId(), value: 0, label: "Elasticsearch" },
  { id: uniqueId(), value: 2, label: "MongoDB" },
];

export const sourceField = [
  { value: 2, label: "Generic Sensor" },
  { value: 1, label: "IS" },
  { value: 0, label: "Operator" },
];
export const typeField = [
  { id: 0, value: "date", label: "Date" },
  { id: 1, value: "string", label: "Autre" },
  { id: 2, value: "integer", label: "Entier" },
  { id: 3, value: "float", label: "flottant" },
];

export const dataStatusOptions = [
  { value: -1, label: "Toutes les données" },
  { value: 1, label: "Que les données non validées" },
  { value: 2, label: "Que les données validées" },
];
