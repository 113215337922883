import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { current, historical, redirect } from "./EventUtils";
import { initEvent, removeDataParams, setBase } from "../../reducers/EventSlice";
import { getSourceWithMeasure, makeSelectField } from "../../utils/common";
import Select from "react-select";
import DataParamCreation from "./DataParamCreation";
import TimeParamCreation from "./TimeParamCreation";
import ComputationParamCreation from "./ComputationParamCreation";
import { useNavigate } from "react-router-dom";
import { postDataApi } from "../../axios/DataManagementApi";
import { push } from "../../reducers/dataSlice";
import { PrimaryButton } from "../../components/Buttons/Button";

const AnalyticalFormulaEvent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const selectedSource = useSelector((state) => state.event.selectedSource);
  const data = useSelector((state) => state.event);

  const source = useSelector((state) => state.event.source);
  const sourceId = useSelector((state) => state.event.sourceId);
  const configurations = useSelector((state) => state.data.configured_source);
  useEffect(() => {
    const init = {
      current: structuredClone(current),
      historical: structuredClone(historical),
    };

    dispatch(
      initEvent({
        data: structuredClone(init),
        source: getSourceWithMeasure("crude_source"),
        selectedSource: null,
        histo: false,
        count: true,
        sourceId: false,
        lockedHisto: true,
      }),
    );
    setLoaded(true);
  }, [dispatch]);
  const create = () => {
    setIsLoading(true);
    const state = structuredClone(data);
    const formattedData = {};
    formattedData["module_name"] = "Analyticalformula";
    formattedData["function_name"] = "process";
    formattedData["parameters"] = {
      computation_params: state.data.current.computation_params,
      data_params: state.data.current.data_params,
      time_params: state.data.current.time_params,
    };
    postDataApi("event/" + sessionStorage.getItem("structure_id"), formattedData).then((res) => {
      formattedData["creation_timestamp"] = new Date(Date.now()).toString();
      formattedData["id"] = res.data.id;
      setIsLoading(false);
      dispatch(push({ type: "event", data: formattedData }));
      history(redirect);
    });
  };

  return (
    <div>
      {loaded && (
        <div>
          <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
            <h2 className="text-2xl font-semibold text-blue-900">Paramètres des données</h2>
            <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Source(s) de données</label>
            <Select
              placeholder={"Source(s) de données..."}
              openMenuOnClick={false}
              isClearable={true}
              isMulti={true}
              value={selectedSource}
              options={makeSelectField(source, ["name"])}
              isDisabled={sourceId}
              isLoading={sourceId}
              onChange={(option, action) => {
                if (action.action === "remove-value") {
                  //                  const measure_identifier = configurations.find((configuration) => configuration.id === action.removedValue.value).configuration[0].measure_identifier;
                  const configuration_source = source.find((source) => source.id === action.removedValue.value).configuration[0];
                  const measure_identifier = configurations.find((value) => value.id === configuration_source).measure_identifier;
                  dispatch(removeDataParams(measure_identifier));
                  dispatch(setBase({ type: "selectedSource", value: option }));
                  dispatch(setBase({ type: "selectedSource", value: option }));
                }
                if (action.action === "select-option") {
                  dispatch(setBase({ type: "selectedSource", value: option }));
                  dispatch(setBase({ type: "sourceId", value: action.option.value }));
                }
              }}
            />
            {sourceId && <DataParamCreation />}
          </div>
          <TimeParamCreation />
          <ComputationParamCreation />
        </div>
      )}
      <div className="flex justify-center">
        <PrimaryButton label={"Créer"} disabled={isLoading} isLoading={isLoading} callback={create} />
      </div>
    </div>
  );
};
export default AnalyticalFormulaEvent;
