//React librairies
import { Fragment, useEffect } from "react";
import { createBrowserRouter, createRoutesFromElements, redirect, Route, useNavigate, useRouteError } from "react-router-dom";
import ConfiguredSource from "../pages/configuration/ConfiguredSource";
import ObservedSystem from "../pages/add/ObservedSystem";
//Pages
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import AdminProfile from "../pages/admin/Profile";
//Routing
import PrivateRoute from "./PrivateRoute";
import Data from "../pages/configuration/Data";
import Instance from "../pages/configuration/Instance";
import MultiFormFile from "../pages/multi/MultistepFormCSV";
import IndicatorCreator from "../pages/indicator/IndicatorCreator";
import Graph from "../pages/graph/Graph";
import AdminUser from "../pages/admin/User";
import UserEdit from "../pages/admin/UserEdit";
import User from "../pages/settings/User";
import CreateUser from "../pages/settings/createUser";
import AdminCreateUser from "../pages/admin/createUser";
import EditUser from "../pages/settings/UserEdit";
import EventCreator from "../pages/event/EventCreator";
import RegressionModelCreator from "../pages/RegressionModel/RegressionModelCreator";
import EventCreator2 from "../pages/event/EventCreator2";
import SystemAndSource from "../pages/configuration/SystemAndSource";
import ThresholdCreator from "../pages/threshold/ThresholdCreator";
import IndicatorView from "../pages/indicator/IndicatorView";
import Structure from "../pages/configuration/Structure";
import IndicatorCompute from "../pages/indicator/IndicatorCompute";
import Structures from "../pages/admin/Structures";
import SystemAndFileUpload from "../pages/configuration/SystemAndFileUpload";
import Log from "../pages/admin/Log";
import AdminCreateProfile from "../pages/admin/createProfile";
import ProfilePage from "../pages/ProfilePage";
import StructureEdit from "../components/edit/StructureEdit";
import CrudeSourceEdit from "../components/edit/CrudeSourceEdit";
import ProfileEdit from "../components/edit/ProfileEdit";
import ObservedSystemEdit from "../components/edit/ObservedSystemEdit";
import StorageSystemEdit from "../components/edit/StorageSystemEdit";
import ConfiguredSourceEdit from "../components/edit/ConfiguredSourceEdit";
import FileBatchUpload from "../pages/configuration/FileBatchUpload";
import { getDataApi } from "../axios/DataManagementApi";
import { getUserApi } from "../axios/UserManagementApi";
import { useDispatch } from "react-redux";
import { logout } from "../reducers/ApplicationSlice";
import IndicatorList from "../components/Table/IndicatorList";
import EventList from "../components/Table/EventList";
import ThresholdList from "../components/Table/ThresholdList";
import CrudeSourceExport from "../components/export/CrudeSourceExport";
import ModeleList from "../pages/modele/ModeleList";
import Training from "../pages/modele/Training";
import Prediction from "../pages/modele/Prediction";
import PredictionGraph from "../pages/modele/PredictionGraph";
import LabelList from "../pages/Label/LabelList";
import StatusList from "../pages/status/StatusList";
import ObservedSystemStatus from "../pages/status/ObservedSystemStatus";
import ConfiguredSourceStatus from "../pages/status/ConfiguredSourceStatus";
import CrudeSourceStatus from "../pages/status/CrudeSourceStatus";
import GraphList from "../pages/graph/GraphList";
import GraphStateTimelineVisualizer from "../pages/graph/GraphStateTimelineVisualizer";
import DataFeedback from "../pages/DataFeedback/DataFeedback";
import ReconstructionModel from "../pages/modele/ReconstructionModel";
import Reconstruction from "../pages/modele/Reconstruction";
import DatabaseFeedback from "../pages/DatabaseFeedback/DatabaseFeedback";
import { toast } from "react-toastify";
import { dataLoadingToast } from "../components/Toasts/DataLoadingToast";
import TransformersList from "../pages/Transformers/TransformersList";
import { dataList } from "../utils/common";

//
const Routing = () => (
  <Fragment>
    <Route path="/" element={<PrivateRoute />} errorElement={<ErrorBoundary />}>
      <Route path="/" element={<Dashboard />} lazy={() => import("./lazy/DashboardLazy")} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="analytics">
        <Route path="/analytics/indicator/create" element={<IndicatorCreator />} />
        <Route path="/analytics/indicator/compute" element={<IndicatorCompute />} />
        <Route path="/analytics/indicator" element={<IndicatorList />} lazy={() => import("./lazy/IndicatorLazy")} />
        <Route path="/analytics/indicator/view/:id_indic" element={<IndicatorView />} />
        <Route path="/analytics/graph/view" element={<Graph />} />
        <Route path="/analytics/graph/view/statetimeline" element={<GraphStateTimelineVisualizer />} />
        <Route path="/analytics/graph" element={<GraphList />} />
        <Route path="/analytics/event" element={<EventList />} lazy={() => import("./lazy/EventLazy")} />
        <Route path="/analytics/event/create" element={<EventCreator />} />
        <Route path="/analytics/event/create2" element={<EventCreator2 />} />

        <Route path="/analytics/model" element={<RegressionModelCreator />} lazy={() => import("./lazy/ReggressionModelLazy")} />

        <Route path="/analytics/threshold" element={<ThresholdList />} lazy={() => import("./lazy/ThresholdLazy")} />
        <Route path="/analytics/threshold/create" element={<ThresholdCreator />} />
        <Route path="/analytics/modele" element={<ModeleList />} lazy={() => import("./lazy/PredictionLazy")} />
        <Route path="/analytics/modele/prediction/training" element={<Training />} />
        <Route path="/analytics/modele/reconstruction/training" element={<ReconstructionModel />} />
        <Route path="/analytics/modele/reconstruction" element={<Reconstruction />} />

        <Route path="/analytics/modele/predict" element={<Prediction />} />
        <Route path="/analytics/modele/predict/:id" element={<Prediction />} />
        <Route path="/analytics/modele/graph/:id" element={<PredictionGraph />} lazy={() => import("./lazy/PredictionGraphLazy")} />
        <Route path="/analytics/labels" element={<LabelList />} lazy={() => import("./lazy/LabelLazy")} />
        <Route path="/analytics/status" element={<StatusList />} />
      </Route>
      <Route
        path={"/crude_source/edit/:id"}
        element={<CrudeSourceEdit />}
        loader={({ params }) =>
          getDataApi("crude_source?crude_source_id=" + params.id + "&observed_system_id=" + sessionStorage.getItem("observed_system_id")).then(
            (res) => res.data.data,
          )
        }
      />
      <Route
        path={"/crude_source/status/:observed_system_id/:crude_source_id"}
        element={<CrudeSourceStatus />}
        loader={({ params }) =>
          getDataApi("status/" + params.observed_system_id + "/" + params.crude_source_id + "?active=true&histo=true")
            .then((res) => res.data.data)
            .catch((error) => {
              toast.error(error.response.data.message);
              return redirect("/");
            })
        }
      />
      <Route path={"/crude_source/export/:id"} element={<CrudeSourceExport />} />
      <Route path={"/crude_source/nouvelle-configuration/:id"} element={<ConfiguredSource />} lazy={() => import("./lazy/ConfiguredSourceLazy")} />
      <Route
        path={"/observed_system/edit/:id"}
        element={<ObservedSystemEdit />}
        loader={({ params }) => getDataApi("observed_system?observed_system_id=" + params.id).then((res) => res.data.data)}
      />
      <Route
        path={"/observed_system/status/:id"}
        element={<ObservedSystemStatus />}
        loader={({ params }) =>
          getDataApi("status/" + params.id + "?active=true&histo=true")
            .then((res) => res.data.data)
            .catch((error) => {
              toast.error(error.response.data.message);
              return redirect("/");
            })
        }
      />
      <Route
        path={"/storage_system/edit/:id"}
        element={<StorageSystemEdit />}
        loader={({ params }) => getDataApi("storage_system?instance_id=" + params.id).then((res) => res.data.data)}
      />
      <Route
        path={"/configured_source/edit/:id"}
        element={<ConfiguredSourceEdit />}
        loader={({ params }) =>
          getDataApi("configured_source?observed_system_id=" + sessionStorage.getItem("observed_system_id") + "&configured_source_id=" + params.id).then(
            (res) => res.data.data,
          )
        }
      />
      <Route
        path={"/configured_source/status/:observed_system_id/:configured_source_id"}
        element={<ConfiguredSourceStatus />}
        loader={({ params }) =>
          getDataApi("status/" + params.observed_system_id + "/" + params.configured_source_id + "?active=true&histo=true")
            .then((res) => res.data.data)
            .catch((error) => {
              toast.error(error.response.data.message);
              return redirect("/");
            })
        }
      />
      <Route
        path={"/configuration/transformers/:id"}
        element={<TransformersList />}
        loader={({ params }) =>
          getDataApi(`transformer/get_transformers?observed_system_id=${sessionStorage.getItem("observed_system_id")}&configured_source_id=${params.id}`)
            .then((res) => dataList(res.data.data))
            .catch(() => [])
        }
      />
      <Route
        path={"/configured_source/upload/:id"}
        element={<FileBatchUpload />}
        loader={({ params }) =>
          getDataApi("configured_source?observed_system_id=" + sessionStorage.getItem("observed_system_id") + "&configured_source_id=" + params.id).then(
            (res) => res.data.data,
          )
        }
      />

      <Route path="settings">
        <Route path="/settings/users" element={<User />} />
        <Route path="/settings/users/create" element={<CreateUser />} />
        <Route path="/settings/users/edit/:id" element={<EditUser />} />
        <Route path="/settings/profile/:id" element={<EditUser />} />
      </Route>

      <Route path="configuration">
        <Route path="/configuration/instance" element={<Instance />} />
        <Route path="/configuration/system" element={<ObservedSystem />} />
        <Route path="/configuration/create" element={<SystemAndSource />} />
        <Route path="/configuration/data" element={<Data />} />
        <Route path="/configuration/upload" element={<MultiFormFile />} />
        <Route path="/configuration/uploadv2" element={<SystemAndFileUpload />} />
        <Route path="/configuration/data-feedback/:id" element={<DataFeedback />} />
        <Route path="/configuration/database/:id" element={<DatabaseFeedback />} />
      </Route>

      <Route path="admin">
        <Route path="/admin/structures" element={<Structures />} lazy={() => import("./lazy/StructureLazy")} />
        <Route path="/admin/users" element={<AdminUser />} lazy={() => import("./lazy/UserLazy")} />
        <Route
          path="/admin/users/edit/:id"
          element={<UserEdit />}
          loader={({ params }) => getUserApi("management?user_id=" + params.id).then((res) => res.data.data)}
        />
        <Route path="/admin/users/create" element={<AdminCreateUser />} />
        <Route path="/admin/structure/create" element={<Structure />} />
        <Route
          path="/admin/structure/edit/:id"
          element={<StructureEdit />}
          loader={({ params }) => getDataApi("structure?structure_id=" + params.id).then((res) => res.data.data)}
        />

        <Route path="/admin/log" element={<Log />} />
        <Route path="/admin/profile" element={<AdminProfile />} lazy={() => import("./lazy/ProfileLazy")} />
        <Route
          path="/admin/profile/edit/:id"
          element={<ProfileEdit />}
          loader={({ params }) =>
            getUserApi("profile?profile_id=" + params.id)
              .then((res) => res.data.data)
              .catch((error) => console.debug(error))
          }
        />

        <Route path="/admin/profile/create" element={<AdminCreateProfile />} />
      </Route>
    </Route>
    <Route path="/login" element={<Login />} />
  </Fragment>
);
export const router = createBrowserRouter(createRoutesFromElements(Routing()));

function ErrorBoundary() {
  let error = useRouteError();
  const history = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const statusCode = error?.response?.status;

    if (statusCode === 500) {
      console.debug(error.response.data);
      dataLoadingToast({ error: true, errorMessage: error.message });

      toast.error(error.message);
      history("/");
    }
    if (statusCode === 401) {
      sessionStorage.clear();
      dispatch(logout());
      history("/login");
    }
  }, [dispatch, error, history]);

  return null;
}
