import BasicTooltip from "./BasicTooltip";
import { isEmptyString } from "../../utils/common";

const LabelTooltip = ({ label = "", tip }) => (
  <div className=" flex items-center gap-1.5">
    {!isEmptyString(label) && <label className="text-sm font-bold tracking-wide text-blue-900 dark:text-white ">{label}</label>}
    <BasicTooltip tip={tip} />
  </div>
);
export default LabelTooltip;
