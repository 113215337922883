import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useEffect, useState } from "react";
import { setDefiningStatus } from "../../reducers/indicatorSlice";
import { isEmptyString, makeSelectField } from "../../utils/common";
import store from "../../store";

const IndicatorDefiningStatus2 = () => {
  const dispatch = useDispatch();
  const { defining_status } = useSelector((state) => state.indicator.moduleData);
  const [idOptions, setIdOptions] = useState([]);

  const { type, id } = defining_status;
  const typeOptions = [
    {
      value: "OBSERVED_SYSTEM",
      label: "système observé",
    },
    {
      value: "CRUDE_SOURCE",
      label: "Source de données",
    },
  ];
  useEffect(() => {
    const state = store.getState();
    if (isEmptyString(type)) return;
    if (type === "CRUDE_SOURCE") {
      const { crude_source } = state.data;
      setIdOptions(makeSelectField(crude_source, ["name"]));
    }
    if (type === "OBSERVED_SYSTEM") {
      const { observed_system } = state.data;
      setIdOptions(makeSelectField(observed_system, ["name"]));
    }
  }, [type]);
  return (
    <div>
      <div>
        <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">Type</label>
        <Select
          required
          onChange={(newValue) => {
            if (type !== newValue.value) dispatch(setDefiningStatus({ key: "type", value: newValue.value }));
          }}
          placeholder={"En selectionner un..."}
          options={typeOptions}
          value={typeOptions.find((value) => value.value === type)}
          isSearchable={false}
          isClearable={false}
        />
      </div>
      <div>
        <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">Element</label>
        <Select
          isDisabled={defining_status.type === "OBSERVED_SYSTEM"}
          required
          placeholder={"En selectionner un..."}
          isSearchable={false}
          isClearable={false}
          options={idOptions}
          value={idOptions.find((value) => value.value === id) ?? ""}
          onChange={(newValue) => {
            if (id !== newValue.value) dispatch(setDefiningStatus({ key: "id", value: newValue.value }));
          }}
        />
      </div>
    </div>
  );
};
export default IndicatorDefiningStatus2;
