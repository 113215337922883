import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import TextInput from "../../components/Selectors/TextInput";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { edit } from "../../reducers/dataSlice";
import { toast } from "react-toastify";
import { putDataApi } from "../../axios/DataManagementApi";
import { PrimaryButton } from "../Buttons/Button";
import { capitalizeFirstLetter, isEmptyString } from "../../utils/common";
import { useTranslation } from "react-i18next";

const CrudeSourceEdit = () => {
  const { t } = useTranslation(["common", "button", "toast"]);

  const dataloaded = useLoaderData();
  const dispatch = useDispatch();
  const redirect = "/";
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const observedSystemId = useSelector((state) => state.selected.observed_system);
  const history = useNavigate();
  const [item, setItem] = useState(dataloaded);
  const onChange = (name, value) => {
    setItem((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <div className="flex gap-x-1.5">
        <IconButton bg={true} Icon={<Prev size={40} />} value={""} callback={() => history(redirect)} />
        <h2 className="text-3xl font-semibold text-blue-900">{capitalizeFirstLetter(t("crude_source"))}</h2>
      </div>
      <div className={"grid grid-cols-2 gap-4  border-b pb-2"}>
        <TextInput name={"name"} value={item?.name} onChange={onChange} label={capitalizeFirstLetter(t("name"))} required placeholder={"..."} />
        <TextInput name={"description"} value={item?.description} onChange={onChange} label={capitalizeFirstLetter(t("description"))} />
      </div>
      <div className={"flex justify-end gap-2 pt-2 "}>
        <PrimaryButton
          isLoading={isLoading}
          label={t("edit", { ns: "button" })}
          disabled={isLoading || isEmptyString(item.name)}
          callback={() => {
            setIsLoading(true);
            putDataApi("crude_source?observed_system_id=" + observedSystemId + "&crude_source_id=" + id, {
              name: item.name,
              description: item.description,
            })
              .then(() => {
                dispatch(edit({ type: "crude_source", updates: item, id: id }));
                setIsLoading(false);
                toast.success(t("CRUDE_SOURCE_EDIT", { ns: "toast" }));
                history(redirect);
              })
              .catch((error) => {
                console.debug(error);
                setIsLoading(false);
              });
          }}
        />
      </div>
    </div>
  );
};
export default CrudeSourceEdit;
