import ReactJson from "@microlink/react-json-view";

const ApiResult = ({ data, setData }) => (
  <ReactJson
    enableClipboard={false}
    onDelete={(e) => {
      setData(e.updated_src);
      console.debug(e);
    }}
    name={false}
    src={data}
  />
);

export default ApiResult;
