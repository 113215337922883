import IndicatorAccordion from "../IndicatorAccordion";
import IndustrialCurrent from "../IndicatorIndustrialHisto/IndustrialCurrent";
import AcpParams from "./AcpParams";

const AcpRightPanel = () => (
  <div className=" grid min-h-[80vh] grid-flow-row auto-rows-max bg-white  shadow-lg">
    <IndicatorAccordion label={"Paramètres de calcul"} composant={<IndustrialCurrent time={true} />} />
    <IndicatorAccordion label={"Paramètres de correlation"} composant={<AcpParams />} />
  </div>
);
export default AcpRightPanel;
