import { useEffect, useState } from "react";
import { TableFilter } from "./TableFilter";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { canRenderAction, canRenderComponent, dataList, getUserPreferences } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ACTION_FLAGS } from "../../constants/action_flags";
import DeleteModal from "../Selectors/DeleteModal";
import { remove, reset, set } from "../../reducers/dataSlice";
import { set as setDataLoaded } from "../../reducers/ApplicationSlice";

import { selectedStructure } from "../../reducers/SelectedSlice";
import { deleteDataApi, getDataApi } from "../../axios/DataManagementApi";
import store from "../../store";
import { PrimaryButton, SecondaryButton } from "../Buttons/Button";
import { getUserApi } from "../../axios/UserManagementApi";
import TextTooltip from "../Tooltip/TextTooltip";
import { useTranslation } from "react-i18next";
import Table from "./Table";
import { mdiDelete, mdiPencil } from "@mdi/js";
import DropdownMenu from "../Selectors/DropDownButton";

const StructureList = () => {
  const { t } = useTranslation(["common", "button"]);

  const [structureToDelete, setStructureToDelete] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);

  const [structureId, setStructureId] = useState(sessionStorage.getItem("structure_id"));
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [canDelete] = useState(canRenderAction(ACTION_FLAGS.CANDELETE, COMPONENTS.STRUCTURE_DISPLAY));
  const [canEdit] = useState(canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.STRUCTURE_DISPLAY));
  const [authorizedStructureCreation, setAuthorizedStructureCreation] = useState(canRenderComponent(COMPONENTS.STRUCTURE_CREATION));
  useEffect(
    () => () => {
      setAuthorizedStructureCreation(canRenderComponent(COMPONENTS.STRUCTURE_CREATION));
    },
    [authorizedComponent],
  );

  const [globalFilter, setGlobalFilter] = useState("");
  const [refresh, setRefresh] = useState(false);
  const structureList = useSelector((state) => state.data.structures);
  const [data, setData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const dispatch = useDispatch();
  const handleStructureChange = (updater) => {
    const newSelected = updater();
    const index = Object.keys(newSelected)[0];
    const structureId = data[index].id;
    dispatch(setDataLoaded({ type: "dataLoaded", payload: false }));
    sessionStorage.setItem("structure_id", structureId);
    sessionStorage.removeItem("observed_system_desc");
    sessionStorage.removeItem("observed_system_id");
    sessionStorage.removeItem("observed_system_name");
    sessionStorage.removeItem("status");
    sessionStorage.removeItem("observed_system_status_graph");
    dispatch(reset()); //clear data
    dispatch(selectedStructure(structureId)); //set selected structure id
    setStructureId(structureId);
    getUserApi("profile?structure_id=" + structureId).then((res) => {
      store.dispatch(set({ data: dataList(res.data.data), type: "profiles" }));
    });
    getUserApi("method").then((res) => {
      store.dispatch(set({ data: dataList(res.data.data), type: "methods" }));
    });
    history("/");
    setRowSelection(newSelected);
  };
  useEffect(() => {
    const newData = structureList;
    const index = newData.findIndex((value) => value.id === structureId);
    if (index !== -1) {
      setRowSelection({ [index]: true });
    }
    setData(newData);
  }, [structureId, structureList]);

  const history = useNavigate();

  const createStructure = () => history("/admin/structure/create");
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: "ID",
      cell: (info) => <TextTooltip text={info.getValue()} tip={info.getValue()} className={"w-28 truncate"} />,
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: t("name"),
      cell: (info) => (
        <button className={"font-semibold"} onClick={() => (info.row.getIsSelected() ? null : info.row.toggleSelected(true))}>
          {info.getValue()}
        </button>
      ),
    }),

    columnHelper.accessor("description", {
      id: "desc",
      header: t("description"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions_button",
      cell: (info) => {
        const row = info.row.original;
        const options = [];
        if (canEdit)
          options.push({
            id: 0,
            label: "Modifier",
            onClick: () => history("/admin/structure/edit/" + row.id),
            path: mdiPencil,
          });
        if (canDelete)
          options.push({
            id: 2,
            label: "Supprimer",
            onClick: () => setStructureToDelete(row),
            path: mdiDelete,
          });
        return <DropdownMenu options={options} />;
      },
    }),
  ];
  const showId = getUserPreferences().find((preference) => preference.name === "showId")?.value ?? true;

  if (!showId) columns.splice(0, 1);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      rowSelection,
    },
    enableMultiRowSelection: false,
    enableRowSelection: (row) => !row.getIsSelected(),
    onRowSelectionChange: handleStructureChange,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className=" m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3 align-middle">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
        <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
          <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
            <SecondaryButton
              isLoading={refresh}
              disabled={refresh}
              callback={() => {
                setRefresh(true);
                getDataApi("structure").then((res) => {
                  store.dispatch(set({ type: "structures", data: dataList(res.data) }));
                  toast.success("Informations mises à jour.");
                  setRefresh(false);
                });
              }}
              label={t("update", { ns: "button" })}
            />
          </div>
          {authorizedStructureCreation && (
            <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
              <PrimaryButton callback={createStructure} label={t("create_structure", { ns: "button" })} />
            </div>
          )}
        </div>
      </div>
      <Table table={table} />
      {structureToDelete && (
        <DeleteModal
          callback={() => {
            if (safeStructure.includes(structureToDelete.id)) {
              toast.warn("Structure importante, ne pas supprimer");
              return;
            }
            setIsDeleting(true);
            deleteDataApi("structure?structure_id=" + structureToDelete.id)
              .then(() => {
                store.dispatch(remove({ type: "structures", id: structureToDelete.id }));
                toast.success("La structure a été supprimé");
                setIsDeleting(false);
                setStructureToDelete(undefined);
              })
              .catch((error) => {
                console.debug(error);
                setIsDeleting(false);
              });
          }}
          loading={isDeleting}
          text={t("DELETE") + " " + structureToDelete.name + "?"}
          modalVisible={structureToDelete}
          setModalVisible={setStructureToDelete}
        />
      )}
    </div>
  );
};
export default StructureList;

const safeStructure = ["3ezO6YIBzrBpdS7jYrKA", "BAC9coUBzrBpdS7jXoCC", "Zv25vYQBzrBpdS7jfA7u", "PjqSiH8BmY0NB0MdU8Vt"];
