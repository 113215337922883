import { Fragment, useState } from "react";
import { DeleteButton, PrimaryButton, SecondaryButton } from "../../components/Buttons/Button";
import { Dialog, Transition } from "@headlessui/react";
import { DisplayValue } from "./DisplayValue";

const RequestParametersFiltersDisplay = ({ filters, setFilters, mappingWithType }) => {
  const [filterToEdit, setFilterToEdit] = useState(null);
  const [valueToUpdate, setValueToUpdate] = useState(null);
  const handleAdd = () => {
    setFilters(filterToEdit, valueToUpdate);
    handleCancel();
  };
  const handleCancel = () => {
    setValueToUpdate(null);
    setFilterToEdit(null);
  };
  return (
    <div>
      <div>
        <Transition appear show={filterToEdit !== null && valueToUpdate !== null} as={Fragment}>
          <Dialog
            as="div"
            className={"relative z-50"}
            onClose={() => {
              setFilterToEdit(null);
              setValueToUpdate(null);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            </Transition.Child>
            <div className="fixed inset-0 ">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className=" h-auto w-2/3 rounded-2xl  bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <div className={"flex flex-col gap-2"}>
                      <DisplayValue
                        value={valueToUpdate}
                        setValue={setValueToUpdate}
                        type={mappingWithType[filterToEdit]}
                        operator={findOperatorFromFilter(valueToUpdate)}
                      />
                      <div className={"flex justify-end gap-x-0.5"}>
                        <DeleteButton label={"Annuler"} callback={handleCancel} />
                        <PrimaryButton label={"Ajouter"} callback={handleAdd} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
      {Object.keys(filters).map((filter, index) => (
        <div className={"grid grid-cols-4 py-1"} key={index}>
          <p className={"flex items-center justify-center px-1 py-0.5"}>{filter}</p>
          <div className={"col-span-2 grid grid-cols-2"}>
            {Object.entries(filters[filter]).map((entry, index) => (
              <Fragment key={index}>
                <div className={"flex items-center justify-center px-1 py-0.5"}>{signToText(entry[0])}</div>

                <div className={"flex items-center justify-center px-1 py-0.5"}>{Array.isArray(entry[1]) ? entry[1].join(" - ") : entry[1]}</div>
              </Fragment>
            ))}
          </div>
          <div className={"flex items-center justify-center gap-x-1"}>
            <SecondaryButton label={"Supprimer"} callback={() => setFilters(filter, null)} />
            <SecondaryButton
              callback={() => {
                console.debug(filters, filter, filters[filter]);
                setFilterToEdit(filter);
                setValueToUpdate(filters[filter]);
              }}
              label={"Modifier"}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
export default RequestParametersFiltersDisplay;

const signToText = (sign) => {
  switch (sign) {
    case "in":
      return "Égal à";
    case ">=":
      return "Supérieur  ou égal à";
    case "<=":
      return "Inférieur ou égal à";
    case ">":
      return "Supérieur à";
    case "<":
      return "Inférieur à";
  }
};

const findOperatorFromFilter = (filter) => {
  if (filter === null) return null;
  if ("in" in filter) {
    return "list";
  }
  if (">=" in filter && "<=" in filter) {
    return "range";
  }
  if (">=" in filter) {
    return "gt";
  }
  if ("<=" in filter) {
    return "lt";
  }
  // Add other conditions if needed
  // For example, you might want to handle other operators as well

  // If none of the conditions match, you can return a default value or null
  return null;
};
