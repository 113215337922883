import IndicatorRecap from "./IndicatorRecap";
import IndicatorHeader from "./IndicatorHeader";
import IndicatorBody from "./IndicatorBody";
import { initModule } from "./utils";

import { useDispatch, useSelector } from "react-redux";
import { set } from "../../reducers/indicatorSlice";
import { indicatorField } from "../../utils/field";
import IndicatorResult from "./IndicatorResult";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/common";

const IndicatorCreator = () => {
  const { t } = useTranslation(["common", "button"]);
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.indicator.moduleData);
  const [result, setResult] = useState({});
  return (
    <>
      <IndicatorHeader
        field={indicatorField}
        title={capitalizeFirstLetter(t("indicator_creator"))}
        set={(e) => {
          setResult({});
          dispatch(set(initModule(e.value)));
        }}
      />
      <IndicatorBody id={name} />
      {name !== 4 && name !== 7 && <IndicatorRecap label={t("create", { ns: "button" })} />}
      <IndicatorResult type={name} result={result} />
    </>
  );
};
export default IndicatorCreator;
