import { combineReducers, configureStore } from "@reduxjs/toolkit";
import applicationReducer from "./reducers/ApplicationSlice";
import analyticsReducer from "./reducers/graphSlice";
import indicatorReducer from "./reducers/indicatorSlice";
import dataReducer from "./reducers/dataSlice";
import logger from "redux-logger";
import eventReducer from "./reducers/EventSlice";
import selectedReducer from "./reducers/SelectedSlice";

const combinedReducer = combineReducers({
  application: applicationReducer,
  analyticsGraph: analyticsReducer,
  indicator: indicatorReducer,
  data: dataReducer,
  event: eventReducer,
  selected: selectedReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "application/logout") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
});
