import { useDispatch, useSelector } from "react-redux";
import { setCurrentTimeParams, setModuleData } from "../../../reducers/indicatorSlice";
import ConfigurationAlerteFormula from "../ConfigurationAlerte/ConfigurationAlerteFormula";
import TimezoneSelect from "react-timezone-select";
import NumericInput from "../../../components/Inputs/NumericInput";
import { useState } from "react";

import SimpleTime from "../Simple/SimpleTime";

const IndustrialCurrent = ({ time = false }) => {
  const dispatch = useDispatch();
  const { current, timezone } = useSelector((state) => state.indicator.moduleData);
  const [timeLabel, setTimeLabel] = useState("Last 5 minutes");

  return (
    <div className="py-2">
      <div>
        <ConfigurationAlerteFormula mode={"current"} />
      </div>
      <div className={"w-fit"}>
        <NumericInput
          name={"bucket_size"}
          value={current.time_params.bucket_size}
          label={"Taille du bucket"}
          step={1}
          min={-1}
          onChange={(name, value) => dispatch(setCurrentTimeParams({ key: name, value: value }))}
        />
      </div>
      {time && (
        <div className="flex flex-wrap gap-x-4">
          <SimpleTime timeLabel={timeLabel} setTimeLabel={setTimeLabel} />
          <div>
            <label className="mb-1 block text-sm font-medium text-gray-900">Fuseau horaire</label>
            <TimezoneSelect
              className={"w-72"}
              value={timezone}
              onChange={(e) => {
                dispatch(
                  setModuleData({
                    key: "timezone",
                    value: e.value,
                  }),
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default IndustrialCurrent;
