import { setDataParam } from "../../reducers/indicatorSlice";
import { configurationAlerteMode, historicalModeOptions } from "../../utils/field";
import store from "../../store";
import { findDateField, isEmptyString } from "../../utils/common";
import dayjs from "dayjs";
import { current, historical } from "../event/EventUtils";

export const getBlockInfo = (block) => (
  <div className={"text-blue-900"}>
    <div className={"pt-1 font-bold tracking-wide text-blue-950"}>
      <p>Timestamp utilisé</p> <p className={"font-normal"}>{block.time_field}</p>
    </div>
    <p className={"pt-1 font-bold tracking-wide text-blue-950"}>Variables</p>
    <div className={"pl-2"}>
      {Object.keys(block.preprocess).map((item, index) => (
        <div key={index} className={"flex gap-x-1"}>
          <p>{item}:</p>
          <p className={"font-normal text-blue-800"}>{block.preprocess[item]}</p>
        </div>
      ))}
    </div>

    <p className={"pt-1 font-bold tracking-wide text-blue-950"}>Configurations</p>
    {Object.keys(block.configured_sources).length === 0 && <p className={"pl-2"}> Toutes les configurations sont sélectionnées </p>}
    {Object.keys(block.configured_sources).map((item, index) => (
      <p key={index} className={"pl-2"}>
        {block.configured_sources[item]}
      </p>
    ))}
  </div>
);
export const formatOffset = (offset) => {
  let hours = Math.trunc(offset);
  let minutes = Math.abs(offset - hours) * 60;
  return (offset >= 0 ? "+" : "-") + (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes;
};

export const initModule = (value) => {
  console.debug(value);
  switch (value) {
    case 0: //Monitoring Remontée données
      return {
        script_name: "indicator_1_module",
        script_parameters: {
          data_fields: [],
          timestamp_field: "",
          observed_system: "",
          configured_sources: [],
          sender: "notifications-retd@preditic.com",
          recipients: [],
          subject: "",
          delay: 3,
          control_mode: 0,
          control_mode_parameter: 2,
          aggregation_mode: 0,
          initial_timestamp: dayjs().subtract(5, "m").format("YYYY-MM-DDTHH:mm:ss"),
          final_timestamp: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
          timezone: "Europe/Paris",
        },
        frequency: 10,
        validity_days: "1-5",
        time_slot: "9-18",
        mode: 1,
        name: value,
      };
    case 1: // Anomaly detection
      return {
        script_name: "outliers_detection_module",
        name: value,
        frequency: 10,
        validity_days: "1-5",
        time_slot: "9-18",
        mode: configurationAlerteMode[0].value,
        historicalMode: historicalModeOptions[0].value,
        current,
        historical,
        module_name: "kalmanvalidation",
        module_params: {
          target_variable: "",
          time_field: "",
          threshold: 0.95,
        },
        isValid: true,
        timezone: "Europe/Paris",
        initial_timestamp: dayjs().subtract(5, "m").format("YYYY-MM-DDTHH:mm:ss"),
        execution_interval: 0,
        alert_settings: {
          type: "counting",
          value: 1,
          event: "",
        },
      };
    case 2: // CORRELATION
    case 20:
      return {
        script_name: "correlation",
        name: value,
        mode: configurationAlerteMode[1].value,
        current: {
          data_params: [],
          time_params: {
            start_time: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
            end_time: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
            bucket_size: 86400,
            label: "",
          },
          computation_params: {
            function: {
              variables: {},
              name: "identity",
            },
            formula: "",
          },
        },
        target_variables: [],
        timezone: "Europe/Paris",
      };
    case 4: //ALERT MONITORING
      return {
        script_name: "alert",
        name: value,
        frequency: 10,
        validity_days: "1-5",
        time_slot: "9-18",
        mode: configurationAlerteMode[1].value,
        historicalMode: historicalModeOptions[0].value,
        observed_system: "",
        timezone: "Europe/Paris",
        initial_timestamp: dayjs().subtract(5, "m").format("YYYY-MM-DDTHH:mm:ss"),
        current,
        historical,
        properties_params: {
          observed_system: [],
          configured_source: {
            id: "",
            fields: [],
          },
        },
        alert_params: {
          alert_name: "",
          threshold_label: "",
          state_i: [],
          mode: {
            type: "COUNTING",
            value: 1,
          },
          event: "",
        },
      };
    case 5: //ANALYTIC FORMULA
    case 21:
      return {
        mode: configurationAlerteMode[1].value,
        name: value,
        current: {
          data_params: [],
          time_params: {
            start_time: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
            end_time: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
            bucket_size: 86400,
            label: undefined,
          },
          computation_params: {
            function: {
              variables: {},
              name: "identity",
            },
            formula: "",
          },
        },
        timezone: "Europe/Paris",
      };
    case 6: //INDUSTRIAL COMPUTATION
    case 24:
      return {
        mode: configurationAlerteMode[1].value,
        historicalMode: historicalModeOptions[0].value,
        timezone: "Europe/Paris",
        name: value,
        historical,
        current: {
          data_params: [],
          time_params: {
            start_time: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
            end_time: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
            bucket_size: 86400,
            label: "",
          },
          computation_params: {
            function: {
              variables: {},
              name: "identity",
            },
            formula: "",
          },
        },
        indicator_params: {
          time_indicators: true,
          threshold_label: "",
          state_i: [],
        },
        properties_params: {
          observed_system: [],
          configured_source: {
            id: "",
            fields: [],
          },
        },
      };
    case 7: // TEMPOREL INDICATOR
      return {
        script_name: "indicator_2_10_module",
        name: value,
        frequency: 10,
        validity_days: "1-5",
        time_slot: "9-18",
        mode: configurationAlerteMode[1].value,
        historicalMode: historicalModeOptions[0].value,
        current,
        historical,
        timezone: "Europe/Paris",
        initial_timestamp: dayjs().subtract(5, "m").format("YYYY-MM-DDTHH:mm:ss"),
        execution_interval: 900,
        event: undefined,
        indicator_params: {
          time_threshold: 7200,
          time_indicators: true,
          threshold_label: "",
          state_i: [],
        },
        properties_params: {
          observed_system: [],
          configured_source: {
            id: "",
            fields: [],
          },
        },
      };
    case 8: //OUTLIER VALIDATION
      return {
        script_name: "outliers_validation_module",
        name: value,
        frequency: 10,
        validity_days: "1-5",
        time_slot: "9-18",
        mode: configurationAlerteMode[1].value,
        timezone: "Europe/Paris",
        initial_timestamp: dayjs().subtract(5, "m").format("YYYY-MM-DDTHH:mm:ss"),
        parameters: {
          measure_identifier: null,
          event_id: undefined,
          start_time: dayjs().subtract(5, "m").format("YYYY-MM-DDTHH:mm:ss"),
          end_time: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
        },
      };
    case 9: //CROSSCORRELATION
    case 22:
      return {
        mode: configurationAlerteMode[1].value,
        script_name: "crosscorrelation",
        name: value,
        current: {
          data_params: [],
          time_params: {
            start_time: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
            end_time: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
            bucket_size: 86400,
            label: "",
          },
          computation_params: {
            function: {
              variables: {},
              name: "identity",
            },
            formula: "",
          },
        },
        target_variables: [],
        timezone: "Europe/Paris",
      };
    case 10: //PCA
    case 23:
      return {
        mode: configurationAlerteMode[1].value,
        script_name: "PCA",
        name: value,
        current: {
          data_params: [],
          time_params: {
            start_time: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
            end_time: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
            bucket_size: 86400,
            label: "",
          },
          computation_params: {
            function: {
              variables: {},
              name: "identity",
            },
            formula: "",
          },
        },
        pca_params: {
          variables: [],
          nb_dims: -1,
          auto_scale: true,
        },
        timezone: "Europe/Paris",
      };
    case 11:
      return {
        script_name: "statusmanager",
        timezone: "Europe/Paris",
        initial_timestamp: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
        name: value,
        frequency: 10,
        validity_days: "1-5",
        time_slot: "9-18",
        indicator_id: "",
        threshold_label: "",
        defining_status: {
          type: "",
          id: "",
        },
        shift: 600,
        is_active: true,
      };
    case 12:
      return {
        script_name: "statussetter",
        timezone: "Europe/Paris",
        initial_timestamp: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
        name: value,
        frequency: 10,
        validity_days: "1-5",
        time_slot: "9-18",
        indicator_id: {},
        threshold_label: "",
        defining_status: {
          type: "",
          id: "",
        },
        shift: 600,
        is_active: true,
      };
    case 25:
      return {
        name: value,
        current: {
          time_params: {
            start_time: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
            end_time: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
            bucket_size: 86400,
            label: "",
          },
          data_params: [],
          computation_params: {
            formula: "",
            function: {
              name: "identity",
              variables: {},
            },
          },
        },
        characterization_params: {
          target_variable: "",
          threshold: 0.8,
          alpha: 0.05,
          random_nb_lags: -1,
        },
      };
    default:
      return {};
  }
};

export function getDataBlocks(source, sourceList, agregation = "identity") {
  const dataBlocks = [];

  for (let i = 0; i < source.length; i++) {
    const sourceId = source[i];
    const sourceData = sourceList.find((item) => item.id === sourceId);

    if (sourceData && sourceData.measure) {
      const timeField = findDateField(sourceData.measure);
      const preprocess = {};
      Object.keys(sourceData.measure).forEach((key) => {
        if (key !== timeField) {
          preprocess[key] = agregation;
        }
      });

      const dataBlock = {
        configured_sources: [],
        time_field: timeField ?? "internal",
        preprocess: preprocess,
        measure_identifier: sourceData.measure_identifier,
        status: -1,
      };

      dataBlocks.push(dataBlock);
    }
  }

  return dataBlocks;
}
/**
 * Creates the field used in selector with the variable used
 * @param dataparams
 * @returns {*[]}
 */
export const variableField = (dataparams) => {
  const ret = [];
  dataparams.forEach((block) => {
    const preprocess = block["preprocess"];
    Object.keys(preprocess).forEach((key) => {
      const to_push = { label: key, value: key };
      if (ret.find((elem) => elem.name === key && elem.value === key) === undefined) {
        ret.push(to_push);
      }
    });
  });
  return ret;
};
/**
 * Creates the field used in selector with the variable used
 * @param dataparams
 * @returns {*[]}
 */
export const variableFieldMulti = (dataparams) => {
  const options = [];
  options.push({
    options: [{ label: "Résultat de la formule", value: "computed_field" }],
    label: "",
  });
  dataparams.forEach((block) => {
    const group = [];
    const preprocess = block["preprocess"];
    Object.keys(preprocess).forEach((key) => {
      const to_push = { label: key, value: key }; //TODO gestion si des variables ont le même nom
      if (group.find((elem) => elem.label === key && elem.value === key) === undefined) {
        group.push(to_push);
      }
    });
    options.push({ options: group, label: block.crude_source });
  });
  return options;
};

export const DetectionAnomalieModuleParam = (type) => {
  switch (type) {
    case "kalman":
      return {
        target_variable: "",
        time_field: "",
        threshold: 0.95,
      };
    default:
      return {};
  }
};

export const getThreshold = (obs) => {
  let threshold_result = [];
  if (!isEmptyString(obs) || obs !== undefined) {
    const state = store.getState();
    const threshold = state.data.threshold;
    threshold_result = threshold.filter((value) => value.observed_system_id === obs);
  }
  return threshold_result;
};

export const add = (crudeSources, selCrudeSource, bloc, selConfiguration, dispatch, timeVariable, status = -1) => {
  const state = store.getState();
  const configurations = state.data.configured_source;
  const configured_id = crudeSources.find((value) => value.id === selCrudeSource).configuration[0];
  const configured_source = configurations.find((value) => configured_id === value.id);
  const measure_identifier = configured_source["measure_identifier"];
  const preprocess = {};
  const timeField = timeVariable.value.replace(timeVariable.crude_source_id + "_", "");
  Object.keys(bloc.to_add).forEach((value) => {
    if (bloc.to_add[value] === true) {
      preprocess[bloc.data[value].name] = bloc.data[value].value;
    }
  });
  const conf = [];
  selConfiguration.forEach((tmp) => conf.push(tmp.value));
  const data = {
    configured_sources: conf,
    time_field: timeField,
    preprocess: preprocess,
    measure_identifier: measure_identifier,
    status: status,
  };
  dispatch(setDataParam({ cur: data }));
};
export const addTwoBlocs = (crudeSources, selCrudeSource, bloc, selConfiguration, timeVariable, dispatch, mode, status) => {
  const state = store.getState();
  const configurations = state.data.configured_source;
  const configured_id = crudeSources.find((source) => source.id === selCrudeSource)["configuration"][0];
  const configured_source = configurations.find((configuration) => configuration.id === configured_id);
  const measure_identifier = configured_source["measure_identifier"];
  const preprocessHist = {};
  const preprocessCur = {};

  Object.keys(bloc.to_add).forEach((value) => {
    if (bloc.to_add[value] === true) {
      preprocessHist[bloc.hist[value].name] = bloc.hist[value].value;
      preprocessCur[bloc.cur[value].name] = bloc.cur[value].value;
    }
  });
  const conf = [];
  selConfiguration.forEach((tmp) => conf.push(tmp.value));
  let timeField = timeVariable.value.replace(timeVariable.crude_source_id + "_", "");
  const data = {};
  data["cur"] = {
    configured_sources: conf,
    time_field: timeField,
    preprocess: preprocessCur,
    measure_identifier: measure_identifier,
    status: status,
  };
  if (mode === 0) {
    data["hist"] = {
      configured_sources: conf,
      time_field: timeField,
      preprocess: preprocessHist,
      measure_identifier: measure_identifier,
      status: status,
    };
  }
  dispatch(setDataParam(data));
};

export const variableOptions = (id, name, type = 0) => [
  {
    type: type,
    id: id,
    value: "identity",
    label: "identity",
    name: name,
  },
  {
    type: type,
    id: id,
    value: "min",
    label: "min",
    name: name,
  },
  {
    type: type,
    id: id,
    value: "max",
    label: "max",
    name: name,
  },
  {
    type: type,
    id: id,
    value: "avg",
    label: "moyenne",
    name: name,
  },
];
