import { variableField } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { setPcaParams } from "../../../reducers/indicatorSlice";
import Select from "react-select";
import NumericInput from "../../../components/Inputs/NumericInput";
import SwitchButton from "../../../components/Selectors/SwitchButton";

const AcpParams = () => {
  const dispatch = useDispatch();
  const { pca_params, current } = useSelector((state) => state.indicator.moduleData);
  const { variables, nb_dims, auto_scale } = pca_params;
  console.debug(variables);

  const onChange = (list) => {
    const target = [];
    list.forEach((opt) => target.push(opt.value));
    dispatch(setPcaParams({ key: "variables", value: target }));
  };
  return (
    <div>
      <label className="py-2 text-sm font-bold tracking-wide text-gray-700 dark:text-white ">Cible(s)</label>
      <Select
        placeholder={"Toutes.."}
        isMulti
        value={variableField(current["data_params"]).filter((value) => variables.includes(value.value))}
        onChange={onChange}
        options={variableField(current["data_params"])}
      />
      <NumericInput
        value={nb_dims}
        min={-1}
        max={variables.length}
        label={"Nombre de dimensions"}
        onChange={(name, value) => dispatch(setPcaParams({ key: "nb_dims", value: value }))}
      />
      <SwitchButton label={"Normalisation des données"} value={auto_scale} onChange={(e) => dispatch(setPcaParams({ key: "auto_scale", value: e }))} />
    </div>
  );
};

export default AcpParams;
