import TextInput from "../../components/Selectors/TextInput";
import NumericInput from "../../components/Inputs/NumericInput";
import PasswordInput from "../../components/Inputs/PasswordInput";

export const MongoDBParam = ({ credential, handleCredentialsChange }) => (
  <div className={" w-full p-4"}>
    <div className={"w-1/2"}>
      <div className={"flex gap-x-2"}>
        <div className={"grow"}>
          <TextInput label={"Host"} value={credential.host} name="host" onChange={handleCredentialsChange} />
        </div>
        <NumericInput label={"Port"} value={credential.port} name="port" onChange={(name, value) => handleCredentialsChange(name, isNaN(value) ? "" : value)} />
      </div>

      <TextInput label={"Name"} value={credential.name} name="name" onChange={handleCredentialsChange} />
      <div className={"flex gap-x-2"}>
        <TextInput label={"User"} value={credential.user} name="user" onChange={handleCredentialsChange} />
        <form>
          <PasswordInput width={"w-fit"} label={"Password"} value={credential.password} name={"password"} onChange={handleCredentialsChange} />
        </form>
      </div>
    </div>
  </div>
);
