import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiArrowLeftThick } from "@mdi/js";
import { SelectGraph } from "../Select/SelectGraph";
import { isEmptyObject } from "../../utils/common";
import TimeSelector from "../TimeSelector";

export function GraphHeader({ onChange, params, onCreate, prev, startTime, endTime }) {
  const history = useNavigate();
  const modeOptions = [
    { label: "linéaire", value: "linear" },
    { label: "logarithmique", value: "logarithm" },
  ];
  const timezoneOptions = [
    { label: "Europe/Paris", value: "Europe/Paris" },
    { label: "UTC", value: "UTC" },
  ];
  const handleNavigate = () => {
    console.debug(prev);
    history(prev);
  };
  return (
    <nav className="bg-white">
      <div className="relative flex items-center justify-end gap-x-4 px-8 py-2">
        <div className="absolute inset-y-0 left-0 flex items-center px-8 py-2">
          <button
            onClick={handleNavigate}
            className={
              "flex gap-x-1.5 rounded-lg border border-white px-3 py-1.5 text-sm font-semibold text-neutral-900 hover:border hover:border-neutral-300 hover:bg-gray-200"
            }
          >
            <Icon path={mdiArrowLeftThick} size={1} />
          </button>
        </div>
        <TimeSelector start_time={startTime} end_time={endTime} callback={onCreate} />
        {!isEmptyObject(params) && (
          <SelectGraph
            onChange={(newValue) => {
              onChange("scale_distribution", newValue.value);
            }}
            value={modeOptions.find((value) => value.value === params["scale_distribution"])}
            options={modeOptions}
            width={"w-32"}
          />
        )}
        {!isEmptyObject(params) && (
          <SelectGraph
            onChange={(newValue) => {
              onChange("timezone", newValue.value);
            }}
            value={timezoneOptions.find((value) => value.value === params["timezone"])}
            options={timezoneOptions}
            width={"w-32"}
          />
        )}
      </div>
    </nav>
  );
}
