export const formatBits = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const chartData = (tooltipFormatter, data, legendData) => {
  return {
    tooltip: {
      trigger: "item",
      formatter: tooltipFormatter,
    },
    legend: {
      data: legendData,
      textStyle: {
        fontWeight: "bold",
        fontSize: 10,
      },
      left: "center",
    },
    series: [
      {
        name: "Taille",
        type: "pie",
        radius: ["30%", "70%"],
        center: ["50%", "60%"],
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 1,
        },
        data: data,
        label: {
          formatter(param) {
            // correct the percentage
            return param.percent > 5 ? param.percent + "%" : "";
          },
          show: true,
          position: "inside",
        },
        labelLine: {
          show: true,
        },
      },
    ],
  };
};
