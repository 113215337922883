import CorrelationRightPanel from "./CorrelationRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const Correlation = () => (
  <div className="mx-4 grid gap-4 py-2 lg:grid-cols-2">
    <IndicatorDataConfiguration />
    <CorrelationRightPanel />
  </div>
);
export default Correlation;
