import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  link: "",
  payload: {},
};
export const graphSlice = createSlice({
  name: "analyticGraph",
  initialState: initialState,
  reducers: {
    setLink: (state, action) => {
      state.link = action.payload;
    },
    setPayload: (state, action) => {
      state.payload = action.payload;
    },
    reset: () => initialState,
  },
});

export const { reset, setLink, setPayload } = graphSlice.actions;
export default graphSlice.reducer;
