import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { GraphHeader } from "../../components/Charts/GraphHeader";
import { isEmptyString } from "../../utils/common";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import store from "../../store";
import { formatBackend } from "../../utils/date.utils";
import { postIndicatorApi } from "../../axios/IndicatorComputationApi";
import GraphCaracterizationStateTimeline from "./GraphCaracterizationStateTimeline";

const GraphStateTimelineVisualizer = () => {
  const { payload, link } = useSelector((state) => state.analyticsGraph);
  const [startTime, setStartTime] = useState(dayjs(payload.start_time).format("YYYY-MM-DDTHH:mm:ss"));
  const [endTime, setEndTime] = useState(dayjs(payload.end_time).format("YYYY-MM-DDTHH:mm:ss"));
  const [graphUrl, setGraphUrl] = useState(link);

  const createDataCharacterizationPayload = useCallback(
    (start = startTime, end = endTime) => {
      const crudeSources = store.getState().data.crude_source;
      const crude_source = crudeSources.find((value) => value.id === payload.parameters.crude_source_id);

      dataLoadingToast({ label: "Caractérisation en cours...", isLoading: true });
      const payloadCarac = {
        current: {
          time_params: {
            start_time: formatBackend(start),
            end_time: formatBackend(end),
            bucket_size: -1,
            label: "",
          },
          data_params: [
            {
              measure_identifier: crude_source.measure_identifier,
              configured_sources: [],
              status: -1,
              preprocess: { [payload.status_field]: "identity" },
              time_field: payload.timestamp_field,
            },
          ],
          computation_params: {
            formula: "",
            function: {
              name: "identity",
              variables: {},
            },
          },
        },
        characterization_params: {
          target_variable: [payload.status_field],
          threshold: 0.8,
          alpha: 0.05,
          random_nb_lags: -1,
        },
      };
      postIndicatorApi("indicator/datacharacterization/" + sessionStorage.getItem("observed_system_id"), payloadCarac).then((res) => {
        setDataCharacterization({ ...res.data, payload: payloadCarac });
        dataLoadingToast({ success: true });
      });
    },
    [endTime, payload.parameters.crude_source_id, payload.status_field, payload.timestamp_field, startTime],
  );
  useEffect(() => {
    createDataCharacterizationPayload();
  }, [createDataCharacterizationPayload, payload]);
  const [dataCharacterization, setDataCharacterization] = useState(undefined);

  const onChange = (start, end) => {
    const urlObj = new URL(graphUrl);
    urlObj.searchParams.set("from", start);
    urlObj.searchParams.set("to", end);
    setGraphUrl(urlObj.toString());
  };
  const onCreate = (startDate, endDate) => {
    setStartTime(startDate);
    setEndTime(endDate);
    onChange(dayjs(startDate).unix() * 1000, dayjs(endDate).unix() * 1000);
  };
  return (
    <div>
      <GraphHeader prev={"/analytics/graph"} onChange={onChange} onCreate={onCreate} startTime={startTime} endTime={endTime} />
      {!isEmptyString(graphUrl) && (
        <div className="m-8 block h-[50vh] rounded border border-gray-200 bg-white p-4">
          <iframe className="h-full  w-full " src={graphUrl} />
        </div>
      )}
      <div className={"flex flex-wrap"}>
        <GraphCaracterizationStateTimeline dataCharacterization={dataCharacterization} />
      </div>
    </div>
  );
};
export default GraphStateTimelineVisualizer;
