import { useState } from "react";

import { capitalizeFirstLetter } from "../../utils/common";
import FileUpload from "../configuration/FileUpload";
import ErrorPanel from "../ErrorPanel";

import RecapCsv from "./RecapCsv";
import { useSelector } from "react-redux";
import ConfiguredSourceData from "./ConfiguredSourceData";
import { PrimaryButton } from "../../components/Buttons/Button";
import { fileDataApi } from "../../axios/DataManagementApi";
import { useTranslation } from "react-i18next";
import HorizontalLine from "../../components/HorizontalLine";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";

const MultiFormFile = ({ selectObs = true, callback }) => {
  const { t } = useTranslation(["common", "button"]);
  const storageSystemIds = useSelector((state) => state.data.storage_system);
  const observedSystem = useSelector((state) => state.data.observed_system);

  const [file, setFile] = useState(undefined);
  const [data, setData] = useState({
    col_type: [],
    error: [],
    preview: [],
    data: [],
    delimiter: "",
  });
  const [sourceParametersValue, updateSourceParametersValue] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [configuredSourceInfo, setConfiguredSourceInfo] = useState({
    source_type: "",
    description: "",
    name: "",
    alert_delay: 0,
    is_active: false,
    configured_source_description: "",
    storage_system_id: "",
    id: "",
    sameStorageType: true,
    system: sessionStorage.getItem("observed_system_id"),
    timezone: "Europe/Paris",
    locale: "fr",
  });
  const handleRequest = () => {
    dataLoadingToast({ label: "Création de la tâche de téléversement de fichier", isLoading: true });

    setLoading(true);
    const mapping_type = {};
    const visualization_format = {};
    const mapping_information = {};
    const data_source_param = [];
    sourceParametersValue.forEach((param) => {
      data_source_param.push({
        value: param.value,
        name: param.name,
        type: param.type,
      });
    });
    data.col_type.forEach((field) => {
      const type = field["type"];
      const name = field["column"];
      mapping_type[name] = type;
      mapping_information[name] = field["mapping_information"];
      visualization_format[name] = field["visualization_format"];
    });
    const data_conf = {
      name: configuredSourceInfo.name,
      description: configuredSourceInfo.description,
      source_type: configuredSourceInfo.source_type,
      configured_source_description: configuredSourceInfo.configured_source_description,
      mapping_type: mapping_type,
      visualization_format: visualization_format,
      mapping_information: mapping_information,
      is_active: configuredSourceInfo.is_active,
      alert_delay: parseInt(configuredSourceInfo.alert_delay),
      source_parameters: data_source_param,
      storage_system_id: configuredSourceInfo.storage_system_id,
      id: configuredSourceInfo.id === "" ? null : configuredSourceInfo.id,
      delimiter: data.delimiter,
      timezone: configuredSourceInfo.timezone,
      locale: configuredSourceInfo.locale,
    };
    if (callback !== undefined) {
      callback(file, data_conf, setLoading);
      return;
    }
    fileDataApi("upload?observed_system_id=" + configuredSourceInfo.system, file, data_conf)
      .then((res) => {
        dataLoadingToast({ successMessage: "Création de la teache terminée", success: true });

        console.debug(res);
        setLoading(false);
      })
      .catch((error) => {
        dataLoadingToast({ errorMessage: "Une erreur est survenu", error: true });
        console.debug(error);
        setLoading(false);
      });
  };
  const isDisabled =
    configuredSourceInfo.name === "" || configuredSourceInfo.description === "" || configuredSourceInfo.source_type === "" || file === undefined;
  return (
    <div>
      <ConfiguredSourceData
        isMulti={true}
        selectObs={selectObs}
        source={configuredSourceInfo}
        setSource={setConfiguredSourceInfo}
        selectMappingValues={false}
        info={configuredSourceInfo}
        setInfo={setConfiguredSourceInfo}
        sourceParametersValue={sourceParametersValue}
        updateSourceParametersValue={updateSourceParametersValue}
        storageSystemIds={storageSystemIds}
      />
      <div className="p-8">
        <div className="w-full rounded-lg border-blue-900 bg-white shadow-md">
          <h3 className="px-4 pt-4 text-2xl font-semibold text-blue-900">{capitalizeFirstLetter(t("file"))}</h3>
          <h2 className="px-6 pb-4">{capitalizeFirstLetter(t("FILE_UPLOAD_DESC"))}</h2>
          <HorizontalLine />

          <FileUpload data={data} setData={setData} file={file} setFile={setFile} />
        </div>
      </div>
      <div className="m-8 rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
        <h2 className="text-2xl font-semibold text-blue-900">Recap</h2>
        <RecapCsv
          file_data_info={data}
          sp_info={sourceParametersValue}
          conf_info={configuredSourceInfo}
          os_info={observedSystem}
          storage_info={storageSystemIds}
        />
        <div className="flex justify-center py-2">
          <PrimaryButton isLoading={isLoading} callback={handleRequest} disabled={isDisabled || isLoading} label={t("add", { ns: "button" })} />
        </div>
      </div>
      {data.error.length !== 0 && (
        <div className="m-8 rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
          <ErrorPanel error={data.error} />
        </div>
      )}
    </div>
  );
};
export default MultiFormFile;
