import { useDispatch, useSelector } from "react-redux";
import { setModuleData } from "../../reducers/indicatorSlice";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import NumericInput from "../../components/Inputs/NumericInput";

const IndicatorRecurentForm = () => {
  const days = { 0: "Dim", 1: "Lun", 2: "Mar", 3: "Mer", 4: "Jeu", 5: "Ven", 6: "Sam" };
  const timeAll = {
    0: "00:00",
    1: "01:00",
    2: "02:00",
    3: "03:00",
    4: "04:00",
    5: "05:00",
    6: "06:00",
    7: "07:00",
    8: "08:00",
    9: "09:00",
    10: "10:00",
    11: "11:00",
    12: "12:00",
    13: "13:00",
    14: "14:00",
    15: "15:00",
    16: "16:00",
    17: "17:00",
    18: "18:00",
    19: "19:00",
    20: "20:00",
    21: "21:00",
    22: "22:00",
    23: "23:00",
  };
  const time = { 0: "00:00", 9: "09:00", 18: "18:00", 23: "23:00" };
  const param = useSelector((state) => state.indicator.moduleData);
  const validity_days = param.validity_days.split("-").map((value) => parseInt(value));
  const validity_days_lalel = validity_days.map((day) => days[day]);
  const time_slot = param.time_slot.split("-").map((value) => parseInt(value));
  const time_slot_label = time_slot.map((slot) => timeAll[slot]);
  const dispatch = useDispatch();
  return (
    <div className="col-span-2">
      <div className="">
        <NumericInput
          value={param.frequency}
          name="frequency"
          onChange={(key, value) => dispatch(setModuleData({ key: key, value: value }))}
          label="Fréquence (en minute)"
          placeholder="entier"
          type="number"
        />
        <div className="flex gap-x-1.5">
          <label className="mb-1 block text-sm font-bold tracking-wide text-gray-900">Jour(s) d&apos;activité </label>
          <p className="mb-1 block text-sm font-medium text-gray-900">( {validity_days_lalel.join(" → ")})</p>
        </div>
        <Slider
          range
          className={"mb-8"}
          min={0}
          max={6}
          value={validity_days}
          marks={days}
          onChange={(value) => {
            dispatch(setModuleData({ key: "validity_days", value: value.join("-") }));
          }}
        />
        <div className="flex gap-x-1.5">
          <label className="mb-1 block text-sm font-bold tracking-wide text-gray-900">Plage horaire</label>
          <p className="mb-1 block text-sm font-medium text-gray-900">( {time_slot_label.join(" → ")})</p>
        </div>
        <Slider
          range
          className={"mb-8"}
          min={0}
          max={23}
          value={time_slot}
          marks={time}
          onChange={(value) => {
            dispatch(setModuleData({ key: "time_slot", value: value.join("-") }));
          }}
        />
      </div>
    </div>
  );
};
export default IndicatorRecurentForm;
