import StateCaraChart from "../../components/Charts/StateCaraChart";
import { formatDisplay } from "../../utils/date.utils";

const GraphCaracterizationStateTimeline = ({ dataCharacterization }) => {
  const DataDisplay = ({ data }) => {
    if (data.length === 0)
      return (
        <div className="p-4">
          <p className={"text-center font-semibold text-gray-700"}>Pas de valeur manquante</p>
        </div>
      );
    if (data.length > 0)
      return (
        <div className="p-4">
          <ul>
            {data.map((item, index) => (
              <li key={index} className="mb-4">
                <p>
                  Du {formatDisplay(item.start)} au {formatDisplay(item.end)}
                </p>
                <div className={"flex w-full justify-center gap-x-1.5 p-2"}>
                  <p className={""}>{item.length === 0 ? "Aucunne" : item.length} valeur manquante</p>
                  <p className={"text-end font-semibold text-gray-700"}>({item.percent.toFixed(2)}%)</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
  };
  if (dataCharacterization === undefined) return;
  if (dataCharacterization.status === "success")
    return (
      <div>
        <div className="m-8 grid w-full grid-cols-4 gap-x-0.5">
          <StateCaraChart summary={dataCharacterization.summary.occurrences} />
          <div className={"col-span-2 h-[50vh] rounded-lg border border-gray-200 bg-white shadow"}>
            <div className={"flex w-full  justify-center gap-x-1.5 p-2"}>
              <p className={"text-center font-semibold text-blue-900"}>valeur manquante</p>
            </div>
            <DataDisplay data={dataCharacterization.na.details} />
          </div>
        </div>
        <div className="m-8 block rounded border border-gray-200 bg-white p-4">
          <div className={"flex justify-between gap-x-0.5"}>
            <div>
              <label className="font-bold tracking-wide text-gray-700 ">Message</label>
              <p>{dataCharacterization.message}</p>
            </div>
            <div>
              <label className="font-bold tracking-wide text-gray-700 ">Temps de calcul (s)</label>
              <p className={"text-end"}>{dataCharacterization.computation_duration}</p>
            </div>
          </div>
        </div>
      </div>
    );
  if (dataCharacterization.status === "error")
    return (
      <div className="m-8 block  rounded border border-gray-200 bg-white p-4">
        <div className={"flex justify-between gap-x-0.5"}>
          <div>
            <label className="font-bold tracking-wide text-gray-700 ">Message d&apos;erreur</label>
            <p>{dataCharacterization.message}</p>
          </div>
          <div>
            <label className="font-bold tracking-wide text-gray-700 ">Temps de calcul (s)</label>
            <p className={"text-end"}>{dataCharacterization.computation_duration}</p>
          </div>
        </div>
      </div>
    );
};
export default GraphCaracterizationStateTimeline;
