import { useEffect, useState } from "react";
import { TableFilter } from "./TableFilter";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { canRenderAction, canRenderComponent } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import ActionButtons from "../Selectors/ActionButtons";
import { ACTION_FLAGS } from "../../constants/action_flags";
import DeleteModal from "../Selectors/DeleteModal";
import { deleteUserApi, getUserApi } from "../../axios/UserManagementApi";
import { PrimaryButton, SecondaryButton } from "../Buttons/Button";
import store from "../../store";
import { remove, set } from "../../reducers/dataSlice";
import { useTranslation } from "react-i18next";

const ProfileList = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const [profileToDelete, setProfileToDelete] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);

  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [canDelete] = useState(canRenderAction(ACTION_FLAGS.CANDELETE, COMPONENTS.PROFILE_DISPLAY));
  const [canEdit] = useState(canRenderAction(ACTION_FLAGS.CANEDIT, COMPONENTS.PROFILE_DISPLAY));
  const [authorizedProfileCreation, setAuthorizedProfileCreation] = useState(canRenderComponent(COMPONENTS.PROFILE_CREATION));
  useEffect(
    () => () => {
      setAuthorizedProfileCreation(canRenderComponent(COMPONENTS.PROFILE_CREATION));
    },
    [authorizedComponent],
  );

  const [globalFilter, setGlobalFilter] = useState("");
  const [refresh, setRefresh] = useState(false);
  const data = useSelector((state) => state.data.profiles);
  const history = useNavigate();

  const createProfile = () => history("/admin/profile/create");
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor("description", {
      id: "desc",
      header: "Description",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <ActionButtons
          handleEdit={() => {
            const row = info.row.original;
            history("/admin/profile/edit/" + row.id);
          }}
          handleDelete={() => {
            const row = info.row.original;
            setProfileToDelete(row);
          }}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      ),
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
        <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
          <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
            <SecondaryButton
              isLoading={refresh}
              disabled={refresh}
              callback={() => {
                setRefresh(true);
                const url = sessionStorage.getItem("structure_id") === null ? "profile" : "profile?structure_id=" + sessionStorage.getItem("structure_id");
                getUserApi(url).then((res) => {
                  toast.success("Informations mises à jour.");
                  setRefresh(false);
                  store.dispatch(set({ type: "profiles", data: res.data.data }));
                });
              }}
              label="Mettre à jour"
            />
          </div>
          {authorizedProfileCreation && (
            <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
              <PrimaryButton callback={createProfile} label="Créer un profil" />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
      {profileToDelete && (
        <DeleteModal
          callback={() => {
            setIsDeleting(true);
            const structure_id = sessionStorage.getItem("structure_id");

            deleteUserApi("profile?profile_id=" + profileToDelete.id + "&structure_id=" + structure_id)
              .then((res) => {
                dispatch(remove({ id: profileToDelete.id, type: "profiles" }));
                console.debug(res);
                setIsDeleting(false);
                setProfileToDelete(null);
              })
              .catch((error) => {
                console.debug(error);
                setIsDeleting(false);
              });
          }}
          loading={isDeleting}
          text={t("DELETE") + " " + profileToDelete.name + "?"}
          modalVisible={profileToDelete}
          setModalVisible={setProfileToDelete}
        />
      )}
    </div>
  );
};
export default ProfileList;
