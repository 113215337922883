import { useState } from "react";
import Recap from "../multi/Recap";
import { useDispatch, useSelector } from "react-redux";
import { parseCategorical } from "../../utils/common";
import ObservedSystemData from "../multi/ObservedSystemData";
import ConfiguredSourceData from "../multi/ConfiguredSourceData";
import { postDataApi } from "../../axios/DataManagementApi";
import { push } from "../../reducers/dataSlice";
import { toast } from "react-toastify";
import { PrimaryButton } from "../../components/Buttons/Button";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const SystemAndSource = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const [observedSystemInfo, setObservedSystemInfo] = useState({
    storage_system_id: sessionStorage.getItem("storageSystemId"),
    membership: "structure",
    description: "",
    name: "",
    owner_ids: [],
  });
  const [isLoading, setLoading] = useState(false);
  const data = useSelector((state) => state.data);
  const [mappingValue, updateMappingValue] = useState([]);
  const [sourceParametersValue, updateSourceParametersValue] = useState([]);
  const [configuredSourceInfo, setConfiguredSourceInfo] = useState({
    source_type: "",
    description: "",
    name: "",
    observed_system_id: sessionStorage.getItem("observedSystemId"),
    alert_delay: 0,
    is_active: false,
    configured_source_description: "",
    storage_system_id: "",
    id: "",
    timezone: "Europe/Paris",
  });
  const handleRequest = () => {
    setLoading(true);
    const mapping_type = {};
    const mapping_information = {};
    const visualization_format = {};
    const data_source_param = [];
    sourceParametersValue.forEach((param) => {
      data_source_param.push({
        value: param.type === "date" ? dayjs(param.value.date).tz(param.value.name).format("YYYY-MM-DDTHH:mm:ss.SSSZ") : param.value,
        name: param.name,
        type: param.type,
      });
    });
    mappingValue.forEach((val) => {
      if (val.locked) {
        mapping_type[val.name] = val.type;
        mapping_information[val.name] = val.mapping_information;
        visualization_format[val.name] = val.visualization_format;
      }
    });
    const data_obs = {
      structure_id: sessionStorage.getItem("structure_id"),
      storage_system_id: observedSystemInfo["storage_system_id"],
      name: observedSystemInfo["name"],
      description: observedSystemInfo["description"],
      membership: observedSystemInfo["membership"],
      owner_ids: observedSystemInfo["membership"] === "structure" ? [] : observedSystemInfo["owner_ids"],
    };
    const data_conf = {
      name: configuredSourceInfo.name,
      description: configuredSourceInfo.description,
      source_type: configuredSourceInfo.source_type,
      configured_source_description: configuredSourceInfo.configured_source_description,
      mapping_type: mapping_type,
      mapping_information: mapping_information,
      visualization_format: visualization_format,
      is_active: configuredSourceInfo.is_active,
      alert_delay: parseInt(configuredSourceInfo.alert_delay),
      source_parameters: data_source_param,
      storage_system_id: configuredSourceInfo.storage_system_id,
      id: configuredSourceInfo.id === "" ? null : configuredSourceInfo.id,
      timezone: configuredSourceInfo.timezone,
      locale: configuredSourceInfo.locale,
    };
    postDataApi("observed_system?structure_id=" + sessionStorage.getItem("structure_id"), data_obs).then((res) => {
      toast.success("Le système observé " + data_obs.name + " a été créé");
      const observedsystemId = res.data["id"];
      dispatch(
        push({
          data: {
            name: data.name,
            description: data.description,
            storage_system_id: data.storage_system_id,
            id: observedsystemId,
          },
          type: "observed_system",
        }),
      );
      data_conf["system"] = observedsystemId;
      postDataApi("configuration/data", data_conf)
        .then(() => {
          toast.success("La source de données " + data_conf.name + " a été créé");
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
          console.debug(res);
        });
    });
  };
  const isDisabled =
    observedSystemInfo.storage_system_id === "" ||
    observedSystemInfo.name === "" ||
    observedSystemInfo.description === "" ||
    configuredSourceInfo.name === "" ||
    configuredSourceInfo.description === "";
  return (
    <div>
      <ObservedSystemData observedSystem={observedSystemInfo} setObservedSystem={setObservedSystemInfo} isMulti={true} />
      <ConfiguredSourceData
        selectObs={false}
        isMulti={true}
        source={configuredSourceInfo}
        setSource={setConfiguredSourceInfo}
        mappingValue={mappingValue}
        updateMappingValue={updateMappingValue}
        sourceParametersValue={sourceParametersValue}
        updateSourceParametersValue={updateSourceParametersValue}
      />
      <div className="p-8">
        <Recap
          obs_info={observedSystemInfo}
          conf_info={configuredSourceInfo}
          mv_info={mappingValue}
          sp_info={sourceParametersValue}
          storage_info={data.storage_system}
          os_info={data.observed_system}
        />
        <div className="px-4 pb-4 lg:flex lg:justify-end">
          <PrimaryButton isLoading={isLoading} label={t("create", { ns: "button" })} callback={handleRequest} disabled={isLoading || isDisabled} />
        </div>
      </div>
    </div>
  );
};
export default SystemAndSource;
