import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { TrainingSimple } from "./TrainingSetup";
import { TrainingResult } from "./TrainingResult";
import { useParams } from "react-router-dom";

const Training = () => {
  const id = useParams();
  console.debug(id);
  const { name } = useSelector((state) => state.indicator.moduleData);

  const [result, setResult] = useState({});
  return (
    <Fragment>
      <TrainingSimple setResult={setResult} />
      <TrainingResult name={name} result={result} />
    </Fragment>
  );
};
export default Training;
