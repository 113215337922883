import { useEffect, useState } from "react";
import Select from "react-select";
import TextInput from "../../components/Selectors/TextInput";
import { canRenderComponent, capitalizeFirstLetter, isEmptyString, makeSelectField } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";

import { ownerField } from "../../utils/field";
import SelectAdd from "../../components/Selectors/SelectAdd";
import { COMPONENTS } from "../../constants/component";
import { postDataApi } from "../../axios/DataManagementApi";
import { push } from "../../reducers/dataSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import HorizontalLine from "../../components/HorizontalLine";
import { PrimaryButton } from "../../components/Buttons/Button";

const ObservedSystemData = ({ observedSystem, setObservedSystem, isMulti = false }) => {
  const { t } = useTranslation(["common", "button"]);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  const [isLoading, setLoading] = useState(false);

  const [authorizedObs, setAuthorizedObs] = useState(canRenderComponent(COMPONENTS.OBSERVED_SYSTEM_CREATION));
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  useEffect(() => {
    setAuthorizedObs(canRenderComponent(COMPONENTS.OBSERVED_SYSTEM_CREATION));
  }, [authorizedComponent]);
  const onSelectChange = (selected, options) => {
    onChange(options.name, selected.value);
  };
  const onChange = (name, value) => {
    setObservedSystem({
      ...observedSystem,
      [name]: value,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      structure_id: sessionStorage.getItem("structure_id"),
      storage_system_id: observedSystem["storage_system_id"],
      name: observedSystem["name"],
      description: observedSystem["description"],
      membership: observedSystem["membership"],
      owner_ids: observedSystem["membership"] === "structure" ? [] : [observedSystem["owner_ids"]],
    };
    postDataApi("observed_system?structure_id=" + sessionStorage.getItem("structure_id"), data).then((res) => {
      dispatch(
        push({
          data: {
            id: res.data.id,
            name: data.name,
            description: data.description,
            storage_system_id: data.storage_system_id,
          },
          type: "observed_system",
        }),
      );
      setLoading(false);
      toast.success("Le système observé " + data.name + " a été créé");
    });
  };
  const disable =
    isEmptyString(observedSystem["name"]) ||
    isEmptyString(observedSystem["description"]) ||
    isEmptyString(observedSystem["storage_system_id"]) ||
    isEmptyString(observedSystem["membership"]);
  return (
    authorizedObs && (
      <div className="p-8">
        <div className="w-full rounded-lg border-blue-900 bg-white shadow-md">
          <h3 className="px-4 pt-4 text-2xl font-semibold text-blue-900">{capitalizeFirstLetter(t("observed_system"))}</h3>
          <h2 className="px-6 pb-4">{capitalizeFirstLetter(t("OBSERVED_SYTEM_DESC"))}</h2>

          <HorizontalLine />
          <div className="grid grid-cols-1 gap-4 p-4 lg:grid-cols-3">
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("instance"))}</label>
              <InstanceSelector
                selectField={makeSelectField(data.storage_system, ["name", "description"])}
                value={makeSelectField(data.storage_system, ["name", "description"]).find((value) => value.value === observedSystem["storage_system_id"])}
                callback={onChange}
              />
            </div>
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("link"))}</label>
              <Select
                value={ownerField.find((owner) => owner.value === observedSystem.membership)}
                isSearchable={false}
                name="membership"
                options={ownerField}
                onChange={onSelectChange}
              />
            </div>
            {observedSystem["membership"] === "observed_system" ? (
              <div>
                <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("link_obs"))}</label>

                <Select name={"owner_ids"} options={makeSelectField(data.observed_system, ["name", "description"])} onChange={onSelectChange} />
              </div>
            ) : (
              <span />
            )}
            <div>
              <TextInput name={"name"} value={observedSystem["name"]} onChange={onChange} label={capitalizeFirstLetter(t("name"))} />
            </div>
            <div>
              <TextInput name={"description"} value={observedSystem["description"]} onChange={onChange} label={capitalizeFirstLetter(t("description"))} />
            </div>
          </div>
          {!isMulti && (
            <div className="px-4 pb-4 lg:flex lg:justify-end">
              <PrimaryButton isLoading={isLoading} label={t("create", { ns: "button" })} callback={handleAdd} disabled={disable} />
            </div>
          )}
        </div>
      </div>
    )
  );
};
export default ObservedSystemData;

const InstanceSelector = ({ selectField, value, callback }) => {
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [authorizedInstance, setAuthorizedInstance] = useState(canRenderComponent(COMPONENTS.INSTANCE_CREATION));

  useEffect(
    () => () => {
      setAuthorizedInstance(canRenderComponent(COMPONENTS.INSTANCE_CREATION));
    },
    [authorizedComponent],
  );
  if (authorizedInstance) return <SelectAdd selectField={selectField} name="storage_system_id" value={value} callback={callback} />;
  return <Select options={selectField} name="storage_system_id" value={value} onChange={callback} />;
};
