import MRD from "./MonitoringRemonteeDonnees/MRD";
import DetectionAnomalie from "./DetectionAnomalie/DetectionAnomalie";
import FormuleAnalytique from "./FormuleAnalytique/FormuleAnalytique";
import Correlation from "./Correlation/Correlation";
import IndustrialHisto from "./IndicatorIndustrialHisto/IndustrialHisto";
import OutlierValidation from "./OutlierValidation/OutlierValidation";
import Crosscorrelation from "./CrossCorellation/Crosscorrelation";
import Acp from "./Acp/Acp";
import CorrelationSimple from "./Simple/CorrelationSimple";
import FormulaSimple from "./Simple/FormulaSimple";
import CrossCorrelationSimple from "./Simple/CrossCorrelationSimple";
import AcpSimple from "./Simple/AcpSimple";
import IndustrialIndicatorSimple from "./Simple/IndustrialIndicatorSimple";
import StatusManager from "./StatusManager/StatusManager";
import AlertSimple from "./Simple/AlertSimple";
import TimeIndicatorSimple from "./Simple/TimeIndicatorSimple";
import CaracterizationSimple from "./Simple/CaracterizationSimple";
import StatusManager2 from "./StatusManager/StatusManager2";

const IndicatorBody = ({ id }) => {
  const body = (id) => {
    switch (id) {
      case 0:
        return <MRD />;
      case 1:
        return <DetectionAnomalie />;
      case 2:
        return <Correlation />;
      case 4:
        return <AlertSimple />;
      case 5:
        return <FormuleAnalytique />;
      case 6:
        return <IndustrialHisto />;
      case 7:
        return <TimeIndicatorSimple />;
      case 8:
        return <OutlierValidation />;
      case 9:
        return <Crosscorrelation />;
      case 10:
        return <Acp />;
      case 20:
        return <CorrelationSimple />;
      case 21:
        return <FormulaSimple />;
      case 22:
        return <CrossCorrelationSimple />;
      case 23:
        return <AcpSimple />;
      case 24:
        return <IndustrialIndicatorSimple />;
      case 11:
        return <StatusManager />;
      case 12:
        return <StatusManager2 />;
      case 25:
        return <CaracterizationSimple />;
      default:
        break;
    }
  };
  return body(id);
};
export default IndicatorBody;
