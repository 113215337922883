import { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import store from "../../store";
import { dataList, getUserPreferences, isEmptyObject, isEmptyString, makeSelectField } from "../../utils/common";
import { getDataApi, putDataApi } from "../../axios/DataManagementApi";
import { TableHeader } from "../../components/Table/TableHeader";
import { TableBody } from "../../components/Table/TableBody";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import LabelTooltip from "../../components/Tooltip/LabelTooltip";
import { useDispatch, useSelector } from "react-redux";
import { set } from "../../reducers/dataSlice";
import { setStatus } from "../../reducers/SelectedSlice";
import { TablePagination } from "../../components/Table/TablePagination";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import ActionButtons from "../../components/Selectors/ActionButtons";
import { StateTimeLine } from "./StateTimeLine";
import { formatDisplay } from "../../utils/date.utils";
import { TableSelector } from "../../components/TableSelector";
import TextTooltip from "../../components/Tooltip/TextTooltip";
import dayjs from "dayjs";

const StatusList = () => {
  const [startTime, setStartTime] = useState(dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"));
  const [endTime, setEndTime] = useState(dayjs().format("YYYY-MM-DDTHH:mm:ss"));
  const [statusId, setStatusId] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);
  const typeOptions = [
    {
      value: "observed_system",
      label: t("observed_system"),
    },
    {
      value: "configured_source",
      label: t("configured_source"),
    },
  ];
  const [rowSelection, setRowSelection] = useState({});
  const { type, id } = useSelector((state) => state.selected.status);
  const [panelUrl, setPanelUrl] = useState([]);
  const [pieChartUrl, setPieChartUrl] = useState(undefined);

  useEffect(() => {
    const makeOptions = () => {
      if (isEmptyString(type)) return [];
      const state = store.getState();
      const updates = state.data[type];
      setObjectOptions(makeSelectField(updates, ["description"]));
    };
    makeOptions();
  }, [type]);
  const [objectOptions, setObjectOptions] = useState([]);
  const data = useSelector((state) => state.data.status);
  const handleObjectChange = (newValue) => {
    setPanelUrl([]);
    setPieChartUrl(undefined);
    setRowSelection({});
    setStatusId("");
    dispatch(set({ type: "status", data: [] }));
    dispatch(setStatus({ type: newValue, id: "" }));
    sessionStorage.setItem("status_type", newValue);
    sessionStorage.setItem("status_id", "");
  };
  const handleObjectIdChange = (newValue) => {
    setPanelUrl([]);
    setPieChartUrl(undefined);
    setRowSelection({});
    setStatusId("");
    dispatch(setStatus({ type: type, id: newValue }));
    sessionStorage.setItem("status_type", type);
    sessionStorage.setItem("status_id", newValue);
    const url = type === "observed_system" ? "status/" + newValue : "status/" + sessionStorage.getItem("observed_system_id") + "/" + newValue;
    getDataApi(url).then((res) => {
      dispatch(set({ type: "status", data: dataList(res.data.data) }));
    });
  };
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.display({
      id: "select",
      cell: ({ row }) => (
        <div className="px-1">
          <TableSelector
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor("status_id", {
      id: "id",
      header: "ID",
      cell: (info) => <TextTooltip text={info.getValue()} tip={info.getValue()} className={"w-28 truncate"} />,
    }),
    columnHelper.display({
      id: "active",
      cell: (info) => {
        const row = info.row.original;
        const { deployment_params } = row;
        if (isEmptyObject(deployment_params)) return "Simple";
        const { frequency, time_slot, validity_days } = deployment_params;
        const days = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
        const formatDay = validity_days.split("-").map((day) => days[parseInt(day) - 1]);
        const display = (
          <div>
            <p>{frequency}min</p>
            <p>{formatDay.join("-")}</p>
            <p>{time_slot}</p>
          </div>
        );
        return <LabelTooltip label={"Récurrent"} tip={display} />;
      },
      header: t("mode"),
    }),
    columnHelper.accessor("timestamp", {
      cell: (info) => formatDisplay(info.getValue()),
      header: t("timestamp"),
      id: "timestamp",
    }),
    columnHelper.accessor("status", {
      cell: (info) => info.getValue(),
      header: t("status"),
      id: "status",
    }),
    columnHelper.display({
      id: "actions",
      header: t("actions"),
      cell: (info) => (
        <ActionButtons
          handleEdit={() => {
            dataLoadingToast({ isLoading: true, label: "activation..." });
            const sp_id = info.row.original.id;
            const payload = { source_parameters_id: sp_id };

            switch (type) {
              case "configured_source":
                payload["configured_source_id"] = id;
                payload["observed_system_id"] = sessionStorage.getItem("observed_system_id");
                break;
              case "crude_source":
                payload["crude_source_id"] = id;
                payload["observed_system_id"] = sessionStorage.getItem("observed_system_id");
                break;
              case "observed_system":
                payload["observed_system_id"] = id;
                break;
            }
            putDataApi("status/activate", payload).then(() =>
              dataLoadingToast({
                success: true,
                successMessage: "activé",
              }),
            );
          }}
          canEdit={true}
        />
      ),
    }),
  ];

  const handleTime = (start_time, end_time, status_id) => {
    dataLoadingToast({ isLoading: true, label: "chargement de l'historique" });
    const args = "?histo=" + status_id + "&start_time=" + start_time + "&end_time=" + end_time;
    const url = type === "observed_system" ? "status/" + id + args : "status/" + sessionStorage.getItem("observed_system_id") + "/" + id + args;
    getDataApi(url.replaceAll("+", "%2B")).then((res) => {
      let msg = "chargement terminé";
      if (isEmptyObject(res.data.data)) {
        setPanelUrl([]);
        setPieChartUrl(undefined);
        msg = "Pas de données entre ces dates";
      } else {
        setPieChartUrl(res.data.data.piechart_url);
        setPanelUrl(res.data.data.panel_url);
      }
      dataLoadingToast({ success: true, successMessage: msg });
    });
  };
  const handleStatusChange = (updater) => {
    const newSelected = updater();
    const index = Object.keys(newSelected)[0];
    const { status_id } = data[index];
    setStatusId(status_id);
    handleTime(startTime, endTime, status_id);
    setRowSelection(newSelected);
  };
  const showId = getUserPreferences().find((preference) => preference.name === "showId")?.value ?? true;

  if (!showId) columns.splice(1, 1);
  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    enableMultiRowSelection: false,
    enableRowSelection: (row) => !row.getIsSelected(),
    onRowSelectionChange: handleStatusChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div>
      <div className="m-4 block rounded-lg border border-gray-200 bg-white shadow">
        <div className=" mt-2 flex flex-col justify-between px-2 lg:flex-row">
          <div className={"flex"}>
            <div>
              <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{t("type")}</label>
              <Select
                className={"w-72"}
                isClearable={false}
                isSearchable={false}
                value={typeOptions.find((value) => value.value === type)}
                options={typeOptions}
                onChange={(newValue) => handleObjectChange(newValue.value)}
              />
            </div>
            <div className="px-2">
              <label className=" text-sm font-bold tracking-wide text-gray-700 ">{t("object_options") + " " + t(type)}</label>
              <Select
                isDisabled={type === ""}
                className={"w-72"}
                isClearable={false}
                isSearchable={false}
                value={id !== "" ? objectOptions.find((value) => value.value === id) : ""}
                options={objectOptions}
                onChange={(newValue) => handleObjectIdChange(newValue.value)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full pt-2 align-middle">
              <div className="overflow-hidden border-b border-gray-200 shadow">
                <table className="min-w-full divide-y divide-gray-200">
                  <TableHeader table={table} />
                  <TableBody table={table} />
                </table>
              </div>
            </div>
          </div>
        </div>
        <TablePagination table={table} />
      </div>
      <div className={"grid grid-cols-2 gap-4"}>
        <div className="m-4  block h-[50vh]  rounded-lg border border-gray-200 bg-white shadow">
          {!isEmptyString(pieChartUrl) && (
            <div className="m-8 block rounded">
              <iframe className="h-[40vh] w-full " src={pieChartUrl} />
            </div>
          )}
        </div>
        <StateTimeLine
          startTime={startTime}
          endTime={endTime}
          panelUrl={panelUrl}
          callback={(start, end) => {
            setStartTime(start);
            setEndTime(end);
            if (!isEmptyString(statusId)) handleTime(start, end, statusId);
          }}
        />
      </div>
    </div>
  );
};
export default StatusList;
