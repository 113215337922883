import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { isEmptyString, makeSelectField } from "../../../utils/common";
import { useEffect, useState } from "react";
import { setCrudeSource, setCurrentTimeParams, setHistoricalMode, setHistoricalTimeParams, setIndicatorParams } from "../../../reducers/indicatorSlice";
import SwitchButton from "../../../components/Selectors/SwitchButton";
import { aggregationOptions, historicalModeOptions } from "../../../utils/field";
import NumericInput from "../../../components/Inputs/NumericInput";
import ConfigurationAlerteFormula from "../ConfigurationAlerte/ConfigurationAlerteFormula";
import { PrimaryButton, RefreshButton } from "../../../components/Buttons/Button";
import { formatBackend } from "../../../utils/date.utils";
import IndicatorResult from "../IndicatorResult";
import { getDataApi } from "../../../axios/DataManagementApi";
import { postIndicatorApi } from "../../../axios/IndicatorComputationApi";
import CreatableSelect from "react-select/creatable";
import { dataLoadingToast } from "../../../components/Toasts/DataLoadingToast";
import { formatThreshold } from "../../../network/DataManagementApi";
import { getThreshold } from "../utils";
import Checkbox from "../../../components/Selectors/Checkbox";
import { useNavigate } from "react-router-dom";
import SimpleTime from "./SimpleTime";

function StateISelector({ options }) {
  console.debug(options);
  const dispatch = useDispatch();
  const { state_i } = useSelector((state) => state.indicator.moduleData.indicator_params);

  const handleCheck = (name, checked) => {
    console.debug(name, checked);
    const updates = [...state_i];
    if (checked) {
      updates.push(name);
      dispatch(setIndicatorParams({ key: "state_i", value: updates }));
    } else {
      updates.filter((value) => value !== name);
      dispatch(
        setIndicatorParams({
          key: "state_i",
          value: updates.filter((value) => value !== name),
        }),
      );
    }
  };
  return options.map((value) => (
    <div key={value.label} className={"mb-1"}>
      <Checkbox name={value.label} label={value.label} onChange={(e) => handleCheck(value.value, e.target.checked)} />
    </div>
  ));
}

const HistoSimple = () => {
  const dispatch = useDispatch();
  const { count, time } = useSelector((state) => state.indicator.moduleData.historical.time_params);
  const { historicalMode } = useSelector((state) => state.indicator.moduleData);

  function handleTimeParams(name, value) {
    dispatch(setHistoricalTimeParams({ key: name, value: value }));
  }

  function handleDelayTimeChange(name, value) {
    const updates = historicalMode === "count" ? { ...count } : { ...time };
    updates[name] = value;
    handleTimeParams(historicalMode, updates);
  }

  return (
    <div className={"px-10 "}>
      <div className={"mb-2 border-l border-blue-900 px-6 "}>
        <div className={"mb-2 grid grid-cols-6 gap-x-4"}>
          <div>
            <label className="text-sm font-bold tracking-wide text-gray-700">Mode</label>
            <Select
              onChange={(newValue) => dispatch(setHistoricalMode(newValue.value))}
              options={historicalModeOptions}
              value={historicalModeOptions.find((value) => value.value === historicalMode)}
            />
          </div>
          <NumericInput label={"Délai"} min={1} value={historicalMode === "count" ? count.delay : time.delay} name={"delay"} onChange={handleDelayTimeChange} />
          <NumericInput
            label={"Taille"}
            min={1}
            value={historicalMode === "count" ? count.length : time.length}
            name={"length"}
            onChange={handleDelayTimeChange}
          />
        </div>
      </div>
    </div>
  );
};
const IndustrialIndicatorSimple = () => {
  const { moduleData, selected_crude_source } = useSelector((state) => state.indicator);
  const { current, indicator_params, historical, historicalMode } = moduleData;
  const { threshold_label, state_i } = indicator_params;
  const { time_params, computation_params } = current;
  const { start_time, end_time, bucket_size, label, time_label } = time_params;
  const { crude_source } = useSelector((state) => state.data);
  const [crudeSourceOptions] = useState(makeSelectField(crude_source, ["name", "description"], true));
  const dispatch = useDispatch();
  const history = useNavigate();
  const [aggregation, setAggregation] = useState(false);
  const [aggregationMode, setAggregationMode] = useState("avg");
  const { showLabel } = useSelector((state) => state.application);

  const [formula, setFormula] = useState(false);
  const [historic, setHistoric] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [threshold, setThreshold] = useState(getThreshold(sessionStorage.getItem("observed_system_id")));
  const [state_iOptions, setState_iOptions] = useState([]);
  useEffect(() => {
    if (isEmptyString(threshold_label)) return;
    const item = threshold.find((value) => value.label === threshold_label);
    const options = [];
    Object.keys(item.formula).forEach((value) =>
      options.push({
        value: value,
        label: value + " : " + item.formula[value],
      }),
    );
    setState_iOptions(options);
  }, [threshold, threshold_label]);

  const setLabel = (value) => {
    dispatch(setIndicatorParams({ key: "threshold_label", value: value.value }));
    dispatch(setIndicatorParams({ key: "state_i", value: [] }));
  };

  function handleCrudeSource(selected, action) {
    if (action.action === "select-option" && action.option.value === "all")
      dispatch(setCrudeSource(crudeSourceOptions.filter((value) => value.value !== "all").map((value) => value.value)));
    else dispatch(setCrudeSource(selected.map((value) => value.value)));
  }

  function handleTimeParams(name, value) {
    dispatch(setCurrentTimeParams({ key: name, value: value }));
  }

  const parseFormulaV1 = (formula, blocks) => {
    let ret = formula;
    blocks.forEach((block) => {
      Object.keys(block.preprocess).forEach((value) => {
        ret = ret.replaceAll(value, "['" + value + "']");
      });
    });
    return ret;
  };

  function getDataBlocks(source, sourceList, agregation = "identity") {
    const dataBlocks = [];

    for (let i = 0; i < source.length; i++) {
      const sourceId = source[i];
      const sourceData = sourceList.find((item) => item.id === sourceId);

      if (sourceData && sourceData.measure) {
        const measureKeys = Object.keys(sourceData.measure);
        const timeField = measureKeys.find((key) => sourceData.measure[key].visualization_type === "date");

        const preprocess = {};
        measureKeys.forEach((key) => {
          if (key !== timeField) {
            preprocess[key] = agregation;
          }
        });

        const dataBlock = {
          configured_sources: [],
          time_field: timeField ?? "internal",
          preprocess: preprocess,
          measure_identifier: sourceData.measure_identifier,
          status: -1,
        };

        dataBlocks.push(dataBlock);
      }
    }

    return dataBlocks;
  }

  function compute() {
    setIsLoading(true);
    const obs = sessionStorage.getItem("observed_system_id");
    const payload = {
      current: {
        data_params: getDataBlocks(selected_crude_source, crude_source, aggregation ? aggregationMode : "identity"),
        time_params: {
          start_time: formatBackend(start_time),
          end_time: formatBackend(end_time),
          bucket_size: aggregation ? bucket_size : -1,
          label: label,
        },
        computation_params: structuredClone(computation_params),
      },
      indicator_params: indicator_params,
    };
    if (showLabel) payload.current.time_params.time_label = time_label;

    payload.current.computation_params.formula = formula ? parseFormulaV1(computation_params.formula, payload.current.data_params) : "";

    if (historic) {
      const { time_params } = historical;

      payload.historical = {
        data_params: getDataBlocks(selected_crude_source, crude_source, "avg"),
        time_params: {
          [historicalMode]: {
            length: historicalMode === "count" ? time_params.count.length : time_params.time.length,
            delay: historicalMode === "count" ? time_params.count.delay : time_params.time.delay,
          },
        },
        computation_params: payload.current.computation_params,
      };
    }
    console.debug(payload);
    postIndicatorApi("indicator/industrialindicator/" + obs, payload)
      .then((res) => {
        setIsLoading(false);
        console.debug(res);
        const tmp = [...result];
        tmp.push(res.data);
        setResult(tmp);
      })
      .catch((error) => {
        setIsLoading(false);
        console.debug(error);
      });
  }

  const setField = () => {
    const field = [];
    threshold.forEach((value) => {
      field.push({ label: value.label, value: value.label });
    });
    return field;
  };
  const [refresh, setRefresh] = useState(false);
  return (
    <div className="mx-4 my-2">
      <div className="block rounded border border-gray-200 bg-white px-4 dark:border-gray-600 dark:bg-gray-800">
        <h2 className="text-2xl font-semibold text-blue-900">Paramètres des données</h2>
        <div className={"mb-2 flex gap-x-4 px-8"}>
          <SimpleTime />
        </div>
        <div className={"mb-2 border-b px-8 pb-2"}>
          <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Source(s) de données</label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            placeholder={"Source de données"}
            value={crudeSourceOptions.filter((value) => selected_crude_source.includes(value.value))}
            options={crudeSourceOptions}
            onChange={handleCrudeSource}
          />
        </div>
        <div className={"mb-2 px-8"}>
          <SwitchButton label={"Agrégation"} value={aggregation} onChange={(value) => setAggregation(value)} />
        </div>
        {aggregation && (
          <div className={"mb-2 grid grid-cols-6 gap-x-4 px-16"}>
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Type</label>
              <Select
                options={aggregationOptions}
                value={aggregationOptions.find((value) => value.value === aggregationMode)}
                onChange={(newValue) => setAggregationMode(newValue.value)}
              />
            </div>
            <NumericInput label={"Intervalle de temps (s)"} value={bucket_size} name={"bucket_size"} onChange={handleTimeParams} />
          </div>
        )}
        <div className={"mb-2 px-8"}>
          <SwitchButton label={"Formule"} value={formula} onChange={(value) => setFormula(value)} />
        </div>
        {formula && (
          <div className={"mb-2 w-1/2 px-16"}>
            <ConfigurationAlerteFormula mode={"current"} verify={false} />
          </div>
        )}
        <div className={"mb-2 px-8"}>
          <SwitchButton label={"Historique"} value={historic} onChange={(value) => setHistoric(value)} />
        </div>
        {historic && <HistoSimple />}
        <div className={"px-8 pb-2"}>
          <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Etats</label>
          <div className={"flex gap-x-1.5"}>
            <CreatableSelect
              className={"min-w-[18rem]"}
              menuPosition={"fixed"}
              onCreateOption={() => history("/analytics/threshold/create")}
              options={setField()}
              value={setField().find((value) => value.value === threshold_label)}
              onChange={setLabel}
            />
            <RefreshButton
              isLoading={refresh}
              callback={() => {
                setRefresh(true);
                dataLoadingToast({ isLoading: true });
                getDataApi("threshold?structure_id=" + sessionStorage.getItem("structure_id"))
                  .then((res) => {
                    dataLoadingToast({ success: true });
                    formatThreshold(res.data.data);
                    setThreshold(getThreshold(sessionStorage.getItem("observed_system_id")));
                    setRefresh(false);
                  })
                  .catch((error) => {
                    dataLoadingToast({ error: true });
                    console.debug(error);
                    setRefresh(false);
                  });
              }}
            />
          </div>
        </div>
        <div className={"mb-2 px-16"}>
          <StateISelector options={state_iOptions} />
        </div>
      </div>
      <div className={"flex justify-center"}>
        <PrimaryButton
          disabled={isLoading || selected_crude_source.length === 0 || state_i.length === 0}
          isLoading={isLoading}
          label={"Calculer"}
          callback={() => compute()}
        />
      </div>
      {result.toReversed().map((res, key) => (
        <div key={key}>
          <p>Résultat #{result.length - key}</p>
          <IndicatorResult key={key} type={6} result={res} />
        </div>
      ))}
    </div>
  );
};
export default IndustrialIndicatorSimple;
