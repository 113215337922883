import { useId } from "react";
import { isEmptyString } from "../../utils/common";
import classNames from "classnames";

const NumericInput = ({ width = "w-full", name, label, value, step = 1, min, max, onChange }) => {
  const id = useId();
  return (
    <div>
      {!isEmptyString(label) && (
        <label htmlFor={id} className="text-sm font-bold tracking-wide text-gray-700">
          {label}
        </label>
      )}
      <input
        onChange={(event) => onChange(event.target.name, parseFloat(event.target.value))}
        name={name}
        step={step}
        min={min}
        max={max}
        type="number"
        id={id}
        value={value}
        className={classNames(
          width,
          "block rounded-lg border border-neutral-300 bg-neutral-50 text-sm text-neutral-900 invalid:border-pink-500 invalid:text-pink-600 focus:border-blue-500 focus:ring-blue-500 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 disabled:opacity-75",
        )}
        placeholder=""
        required
      />
    </div>
  );
};
export default NumericInput;
