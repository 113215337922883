import axios from "axios";
import store from "../store";
import { toast } from "react-toastify";
import { updateToken } from "../network/common";
import { logout } from "../reducers/ApplicationSlice";

const handleError = (statusCode, errorData) => {
  switch (statusCode) {
    case 404:
      toast.dismiss();
      toast.error(errorData.error);
      break;
    case 403:
      toast.dismiss();
      toast.error("Vous n'êtes pas autorisé à réalisé cette action. " + errorData.function);
      break;
    case 401:
      toast.dismiss("loading_data");
      toast.error(
        "Votre session a expiré ou vos informations d'identification ne sont plus valides. Veuillez vous reconnecter pour continuer à accéder à cette ressource.",
        {
          toastId: "401_error",
        },
      );
      store.dispatch(logout());
      sessionStorage.clear();
      break;
    case 400:
      toast.error(errorData.message);
      break;
    default:
      toast.dismiss();
      console.debug(statusCode, errorData);
  }
};

const makeRequest = (auth = true) => {
  const instance = axios.create();
  if (auth) instance.defaults.headers.common["Authorization"] = "Bearer " + store.getState().authentication.token;
  instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  instance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error),
  );
  instance.interceptors.response.use(
    (res) => {
      updateToken(res.headers["refresh-token"]);
      return res;
    },
    (error) => {
      console.debug(error);
      //TODO handle other errors
      const statusCode = error.response.status;
      const errorData = error.response.data;
      console.debug(statusCode, errorData);
      // UNAUTHORIZED
      handleError(statusCode, errorData);
      return Promise.reject(error);
    },
  );
  return instance;
};

/**
 *
 * @param {string} url
 * @returns {Promise}
 */
export const getRequest = (url) => makeRequest().get(url);
/**
 *
 * @param {string[]} urls
 */
export const getMultiple = async (urls) => {
  const requests = urls.map((url) => makeRequest().get(url));
  return await axios.all(requests);
};
export const postRequest = (url, body) => makeRequest().post(url, body);
export const putRequest = (url, body) => makeRequest().put(url, body);

export const indicatorComputation = (url, body) => {
  const { REACT_APP_INDICATOR_HOST, REACT_APP_API_TOKEN } = process.env;
  const instance = axios.create({
    baseURL: REACT_APP_INDICATOR_HOST,
  });
  instance.defaults.headers.common["Authorization"] = REACT_APP_API_TOKEN;
  instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  return instance.post(url, body);
};

export const deleteRequest = (url) => makeRequest().delete(url);

export const uploadFile = (url, file, data) => {
  const instance = axios.create();
  instance.defaults.headers.common["Authorization"] = "Bearer " + store.getState().authentication.token;
  instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  instance.defaults.headers.common["Content-Type"] = "multipart/form-data";
  const form = new FormData();
  form.append("file", file);
  form.append("data", JSON.stringify(data));
  return instance.post(url, form);
};
