//Icons
import { IoAnalyticsOutline as Analytics, IoHomeOutline as Dashboard } from "react-icons/io5";
import { VscSettings as Indicator } from "react-icons/vsc";
import { AdjustmentsHorizontalIcon, ChevronLeftIcon, ChevronRightIcon, Cog8ToothIcon, HomeIcon, WrenchIcon } from "@heroicons/react/24/outline";
import { MdEvent as Event, MdFileUpload as Upload, MdOutlineCreateNewFolder as Create } from "react-icons/md";
import { FiSettings as Preferences } from "react-icons/fi";
import { AiOutlineRadiusSetting as Threshold } from "react-icons/ai";
import Section from "./Section";
import { useDispatch, useSelector } from "react-redux";
import { set } from "../../reducers/ApplicationSlice";
import { capitalizeFirstLetter, isEmptyString } from "../../utils/common";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import classNames from "classnames";

const Sidebar = () => {
  const { t } = useTranslation(["common"]);
  const { structure } = useSelector((state) => state.selected);
  const showSidebar = useSelector((state) => state.application.showSidebar);

  const dispatch = useDispatch();
  const handleSidebar = () => {
    dispatch(set({ type: "showSidebar", payload: !showSidebar }));
    sessionStorage.setItem("showSidebar", (!showSidebar).toString());
  };
  const menuSections = [
    {
      id: 1,
      icon: <Dashboard size={20} />,
      name: capitalizeFirstLetter(t("dashboard")),
      path: "/",
    },
  ];

  //SETTINGS section array (we are going to loop across it)
  const settingSections = [
    {
      id: 14,
      icon: <Preferences size={20} />,
      name: capitalizeFirstLetter(t("users")),
      path: "/admin/users",
    },
    {
      id: 16,
      icon: <Preferences size={20} />,
      name: capitalizeFirstLetter(t("historic")),
      path: "/admin/log",
    },
    {
      id: 17,
      icon: <Preferences size={20} />,
      name: capitalizeFirstLetter(t("profile")),
      path: "/admin/profile",
    },
    // {
    //   id: 17,
    //   icon: <Users size={20}/>,
    //   name: "Profil",
    //   path: "/settings/profile/" + sessionStorage.getItem("id"),
    // },
  ];

  const configurationSections = [
    {
      //     id: 0, icon: <Create size={20}/>, name: "Structure", path: "/configuration/structure", user: role,
      // }, {
      id: 2,
      icon: <Create size={20} />,
      name: capitalizeFirstLetter(t("instances")),
      path: "/configuration/instance",
    },
    {
      id: 3,
      icon: <Create size={20} />,
      name: capitalizeFirstLetter(t("observed_systems")),
      path: "/configuration/system",
    },
    {
      id: 4,
      icon: <Create size={20} />,
      name: capitalizeFirstLetter(t("system&source")),
      path: "/configuration/create",
    },
    {
      id: 5,
      icon: <Create size={20} />,
      name: capitalizeFirstLetter(t("data_source")),
      path: "/configuration/data",
    },
    {
      id: 7,
      icon: <Upload size={20} />,
      name: capitalizeFirstLetter(t("file_deposit")),
      path: "/configuration/upload",
    },
    {
      id: 8,
      icon: <Upload size={20} />,
      name: capitalizeFirstLetter(t("system&file_deposit")),
      path: "/configuration/uploadv2",
    },
  ];
  const AnalyticsSection = [
    {
      id: 9,
      icon: <Indicator size={20} />,
      name: capitalizeFirstLetter(t("indicators")),
      path: "/analytics/indicator",
    },
    {
      id: 10,
      icon: <Analytics size={20} />,
      name: capitalizeFirstLetter(t("graphics")),
      path: "/analytics/graph",
    },
    {
      id: 11,
      icon: <Event size={20} />,
      name: capitalizeFirstLetter(t("events")),
      path: "/analytics/event",
    },
    // {
    //   id: 12,
    //   icon: <Learning size={20} />,
    //   name: "Modèle d'apprentissage",
    //   path: "/analytics/model",
    // },
    {
      id: 13,
      icon: <Threshold color={"white"} size={20} />,
      name: capitalizeFirstLetter(t("thresholds")),
      path: "/analytics/threshold",
    },
    {
      id: 30,
      icon: <Threshold color={"white"} size={20} />,
      name: capitalizeFirstLetter(t("models")),
      path: "/analytics/modele",
    },
    {
      id: 31,
      icon: <Threshold color={"white"} size={20} />,
      name: capitalizeFirstLetter(t("labels")),
      path: "/analytics/labels",
    },
    {
      id: 32,
      icon: <Threshold color={"white"} size={20} />,
      name: capitalizeFirstLetter(t("status")),
      path: "/analytics/status",
    },
  ];
  const AdminSection = [
    {
      id: 14,
      icon: <Preferences size={20} />,
      name: capitalizeFirstLetter(t("users")),
      path: "/admin/users",
    },
    {
      id: 15,
      icon: <Preferences size={20} />,
      name: capitalizeFirstLetter(t("structures")),
      path: "/admin/structures",
    },
    {
      id: 16,
      icon: <Preferences size={20} />,
      name: capitalizeFirstLetter(t("historic")),
      path: "/admin/log",
    },
    {
      id: 17,
      icon: <Preferences size={20} />,
      name: capitalizeFirstLetter(t("profile")),
      path: "/admin/profile",
    },
  ];
  //Access ENV variables (.env)
  const { REACT_APP_VERSION } = process.env;
  const profile = useSelector((state) => state.application.profile);
  const user_profil = profile["id_role"];
  return (
    <aside
      id="default-sidebar"
      className={classNames(
        "fixed left-0 top-14 flex w-14 flex-col bg-blue-800 hover:w-64",
        {
          "transition-all duration-300 md:w-64": showSidebar,
          "transition-all duration-300 md:w-14 md:hover:w-64": !showSidebar,
        },
        "z-20 h-full border-none text-white transition-all duration-300",
      )}
      aria-label="Sidebar"
    >
      <div className="flex grow flex-col justify-between overflow-y-auto overflow-x-hidden ">
        <ul className="space-y-2 font-medium">
          {!isEmptyString(structure) && <Section name="MENU" section={menuSections} icon={<HomeIcon className={"h-5 w-5 uppercase text-gray-400"} />} />}
          {!isEmptyString(structure) && (
            <Section name="Configuration" section={configurationSections} icon={<WrenchIcon className={"h-5 w-5 uppercase text-gray-400"} />} />
          )}
          {!isEmptyString(structure) && (
            <Section name="Analytics" section={AnalyticsSection} icon={<AdjustmentsHorizontalIcon className={"h-5 w-5 uppercase text-gray-400"} />} />
          )}
          <Section
            name="Administration"
            section={user_profil === 1 ? AdminSection : settingSections}
            icon={<Cog8ToothIcon className={"h-5 w-5 uppercase text-gray-400"} />}
          />
        </ul>
        {/* FOOTER (VERSION NUMBER) */}
        <div className="mb-14 text-center text-xs">
          <p className="truncate px-5 py-3 tracking-wide">Version {REACT_APP_VERSION}</p>
          <div className="hidden bg-blue-950 px-5 py-3 md:block" onClick={handleSidebar}>
            <p className="flex flex-row items-center justify-center">
              {showSidebar ? <ChevronLeftIcon className={"h-6 w-6"} /> : <ChevronRightIcon className={"h-6 w-6"} />}
              <span className={`mx-3 ${showSidebar ? "md:block" : "truncate"}`}>
                {showSidebar ? "Masquer la barre latérale" : "Afficher la barre latérale"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
