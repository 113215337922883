import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import SwitchButton from "../../../components/Selectors/SwitchButton";

const CrossCorrelationChart = ({ data }) => {
  const [seriesData, setSeriesData] = useState([]);
  const xData = data.x;
  const btn = [];

  useEffect(() => {
    const tmp = [];
    Object.keys(data.y).forEach((firstKey) =>
      Object.keys(data.y[firstKey]).forEach((secondKey) => {
        tmp.push({
          data: data.y[firstKey][secondKey],
          name: firstKey + " - " + secondKey,
          disabled: false, // Initially, all series are enabled
        });
      }),
    );
    setSeriesData(tmp);
  }, [data]);
  seriesData.forEach((serie) => {
    btn.push(<SwitchButton value={!serie.disabled} label={serie.name} onChange={() => toggleSeries(serie.name)} />);
  });
  const toggleSeries = (seriesIndex) => {
    const updatedSeriesData = [...seriesData];
    updatedSeriesData.find((serie) => serie.name === seriesIndex).disabled = !updatedSeriesData.find((serie) => serie.name === seriesIndex).disabled;
    setSeriesData(updatedSeriesData);
  };

  const options = {
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      name: "Décalage",
      type: "category",
      data: xData,
    },
    yAxis: {
      name: "Correlation",
      type: "value",
    },
    series: seriesData.map((data) => {
      return {
        type: "line",
        data: data.data,
        name: data.name,
        emphasis: {
          focus: "series", // Enable highlighting when series is clicked
        },
        itemStyle: {
          normal: {
            opacity: data.disabled ? 0 : 1, // Adjust opacity based on the disabled property
          },
        },
        lineStyle: {
          normal: {
            opacity: data.disabled ? 0 : 1, // Adjust line opacity based on the disabled property
          },
        },
      };
    }),
  };
  return (
    <div>
      <div className={"flex justify-center"}>{btn}</div>
      <ReactECharts option={options} style={{ height: "400px" }} />
    </div>
  );
};

export default CrossCorrelationChart;
