import TimezoneSelect from "react-timezone-select";
import { useDispatch, useSelector } from "react-redux";
import { setModuleData, setScriptParameters } from "../../../reducers/indicatorSlice";
import Select from "react-select";
import { hasSeconds, makeVariableField } from "../../../utils/common";
import TextInput from "../../../components/Selectors/TextInput";

const MRDTimestamp = () => {
  const dispatch = useDispatch();
  const module = useSelector((state) => state.indicator.moduleData.script_parameters);
  const selSource = useSelector((state) => state.indicator.selSource);
  const sources = useSelector((state) => state.data.crude_source);
  const timestampFieldOptions = () =>
    selSource === undefined ? [] : makeVariableField(1, selSource.value, sources.find((source) => source.id === selSource.value)["measure"]);

  return (
    <div>
      <div className="grid grid-cols-2 gap-x-1.5">
        <div>
          <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white ">Variable temporelle</label>
          <Select
            menuPosition={"fixed"}
            isSearchable={false}
            value={module.timestamp_field}
            onChange={(value) => dispatch(setScriptParameters({ key: "timestamp_field", value: value }))}
            options={timestampFieldOptions()}
          />
        </div>
        <div>
          <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white ">Fuseau horaire</label>
          <TimezoneSelect
            value={module.timezone}
            onChange={(e) => {
              dispatch(
                setScriptParameters({
                  key: "timezone",
                  value: e.value,
                }),
              );
            }}
          />
        </div>
        <div>
          <TextInput
            value={module.initial_timestamp}
            label={"Date de début"}
            onChange={(name, value) => {
              dispatch(setScriptParameters({ key: "initial_timestamp", value: value }));
            }}
            type={"datetime-local"}
            step={"1"}
          />
        </div>

        {module.mode === 0 && (
          <div>
            <span className={"col-span-1"} />

            <TextInput
              value={module.final_timestamp}
              label={"Date de fin"}
              onChange={(name, value) => {
                dispatch(
                  setModuleData({
                    key: "final_timestamp",
                    value: hasSeconds(value) ? value : value + ":00",
                  }),
                );
              }}
              type={"datetime-local"}
              step={"1"}
            />
            <span className={"col-span-1"} />
          </div>
        )}
      </div>
    </div>
  );
};
export default MRDTimestamp;
