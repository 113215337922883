import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { dataList, makeSelectField } from "../../../utils/common";
import { useState } from "react";
import { getDataApi } from "../../../axios/DataManagementApi";
import { setModuleData } from "../../../reducers/indicatorSlice";

const IndicatorId = () => {
  const dispatch = useDispatch();
  const { indicator_id } = useSelector((state) => state.indicator.moduleData);

  const { virtual_crude_source } = useSelector((state) => state.data);
  const [virtualCrudeSourceOptions] = useState(makeSelectField(virtual_crude_source, ["name"]));
  const [virtualConfiguredSourceOptions, setVirtualConfiguredSourceOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVirtualCrudeSource, setSelectedVirtualCrudeSource] = useState(undefined);
  const handleVirtualCrudeSourceChange = (newValue) => {
    setSelectedVirtualCrudeSource(newValue);
    setIsLoading(true);
    getDataApi("configuration/element/" + sessionStorage.getItem("observed_system_id") + "/" + newValue.value)
      .then((res) => {
        const tmp = makeSelectField(dataList(res.data.data), ["id"]);
        console.debug(tmp);
        setVirtualConfiguredSourceOptions(tmp);
        setIsLoading(false);
        console.debug(res);
      })
      .catch((error) => {
        setIsLoading(false);
        console.debug(error);
      });
  };
  return (
    <div className={"grid grid-cols-2"}>
      <div>
        <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"virtual crude source"}</label>
        <Select
          value={selectedVirtualCrudeSource}
          options={virtualCrudeSourceOptions}
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={handleVirtualCrudeSourceChange}
        />
      </div>
      <div>
        <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"virtual conf source"}</label>
        <Select
          value={virtualConfiguredSourceOptions.find((value) => value.value === indicator_id)}
          isDisabled={isLoading}
          options={virtualConfiguredSourceOptions}
          onChange={(newValue) => dispatch(setModuleData({ key: "indicator_id", value: newValue.value }))}
        />
      </div>
    </div>
  );
};
export default IndicatorId;
