import { useDispatch, useSelector } from "react-redux";
import TimeInput from "../../components/Inputs/TimeInput";
import { setCrudeSource, setCurrentTimeParams, setModuleData } from "../../reducers/indicatorSlice";
import { PredictionParams } from "../indicator/PredictionParams";
import NumericInput from "../../components/Inputs/NumericInput";
import { useState } from "react";
import { dataList, findDateField, isEmptyObject, makeSelectField } from "../../utils/common";
import { formatBackend } from "../../utils/date.utils";
import dayjs from "dayjs";
import { getDataApi, putDataApi } from "../../axios/DataManagementApi";
import { toast } from "react-toastify";
import { postIndicatorApi } from "../../axios/IndicatorComputationApi";
import { PrevButton, PrimaryButton } from "../../components/Buttons/Button";
import HorizontalLine from "../../components/HorizontalLine";
import SwitchButton from "../../components/Selectors/SwitchButton";
import IndicatorRecurentForm from "../indicator/IndicatorRecurentForm";
import SimpleTime from "../indicator/Simple/SimpleTime";
import Select from "react-select";
import { aggregationOptions } from "../../utils/field";
import ConfigurationAlerteFormula from "../indicator/ConfigurationAlerte/ConfigurationAlerteFormula";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import store from "../../store";
import { set } from "../../reducers/dataSlice";
import { useNavigate } from "react-router-dom";
import { ReconstructionParams } from "../indicator/ReconstructionParams";

const ReconstructionSimple = ({ result, setResult }) => {
  const dispatch = useDispatch();
  const { crude_source } = useSelector((state) => state.data);
  const [formula, setFormula] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { showLabel } = useSelector((state) => state.application);
  const [recurrent, setRecurrent] = useState(false);
  const [aggregation, setAggregation] = useState(false);
  const [aggregationMode, setAggregationMode] = useState("avg");
  const [crudeSourceOptions] = useState(makeSelectField(crude_source, ["name", "description"], true));
  const { moduleData, selected_crude_source } = useSelector((state) => state.indicator);
  const history = useNavigate();
  const [graphLoading, setGraphLoading] = useState(false);

  if (isEmptyObject(moduleData)) return null; //TODO fix this issue when going from prediction creation to indicator page
  const { current, initial_timestamp, shift } = moduleData;
  const { time_params, computation_params } = current;
  const { start_time, end_time, bucket_size, label, time_label } = time_params;

  function handleCrudeSource(selected, action) {
    if (action.action === "select-option" && action.option.value === "all") {
      const newSource = crudeSourceOptions.filter((value) => value.value !== "all").map((value) => value.value);
      dispatch(setCrudeSource(newSource));
    } else {
      dispatch(setCrudeSource(selected.map((value) => value.value)));
    }
  }

  function handleTimeParams(name, value) {
    dispatch(setCurrentTimeParams({ key: name, value: value }));
  }

  function createPrediction() {
    setIsLoading(true);

    function getDataBlocks(source, sourceList, agregation = "identity") {
      const dataBlocks = [];

      for (let i = 0; i < source.length; i++) {
        const sourceId = source[i];
        const sourceData = sourceList.find((item) => item.id === sourceId);

        if (sourceData && sourceData.measure) {
          const timeField = findDateField(sourceData.measure);
          const preprocess = {};
          Object.keys(sourceData.measure).forEach((key) => {
            if (key !== timeField) {
              preprocess[key] = agregation;
            }
          });

          const dataBlock = {
            configured_sources: [],
            time_field: timeField ?? "internal",
            preprocess: preprocess,
            measure_identifier: sourceData.measure_identifier,
            status: -1,
          };

          dataBlocks.push(dataBlock);
        }
      }

      return dataBlocks;
    }

    const { reconstruction_params } = structuredClone(moduleData);
    const parseFormulaV1 = (formula, blocks) => {
      let ret = formula;
      blocks.forEach((block) => {
        Object.keys(block.preprocess).forEach((value) => {
          ret = ret.replaceAll(value, "['" + value + "']");
        });
      });
      return ret;
    };
    const obs = sessionStorage.getItem("observed_system_id");
    const payload = {
      current: {
        data_params: getDataBlocks(selected_crude_source, crude_source, aggregation ? aggregationMode : "identity"),
        time_params: {
          start_time: formatBackend(start_time),
          end_time: formatBackend(end_time),
          bucket_size: aggregation ? bucket_size : -1,
          label: label,
        },
        computation_params: structuredClone(computation_params),
      },
      reconstruction_params: reconstruction_params,
    };

    if (showLabel) payload.current.time_params.time_label = time_label;
    payload.current.computation_params.formula = formula ? parseFormulaV1(computation_params.formula, payload.current.data_params) : "";
    if (recurrent) {
      const { script_name, initial_timestamp, shift, frequency, validity_days, time_slot } = structuredClone(moduleData);
      const recurrentPayload = {
        script_name: script_name,
        script_parameters: {
          observed_system: obs,
          parameters: payload,
          initial_timestamp: dayjs(initial_timestamp).tz("Europe/Paris").format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          timezone: "Europe/Paris",
          frequency: frequency * 60,
          shift: shift,
        },
        frequency: frequency,
        time_slot: time_slot,
        validity_days: validity_days,
      };
      recurrentPayload.script_parameters.parameters.current.time_params.start_time = "";
      recurrentPayload.script_parameters.parameters.current.time_params.end_time = "";
      putDataApi("configuration/module/" + obs, recurrentPayload)
        .then((res) => {
          setIsLoading(false);
          if (res === 401) return;
          toast.warn(res["data"]["message"] + " - " + res["data"]["module_instance_id"], {
            autoClose: false,
            closeOnClick: false,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          console.debug(error);
        });
    } else
      postIndicatorApi("indicator/reconstruction/" + obs, payload)
        .then((res) => {
          setIsLoading(false);
          setResult(res.data);
          console.debug(res);
        })
        .catch((error) => {
          setIsLoading(false);
          console.debug(error);
        });
  }

  const handleDisplayGraph = () => {
    setGraphLoading(true);
    dataLoadingToast({ isLoading: true, id: "graph-create" });
    getDataApi("training/predict/" + sessionStorage.getItem("observed_system_id"))
      .then((res) => {
        console.debug(res.data.data);
        store.dispatch(
          set({
            type: "prediction",
            data: dataList(res.data.data),
          }),
        );
        setGraphLoading(false);
        history("/analytics/modele/graph/" + result.virtual_config);
      })
      .catch((error) => {
        dataLoadingToast({ error: true, errorMessage: error.data.result.message, id: "graph-create" });
        setGraphLoading(false);
        console.debug(error);
      });
  };
  return (
    <div className={"p-8"}>
      <div className="w-full rounded-lg border border-gray-300 bg-white shadow-md">
        <div className={"flex gap-x-1.5 p-4"}>
          <div className={"h-fit"}>
            <PrevButton url={"/analytics/modele"} />
          </div>
          <h2 className="text-3xl font-semibold text-blue-800">Reconstruction</h2>
        </div>
        <HorizontalLine />
        <div className="mx-4 my-2">
          <div className={"mb-2 px-8"}>
            <SwitchButton label={"Récurent"} value={recurrent} onChange={(value) => setRecurrent(value)} />
          </div>
          {recurrent ? (
            <div className={"mb-2 grid grid-cols-6 gap-x-4 px-16"}>
              <TimeInput
                name={"initial_timestamp"}
                label={"Date et heure"}
                value={initial_timestamp}
                onChange={(name, value) => dispatch(setModuleData({ key: name, value: value }))}
              />
              <div className={"w-32"}>
                <NumericInput
                  name={"shift"}
                  label={"fin (en seconde)"}
                  value={shift}
                  onChange={(name, value) => dispatch(setModuleData({ key: name, value: value }))}
                />
              </div>
              <IndicatorRecurentForm />
            </div>
          ) : (
            <div className={"mb-2 flex justify-between gap-x-4 px-16"}>
              <SimpleTime />
            </div>
          )}
        </div>

        <div className={"mb-2 border-b px-8 pb-2"}>
          <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Source(s) de données</label>
          <Select
            isMulti
            closeMenuOnSelect={false}
            placeholder={"Source de données"}
            value={crudeSourceOptions.filter((value) => selected_crude_source.includes(value.value))}
            options={crudeSourceOptions}
            onChange={handleCrudeSource}
          />
        </div>
        <div className={"mb-2 px-8"}>
          <SwitchButton label={"Agrégation"} value={aggregation} onChange={(value) => setAggregation(value)} />
        </div>
        {aggregation && (
          <div className={"mb-2 grid grid-cols-6 gap-x-4 px-16"}>
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">Type</label>
              <Select
                options={aggregationOptions}
                value={aggregationOptions.find((value) => value.value === aggregationMode)}
                onChange={(newValue) => setAggregationMode(newValue.value)}
              />
            </div>
            <NumericInput label={"Intervalle de temps (s)"} value={bucket_size} name={"bucket_size"} onChange={handleTimeParams} />
          </div>
        )}
        <div className={"mb-2 border-b px-8 pb-2"}>
          <SwitchButton label={"Formule"} value={formula} onChange={(value) => setFormula(value)} />
        </div>
        {formula && (
          <div className={"mb-2 w-1/2 px-16"}>
            <ConfigurationAlerteFormula mode={"current"} verify={false} />
          </div>
        )}
        <div className={"mb-2 px-8"}>
          <ReconstructionParams />
        </div>
      </div>
      <div className={"flex justify-center gap-x-1.5"}>
        <PrimaryButton
          disabled={isLoading || selected_crude_source.length === 0}
          isLoading={isLoading}
          label={recurrent ? "Créer" : "Calculer"}
          callback={() => createPrediction()}
        />
        <PrimaryButton label={"Afficher le graphique"} disabled={isEmptyObject(result) || graphLoading} callback={() => handleDisplayGraph()} />
      </div>
    </div>
  );
};

export default ReconstructionSimple;
