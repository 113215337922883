import { getTrackBackground, Range } from "react-range";
import { setBase } from "../../reducers/EventSlice";
import { useDispatch, useSelector } from "react-redux";

const CronDualRange = ({ model, name, min, max }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.event);
  const values = data[model].split("-").map((value) => parseInt(value));
  return (
    <div>
      <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">{name}</label>
      <div className="flex flex-wrap justify-center">
        <Range
          values={values}
          step={1}
          min={min}
          max={max}
          onChange={(values) =>
            dispatch(
              setBase({
                type: model,
                value: values.join("-"),
              }),
            )
          }
          renderTrack={({ props, children }) => (
            <div className={"flex h-10 w-full"} onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart}>
              <div
                ref={props.ref}
                className="h-1.5 w-full self-center rounded"
                style={{
                  background: getTrackBackground({
                    values,
                    colors: ["#cbd5e1", "#349ed0", "#cbd5e1"],
                    min: min,
                    max: max,
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ index, props, isDragged }) => (
            <div {...props} className="flex h-8 w-8 items-center justify-center rounded bg-white shadow">
              <div className="absolute top-9 flex w-6 items-center justify-center rounded bg-blue-900 p-1 text-sm font-bold text-white" style={{}}>
                {values[index]}
              </div>
              <div className={`${"h-4 w-1.5"} ${isDragged ? "bg-blue-900" : "bg-slate-300"}`} />
            </div>
          )}
        />
      </div>
    </div>
  );
};
export default CronDualRange;
