import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parser } from "mathjs";
import { variableField } from "../utils";
import { toast } from "react-toastify";
import { setCurrentComputationParams, setHistoricalComputationParams } from "../../../reducers/indicatorSlice";
import TextInput from "../../../components/Selectors/TextInput";
import { SecondaryButton } from "../../../components/Buttons/Button";

const ConfigurationAlerteFormula = ({ mode, verify = true }) => {
  const [toastId, setToastId] = useState("" | 0);
  const dispatch = useDispatch();
  const current = useSelector((state) => state.indicator.moduleData[mode]);
  const checkFormula = () => {
    const parse = parser();
    variableField(current.data_params).forEach((obj) => parse.set(obj.value, 1));
    try {
      parse.evaluate(current.computation_params.formula);
      toast.dismiss(toastId);
      setToastId(toast.info("Formule valide"));
    } catch (e) {
      toast.dismiss(toastId);

      if (e instanceof SyntaxError) setToastId(toast.error("Formule incorrect"));
      else {
        const wrongVariable = e.message.split(" ")[2];
        setToastId(toast.error("Variable incorrect: " + wrongVariable));
      }
    }
  };

  return (
    <div className=" flex w-full gap-1.5">
      <div className={"w-full"}>
        <TextInput
          required={true}
          name={"formula"}
          value={current.computation_params.formula}
          onChange={(name, value) => {
            mode === "current"
              ? dispatch(setCurrentComputationParams({ key: name, value: value }))
              : dispatch(setHistoricalComputationParams({ key: name, value: value }));
          }}
        />
      </div>
      {verify && <SecondaryButton callback={checkFormula} label={"Vérifier"} />}
    </div>
  );
};
export default ConfigurationAlerteFormula;
