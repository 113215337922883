import { useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";
import { chartData, formatBits } from "./chart.utils";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/common";

const StorageSizeChart = () => {
  const { t } = useTranslation(["chart"]);
  const { storage_system } = useSelector((state) => state.data);
  const description = storage_system.map((item) => item.name);
  const storageTypes = storage_system.map((item) => item.storage_type);
  const storageSizes = storage_system.map((item) => parseFloat(item.raw_storage_size));
  const storageTable = storage_system.map((item) => item.number_of_tables);
  const sum = storageTable.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const storageTotalSize = storageSizes.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  if (storage_system.length === 0) return null;
  const tooltipFormatter_storage_size = (params) => {
    const { dataIndex, value, percent, name } = params;
    return `<h1>${name}</h1><p><b> ${formatBits(value)} </b>(${percent}%)</p> <p><i>${storageTypes[dataIndex]}</i></p>`;
  };
  const data_storage_size = storage_system.map((item) => {
    return { name: item.name, value: item.raw_storage_size };
  });

  const tooltipFormatter_storage_number_table = (params) => {
    const { dataIndex, value, percent, name } = params;
    return `<h1>${name}</h1><p><b>${value}</b> tables (${percent}%)</p> <p><i>${storageTypes[dataIndex]}</i></p>`;
  };
  const data_storage_number_table = storage_system.map((item) => {
    return { name: item.name, value: item.number_of_tables };
  });

  return (
    <div className="m-4 flex h-[400px] justify-center rounded-lg border border-gray-200 bg-white">
      <div className={" h-full w-full py-2"}>
        <div className={"mb-1"}>
          <p className={"text-center font-semibold text-blue-900"}>{capitalizeFirstLetter(t("storage_title_storage"))}</p>
          <p className={"text-center font-mono text-xs"}>{formatBits(storageTotalSize)}</p>
          <p className={"text-center font-mono text-xs"}>{sum} tables</p>
        </div>
        <div className={"flex justify-between"}>
          <ReactECharts className={"w-2/4"} option={chartData(tooltipFormatter_storage_size, data_storage_size, description)} />
          <ReactECharts className={"w-2/4"} option={chartData(tooltipFormatter_storage_number_table, data_storage_number_table, description)} />
        </div>
      </div>
    </div>
  );
};

export default StorageSizeChart;
