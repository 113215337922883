import { useDispatch, useSelector } from "react-redux";
import { setScriptParameters } from "../../reducers/indicatorSlice";
import TextInput from "../../components/Selectors/TextInput";

import Select from "react-select";

const IndicatorMailingForm = () => {
  const module = useSelector((state) => state.indicator.moduleData.script_parameters);
  const users = useSelector((state) => state.data.users);
  const dispatch = useDispatch();
  const onMailingChange = (key, value) => {
    dispatch(setScriptParameters({ key: key, value: value }));
  };
  const usersSelector = () =>
    users.map((value) => {
      return { value: value.email, label: value.email };
    });
  const userOptions = usersSelector();
  return (
    <>
      <TextInput
        name="sender"
        onChange={onMailingChange}
        placeholder="Adresse mail de l'expediteur"
        type="email"
        value={module["sender"]}
        disabled={true}
        label={"Expediteur"}
      />
      <div>
        <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Destinataires</label>
        <Select
          placeholder={"Destinataires..."}
          options={userOptions}
          isClearable={true}
          isMulti
          value={userOptions.filter((user) => module["recipients"].includes(user.value))}
          onChange={(options, actionMeta) => {
            if (actionMeta.action === "select-option")
              onMailingChange(
                "recipients",
                options.map((user) => user.value),
              );
            else onMailingChange("recipients", []);
          }}
        />
      </div>
      <TextInput name="subject" value={module["subject"]} onChange={onMailingChange} placeholder="Sujet du mail" label={"Sujet du mail"} />
    </>
  );
};
export default IndicatorMailingForm;
