import { Tooltip } from "react-tooltip";
import { useId } from "react";
import { toast } from "react-toastify";

const TextTooltip = ({ text, tip, className }) => {
  const id = useId();
  return (
    <div>
      <Tooltip id={id}>
        <div>{tip}</div>
      </Tooltip>
      <p
        data-tooltip-id={id}
        onClick={() => {
          navigator.clipboard.writeText(text);
          toast.info("Copié dans le presse-papiers", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }}
        className={className}
      >
        {" "}
        {text}
      </p>
    </div>
  );
};
export default TextTooltip;
