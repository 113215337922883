import { useLoaderData, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { isEmptyString } from "../../utils/common";
import { GraphHeader } from "../../components/Charts/GraphHeader";
import dayjs from "dayjs";
import store from "../../store";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import { formatBackend } from "../../utils/date.utils";
import { postIndicatorApi } from "../../axios/IndicatorComputationApi";

const PredictionGraph = () => {
  const { id } = useParams();
  const data = useLoaderData();
  const { payload, url, target, target_time_field } = data;
  console.debug(payload);
  const [graphUrl, setGraphUrl] = useState(url);
  const [isLoading, setIsLoading] = useState(false);
  const [graphParam, setGraphParam] = useState(payload);
  const [startTime, setStartTime] = useState(dayjs().subtract(payload.timestamp_shift, payload.shift_unit).format("YYYY-MM-DDTHH:mm:ss"));
  const [endTime, setEndTime] = useState(dayjs().format("YYYY-MM-DDTHH:mm:ss"));
  console.debug(startTime, endTime);
  console.debug(id);
  const createDataCharacterizationPayload = useCallback(
    (start = startTime, end = endTime) => {
      const crudeSources = store.getState().data.crude_source;
      const crude_source = crudeSources.find((value) => value.id === payload.historical_data.data_source_id);

      dataLoadingToast({ label: "Caractérisation en cours...", isLoading: true });
      const payloadCarac = {
        current: {
          time_params: {
            start_time: formatBackend(start),
            end_time: formatBackend(end),
            bucket_size: -1,
            label: "",
          },
          data_params: [
            {
              measure_identifier: crude_source.measure_identifier,
              configured_sources: [],
              status: -1,
              preprocess: { [target]: "identity" },
              time_field: target_time_field,
            },
          ],
          computation_params: {
            formula: "",
            function: {
              name: "identity",
              variables: {},
            },
          },
        },
        characterization_params: {
          target_variable: [target],
          prediction_id: id,
          threshold: 0.8,
          alpha: 0.05,
          random_nb_lags: -1,
        },
      };

      postIndicatorApi("indicator/datacharacterization/" + sessionStorage.getItem("observed_system_id"), payloadCarac)
        .then((res) => {
          console.debug(res);
          if (res.data.graphic_url) setDataCharacterization(res.data.graphic_url.characterization);
          else setDataCharacterization(null);
          dataLoadingToast({ success: true });
          setIsLoading(false);
        })
        .catch((error) => {
          console.debug(error);
          dataLoadingToast({ error: true });
          setIsLoading(false);
        });
    },
    [endTime, id, payload.historical_data.data_source_id, startTime, target, target_time_field],
  );
  useEffect(() => {
    createDataCharacterizationPayload();
  }, [createDataCharacterizationPayload, payload]);
  const [dataCharacterization, setDataCharacterization] = useState(null);
  const onCreate = (startDate, endDate) => {
    setIsLoading(true);
    setStartTime(startDate);
    setEndTime(endDate);
    const urlObj = new URL(graphUrl);
    urlObj.searchParams.set("from", dayjs(startDate).unix() * 1000);
    urlObj.searchParams.set("to", dayjs(endDate).unix() * 1000);
    setGraphUrl(urlObj.toString());
  };

  const onChange = (name, value) => {
    setGraphParam({ ...graphParam, [name]: value });
  };
  return (
    <div>
      <GraphHeader
        onChange={onChange}
        params={graphParam}
        onCreate={onCreate}
        isLoading={isLoading}
        prev={"/analytics/modele"}
        startTime={startTime}
        endTime={endTime}
      />
      {!isEmptyString(graphUrl) && (
        <div className="m-8 block rounded border border-gray-200 bg-white  p-4 dark:border-gray-600 dark:bg-gray-800">
          <div className="h-[70vh] w-full">
            <iframe onChange={(event) => console.debug(event)} className="h-full  w-full shadow" src={graphUrl} />
          </div>
        </div>
      )}
      {dataCharacterization !== null && <iframe className="min-h-[400px] w-full" src={dataCharacterization} />}
    </div>
  );
};
export default PredictionGraph;
