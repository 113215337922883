import { useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableFilter } from "../../components/Table/TableFilter";
import { TableHeader } from "../../components/Table/TableHeader";
import { TableBody } from "../../components/Table/TableBody";
import { TablePagination } from "../../components/Table/TablePagination";
import Select from "react-select";
import SwitchButton from "../../components/Selectors/SwitchButton";
import { setBase } from "../../reducers/EventSlice";
import { useDispatch, useSelector } from "react-redux";
import { TableSelector } from "../../components/TableSelector";

const VariableTable = ({ data, setData, row, setRow }) => {
  const histo = useSelector((state) => state.event.histo);
  const dispatch = useDispatch();
  const lockedHisto = useSelector((state) => state.event.lockedHisto) ?? true;
  const updateFormat = (option) => {
    const rowc = { ...option.row };
    rowc[option.format] = option.value;
    const index = data.findIndex((value) => value[0] === option.name);
    const datac = structuredClone(data);
    datac[index][1] = rowc;
    setData(datac);
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.display({
      id: "select",
      cell: ({ row }) => (
        <div className="px-1">
          <TableSelector
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
      header: ({ table }) => (
        <TableSelector
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
    }),
    columnHelper.accessor((row) => row[0], {
      id: "name",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row[1].type, {
      id: "type",
      header: "Type",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "current",
      header: "Format",
      cell: (info) => {
        const row = info.row.original;
        return (
          <Select
            menuPosition={"fixed"}
            className={"w-32"}
            value={{ value: row[1], label: row[1].current }}
            onChange={(option) => updateFormat(option)}
            options={[
              {
                format: "current",
                name: row[0],
                row: row[1],
                value: "identity",
                label: "identity",
              },
              { format: "current", name: row[0], row: row[1], value: "max", label: "max" },
              {
                format: "current",
                name: row[0],
                row: row[1],
                value: "avg",
                label: "avg",
              },
              { format: "current", name: row[0], row: row[1], value: "min", label: "min" },
            ]}
          />
        );
      },
    }),
  ];

  const [globalFilter, setGlobalFilter] = useState("");
  const table = useReactTable({
    data,
    columns: columns,
    state: {
      globalFilter,
      rowSelection: row,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRow,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3">
          <h2 className="w-full text-3xl font-semibold text-gray-800">Variables</h2>
          {!lockedHisto && (
            <SwitchButton label={"historique"} value={histo} disabled={lockedHisto} onChange={() => dispatch(setBase({ type: "histo", value: !histo }))} />
          )}
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="w-full">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>

      <TablePagination table={table} />
    </div>
  );
};
export default VariableTable;
