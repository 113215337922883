import NumericInput from "../../components/Inputs/NumericInput";
import TextInput from "../../components/Selectors/TextInput";

const TransformerVariable = ({ variable, parameters, handleValueChange }) => {
  if (parameters[variable[0]] === undefined) return;
  const mode = parameters[variable[0]].type;

  if (mode === "DELETION") return <p className={"flex  items-center justify-center px-1 py-0.5"}>Suppression de la donnée</p>;
  if (mode === "SIMPLE")
    return (
      <div className={"flex items-center justify-center px-1 py-0.5"}>
        <TransformerVariableSimple value={parameters[variable[0]].value} name={variable[0]} type={variable[1]} handleValueChange={handleValueChange} />
      </div>
    );
  if (mode === "FORMULA")
    return (
      <div className={"flex items-center justify-center px-1 py-0.5"}>
        <TransformerVariableFormula value={parameters[variable[0]].value} name={variable[0]} type={variable[1]} handleValueChange={handleValueChange} />
      </div>
    );
};
export default TransformerVariable;

const TransformerVariableSimple = ({ value, name, type, handleValueChange }) => {
  if (type === "dynamic_numerical") return <NumericInput width={" w-52 "} value={value} name={name} onChange={handleValueChange} />;
};
const TransformerVariableFormula = ({ value, name, type, handleValueChange }) => {
  if (type === "dynamic_numerical") return <TextInput width={" w-52 "} value={value} name={name} onChange={handleValueChange} />;
};
