import { InformationCircleIcon as Info } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import { useId } from "react";

const BasicTooltip = ({ tip }) => {
  const id = useId();
  return (
    <div>
      <Tooltip className={"z-40"} id={id}>
        {tip}
      </Tooltip>
      <Info data-tooltip-id={id} className=" h-5 w-5 text-blue-900 hover:text-blue-950" />
    </div>
  );
};
export default BasicTooltip;
