import ReactECharts from "echarts-for-react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const FormulaChart = ({ data }) => {
  console.debug(data);
  const { formula } = useSelector((state) => state.indicator.moduleData.current.computation_params);
  // Transforming the data to suit ECharts format (date and value keys)
  const transformedData = data.map((item) => {
    return { name: dayjs(item.date * 1000).format("DD/MM/YY HH:mm:ss"), value: item.value };
  });
  const titleStyle = {
    textAlign: "left", // text-left
    fontWeight: "500", // font-medium
    lineHeight: 16,
    fontSize: "0.75rem", // text-xs
    letterSpacing: "0.05em", // tracking-wider
    color: "#ffffff", // text-white
  };
  const option = {
    title: {
      left: "center",
      text: "Résultat de la formule en fonction du temps",
      backgroundColor: "#1e3a8a",
      textStyle: titleStyle,
      padding: [
        12, // up
        10000, // right
        12, // down
        10000, // left
      ],
    },
    tooltip: {
      trigger: "axis",
      formatter: "{b}: {c}", // Display both date and value in the tooltip
    },
    legend: {
      data: [formula], // Legend label
      bottom: 10,
    },
    xAxis: {
      type: "category",
      data: transformedData.map((item) => item.name),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: formula,
        symbol: "none",
        type: "line",
        data: transformedData.map((item) => item.value),
      },
    ],
  };

  return (
    <div className="block h-[40vh] rounded-lg border border-gray-200 shadow">
      <ReactECharts option={option} />
    </div>
  );
};

export default FormulaChart;
