import dayjs from "dayjs";
import { isEmptyString } from "./common";

export const formatBackend = (date, timezone = "") => {
  if (isEmptyString(timezone)) return dayjs(date).tz().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  return dayjs(date).tz(timezone).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
};
export const formatDisplay = (date) => (isEmptyString(date) ? "N/A" : dayjs(date).format("dddd DD/MM/YYYY HH:mm:ss"));

export const formatDateInput = (date) => dayjs(date).format("YYYY-MM-DDTHH:mm:ss");
