import MRDTimestamp from "./MRDTimestamp";

import { useDispatch, useSelector } from "react-redux";
import { setScriptParameters } from "../../../reducers/indicatorSlice";
import Select from "react-select";
import NumericInput from "../../../components/Inputs/NumericInput";
import { Fragment } from "react";
import SwitchButton from "../../../components/Selectors/SwitchButton";

const MRDParameters = () => {
  const dispatch = useDispatch();
  const module = useSelector((state) => state.indicator.moduleData.script_parameters);
  const controlModeOptions = [
    { value: 0, label: "Comptage" },
    { value: 1, label: "Proportion" },
  ];
  return (
    <Fragment>
      <MRDTimestamp />
      <div className={"grid  grid-cols-2 gap-x-1.5 py-2"}>
        <NumericInput
          min={0}
          name={"delay"}
          value={module.delay}
          label={"Délai de controle"}
          type={"number"}
          step={1}
          onChange={(key, value) => dispatch(setScriptParameters({ key: key, value: value }))}
        />
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-900">Mode de contrôle</label>
          <Select
            name={"control_mode"}
            value={controlModeOptions.find((value) => value.value === module.control_mode)}
            onChange={(newValue) => dispatch(setScriptParameters({ key: "control_mode", value: newValue.value }))}
            options={controlModeOptions}
          />
        </div>
        <NumericInput
          min={0}
          name={"control_mode_parameter"}
          value={module.control_mode_parameter}
          label={"Seuil à considérer"}
          type={"number"}
          step={1}
          onChange={(key, value) => dispatch(setScriptParameters({ key: key, value: value }))}
        />
        <SwitchButton
          label={"Aggrégation"}
          value={module.aggregation_mode === 1}
          onChange={(e) => dispatch(setScriptParameters({ key: "aggregation_mode", value: e ? 1 : 0 }))}
        />
      </div>
    </Fragment>
  );
};
export default MRDParameters;
