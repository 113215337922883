import { useEffect, useState } from "react";
import TextInput from "../../components/Selectors/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { setModelParams } from "../../reducers/EventSlice";
import Select from "react-select";
import { getDataApi } from "../../axios/DataManagementApi";

const ModelParamCreationName = () => {
  const dispatch = useDispatch();

  const params = useSelector((state) => state.event.data.model_params);
  const data_params = useSelector((state) => state.event.data.current.data_params);
  const [models, setModels] = useState([]);
  useEffect(() => {
    getDataApi("model/" + sessionStorage.getItem("structure_id")).then((res) => {
      const options = [];
      res.data.data.forEach((model) => options.push({ value: model, label: model }));
      setModels(options);
    });
  }, []);
  const [variables, setVariables] = useState([]);
  useEffect(() => {
    const tmp = [];
    setVariables([]);
    data_params.forEach((bloc) => {
      Object.keys(bloc.preprocess).forEach((variable) =>
        tmp.push({
          label: variable,
          value: variable,
        }),
      );
    });

    dispatch(setModelParams({ type: "target_variable", value: "" }));
    dispatch(setModelParams({ type: "predictor_variables", value: [] }));
    setVariables(tmp);
  }, [data_params, dispatch]);
  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <h2 className="text-2xl font-semibold text-blue-900">Paramètres du modèle</h2>
      <div>
        <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Modèle</label>
        <Select
          options={models}
          onChange={(option) => {
            dispatch(setModelParams({ type: "model_name", value: option.label }));
          }}
          placeholder={"Modèle..."}
        />
      </div>

      <TextInput
        name={"threshold"}
        min={0}
        max={1}
        step={"0.01"}
        label={"Seuil"}
        value={params.threshold}
        type={"number"}
        onChange={(name, value) => dispatch(setModelParams({ type: name, value: parseFloat(value) }))}
      />
      <div>
        <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"Variable cible"}</label>
        <Select
          value={variables.length === 0 ? null : variables[variables.findIndex((value) => value.value === params.target_variable)]}
          isSearchable={false}
          onChange={(option) => {
            dispatch(setModelParams({ type: "target_variable", value: option.label }));
          }}
          options={variables}
        />
      </div>
      <TextInput
        name={"delay"}
        disabled={true}
        min={0}
        step={"1"}
        label={"Delai"}
        value={params.delay}
        type={"number"}
        onChange={(name, value) => dispatch(setModelParams({ type: name, value: parseFloat(value) }))}
      />
      <TextInput
        name={"order"}
        min={0}
        step={"1"}
        label={"Ordre"}
        value={params.order}
        type={"number"}
        onChange={(name, value) => dispatch(setModelParams({ type: name, value: parseFloat(value) }))}
      />
      <div>
        <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"Variable(s) de prédiction"}</label>
        <Select
          isMulti={true}
          isSearchable={false}
          value={params.predictor_variables}
          onChange={(option) => {
            dispatch(setModelParams({ type: "predictor_variables", value: option }));
          }}
          options={variables}
        />
      </div>
    </div>
  );
};
export default ModelParamCreationName;
