import TimeSelector from "../../components/TimeSelector";
import { isEmptyString } from "../../utils/common";

export const StateTimeLine = ({ startTime, endTime, panelUrl, callback }) => (
  <div className="m-4  block h-[50vh]  rounded-lg border border-gray-200 bg-white shadow">
    <div className="flex flex-row px-3 ">
      <TimeSelector end_time={endTime} start_time={startTime} callback={callback} />
    </div>
    {!isEmptyString(panelUrl) && (
      <div className="m-8 block rounded">
        <iframe className="h-[30vh] w-full " src={panelUrl} />
      </div>
    )}
  </div>
);
