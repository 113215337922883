import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  configured_source: [],
  observed_system: [],
  structure: {},
  crude_source: [],
  storage_system: [],
  event: [],
  moduleName: [],
  threshold: [],
  users: [],
  profiles: [],
  tasks: [],
  methods: [],
  structures: [],
  training_model: [],
  prediction: [],
  virtual_crude_source: [],
  virtual_configured_source: [],
  labels: [],
  timelabel: [],
  status: [],
};

export const dataSlice = createSlice({
  name: "data",
  initialState: initialState,
  reducers: {
    hydrate: (state, action) => action.payload,
    setData: (state, action) => {
      const { type, data } = action.payload;
      state[type] = data;
    },
    set: (state, action) => {
      const { data, type } = action.payload;
      state[type] = data;
    },
    add: (state, action) => {
      const { data, type, index } = action.payload;
      state[type][index] = data;
    },
    push: (state, action) => {
      const { data, type } = action.payload;
      state[type].push(data);
    },
    remove: (state, action) => {
      const { id, type } = action.payload;
      state[type] = state[type].filter((value) => value.id !== id);
    },
    reset: (state) => {
      return { ...initialState, profile: state.profile };
    },
    edit: (state, action) => {
      const { type, id, updates } = action.payload;
      let index = state[type].findIndex((value) => value.id === id);
      state[type][index] = { ...state[type][index], ...updates };
    },
  },
});

export const { setData, set, reset, add, remove, push, edit, hydrate } = dataSlice.actions;
export default dataSlice.reducer;
