import Select from "react-select";
import { useEffect, useState } from "react";
import { getObjectPaths } from "./dataFeedback.utils";

const SourceParam = ({ fieldsToUpload, setNestedParameters, response, setSelectedFields, selectedFields }) => {
  const handleSelect = (newValue, actionMeta) => {
    const indexToReplace = selectedFields.findIndex((field) => field.name === actionMeta.name);
    if (indexToReplace === -1) {
      setNestedParameters("fields_to_upload." + actionMeta.name + ".field", newValue.value);
      const copySelectedFields = structuredClone(selectedFields);
      copySelectedFields.push({ ...newValue, name: actionMeta.name });
      setSelectedFields(copySelectedFields);
    } else {
      setNestedParameters("fields_to_upload." + actionMeta.name + ".field", newValue.value);
      const copySelectedFields = structuredClone(selectedFields);
      copySelectedFields[indexToReplace] = { ...newValue, name: actionMeta.name };
      setSelectedFields(copySelectedFields);
    }
  };
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const options = getObjectPaths(response);
    setOptions(options.filter((option) => selectedFields.find((field) => field.value === option.value) === undefined));
  }, [response, selectedFields]);

  return (
    <div className={"grid grid-cols-4 gap-x-0.5"}>
      {Object.entries(fieldsToUpload).map((p, index) => (
        <div key={index}>
          <p>{p[0]}</p>
          <Select options={options} name={p[0]} value={selectedFields.find((value) => value.name === p[0])} onChange={handleSelect} />
        </div>
      ))}
    </div>
  );
};
export default SourceParam;
