import { postRequest, putRequest } from "../axios/axios";
import { edit as editData } from "../reducers/dataSlice";
import store from "../store";
import { toast } from "react-toastify";

const makeUrl = (url) => {
  const { REACT_APP_KEYCLOCK_HOST } = process.env;
  return REACT_APP_KEYCLOCK_HOST + url;
};

export const createUser = (body) => {
  const structure_id = sessionStorage.getItem("structure_id");
  return postRequest(makeUrl("management?management_action=adduser&structure_id=" + structure_id), body);
};

export const editUser = (data, callback) => {
  const structure_id = sessionStorage.getItem("structure_id");
  putRequest(makeUrl("management?mangement_action=edituser&structure_id=" + structure_id), data).then((res) => {
    store.dispatch(editData({ type: "EDIT_USER", id: data.id, updates: data.payload }));
    callback(res);
  });
};

export const resetPwd = (data, callback) => {
  const structure_id = sessionStorage.getItem("structure_id");
  postRequest(makeUrl("management?mangement_action=regenerateuser&structure_id=" + structure_id), data)
    .then((res) => {
      callback(res);
      toast.success("Email de réinitialisation de mot de passe envoyé.");
    })
    .catch(() => {
      toast.error("Utilisation introuvable");
    });
};

export const updatePreference = (preference, userId) => putRequest(makeUrl("preference?userId=" + userId), preference);
