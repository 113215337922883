import { useState } from "react";
import Button from "../../components/Selectors/Button";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/Selectors/TextInput";
import { setComputationParams } from "../../reducers/EventSlice";
import { parser } from "mathjs";
import { toast } from "react-toastify";
import { variableField } from "../indicator/utils";

const EventFormulaVerification = ({ type }) => {
  const [toastId, setToastId] = useState("" | 0);
  const dispatch = useDispatch();
  const module = useSelector((state) => state.event.data[type].computation_params);
  const data_params = useSelector((state) => state.event.data[type].data_params);
  const checkFormula = () => {
    const parse = parser();
    variableField(data_params).forEach((obj) => parse.set(obj.value, 1));
    try {
      parse.evaluate(module.formula);
      toast.dismiss(toastId);
      setToastId(toast.info("Formule valide"));
    } catch (e) {
      toast.dismiss(toastId);

      if (e instanceof SyntaxError) setToastId(toast.error("Formule incorrect"));
      else {
        const wrongVariable = e.message.split(" ")[2];
        setToastId(toast.error("Variable incorrect: " + wrongVariable));
      }
    }
  };

  return (
    <div>
      <TextInput
        label={
          <div>
            Formule{" "}
            <Button callback={checkFormula} label={"Vérifier"} extraStyle={"px-2 rounded-md"}>
              Vérifier
            </Button>
          </div>
        }
        name={"formula"}
        value={module.formula}
        onChange={(name, value) =>
          dispatch(
            setComputationParams({
              type: type,
              type2: name,
              value: value,
            }),
          )
        }
      />
    </div>
  );
};
export default EventFormulaVerification;
