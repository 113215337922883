import { useSelector } from "react-redux";
import { chartData, formatBits } from "./chart.utils";
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/common";

const CrudeSourceSizeChart = () => {
  const { t } = useTranslation(["chart"]);
  const { crude_source } = useSelector((state) => state.data);
  if (crude_source.length === 0) return null;
  const description = crude_source.map((item) => item.name);
  const storageTypes = crude_source.map((item) => item.storage_type);
  const storageSizes = crude_source.map((item) => parseFloat(item.storage_size));
  const records = crude_source.map((item) => item.number_of_records);
  const storageTotalSize = storageSizes.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const sum = records.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const tooltipFormatter_storage = (params) => {
    const { dataIndex, value, percent, name } = params;
    return `<h1>${name}</h1><p><b> ${formatBits(value)} </b>(${percent}%)</p> <p><i>${storageTypes[dataIndex]}</i></p>`;
  };
  const data_storage = crude_source.map((item) => {
    return { name: item.name, value: item.storage_size };
  });

  const tooltipFormatter_number = (params) => {
    const { dataIndex, value, percent, name } = params;
    return `<h1>${name}</h1><p><b> ${value} </b> ${t("data")} (${percent}%)</p> <p><i>${storageTypes[dataIndex]}</i></p>`;
  };
  const data_number = crude_source.map((item) => {
    return { name: item.name, value: item.number_of_records };
  });

  return (
    <div className="m-4 flex h-[450px] justify-center rounded-lg border border-gray-200 bg-white">
      <div className={"h-full w-full py-2"}>
        <div className={"mb-1"}>
          <p className={"text-center font-semibold text-blue-900"}>{capitalizeFirstLetter(t("crude_source_storage"))}</p>
        </div>
        <div className={"flex items-center text-center"}>
          <div className={"flex-1"}>
            <ReactECharts option={chartData(tooltipFormatter_storage, data_storage, description)} />
            <p className={"text-xs"}>{t("title_mb")}</p>
          </div>
          <div className={"flex-1"}>
            <ReactECharts option={chartData(tooltipFormatter_number, data_number, description)} />
            <p className={"text-xs"}>{t("title_data")}</p>
          </div>
        </div>
        <div className={"mt-5"}>
          <p className={"text-center font-semibold text-blue-700"}>{capitalizeFirstLetter(t("general_info"))}</p>
          <div className={"flex justify-center text-center font-mono text-xs"}>
            <div className={"text-left"}>
              <p>
                <span className={"font-bold"}>{t("total_title_mb")} :</span> {formatBits(storageTotalSize)}
              </p>
              <p>
                <span className={"font-bold"}>{t("total_title_data")} :</span> {sum}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrudeSourceSizeChart;
