import { flexRender } from "@tanstack/react-table";

export function TableBodySelectable({ table, callback, selected }) {
  return (
    <tbody className="max-h-96 overflow-visible bg-white">
      {table.getRowModel().rows.map((row) => {
        let className = "px-2 py-2 border overflow-visible dark:bg-gray-900 text-justify ";
        if (selected === row.original[0]) className = "px-2 py-2 border overflow-visible dark:bg-gray-900 text-justify text-theme2";
        return (
          <tr className={"cursor-pointer odd:bg-gray-50"} key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                onClick={() => {
                  if (cell.column.columnDef.clickable) callback(row);
                }}
                className={className}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
}
