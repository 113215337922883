export function getObjectPaths(obj, path = "") {
  return Object.keys(obj).reduce((acc, key) => {
    const newPath = path ? `${path}/${key}` : key;
    if (Array.isArray(obj[key]) && obj[key].length > 0 && typeof obj[key][0] === "object") {
      return acc.concat(getObjectPaths(obj[key][0], newPath));
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      return acc.concat(getObjectPaths(obj[key], newPath));
    } else {
      return acc.concat({ label: newPath, value: newPath });
    }
  }, []);
}

export function transformObject(obj) {
  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key] = [obj[key][0]];
    } else if (typeof obj[key] === "object") {
      transformObject(obj[key]);
    }
  }
  return obj;
}

export const selectedFieldsToApiResponse = (selectedFields, response, firstMultiple) =>
  selectedFields.map((selectedField) => {
    const selectedFieldArray = selectedField.label.split("/");
    const multiple = isArray(selectedFieldArray, response);
    if (selectedFieldArray.length === 1)
      return {
        source_field: "",
        multiple: firstMultiple || multiple,
        variables: {
          [selectedFieldArray[0]]: "",
        },
      };
    if (selectedFieldArray.length === 2)
      return {
        source_field: selectedFieldArray[0],
        multiple: firstMultiple || multiple,
        variables: {
          [selectedFieldArray[1]]: "",
        },
      };
    else
      return {
        source_field: selectedFieldArray[0],
        multiple: firstMultiple || multiple,
        variables: {
          [selectedFieldArray[selectedFieldArray.length - 1]]: removeFirstAndLastJoinWithSymbol(selectedFieldArray),
        },
      };
  });

function removeFirstAndLastJoinWithSymbol(arr) {
  // Check if the array has at least two elements
  if (arr.length >= 2) {
    // Remove the first and last elements
    arr.shift();
    arr.pop();

    // Join the elements with "$%$"
    const result = arr.join("$%$");

    return result;
  } else {
    // Handle cases where the array has less than two elements
    console.error("Array should have at least two elements.");
    return null;
  }
}

function isArray(labels, obj) {
  for (const label of labels) {
    if (obj[label] && Array.isArray(obj[label])) {
      return true;
    }
  }
  return false;
}
