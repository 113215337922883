import { toast } from "react-toastify";

export const dataLoadingToast = ({
  id = "loading-toast",
  label = "Chargement des données",
  success = false,
  error = false,
  errorMessage = "Une erreur est survenue",
  successMessage = "Chargement terminé",
  isLoading = false,
}) => {
  if (success) {
    if (!toast.isActive(id))
      toast.success(successMessage, {
        toastId: id,
        hideProgressBar: false,
        autoClose: 5000,
      });
    toast.update(id, {
      render: successMessage,
      type: toast.TYPE.SUCCESS,
      hideProgressBar: false,
      autoClose: 5000,
      isLoading: isLoading,
    });
  } else if (error)
    toast.update(id, {
      render: <p className={"truncate"}> {errorMessage}</p>,
      type: toast.TYPE.ERROR,
      hideProgressBar: false,
      autoClose: 5000,
      isLoading: isLoading,
    });
  else {
    if (!toast.isActive(id)) toast.info(label, { toastId: id, containerId: "loadingToast", isLoading: isLoading });
    else
      toast.update(id, {
        type: toast.TYPE.INFO,
        hideProgressBar: true,
        autoClose: false,
        isLoading: isLoading,
        render: label,
      });
  }
};
