import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import { Fragment, useState } from "react";
import Select from "react-select";
import ActionButtons from "../Selectors/ActionButtons";
import { Dialog, Transition } from "@headlessui/react";
import { handleTypeChange, typeRender } from "../../utils/fileUtils";
import { DeleteButton, PrimaryButton } from "../Buttons/Button";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { useTranslation } from "react-i18next";

const FileInformationList = ({ file, handleClear, data, setData, onFileChange }) => {
  const { t } = useTranslation(["common", "button"]);
  const mappingTypeField = [
    {
      label: t("numeric"),
      options: [
        { value: "int", label: t("int") },
        { value: "float", label: t("float") },
      ],
    },
    {
      label: t("categorical"),
      options: [
        { value: "nominal", label: t("nominal") },
        { value: "ordered", label: t("ordered") },
      ],
    },
    {
      label: t("date"),
      options: [{ value: "date", label: t("date") }],
    },
    {
      label: t("text"),
      options: [
        { value: "string", label: t("string") },
        { value: "url", label: t("url") },
      ],
    },
  ];
  const [selected, setSelected] = useState(null);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row.column, {
      id: "header",
      header: t("name"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "type",
      header: t("type"),
      cell: (info) => {
        const row = info.row.original;
        const index = info.row.index;
        return (
          <Select
            hideSelectedOptions
            onChange={(newValue) => handleTypeChange(newValue.value, index, data, setData)}
            styles={{
              menuPortal: (base) => {
                return { ...base, zIndex: 9999 };
              },
            }}
            getOptionValue={(option) => option.value} // This line is important
            value={mappingTypeField.reduce((selected, option) => {
              const found = option.options.find((opt) => opt.value === row.type);
              return found ? found : selected;
            }, null)}
            menuPortalTarget={document.body}
            options={mappingTypeField}
          />
        );
      },
    }),
    columnHelper.display({
      id: "values",
      header: t("value"),
      cell: (info) => {
        const name = info.row.original.column;
        let id = 0;
        const values = data.preview.map((value) => {
          const beforeIncr = id;
          ++id;
          return {
            value: beforeIncr + value[name],
            label: value[name],
          };
        });
        return (
          <Select
            className={""}
            classNames={{
              multiValue: () => "font-semibold text-primary-theme-900 px-2",
            }}
            components={{
              MultiValueRemove: () => null,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            isDisabled
            isMulti
            value={values}
            options={values}
          />
        );
      },
    }),
    columnHelper.display({
      id: "actions",
      header: t("actions"),
      cell: (info) => (
        <ActionButtons
          handleEdit={() => {
            setSelected(info.row.index);
          }}
          canEdit={true}
        />
      ),
    }),
  ];
  const table = useReactTable({
    data: data.col_type,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-end lg:flex-row">
        <div className="mt-3 flex-row items-center gap-x-1.5 px-3 sm:flex lg:mt-0">
          <label className="inline-flex items-center rounded bg-blue-900 px-4 py-2 font-semibold text-blue-50 ring-0 hover:bg-blue-950 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm lg:text-base">
            {t("select_file", { ns: "button" })}
            <p className={"ml-2 font-light italic"}>{file && file.name}</p>

            <input type="file" accept=".csv" className="hidden" onChange={onFileChange} />
          </label>
          {file && <DeleteButton label={<Icon path={mdiClose} size={1} />} callback={handleClear} />}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
      {selected !== null && <EditModal data={data} setData={setData} selected={selected} setSelected={setSelected} />}
    </div>
  );
};

const EditModal = ({ selected, setSelected, data, setData }) => {
  const { t } = useTranslation(["common", "button"]);
  const closeModal = () => setSelected(null);
  return (
    <Transition appear show={selected !== null} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="flex justify-between py-2 text-2xl font-semibold text-blue-900">
                  {t("EDIT_MODAL_DATA_PARAM")}
                  <DeleteButton callback={closeModal} label={<Icon path={mdiClose} className={"h-4 w-4"} />} />
                </Dialog.Title>
                <div className="text-center text-blue-900">{typeRender(data.col_type[selected].type, selected, data, closeModal, setData)}</div>
                <div className="mt-2 flex flex-col justify-end lg:flex-row">
                  <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
                    <PrimaryButton callback={closeModal} label={t("validate", { ns: "button" })} />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default FileInformationList;
