import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { DeleteButton } from "../Buttons/Button";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

const FileUploadList = ({ selectedFile, handleClear, data, header, onFileChange, columnMap, setColumnMap }) => {
  const { t } = useTranslation(["common", "button"]);
  const options = header.map((value) => {
    return { value: value, label: value };
  });
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row[0], {
      id: "header",
      header: t("name"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row[1].type, {
      id: "type",
      header: t("type"),
      cell: (info) => t(info.getValue()),
    }),
    columnHelper.display({
      id: "target",
      header: t("target"),
      cell: (info) => {
        const row = info.row.original;
        return (
          <Select
            value={options.find((value) => value.value === columnMap[row[0]])}
            styles={{
              menuPortal: (base) => {
                return { ...base, zIndex: 9999 };
              },
            }}
            menuPortalTarget={document.body}
            options={options}
            onChange={(newValue) => setColumnMap({ ...columnMap, [row[0]]: newValue.value })}
          />
        );
      },
    }),
  ];
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3" />
        <div className="mt-3 w-1/6 flex-row items-center px-3 sm:flex lg:mt-0" />
        <div className="mt-3 flex-row items-center gap-x-1.5 px-3 sm:flex lg:mt-0">
          <label className="inline-flex items-center rounded bg-blue-900 px-4 py-2 font-semibold text-blue-50 ring-0 hover:bg-blue-950 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm lg:text-base">
            {t("select_file", { ns: "button" })}
            <p className={"ml-2 font-light italic"}>{selectedFile && selectedFile.name}</p>

            <input type="file" accept=".csv" className="hidden" onChange={onFileChange} />
          </label>
          {selectedFile && <DeleteButton label={<Icon path={mdiClose} size={1} />} callback={handleClear} />}
        </div>
      </div>

      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};
export default FileUploadList;
