import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModelParams } from "../../reducers/EventSlice";
import Select from "react-select";
import { makeVariableField } from "../../utils/common";
import NumericInput from "../../components/Inputs/NumericInput";

const ModelParamCreation = () => {
  const dispatch = useDispatch();

  const params = useSelector((state) => state.event.data.model_params);
  const data_params = useSelector((state) => state.event.data.current.data_params);
  const sources = useSelector((state) => state.event.source);
  const [variables, setVariables] = useState([]);
  const [timeVariables, setTimeVariables] = useState([]);
  const configurations = useSelector((state) => state.data.configured_source);
  useEffect(() => {
    const tmp = [];
    const sourceIds = [];
    setVariables([]);
    data_params.forEach((bloc) => {
      const measure_identifier = bloc.measure_identifier;
      sourceIds.push(
        sources.find((value) => measure_identifier === configurations.find((configuration) => value.configuration[0] === configuration.id).measure_identifier)
          .id,
      );
      Object.keys(bloc.preprocess).forEach((variable) =>
        tmp.push({
          label: variable,
          value: variable,
        }),
      );
    });

    // if (!tmp.includes({
    //     value: params.target_variable, label: params.target_variable
    // }))
    dispatch(setModelParams({ type: "target_variable", value: "" }));
    setVariables(tmp);
    sourceIds.length !== 0
      ? setTimeVariables(makeVariableField(1, sourceIds[0], sources.find((source) => sourceIds[0] === source.id).measure))
      : setTimeVariables([]);
  }, [configurations, data_params, dispatch, sources]);

  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <h2 className="text-2xl font-semibold text-blue-900">Paramètres du modèle</h2>
      <div>
        <label className="text-sm font-bold tracking-wide text-gray-700">Timefield</label>
        <Select
          options={timeVariables}
          value={timeVariables.length === 0 ? null : timeVariables[timeVariables.findIndex((value) => value.value === params.time_field)]}
          onChange={(option) => {
            dispatch(setModelParams({ type: "time_field", value: option.label }));
          }}
          placeholder={"Index de temps..."}
        />
      </div>

      <NumericInput
        name={"threshold"}
        min={0}
        max={1}
        step={0.01}
        label={"Seuil"}
        value={params.threshold}
        onChange={(name, value) => dispatch(setModelParams({ type: name, value: parseFloat(value) }))}
      />
      <div>
        <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"Variable cible"}</label>
        <Select
          value={variables.length === 0 ? null : variables[variables.findIndex((value) => value.value === params.target_variable)]}
          isSearchable={true}
          onChange={(option) => {
            dispatch(setModelParams({ type: "target_variable", value: option.label }));
          }}
          options={variables}
        />
      </div>
    </div>
  );
};
export default ModelParamCreation;
