import TextInput from "../../../components/Selectors/TextInput";

const TimeParamView = ({ data }) => {
  const current = data.parameters.current.time_params;
  const historical = data.parameters.historical.time_params;
  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <h2 className="text-2xl font-semibold text-blue-900">Paramètres de temps</h2>
      <div className="grid grid-cols-2 gap-4">
        <div className="px-4">
          <div className="grid grid-cols-2">
            <h2 className="text-xl font-semibold text-blue-900">Courant</h2>
          </div>
          <div className={"grid grid-cols-3 gap-4"}>
            <TextInput name={"bucket_size"} type={"number"} value={current.bucket_size} disabled={true} label={"Taille du bucket"} step={"1"} />
            <TextInput name={"start_time"} value={current.start_time} disabled={true} label={"Date de début"} />
            <TextInput name={"end_time"} value={current.end_time} disabled={true} label={"Date de fin"} />
          </div>
        </div>
        <div className="px-4">
          <div className="grid grid-cols-2">
            <h2 className="text-xl font-semibold text-blue-900">Historique</h2>
          </div>
          <div className={"grid grid-cols-3 gap-4"}>
            <TextInput name={"bucket_size"} value={historical.bucket_size} label={"Taille du bucket"} type={"number"} step={"1"} disabled={true} />
            <TextInput name={"delay"} value={historical.count ? historical.count.delay : historical.time.delay} label={"Délai"} type={"number"} step={"1"} />
            <TextInput
              name={"length"}
              value={historical.count ? historical.count.length : historical.time.length}
              label={"Taille"}
              type={"number"}
              step={"1"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TimeParamView;
