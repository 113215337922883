import TimezoneSelect from "react-timezone-select";
import { useDispatch, useSelector } from "react-redux";
import { setModuleData, setModuleParams } from "../../../reducers/indicatorSlice";
import Select from "react-select";
import { getOptionByValue, makeVariableField } from "../../../utils/common";
import { useEffect, useState } from "react";
import NumericInput from "../../../components/Inputs/NumericInput";
import TimeInput from "../../../components/Inputs/TimeInput";

const DetectionAnomalieParameters = () => {
  const dispatch = useDispatch();
  const { crude_source, configured_source } = useSelector((state) => state.data);
  const data_params = useSelector((state) => state.indicator.moduleData.current.data_params);
  const { module_name, module_params, initial_timestamp, timezone, execution_interval } = useSelector((state) => state.indicator.moduleData);
  const [variables, setVariables] = useState([]);
  const [timeVariables, setTimeVariables] = useState([]);
  useEffect(() => {
    const tmp = [];
    const sourceIds = [];
    setVariables([]);
    data_params.forEach((bloc) => {
      const measure_identifier = bloc.measure_identifier;
      sourceIds.push(
        crude_source.find((value) => measure_identifier === configured_source.find((conf) => conf.id === value.configuration[0]).measure_identifier).id,
      );
      Object.keys(bloc.preprocess).forEach((variable) =>
        tmp.push({
          label: variable,
          value: variable,
        }),
      );
    });
    // if (!tmp.includes({
    //     value: params.target_variable, label: params.target_variable
    // }))
    setVariables(tmp);
    sourceIds.length !== 0
      ? setTimeVariables(makeVariableField(3, sourceIds[0], crude_source.find((source) => source.id === sourceIds[0]).measure))
      : setTimeVariables([]);
  }, [configured_source, data_params, crude_source]);
  console.debug(timeVariables);
  const param = () => {
    switch (module_name) {
      case "kalmanvalidation":
        return (
          <div>
            <div className="grid grid-cols-2 gap-1.5">
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-900">Variable cible</label>
                <Select
                  onChange={(newValue) => dispatch(setModuleParams({ key: "target_variable", value: newValue.value }))}
                  value={variables.find((variable) => variable.value === module_params.target_variable)}
                  options={variables}
                />
              </div>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-900">Variable temporel</label>
                <Select
                  onChange={(newValue) => dispatch(setModuleParams({ key: "time_field", value: newValue.value }))}
                  value={getOptionByValue(timeVariables, module_params.time_field)}
                  options={timeVariables}
                />
              </div>
              <NumericInput
                min={0}
                max={1}
                step={0.01}
                label={"Seuil"}
                type={"number"}
                value={module_params.threshold}
                onChange={(name, value) => dispatch(setModuleParams({ key: name, value: value }))}
                name={"threshold"}
              />
              <NumericInput
                min={0}
                step={60}
                label={"Interval d'execution (s)"}
                type={"number"}
                value={execution_interval}
                name={"execution_interval"}
                onChange={(name, value) => {
                  dispatch(setModuleData({ key: name, value: value }));
                }}
              />
            </div>
            <div className={"flex gap-x-0.5"}>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-900">Date de début</label>
                <TimeInput
                  value={initial_timestamp}
                  name={"initial_timestamp"}
                  onChange={(name, value) => {
                    dispatch(setModuleData({ key: name, value: value }));
                  }}
                />
              </div>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-900">Fuseau horaire</label>
                <TimezoneSelect
                  value={timezone}
                  onChange={(e) => {
                    dispatch(setModuleData({ key: "timezone", value: e.value }));
                  }}
                />
              </div>
            </div>
          </div>
        );
      default:
        return <p> N/A</p>;
    }
  };
  return param();
};
export default DetectionAnomalieParameters;
