import Icon from "@mdi/react";
import { mdiLoading, mdiMenuLeft, mdiRefresh } from "@mdi/js";
import { useNavigate } from "react-router-dom";

/**
 * PrimaryButton component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} [props.disabled=false] - Whether the button is disabled.
 * @param {boolean} [props.isLoading=false] - Whether the button is in loading state.
 * @param {string|JSX.Element} [props.label=""] - The label text or JSX content for the button.
 * @param {function} props.callback - The function to be called when the button is clicked.
 * @returns {JSX.Element} The rendered PrimaryButton component.
 */
const PrimaryButton = ({ disabled = false, isLoading = false, label = "", callback }) => (
  <button
    type={"button"}
    disabled={disabled}
    className="inline-flex items-center rounded bg-blue-900 px-4 py-2 font-semibold text-blue-50 ring-0 hover:bg-blue-950 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm lg:text-base"
    onClick={callback}
  >
    {isLoading && (
      <div className={"animate-spin"}>
        <Icon path={mdiLoading} size={1} />
      </div>
    )}
    {label}
  </button>
);

const SecondaryButton = ({ disabled = false, isLoading = false, label = "", callback }) => (
  <button
    type={"button"}
    disabled={disabled}
    className=" inline-flex items-center rounded border border-gray-300 px-4 py-2 font-bold text-gray-700 hover:bg-gray-100 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm lg:text-base"
    onClick={callback}
  >
    {isLoading && (
      <div className={"animate-spin"}>
        <Icon path={mdiLoading} size={1} />
      </div>
    )}
    {label}
  </button>
);

const TertiaryButton = ({ disabled = false, isLoading = false, label = "", callback }) => (
  <button
    type={"button"}
    disabled={disabled}
    className="inline-flex items-center gap-x-2 rounded border border-blue-800 px-4 py-2 font-semibold  text-blue-500  hover:underline disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm lg:text-base"
    onClick={callback}
  >
    {isLoading && (
      <div className={"animate-spin"}>
        <Icon path={mdiLoading} size={1} />
      </div>
    )}
    {label}
  </button>
);

const DeleteButton = ({ disabled = false, isLoading = false, label = "", callback }) => (
  <button
    type={"button"}
    disabled={disabled}
    className="inline-flex items-center rounded bg-red-600 px-4 py-2 font-semibold text-red-50 ring-0 hover:bg-red-700 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm lg:text-base"
    onClick={callback}
  >
    {isLoading && (
      <div className={"animate-spin"}>
        <Icon path={mdiLoading} size={1} />
      </div>
    )}
    {label}
  </button>
);
const RefreshButton = ({ disabled = false, isLoading = false, callback }) => (
  <button
    type={"button"}
    disabled={disabled}
    className="block rounded-lg border border-neutral-300 bg-neutral-50 px-4 text-sm text-neutral-900 focus:border-blue-500 focus:ring-blue-500"
    onClick={callback}
  >
    {isLoading ? (
      <div className={"animate-spin"}>
        <Icon path={mdiRefresh} size={1} />
      </div>
    ) : (
      <div className={""}>
        <Icon path={mdiRefresh} size={1} />
      </div>
    )}
  </button>
);

const MdiButton = ({ disabled = false, isLoading = false, callback, icon }) => (
  <button
    type={"button"}
    disabled={disabled}
    className="block rounded-lg border border-neutral-300 bg-neutral-50 px-4 text-sm text-neutral-900 focus:border-blue-500 focus:ring-blue-500"
    onClick={callback}
  >
    {isLoading ? (
      <div className={"animate-spin"}>
        <Icon path={icon} size={1} />
      </div>
    ) : (
      <div className={""}>
        <Icon path={icon} size={1} />
      </div>
    )}
  </button>
);

const PrevButton = ({ url }) => {
  const history = useNavigate();
  return (
    <button
      onClick={() => history(url)}
      className={
        "inline-flex items-center rounded bg-blue-900 font-semibold text-blue-50 ring-0 hover:bg-blue-950 disabled:cursor-not-allowed disabled:opacity-50 sm:text-sm lg:text-base"
      }
    >
      <Icon path={mdiMenuLeft} size={1.5} />
    </button>
  );
};

export { PrimaryButton, SecondaryButton, TertiaryButton, DeleteButton, RefreshButton, MdiButton, PrevButton };
