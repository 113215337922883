import { current, historical } from "./EventUtils";
import { useEffect, useState } from "react";
import DataParamCreation from "./DataParamCreation";
import Select from "react-select";
import { getSourceWithMeasure, makeSelectField } from "../../utils/common";
import TimeParamCreation from "./TimeParamCreation";
import ComputationParamCreation from "./ComputationParamCreation";
import ModelParamCreation from "./ModelParamCreation";
import { useDispatch, useSelector } from "react-redux";
import { initEvent, removeDataParams, setBase, setModelParams } from "../../reducers/EventSlice";

import { PrimaryButton } from "../../components/Buttons/Button";
import { postDataApi } from "../../axios/DataManagementApi";
import { push } from "../../reducers/dataSlice";
import store from "../../store";

const KalmanEvent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const selectedSource = useSelector((state) => state.event.selectedSource);
  const data = useSelector((state) => state.event);
  const source = useSelector((state) => state.event.source);
  const sourceId = useSelector((state) => state.event.sourceId);
  const configurations = useSelector((state) => state.data.configured_source);
  useEffect(() => {
    const init = {
      current: structuredClone(current),
      historical: structuredClone(historical),
      model_params: {
        time_field: "",
        target_variable: "",
        threshold: 0.95,
      },
    };
    store.dispatch(
      initEvent({
        data: structuredClone(init),
        source: getSourceWithMeasure("crude_source"),
        selectedSource: null,
        histo: true,
        count: true,
        sourceId: false,
        lockedHisto: true,
      }),
    );
    setLoaded(true);
  }, []);
  const create = () => {
    setIsLoading(true);
    const state = structuredClone(data);
    console.debug(state);
    const formattedData = {};
    formattedData["module_name"] = "Kalmanvalidation";
    formattedData["function_name"] = "process";
    formattedData["parameters"] = state.data;
    const { time_field } = formattedData["parameters"]["model_params"];
    state.count
      ? delete formattedData["parameters"]["historical"]["time_params"]["time"]
      : delete formattedData["parameters"]["historical"]["time_params"]["count"];
    formattedData["parameters"]["current"]["data_params"][0]["preprocess"] = {
      ...formattedData["parameters"]["current"]["data_params"][0]["preprocess"],
      [time_field]: "identity",
    };
    formattedData["parameters"]["historical"]["data_params"][0]["preprocess"] = {
      ...formattedData["parameters"]["historical"]["data_params"][0]["preprocess"],
      [time_field]: "identity",
    };
    console.debug(formattedData);
    postDataApi("event/" + sessionStorage.getItem("structure_id"), formattedData).then((res) => {
      console.debug(res.data.message);
      formattedData["creation_timestamp"] = new Date(Date.now()).toString();
      formattedData["id"] = res.data.id;
      setIsLoading(false);
      dispatch(push({ type: "event", data: formattedData }));
    });
  };
  return (
    <div>
      {loaded && (
        <div>
          <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
            <h2 className="text-2xl font-semibold text-blue-900">Paramètres des données</h2>
            <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Source(s) de données</label>
            <Select
              placeholder={"Source(s) de données..."}
              isClearable={true}
              isMulti
              value={selectedSource}
              options={makeSelectField(source, ["name"])}
              isDisabled={sourceId}
              isLoading={sourceId}
              onChange={(option, action) => {
                if (action.action === "remove-value") {
                  const configured_source_id = source.find((value) => value.id === action.removedValue.value).configuration[0];
                  const measure_identifier = configurations.find((value) => value.id === configured_source_id).measure_identifier;
                  dispatch(removeDataParams(measure_identifier));
                  dispatch(setBase({ type: "selectedSource", value: option }));
                  dispatch(setModelParams({ type: "target_variable", value: "" }));
                  dispatch(setModelParams({ type: "time_field", value: "" }));
                }
                if (action.action === "select-option") {
                  dispatch(setBase({ type: "selectedSource", value: option }));
                  dispatch(setBase({ type: "sourceId", value: action.option.value }));
                }
              }}
            />

            {sourceId && <DataParamCreation />}
          </div>
          <TimeParamCreation />
          <ComputationParamCreation />
          <ModelParamCreation />
        </div>
      )}
      <div className="flex justify-center">
        <PrimaryButton isLoading={isLoading} label={"Créer"} disabled={isLoading} callback={create} />
      </div>
    </div>
  );
};
export default KalmanEvent;
