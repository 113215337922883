import { capitalizeFirstLetter, isEmptyObject, isEmptyString, renderComponent } from "../../utils/common";
import { useSelector } from "react-redux";
import DashBoardHeader from "./DashBoardHeader";
import { COMPONENTS } from "../../constants/component";
import StoragesystemList from "../Table/StoragesystemList";
import CrudeSourceList from "../Table/CrudeSourceList";
import ConfiguredSourceList from "../Table/ConfiguredSourceList";
import TableWrapper from "../Table/TableWrapper";
import StorageSizeChart from "../Charts/StorageSizeChart";
import CrudeSourceSizeChart from "../Charts/CrudeSourceSizeChart";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import { ObservedSystemTimeLine } from "./ObservedSystemTimeLine";

const DashboardData = () => {
  const selectedCrudeSourceId = useSelector((state) => state.selected.crude_source);
  const crudeSourceList = useSelector((state) => state.data.crude_source);
  const { t } = useTranslation(["common"]);
  const [displayInstance, setDisplayInstance] = useState(false);
  return (
    <div>
      <DashBoardHeader instanceDisplay={displayInstance} setInstanceDisplay={setDisplayInstance} />
      <div className="grid xl:grid-cols-1 2xl:grid-cols-4  ">
        <div className={"col-span-2"}>{renderComponent(<ObservedSystemTimeLine component_id={COMPONENTS.OBSERVED_SYSTEM_DISPLAY} />)}</div>
        <div className={"col-span-2"}>
          {!isEmptyObject(crudeSourceList) && renderComponent(<CrudeSourceSizeChart component_id={COMPONENTS.CRUDE_SOURCE_DISPLAY} />)}
        </div>

        {/*<div className={"col-span-1"}>*/}
        {/*  {!isEmptyObject(crudeSourceList) && renderComponent(<CrudeSourceRecordsChart component_id={COMPONENTS.CRUDE_SOURCE_DISPLAY} />)}*/}
        {/*</div>*/}

        <div className={"col-span-2"}>
          {!isEmptyObject(crudeSourceList) &&
            renderComponent(
              <TableWrapper label={capitalizeFirstLetter(t("crude_sources"))} component_id={COMPONENTS.CRUDE_SOURCE_DISPLAY} component={<CrudeSourceList />} />,
            )}
        </div>
        <div className={"col-span-2"}>
          {!isEmptyString(selectedCrudeSourceId) &&
            renderComponent(
              <TableWrapper
                label={capitalizeFirstLetter(t("configured_sources"))}
                component_id={COMPONENTS.CONFIGURED_SOURCE_DISPLAY}
                component={<ConfiguredSourceList />}
              />,
            )}
        </div>
        {!isEmptyString(selectedCrudeSourceId) && <DemoGraph crudeSourceId={selectedCrudeSourceId} />}

        {displayInstance && (
          <Fragment>
            <div className={"col-span-2"}>{renderComponent(<StorageSizeChart component_id={COMPONENTS.INSTANCE_DISPLAY} />)}</div>
            {/*{renderComponent(<StorageTableChart component_id={COMPONENTS.INSTANCE_DISPLAY} />)}*/}
            <div className={"col-span-2 h-fit"}>
              {renderComponent(
                <TableWrapper label={capitalizeFirstLetter(t("instances"))} component_id={COMPONENTS.INSTANCE_DISPLAY} component={<StoragesystemList />} />,
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DashboardData;

const DemoGraph = ({ crudeSourceId }) => {
  switch (crudeSourceId) {
    case "5224c424-aee3-4afd-8c2d-58971bcc937a":
      return (
        <Fragment>
          <div className="col-span-2 m-4 h-[400px] justify-center rounded-lg border border-gray-200 bg-white">
            <iframe
              className="h-[350px] w-full"
              src={
                "https://grafana.prototype5.research.preditic.com/d-solo/f8956285-018b-463f-bfc8-b38d7fad61ba/prototype-5-interface-web-other-graphics-sh-1698921613835820719?orgId=1&theme=light&panelId=1&from=now%2Fd&to=now%2Fd"
              }
            />
          </div>
          <div className="col-span-2 m-4 h-[400px] justify-center rounded-lg border border-gray-200 bg-white">
            <iframe
              className="h-[350px] w-full"
              src={
                "https://grafana.prototype5.research.preditic.com/d-solo/ba21f473-75c1-4718-a682-c1318c8e5382/prototype-5-interface-web-other-graphics-sh-1698921907236329118?orgId=1&theme=light&panelId=1&from=now%2Fd&to=now%2Fd"
              }
            />
          </div>
        </Fragment>
      );
    case "de080de0-798b-4da1-af47-1e87ecf40258":
      return (
        <Fragment>
          <div className="col-span-2 m-4 h-[400px] justify-center rounded-lg border border-gray-200 bg-white">
            <iframe
              className="h-[350px] w-full "
              src={
                "https://grafana.prototype4.research.preditic.com/d-solo/mi5r1O4Iz/prototype-4-interface-web-1698921319459043078?orgId=1&refresh=5s&theme=light&panelId=0&from=1696111200000&to=1698620400000"
              }
            />
          </div>
          <div className="col-span-2 m-4 h-[400px] justify-center rounded-lg border border-gray-200 bg-white">
            <iframe
              className="h-[350px] w-full "
              src={
                "https://grafana.prototype5.research.preditic.com/d-solo/d9a4336c-3411-4311-8851-02a7b84ecb06/prototype-5-interface-web-other-graphics-sh-1698921489831223885?orgId=1&theme=light&panelId=1&from=now%2Fd&to=now%2Fd"
              }
            />
          </div>
        </Fragment>
      );
  }
};
