import TextInput from "../../../components/Selectors/TextInput";

export const ComputationParamView = ({ data }) => {
  console.debug(data.parameters);
  const current = data.parameters.current.computation_params;
  const historical = data.parameters.historical.computation_params;
  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <h2 className="text-2xl font-semibold text-blue-900">Paramètres de calcul</h2>
      <div className="grid grid-cols-2 gap-4">
        <div className="px-4">
          <div className="grid grid-cols-2">
            <h2 className="text-xl font-semibold text-blue-900">Courant</h2>
          </div>
          <TextInput name={"formula"} value={current.formula} label={"Formule"} disabled={true} />
        </div>
        <div className="px-4">
          <div className="grid grid-cols-2">
            <h2 className="text-xl font-semibold text-blue-900">Historique</h2>
          </div>

          <TextInput name={"formula"} value={historical.formula} label={"Formule"} disabled={true} />
        </div>
      </div>
    </div>
  );
};
