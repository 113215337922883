import BasicTooltip from "../Tooltip/BasicTooltip";

const Checkbox = ({
  color = "dark:bg-gray-900",
  disabled = false,
  name,
  label,
  checked,
  onChange,
  tooltip,
  style = "form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer",
  value = undefined,
}) => (
  <>
    <div className=" flex items-center gap-1">
      <input
        defaultChecked={checked}
        checked={value}
        disabled={disabled}
        className={`${color}  ${style}  ${disabled ? "pointer-events-none opacity-50" : ""}`}
        name={name}
        type="checkbox"
        onChange={(e) => onChange(e)}
      />
      <label className={"text-sm  font-bold tracking-wide text-gray-700 dark:text-white"}> {label}</label>

      {tooltip && <BasicTooltip tip={tooltip} />}
    </div>
  </>
);
export default Checkbox;
