import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import timeLabelFR from "./locales/fr/TimeLabel.json";
import timeLabelEN from "./locales/en/TimeLabel.json";
import ButtonLabelFR from "./locales/fr/ButtonLabel.json";
import ButtonLabelEN from "./locales/en/ButtonLabel.json";
import CommonFR from "./locales/fr/Common.json";
import CommonEN from "./locales/en/Common.json";
import ChartFR from "./locales/fr/Chart.json";
import ChartEN from "./locales/en/Chart.json";
import ToastEN from "./locales/en/Toast.json";
import ToastFR from "./locales/fr/Toast.json";
import TooltipEN from "./locales/en/Tooltip.json";
import TooltipFR from "./locales/en/Tooltip.json";
import TransformersFR from "./locales/fr/Transformers.json";
import TransformersEN from "./locales/en/Transformers.json";

const resources = {
  en: {
    timelabel: timeLabelEN,
    button: ButtonLabelEN,
    common: CommonEN,
    chart: ChartEN,
    toast: ToastEN,
    tooltip: TooltipEN,
    transformers: TransformersEN,
  },
  fr: {
    timelabel: timeLabelFR,
    button: ButtonLabelFR,
    common: CommonFR,
    chart: ChartFR,
    toast: ToastFR,
    tooltip: TooltipFR,
    transformers: TransformersFR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
