import IndicatorDataConfiguration from "../IndicatorDataConfiguration";
import AcpRightPanel from "./AcpRightPanel";

const Acp = () => (
  <div className="mx-4 grid gap-4 py-2 lg:grid-cols-2">
    <IndicatorDataConfiguration />
    <AcpRightPanel />
  </div>
);
export default Acp;
