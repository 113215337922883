import { useEffect, useState } from "react";
import TextInput from "../../components/Selectors/TextInput";
import { canRenderComponent, capitalizeFirstLetter, isEmptyString } from "../../utils/common";
import { storageField } from "../../utils/field";

import Select from "react-select";
import { COMPONENTS } from "../../constants/component";
import { useDispatch, useSelector } from "react-redux";
import { postDataApi } from "../../axios/DataManagementApi";
import { toast } from "react-toastify";
import { push } from "../../reducers/dataSlice";
import { useTranslation } from "react-i18next";
import HorizontalLine from "../../components/HorizontalLine";
import { PrimaryButton } from "../../components/Buttons/Button";

const Instance = () => {
  const { t } = useTranslation(["common", "button"]);
  const dispatch = useDispatch();

  const [authorized, setAuthorized] = useState(canRenderComponent(COMPONENTS.INSTANCE_CREATION));
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  useEffect(() => {
    if (!authorized)
      return () => {
        setAuthorized(canRenderComponent(COMPONENTS.INSTANCE_CREATION));
      };
  }, [authorized, authorizedComponent]);

  const [storageSystem, setStorageSystem] = useState(-1);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const handleInstance = () => {
    setLoading(true);
    const data = {
      structure_id: sessionStorage.getItem("structure_id"),
      name: name,
      description: description,
      storage_type: storageSystem,
    };
    postDataApi("storage_system?structure_id=" + sessionStorage.getItem("structure_id"), data)
      .then((res) => {
        toast.success("Système de stockage créé");
        const newData = {
          id: res.data.id,
          description: data.description,
          name: data.name,
          instance_id: res.data.credential_data.credential.instance_id,
          number_of_tables: 0,
          raw_storage_size: 0,
          storage_size: "TBD",
          storage_type: storageField.find((sf) => sf.value === storageSystem),
        };
        dispatch(
          push({
            data: newData,
            type: "storage_system",
          }),
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.debug(error);
      });
  };

  const canAdd = storageSystem !== -1 && !isEmptyString(name);

  return (
    authorized && (
      <div className="p-8">
        <div className="w-full rounded-lg border-blue-900 bg-white shadow-md">
          <h3 className="px-4 pt-4 text-2xl font-semibold text-blue-900">{capitalizeFirstLetter(t("instance"))}</h3>
          <h3 className="px-6 pb-4">{capitalizeFirstLetter(t("INSTANCE_DESC"))}</h3>
          <HorizontalLine />
          <div className="grid grid-cols-1 gap-4 p-4 lg:grid-cols-3">
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("storage"))}</label>
              <Select options={storageField} onChange={(e) => setStorageSystem(e.value)} name="storage_type" />
            </div>
            <div>
              <TextInput required label={capitalizeFirstLetter(t("name"))} name={"name"} value={name} onChange={(e, v) => setName(v)} />
            </div>
            <div>
              <TextInput label={capitalizeFirstLetter(t("description"))} name={"description"} value={description} onChange={(e, v) => setDescription(v)} />
            </div>
          </div>
          <div className="px-4 pb-4 lg:flex lg:justify-end">
            <PrimaryButton isLoading={loading} label={t("create", { ns: "button" })} callback={handleInstance} disabled={!canAdd} />
          </div>
        </div>
      </div>
    )
  );
};
export default Instance;
