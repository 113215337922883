import IndicatorAccordion from "../IndicatorAccordion";
import CorrelationParam from "./CorrelationParam";
import ConfigurationAlerteFormula from "../ConfigurationAlerte/ConfigurationAlerteFormula";

const CorrelationRightPanel = () => (
  <div className="block rounded border border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-800 ">
    <IndicatorAccordion label={"Paramètres de calcul"} composant={<ConfigurationAlerteFormula mode={"current"} />} />
    <IndicatorAccordion label={"Paramètres"} composant={<CorrelationParam />} />
  </div>
);
export default CorrelationRightPanel;
