import { useSelector } from "react-redux";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Fragment, useCallback, useState } from "react";
import Select from "react-select";
import { dataList, makeSelectField } from "../../utils/common";
import TextInput from "../../components/Selectors/TextInput";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "../../components/Buttons/Button";
import { mdiAccount } from "@mdi/js";
import Icon from "@mdi/react";
import { postUserApi, putUserApi } from "../../axios/UserManagementApi";
import { toast } from "react-toastify";
import { getDataApi } from "../../axios/DataManagementApi";
import store from "../../store";
import { set } from "../../reducers/dataSlice";

const UserEdit = () => {
  const dataloaded = useLoaderData();
  console.debug(dataloaded);
  const history = useNavigate();
  const [user, setUser] = useState(dataloaded);
  const profiles = useSelector((state) => state.data.profiles);
  const profileOption = useCallback(() => makeSelectField(profiles, ["name"]), [profiles]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoadingEnable, setIsLoadingEnable] = useState(false);
  const structures = useSelector((state) => state.data.structures);

  const structuresOption = useCallback(() => {
    if (structures.length === 0)
      getDataApi("structure")
        .then((res) =>
          store.dispatch(
            set({
              type: "structures",
              data: dataList(res.data),
            }),
          ),
        )
        .catch((error) => console.debug(error));
    return makeSelectField(structures, ["description", "name"]);
  }, [structures]);
  const handleEditUser = () => {
    setIsLoading2(true);
    const payload = {
      id_role: user["id_role"],
      structure_id: user["structure_id"],
      lastname: user["lastname"],
      firstname: user["firstname"],
      id: user["id"],
    };
    const updates = {
      payload: payload,
      id: user["id_keycloak"],
    };
    const structure_id = sessionStorage.getItem("structure_id");
    console.debug(user);
    putUserApi("management?management_action=edituser&structure_id=" + structure_id, updates)
      .then((res) => {
        console.debug(res);
        setIsLoading2(false);
        toast.success("Utilisateur modifié avec succès");
      })
      .catch((error) => {
        console.debug(error);
        setIsLoading2(false);
        toast.error("Utilisateur non modifié. Une erreur est survenue");
      });
  };
  const onChange = (name, value) => {
    console.debug(name, value);
    setUser({
      ...user,
      [name]: value,
    });
  };
  return (
    <Fragment>
      <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
        <div className="flex gap-x-1.5">
          <IconButton bg={true} Icon={<Prev size={40} />} value={""} callback={() => history("/admin/users")} />
          <h2 className="text-3xl font-semibold text-blue-900">Modification d&apos;un utilisateur</h2>
        </div>
        <div className={"mt-2"}>
          <div className={"grid gap-4 lg:grid-cols-2"}>
            <TextInput name={"email"} value={user?.email} disabled={true} onChange={onChange} label={"Adresse email"} type={"email"} />
            <TextInput name={"firstname"} value={user["firstname"]} onChange={onChange} label={"Prénom"} type={"text"} />
            <TextInput name={"lastname"} value={user["lastname"]} onChange={onChange} label={"Nom"} type={"text"} />
            <div>
              <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">Profile</label>
              <Select
                onChange={(e) => onChange("id_role", e.value)}
                value={profileOption().find((profile) => profile.value === user["id_role"])}
                options={profileOption()}
              />
            </div>
            <div>
              <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">Struture</label>
              <Select
                isClearable
                onChange={(e, actionMeta) => {
                  if (actionMeta.action === "select-option") onChange("structure_id", e.value);
                  if (actionMeta.action === "clear") onChange("structure_id", null);
                }}
                value={structuresOption().find((structure) => structure.value === user["structure_id"])}
                options={structuresOption()}
              />
            </div>
          </div>
        </div>
        <div className={"flex justify-end gap-2 pt-4"}>
          <SecondaryButton label={"Annuler"} callback={() => history("/admin/users")} />
          <PrimaryButton isLoading={isLoading2} label={"Modifier"} callback={() => handleEditUser()} />
        </div>
      </div>
      <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
        <div className="flex gap-x-1.5 ">
          <Icon path={mdiAccount} size={1.5} />
          <h2 className="text-3xl font-semibold text-blue-900">Actions possibles</h2>
        </div>
        <div className={"grid gap-4 pt-2 lg:grid-cols-2"}>
          <div>
            <TertiaryButton
              isLoading={isLoadingEnable}
              disabled={isLoadingEnable}
              callback={() => {
                setIsLoadingEnable(true);
                putUserApi("management?management_action=edituser", {
                  payload: { is_active: !user.is_active, id: user.id },
                  id: user["id_keycloak"],
                })
                  .then((res) => {
                    console.debug(res);
                    onChange("is_active", !user.is_active);
                    setIsLoadingEnable(false);
                  })
                  .catch((error) => {
                    setIsLoadingEnable(false);
                    toast.error(error.response.data.message);
                  });
              }}
              value={user.is_active}
              label={user.is_active ? "Désactiver l'utilisateur" : "Activer l'utilisateur"}
            />
          </div>
          <div>
            <TertiaryButton
              label={"Réinitialiser le mot de passe"}
              callback={() => {
                setIsLoading(true);
                postUserApi("regenerate?user_id=" + user["id_keycloak"], {})
                  .then((res) => {
                    console.debug(res);
                    toast.success("Email de réinitialisation de mot de passe envoyé.");
                    setIsLoading(false);
                  })
                  .catch((e) => {
                    console.debug(e.response);
                    toast.error(e.response.data.message);
                    setIsLoading(false);
                  });
              }}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default UserEdit;
