import Select from "react-select";
import classNames from "classnames";

export const SelectGraph = ({ onChange, value, options, width = "w-48" }) => (
  <Select
    classNames={{
      container: () => "relative",
      control: () => classNames(width, "rounded-lg border border-neutral-300 bg-neutral-50 px-3 py-2 text-sm font-medium text-neutral-900 hover:bg-gray-200"),
      menu: () =>
        classNames(width, "absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"),
      option: (state) => classNames(state.isFocused ? "w-full bg-gray-100" : "", "block w-full px-4 py-2 text-sm text-gray-700"),
    }}
    unstyled
    onChange={onChange}
    value={value}
    options={options}
  />
);
