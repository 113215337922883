import IndicatorRecap from "./IndicatorRecap";
import IndicatorHeader from "./IndicatorHeader";
import IndicatorBody from "./IndicatorBody";
import { initModule } from "./utils";

import { useDispatch, useSelector } from "react-redux";
import { set } from "../../reducers/indicatorSlice";
import { computationField } from "../../utils/field";
import { useState } from "react";
import IndicatorResult from "./IndicatorResult";

const IndicatorCompute = () => {
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.indicator.moduleData);
  const [result, setResult] = useState({});
  return (
    <>
      <IndicatorHeader
        field={computationField}
        title={"Calcul d'un indicateur"}
        set={(e) => {
          console.debug(e);
          setResult({});
          dispatch(set(initModule(e.value)));
        }}
      />
      <IndicatorBody id={name} />
      {name < 20 && <IndicatorRecap label={"Calculer"} setResult={setResult} />} <IndicatorResult type={name} result={result} />
    </>
  );
};
export default IndicatorCompute;
