import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { canRenderAction, dataList, getUserPreferences, isEmptyObject } from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import { getDataApi } from "../../axios/DataManagementApi";
import { set as setData } from "../../reducers/dataSlice";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import LabelTooltip from "../../components/Tooltip/LabelTooltip";
import Icon from "@mdi/react";
import { mdiCancel, mdiCheck } from "@mdi/js";
import ActionButtons from "../../components/Selectors/ActionButtons";
import { TableFilter } from "../../components/Table/TableFilter";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons/Button";
import { set as setModuleData } from "../../reducers/indicatorSlice";
import { current } from "../event/EventUtils";
import dayjs from "dayjs";
import { TableHeader } from "../../components/Table/TableHeader";
import { TableBody } from "../../components/Table/TableBody";
import { TablePagination } from "../../components/Table/TablePagination";

export const PredictionModelList = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const data = useSelector((state) => state.data.prediction);
  const { crude_source, configured_source } = useSelector((state) => state.data);
  const showId = getUserPreferences().find((preference) => preference.name === "showId")?.value ?? true;
  const [canView] = useState(canRenderAction(ACTION_FLAGS.CANVIEW, COMPONENTS.PREDICTION_DISPLAY));
  const update = () => {
    dataLoadingToast({ label: "Mise à jour", isLoading: true });
    setLoading(true);
    getDataApi("training/predict/" + sessionStorage.getItem("observed_system_id"))
      .then((res) => {
        setLoading(false);
        dataLoadingToast({ success: true, successMessage: "Mis à jour" });
        dispatch(
          setData({
            type: "prediction",
            data: dataList(res.data.data),
          }),
        );
      })
      .catch((error) => {
        dataLoadingToast({ error: true, errorMessage: error.response.data.message });
        console.debug(error);
        setLoading(false);
      });
  };
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      cell: (info) => info.getValue(),
      header: "id",
    }),
    columnHelper.accessor("model_id", {
      id: "mid",
      cell: (info) => info.getValue(),
      header: "ID du modèle",
    }),
    columnHelper.display({
      id: "target",
      cell: (info) => {
        const row = info.row.original;
        const { model_name, target, target_configured_source, target_crude_source, target_time_field } = row;
        const display = (
          <div className={"border-l-2 border-l-blue-900 px-1"}>
            <div className={"flex gap-x-1.5 text-base text-blue-900"}>
              <p>{crude_source.find((value) => value.id === target_crude_source).name}</p>
            </div>
            <div className={"flex gap-x-1.5 px-1"}>
              <label className="font-bold tracking-wide text-gray-700 ">Variable cible: </label>
              <p>{target}</p>
            </div>
            <div className={"flex gap-x-1.5 px-1"}>
              <label className="font-bold tracking-wide text-gray-700 ">horodatage: </label>
              <p>{target_time_field}</p>
            </div>
            <div className={"flex gap-x-1.5 px-1"}>
              <label className="font-bold tracking-wide text-gray-700 ">Configuration: </label>
              <p>{configured_source.find((value) => value.id === target_configured_source).description}</p>
            </div>
          </div>
        );
        return <LabelTooltip label={model_name} tip={display} />;
      },
      header: "Modèle",
    }),
    columnHelper.display({
      id: "active",
      cell: (info) => {
        const row = info.row.original;
        const { active, recurrent } = row;
        if (isEmptyObject(recurrent)) return "Simple";
        const { frequency, time_slot, validity_days } = recurrent;
        const days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
        const formatDay = validity_days.split("-").map((day) => days[parseInt(day)]);
        const display = (
          <div>
            {active ? <Icon path={mdiCheck} className={"h-6 w-6 text-green-500"} /> : <Icon path={mdiCancel} className={"h-6 w-6 text-red-500"} />}
            <p>{frequency}min</p>
            <p>{formatDay.join("-")}</p>
            <p>{time_slot}</p>
          </div>
        );
        return <LabelTooltip label={"Récurrent"} tip={display} />;
      },
      header: "Mode",
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <ActionButtons
          canView={canView}
          handleView={() => {
            const row = info.row.original;
            history("graph/" + row.id);
          }}
        />
      ),
    }),
  ];
  if (!showId) columns.splice(0, 1);
  if (!showId) columns.splice(0, 1);

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
        <div className="mt-3 flex items-center gap-x-1.5 px-3 sm:flex lg:mt-0">
          <SecondaryButton isLoading={loading} disabled={loading} callback={update} label="Mettre à jour" />

          <PrimaryButton
            callback={() => {
              dispatch(
                setModuleData({
                  name: 1,
                  timezone: "Europe/Paris",
                  current,
                  prediction_params: {
                    model_id: undefined,
                    target: {
                      origin: "",
                      usage: "",
                    },
                    predictors: {},
                    prediction_mode: 1,
                    opf_mode: 0,
                  },
                  execution_mode: 0,
                  script_name: "prediction",
                  initial_timestamp: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
                  frequency: 5,
                  validity_days: "1-5",
                  time_slot: "9-18",
                  multiple_sources: {},
                  mode: 1,
                }),
              );
              history("/analytics/modele/predict");
            }}
            label="Créer une prediction"
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
        <TablePagination table={table} />
      </div>
    </div>
  );
};
