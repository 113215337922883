import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { formatDateInput } from "../../utils/date.utils";
import UrlInput from "./UrlInput";
import { capitalizeFirstLetter } from "../../utils/common";
import { useTranslation } from "react-i18next";
import HorizontalLine from "../../components/HorizontalLine";
import Select from "react-select";
import { PrimaryButton } from "../../components/Buttons/Button";
import axios from "axios";
import ApiParameters from "./ApiParameters";
import ApiResult from "./ApiResult";
import { useSelector } from "react-redux";
import store from "../../store";
import SourceParam from "./SourceParam";
import TextInput from "../../components/Selectors/TextInput";
import TimeInput from "../../components/Inputs/TimeInput";
import NumericInput from "../../components/Inputs/NumericInput";
import { selectedFieldsToApiResponse, transformObject } from "./dataFeedback.utils";
import { putDataApi } from "../../axios/DataManagementApi";
import { useParams } from "react-router-dom";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import AuthentificationParameters from "./AuthentificationParameters";
import { toast } from "react-toastify";

const DataFeedback = () => {
  const { id } = useParams();
  const { configured_source } = useSelector((state) => state.data);
  const { t } = useTranslation(["common", "button"]);
  const methodOptions = [
    {
      value: "GET",
      label: "GET",
    },
    {
      value: "POST",
      label: "POST",
    },
  ];
  const [firstMultiple, setFirstMultiple] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCheckUrl, setIsLoadingCheckUrl] = useState(false);
  const [response, setResponse] = useState({});
  const [data_source_name, setData_source_name] = useState("");
  const [initial, setInitial] = useState(formatDateInput(dayjs()));
  const [timezone, _setTimezone] = useState("Europe/Paris");
  const [frequency, setFrequency] = useState(15);
  const [validity_days, setValidity_days] = useState("1-5");
  const [time_slot, setTime_slot] = useState("9-18");
  const [parameters, setParameters] = useState({
    data_to_upload: {
      api_parameters: {
        url: "",
        method_type: "GET",
        authentification: {
          type: "PREDITIC",
          parameters: {},
        },
        parameters: {},
      },
      api_response: [],
    },
    fields_to_upload: {},
    collected_by: id,
  });
  const authentificationOptions = [
    {
      label: "Pas d'autentification",
      value: "PREDITIC",
      parameters: {},
    },
    {
      label: "Clé API",
      value: "TOKEN",
      parameters: {
        key: "Authorization",
        value: "",
      },
    },
    {
      label: "Identifiant - mot de passe",
      value: "PWD",
      parameters: {
        id: "",
        pwd: "",
      },
    },
  ];
  const setNestedParameters = (keyPath, value) => {
    setParameters((prevState) => {
      // Make a deep copy of the state
      let newState = JSON.parse(JSON.stringify(prevState));

      // Split the keyPath into an array of keys
      let keys = keyPath.split(".");

      // Use reduce to navigate to the nested key
      keys.reduce((obj, key, index) => {
        if (index === keys.length - 1) {
          // If we're at the last key, set the value
          obj[key] = value;
        } else {
          // Otherwise, keep navigating deeper
          return obj[key];
        }
      }, newState);

      return newState;
    });
  };
  useEffect(() => {
    initFieldsToUpload(configured_source.find((configuration) => configuration.id === id).crude_source, setNestedParameters);
  }, [configured_source, id]);
  const create = () => {
    setIsLoading(true);
    dataLoadingToast({ label: "Mise en place de la remontée", isLoading: true, id: "login-toast" });

    const parametersCopy = structuredClone(parameters);
    parametersCopy.data_to_upload.api_response = selectedFieldsToApiResponse(selectedFields, response, firstMultiple);
    const fieldsToUploadFormatted = {};
    Object.entries(parametersCopy.fields_to_upload).forEach((field) => {
      const valueArray = field[1].field.split("/");
      const newValue = valueArray[valueArray.length - 1];
      fieldsToUploadFormatted[field[0]] = { ...field[1], field: newValue };
    });
    const noOldValueApiParameters = {};
    Object.entries(parametersCopy.data_to_upload.api_parameters.parameters).forEach((parameter) => {
      noOldValueApiParameters[parameter[0]] = {
        value: parameter[1].value,
        status: parameter[1].status,
        value_parameters: parameter[1].value_parameters,
      };
    });
    parametersCopy.data_to_upload.api_parameters.parameters = noOldValueApiParameters;
    parametersCopy.fields_to_upload = fieldsToUploadFormatted;
    const payload = {
      script_name: "open_data_upload_module",
      script_parameters: {
        observed_system: sessionStorage.getItem("observed_system_id"),
        parameters: parametersCopy,
        data_source_name: data_source_name,
        initial_timestamp: initial,
        timezone: timezone,
      },
      frequency: frequency,
      time_slot: time_slot,
      validity_days: validity_days,
    };
    console.debug(payload);
    putDataApi("configuration/module/" + sessionStorage.getItem("observed_system_id"), payload)
      .then((res) => {
        if (res === 401) return;
        dataLoadingToast({ successMessage: "Remontée mise en place", success: true, id: "login-toast" });
        console.log(res); //TODO handle res
        setIsLoading(false);
      })
      .catch((error) => {
        dataLoadingToast({ error: true, id: "login-toast" });
        console.debug(error);
        setIsLoading(false);
      });
  };
  const handleRequest = () => {
    const { url, method_type, authentification } = parameters.data_to_upload.api_parameters;
    let config = {};
    if (authentification.type === "PWD") {
      // Basic Authentication with a username and password
      config = {
        auth: {
          username: authentification.parameters.id,
          password: authentification.parameters.pwd,
        },
      };
    } else if (authentification.type === "TOKEN") {
      // Authentication using a token
      config = {
        headers: {
          [authentification.parameters.key]: authentification.parameters.value,
        },
      };
    }
    setIsLoadingCheckUrl(true);
    switch (method_type) {
      case "GET":
        axios
          .get(url, config)
          .then((res) => {
            const urlObj = new URL(url);
            setNestedParameters("data_to_upload.api_parameters.url", urlObj.origin + urlObj.pathname);
            if (transformObject(Array.isArray(res.data))) setFirstMultiple(true);
            setResponse(transformObject(Array.isArray(res.data) ? res.data[0] : res.data));
            setIsLoadingCheckUrl(false);
          })
          .catch((error) => {
            if (error.response.status) toast.error("Erreur d'authentification");
            setIsLoadingCheckUrl(false);
          });
        break;
      default:
        break;
    }
  };
  const handleAuthentificationChange = (newValue) => {
    setNestedParameters("data_to_upload.api_parameters.authentification", {
      parameters: newValue.parameters,
      type: newValue.value,
    });
  };
  return (
    <div className={"p-8"}>
      <div className="w-full rounded-lg border-blue-900 bg-white shadow-md">
        <h3 className="px-4 pt-4 text-2xl font-semibold text-blue-900">
          {capitalizeFirstLetter(t("data_feedback"))} - {configured_source.find((configuration) => configuration.id === id).description}
        </h3>
        <HorizontalLine />
        <div className="grid grid-cols-1 gap-4 p-4 lg:grid-cols-3">
          <div>
            <label className="font-bold tracking-wide text-gray-700">{"Authentification"}</label>
            <Select
              onChange={handleAuthentificationChange}
              options={authentificationOptions}
              value={authentificationOptions.find((auth) => auth.value === parameters.data_to_upload.api_parameters.authentification.type)}
            />
          </div>
          <AuthentificationParameters authentification={parameters.data_to_upload.api_parameters.authentification} setNestedParameters={setNestedParameters} />
          <div className={"col-span-3"}>
            <label className="font-bold tracking-wide text-gray-700">{"Méthode et url du point d'API à remonter"}</label>

            <div className={"flex gap-x-1.5"}>
              <Select
                className={"w-32"}
                onChange={(newValue) => setNestedParameters("data_to_upload.api_parameters.method_type", newValue.value)}
                options={methodOptions}
                value={methodOptions.find((method) => method.value === parameters.data_to_upload.api_parameters.method_type)}
              />
              <div className={"w-full"}>
                <UrlInput url={parameters.data_to_upload.api_parameters.url} setNestedParameters={setNestedParameters} />
              </div>
              <div>
                <PrimaryButton isLoading={isLoadingCheckUrl} label={"envoyer"} callback={() => handleRequest()} />
              </div>
            </div>
          </div>
          <div className={"col-span-3 grid grid-cols-2"}>
            <ApiParameters parameters={parameters.data_to_upload.api_parameters.parameters} setNestedParameters={setNestedParameters} configuredSourceId={id} />
            <ApiResult
              data={response}
              setData={setResponse}
              parameters={parameters.data_to_upload.api_response}
              setNestedParameters={setNestedParameters}
              apiResponse={parameters.data_to_upload.api_response}
            />
          </div>
          <div className={"col-span-3"}>
            <label className="font-bold tracking-wide text-gray-700">{"Liaison"}</label>
            <SourceParam
              fieldsToUpload={parameters.fields_to_upload}
              setNestedParameters={setNestedParameters}
              response={response}
              selectedFields={selectedFields}
              setSelectedFields={setSelectedFields}
            />
          </div>
          <div className={"col-span-3"}>
            <HorizontalLine />
          </div>
          <div className={"col-span-3 grid grid-cols-5 gap-x-0.5"}>
            <TextInput label={"Nom"} value={data_source_name} name={"data_source_name"} onChange={(name, value) => setData_source_name(value)} />
            <TimeInput label={"Date de début"} name={"initial"} value={initial} onChange={(name, value) => setInitial(value)} />
            <NumericInput label={"Frequence"} name={"frequency"} value={frequency} onChange={(name, value) => setFrequency(value)} />
            <TextInput label={"Jours"} value={validity_days} name={"validity_days"} onChange={(name, value) => setValidity_days(value)} />
            <TextInput label={"Heures"} value={time_slot} name={"time_slot"} onChange={(name, value) => setTime_slot(value)} />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <PrimaryButton isLoading={isLoading} label={"Créer"} disabled={isLoading} callback={create} />
      </div>
    </div>
  );
};
export default DataFeedback;

const initFieldsToUpload = (crudeSourceId, setNestedParameters) => {
  const { crude_source } = store.getState().data;
  const measure = crude_source.find((crude_source) => crude_source.id === crudeSourceId).measure;
  const fieldsToUpload = {};
  Object.keys(measure).forEach((key) => {
    fieldsToUpload[key] = {
      field: "",
      position: -1,
    };
  });
  setNestedParameters("fields_to_upload", fieldsToUpload);
};
