import { Tab } from "@headlessui/react";

/**
 *
 * @param {string[]}  headers
 * @returns {Tab[]}
 * @constructor
 */
const TabHeader = ({ headers }) =>
  headers.map((value, index) => (
    <Tab
      key={index}
      className={
        "group inline-flex items-center justify-center gap-x-0.5 rounded-t-lg border-b-2 border-transparent p-4 hover:border-gray-300 hover:text-gray-600 ui-selected:border-blue-600 ui-selected:text-blue-600"
      }
    >
      {value}
    </Tab>
  ));
export default TabHeader;
