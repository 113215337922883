const IconButton = ({ Icon, callback, value, bg = false }) => {
  const className = bg
    ? "text-white bg-blue-900 hover:bg-blue-950 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg  text-center inline-flex items-center"
    : "text-blue-900 hover:text-blue-950 focus:outline-none  font-medium rounded-full   text-center inline-flex items-center";
  return (
    <button type="button" onClick={callback} value={value} className={className}>
      {Icon}
    </button>
  );
};
export default IconButton;
