import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TableHeader } from "../Table/TableHeader";
import { TableBody } from "../Table/TableBody";
import { useTranslation } from "react-i18next";

const TableMV = ({ data, name }) => {
  const { t } = useTranslation(["common"]);
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      id: "header",
      header: t("name"),
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("type", {
      id: "type",
      header: t("type"),
      cell: (info) => t(info.getValue()),
    }),

    columnHelper.accessor("param", {
      id: "param",
      header: t("restriction"),
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <div className="col-span-2 lg:col-span-1 ">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-semibold leading-6 text-blue-900">{name}</h3>
      </div>
      <div className="rounded-lg bg-white px-8  pb-8 pt-4 shadow-lg  md:col-span-2 2xl:col-span-1">
        <div>
          <div className="mt-2 flex flex-col justify-between lg:flex-row">
            <div className="flex w-full flex-row px-3" />
          </div>
          <div className="flex flex-col">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="border-b  border-gray-200 shadow">
                <table className="w-full">
                  <TableHeader table={table} />
                  <TableBody table={table} />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TableMV;
