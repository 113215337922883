import { Popover } from "@headlessui/react";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DeleteButton, PrimaryButton } from "./Buttons/Button";
import { usePopper } from "react-popper";
import { formatDisplay } from "../utils/date.utils";
import TimeInput from "./Inputs/TimeInput";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../utils/common";

export default function TimeSelector({ start_time, end_time, callback }) {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement);
  const { timelabel } = useSelector((state) => state.data);
  const { t } = useTranslation(["common", "button", "timelabel"]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    setStartDate(dayjs(start_time).format("YYYY-MM-DDTHH:mm:ss"));
    setEndDate(dayjs(end_time).format("YYYY-MM-DDTHH:mm:ss"));
  }, [end_time, start_time]);
  const handleTimeChange = (name, value) => {
    if (name === "startDate")
      if (value === "") setStartDate(dayjs().format("YYYY-MM-DDTHH:mm:ss"));
      else setStartDate(value);
    if (name === "endDate")
      if (value === "") setEndDate(dayjs().format("YYYY-MM-DDTHH:mm:ss"));
      else setEndDate(value);
  };
  const handleLabelChange = (event) => {
    const { value } = event.target;
    const { REACT_APP_DATA_API } = process.env;

    const urlObj = new URL(REACT_APP_DATA_API + "timelabel");
    urlObj.searchParams.set("label", value);
    axios.get(urlObj.toString()).then((res) => {
      const { start_time, end_time } = res.data.data;
      setStartDate(dayjs(start_time).format("YYYY-MM-DDTHH:mm:ss"));
      setEndDate(dayjs(end_time).format("YYYY-MM-DDTHH:mm:ss"));
    });
  };
  return (
    <div>
      <Popover className="relative">
        <Popover.Button
          ref={setReferenceElement}
          className={
            "my-1 block w-screen max-w-md rounded-lg border border-gray-300 bg-gray-200 px-1 py-2 shadow-sm outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200/50"
          }
        >
          {formatDisplay(start_time)} → {formatDisplay(end_time)}
        </Popover.Button>
        <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper} className="z-10 m-1 w-full border border-gray-300 bg-gray-200 px-1">
          <div className="grid grid-cols-2 divide-x divide-gray-300 border-b  border-gray-300">
            <div className="">
              <h2 className="py-2">{capitalizeFirstLetter(t("from"))}</h2>
              <div>
                <TimeInput value={startDate} onChange={handleTimeChange} name={"startDate"} />
              </div>
              <h2 className="py-2">{capitalizeFirstLetter(t("to"))}</h2>
              <div>
                <TimeInput value={endDate} onChange={handleTimeChange} name={"endDate"} />
              </div>
            </div>
            <div className="mx-2 h-64 py-2">
              <h1 className="sticky top-0 px-1">{capitalizeFirstLetter(t("quick_range"))}</h1>
              <div className="h-56 overflow-y-scroll">
                {timelabel.map((value, key) => (
                  <div className={"hover:bg-gray-300"} key={key}>
                    <button onClick={handleLabelChange} value={Object.entries(value)[0][0]} className="w-full p-1 text-start font-light">
                      {t(Object.entries(value)[0][0], { ns: "timelabel" })}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Popover.Button as={"div"} className="flex justify-end gap-x-0.5 p-1">
            <DeleteButton label={t("cancel", { ns: "button" })} callback={() => callback(start_time, end_time)} />
            <PrimaryButton callback={() => callback(startDate, endDate)} label={t("apply", { ns: "button" })} />
          </Popover.Button>
        </Popover.Panel>
      </Popover>
    </div>
  );
}
