import Papa from "papaparse";
import StringParam from "../components/FileParam/StringParam";
import CategoricalParam from "../components/FileParam/CategoricalParam";
import NumericParam from "../components/FileParam/NumericParam";
import dayjs from "dayjs";
import DateParam from "../components/FileParam/DateParam";

/**
 * Check the type of a value of a  csv file.
 * @param {*} value
 * @returns
 */
const checkType = (value) => {
  let date = undefined;
  switch (typeof value) {
    case "number":
      if (Number.isInteger(value)) return "int";
      else return "float";
    case "string":
      date = new Date(value);
      if (date.toUTCString() === "Invalid Date") return "string";
      return "date";
    case "object":
      date = dayjs(value);
      return "date";
    default:
      console.debug(typeof value, value);
      return "";
  }
};
/**
 * Return an object corresponding to the default param for the field.
 * @param {string} type
 * @returns
 */
export const initVisualization = (type) => {
  switch (type) {
    case "ordered":
      return {
        description: "",
        visualization_type: "ordered_categorical",
        label: [],
      };
    case "nominal":
      return {
        description: "",
        visualization_type: "nominal_categorical",
        label: [],
      };
    case "string":
    case "int":
      return {
        description: "",
        visualization_type: "dynamic_numerical",
      };
    default:
      return {
        description: "",
        visualization_type: type,
      };
  }
};

export const checkFileType = (file, state, setState) => {
  if (file === undefined) return false;
  Papa.parse(file, {
    header: true,
    preview: 4,
    skipEmptyLines: true,
    dynamicTyping: true,
    complete: function (results) {
      const res = [];
      console.log(results);
      let data = results.data;
      for (const column in data[0]) {
        const value = data[0][column];
        const type = checkType(value);
        if (type === "date" && typeof value === "object")
          data = data.map((elem) => {
            return { ...elem, [column]: value.toISOString() };
          });
        if (column !== "__parsed_extra")
          res.push({
            column: column,
            type: type,
            visualization_format: initVisualization(type),
            mapping_information: initMappingInformation(type),
          });
      }
      console.log(res);
      setState({
        ...state,
        col_type: res,
        error: results.errors,
        preview: data,
        delimiter: results.meta.delimiter,
      });
      return true;
    },
  });
  return true;
};
export const getHeaderFromCSV = (csvFile, columnName) =>
  new Promise((resolve, reject) => {
    Papa.parse(csvFile, {
      header: true,
      complete: (results) => {
        if (results.meta && results.meta.fields) {
          const headers = results.meta.fields;
          const columnMap = {};
          columnName.forEach((name) => {
            const columnIndex = headers.indexOf(name);
            if (columnIndex !== -1) {
              columnMap[name] = name;
            } else {
              columnMap[name] = null;
            }
          });
          resolve({
            header: results.meta.fields,
            columnMap: columnMap,
            delimiter: results.meta.delimiter,
          });
        } else {
          reject(new Error("CSV file has no headers"));
        }
      },
      error: (error) => {
        reject(error);
      },
    });
  });
const initMappingInformation = (type) => {
  switch (type) {
    case "string":
    case "url":
      return 100;
    case "int":
    case "float":
      return { min: 0, max: 100, min_included: true, max_included: true };
    default:
      return {};
  }
};
export const typeRender = (type, id, data, closeModal, setData) => {
  switch (type) {
    case "int":
    case "float":
      return <NumericParam data={data} setData={setData} id={id} />;
    case "date":
      return <DateParam data={data} setData={setData} id={id} />;
    case "string":
    case "url":
      return <StringParam data={data} setData={setData} id={id} />;
    case "nominal":
    case "ordered":
      return <CategoricalParam data={data} setData={setData} id={id} />;
    default:
      break;
  }
};

export const handleTypeChange = (newValue, index, data, setData) => {
  const newData = { ...data.col_type[index] };
  const newcolData = [...data.col_type];
  newData.type = newValue;
  newData.mapping_information = initMappingInformation(newValue);
  newData.visualization_format = initVisualization(newValue);
  newcolData[index] = newData;
  setData({
    ...data,
    col_type: newcolData,
  });
};
export const handleMappingInformationChange = (newValue, index, data, setData, name) => {
  const newcolData = [...data.col_type];
  newcolData[index].mapping_information[name] = newValue;
  setData({
    ...data,
    col_type: newcolData,
  });
};
export const handleVisualizationFormatChange = (newValue, index, data, setData, name) => {
  const newcolData = [...data.col_type];
  newcolData[index].visualization_format[name] = newValue;
  setData({
    ...data,
    col_type: newcolData,
  });
};
export const handleParamsChange = (newValues, index, data, setData) => {
  const newcolData = [...data.col_type];
  newValues.forEach((value) => {
    newcolData[index].param[value.name] = value.value;
  });
  setData({
    ...data,
    col_type: newcolData,
  });
};
