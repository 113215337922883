import { Tab } from "@headlessui/react";

const TabPanel = ({ panel }) => (
  <Tab.Panels className={""}>
    {panel.map((value) => (
      <Tab.Panel className={"py-3"} key={value.id}>
        {value.component}
      </Tab.Panel>
    ))}
  </Tab.Panels>
);
export default TabPanel;
