import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PreferenceSection from "../components/PreferenceSection";
import { createAction } from "../utils/axios.utils";
import { Transition } from "@headlessui/react";
import { transitionClasses } from "../utils/common";
import { PrimaryButton, TertiaryButton } from "../components/Buttons/Button";
import { postUserApi, putUserApi } from "../axios/UserManagementApi";
import store from "../store";
import { logout, set } from "../reducers/ApplicationSlice";
import { dataLoadingToast } from "../components/Toasts/DataLoadingToast";

const ProfilePage = () => {
  const [showPreferences, setShowPreferences] = useState(false);
  const dispatch = useDispatch();
  const { structure, profiles } = useSelector((state) => state.data);
  const { token, profile } = useSelector((state) => state.application);
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();

  function handleSavePreferences(updatedPreference) {
    setIsLoading(true);
    dataLoadingToast({ isLoading: true, label: "Mise à jour..." });
    putUserApi("preference?userId=" + profile["id_keycloak"], updatedPreference)
      .then((res) => {
        dataLoadingToast({ success: true, successMessage: "Mis à jour" });

        console.debug(res);
        setIsLoading(false);
        dispatch(set(createAction("profile", { ...profile, preference: updatedPreference })));
        setShowPreferences(false);
      })
      .catch((error) => {
        console.debug(error);
        dataLoadingToast({ error: true, errorMessage: "La mise à jour des données a échoué" });

        setIsLoading(false);
      });
  }

  const namedProfile = () => profiles.find((p) => p.id === profile["id_role"])?.name;
  return (
    <div>
      <div className="flex w-full justify-center">
        <div className="m-6 w-full rounded-lg border border-gray-200 bg-white shadow-md">
          <div className="grid grid-cols-12">
            <div className="col-span-4 space-y-4 rounded-l-lg bg-blue-800 p-3 text-center text-white">
              <h1 className="text-xl font-bold">
                {profile.first_name} {profile.last_name}
              </h1>
              <p>{namedProfile()}</p>
              <p>{structure.name || "Aucune structure"}</p>
              <div className={"flex justify-center"}>
                <TertiaryButton
                  callback={() =>
                    postUserApi("user/logout", {}).then(() => {
                      store.dispatch(logout());
                      sessionStorage.clear();
                      history("/login");
                    })
                  }
                  label="Déconnexion"
                />
              </div>
            </div>
            <div className="col-span-8">
              <div className="p-4">
                <h5 className="mb-2 text-xl font-bold tracking-tight">Informations</h5>

                <div className="flex border-t border-gray-400" />

                <div className="my-3 grid grid-cols-1 font-normal lg:grid-cols-2">
                  <div>
                    <span className="font-semibold">Email: </span>
                    <span>{profile.email || "NA"}</span>
                  </div>
                  <div>
                    <span className="font-semibold">Id: </span>
                    <span>{profile["id_keycloak"]}</span>
                  </div>
                  <div>
                    <span className="font-semibold">Token: </span>
                    <button className={"font-semibold tracking-tight text-blue-800 active:text-blue-900"} onClick={() => navigator.clipboard.writeText(token)}>
                      {" "}
                      Copier le token
                    </button>
                  </div>
                </div>

                <div className="flex border-t border-gray-400" />

                <div className="my-3  flex justify-end  font-normal ">
                  <div>
                    <PrimaryButton disabled={isLoading} isLoading={isLoading} label={"Preferences"} callback={() => setShowPreferences(!showPreferences)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"flex w-full justify-center"}>
        <Transition
          className="m-6 w-full rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800"
          show={showPreferences}
          as={"div"}
          {...transitionClasses}
        >
          <div className="p-2 shadow-md">
            <PreferenceSection user_id={profile["id_keycloak"]} preference={profile.preference} onSave={handleSavePreferences} isLoading={isLoading} />
          </div>
        </Transition>
      </div>
    </div>
  );
};
export default ProfilePage;
