import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import store from "../../store";
import { setPage } from "../../reducers/SelectedSlice";

const Section = ({ icon, name, section }) => {
  const { page } = useSelector((state) => state.selected);
  console.debug(page);
  const authorized_pages = useSelector((state) => state.application.authorized_pages);
  return (
    <>
      <li className="flex px-5 ">
        <div className="mt-5 flex h-8 items-center gap-x-4">
          {icon}
          <div className="text-sm font-light uppercase tracking-wide text-gray-400">{name}</div>
        </div>
      </li>

      {/* SETTINGS SECTIONS */}
      {section.map(
        (row) =>
          authorized_pages.includes(row.id) && (
            <li key={row.id}>
              <NavLink
                onClick={() => {
                  sessionStorage.setItem("selectedSidebar", row.id);
                  store.dispatch(setPage(row.id));
                }}
                className={classNames(
                  "hover:border-400 relative flex flex-row items-center border-l-4 border-transparent py-2 pr-6 text-white hover:bg-blue-500 focus:outline-none",
                  page === row.id && "border-blue-600 bg-blue-700 font-semibold text-blue-200",
                )}
                to={row.path}
              >
                <span className="ml-2 truncate pl-8 text-sm tracking-wide">{row.name}</span>
              </NavLink>
            </li>
          ),
      )}
    </>
  );
};
export default Section;
