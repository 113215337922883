import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { canRenderComponent, dataList } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";
import { TableFilter } from "./TableFilter";
import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import { set } from "../../reducers/dataSlice";
import { getDataApi } from "../../axios/DataManagementApi";
import { PrimaryButton, SecondaryButton } from "../Buttons/Button";

const EventList = () => {
  const dispatch = useDispatch();
  const authorizedComponent = useSelector((state) => state.application.authorized_components);

  const [authorizedEventCreation, setAuthorizedEventCreation] = useState(canRenderComponent(COMPONENTS.EVENT_CREATION));
  useEffect(
    () => () => {
      setAuthorizedEventCreation(canRenderComponent(COMPONENTS.EVENT_CREATION));
    },
    [authorizedComponent],
  );
  const [isLoading, setIsLoading] = useState(false);

  const data = useSelector((state) => state.data.event);
  const [globalFilter, setGlobalFilter] = useState("");
  const history = useNavigate();
  const createEvent = () => history("/analytics/event/create");

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("function_name", {
      id: "function_name",
      header: "Fonction",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("module_name", {
      id: "module_name",
      header: "Nom",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => Date.parse(row.creation_timestamp), {
      id: "creation_timestamp",
      header: "Date de création",
      cell: (info) => {
        const date = new Date(info.getValue());
        return date.toLocaleString();
      },
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>

        <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
          <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
            <SecondaryButton
              isLoading={isLoading}
              disabled={isLoading}
              callback={() => {
                setIsLoading(true);
                getDataApi("event/" + sessionStorage.getItem("structure_id"))
                  .then((res) => {
                    dispatch(
                      set({
                        type: "event",
                        data: dataList(res.data.data),
                      }),
                    );
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    console.debug(error);
                    setIsLoading(false);
                  });
              }}
              label="Mettre à jour"
            />
          </div>
          {authorizedEventCreation && (
            <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
              <PrimaryButton callback={createEvent} label="Créer une action" />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
    </div>
  );
};
export default EventList;
