import TimeParamHisto from "./TimeParamHisto";
import TimeParam from "./TimeParam";
import { useSelector } from "react-redux";

const TimeParamCreation = () => {
  const histo = useSelector((state) => state.event.histo);
  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <h2 className="text-2xl font-semibold text-blue-900">Paramètres de temps</h2>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 ">
        <TimeParam />
        {histo && <TimeParamHisto />}
      </div>
    </div>
  );
};
export default TimeParamCreation;
