import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { canRenderAction, dataList, getUserPreferences, isEmptyObject } from "../../utils/common";
import { ACTION_FLAGS } from "../../constants/action_flags";
import { COMPONENTS } from "../../constants/component";
import { toast } from "react-toastify";
import { getDataApi } from "../../axios/DataManagementApi";
import { set as setData } from "../../reducers/dataSlice";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import LabelTooltip from "../../components/Tooltip/LabelTooltip";
import Icon from "@mdi/react";
import { mdiCancel, mdiCheck, mdiPencilPlus, mdiReload } from "@mdi/js";
import { set as setModuleData } from "../../reducers/indicatorSlice";
import { current } from "../event/EventUtils";
import dayjs from "dayjs";
import DropdownMenu, { createDropdownOption } from "../../components/Selectors/DropDownButton";
import { TableFilter } from "../../components/Table/TableFilter";
import Table from "../../components/Table/Table";

export const TrainingModelList = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [globalFilter, setGlobalFilter] = useState("");
  const [canCreate] = useState(canRenderAction(ACTION_FLAGS.CANCREATE, COMPONENTS.PREDICTION_DISPLAY));
  const data = useSelector((state) => state.data.training_model);
  const showId = getUserPreferences().find((preference) => preference.name === "showId")?.value ?? true;
  const update = () => {
    const toastId = toast.info("Mise à jour", { containerId: "loadingToast", isLoading: true });
    getDataApi("training/model/" + sessionStorage.getItem("structure_id"))
      .then((res) => {
        toast.update(toastId, {
          render: "Mis à jour !",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
          isLoading: false,
          hideProgressBar: false,
        });
        console.debug(res.data.data);
        dispatch(
          setData({
            type: "training_model",
            data: dataList(res.data.data),
          }),
        );
      })
      .catch((error) => {
        toast.update(toastId, {
          render: <p className={"truncate"}> {error.response.data.message}</p>,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
          autoClose: 5000,
          isLoading: false,
        });
        console.debug(error);
      });
  };
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      cell: (info) => info.getValue(),
      header: "id",
    }),
    columnHelper.accessor("model_name", {
      id: "model_name",
      cell: (info) => info.getValue(),
      header: "Nom",
    }),
    columnHelper.accessor("target", {
      id: "target",
      cell: (info) => info.getValue(),
      header: "Cible",
    }),
    columnHelper.display({
      id: "predictors",
      cell: (info) => {
        const row = info.row.original;
        const { predictors } = row;
        return <LabelTooltip tip={Object.keys(predictors).join(" - ")} label={Object.keys(predictors).length + " variable(s)"} />;
      },
      header: "Variables de predictions",
    }),

    columnHelper.display({
      id: "metrics",
      cell: (info) => {
        const row = info.row.original;
        const { metrics } = row;
        const { rmse, target_mean } = metrics;
        return (
          <div className={"flex gap-x-0.5"}>
            <p className={"font-light"}>{target_mean.toFixed(3)}</p> <p>± </p>
            <p className={"font-semibold"}>{rmse.toFixed(3)} </p>
          </div>
        );
      },
      header: "Marge d'erreur",
    }),

    columnHelper.display({
      id: "r2",
      cell: (info) => {
        const row = info.row.original;
        const { metrics } = row;
        const { r2 } = metrics;
        return (r2 * 100).toFixed(2) + "%";
      },
      header: "Qualité",
    }),
    columnHelper.display({
      id: "active",
      cell: (info) => {
        const row = info.row.original;
        const { active, recurrent } = row;
        if (isEmptyObject(recurrent)) return "Simple";
        const { frequency, time_slot, validity_days } = recurrent;
        const days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
        const formatDay = validity_days.split("-").map((day) => days[parseInt(day)]);
        const display = (
          <div>
            {active ? <Icon path={mdiCheck} className={"h-6 w-6 text-green-500"} /> : <Icon path={mdiCancel} className={"h-6 w-6 text-red-500"} />}
            <p>{frequency}min</p>
            <p>{formatDay.join("-")}</p>
            <p>{time_slot}</p>
          </div>
        );
        return <LabelTooltip label={"Récurrent"} tip={display} />;
      },
      header: "Mode",
    }),
    columnHelper.display({
      id: "actions_button",
      cell: (info) => {
        const row = info.row.original;
        const { target, id, predictors } = row;
        const predict = {};
        Object.entries(predictors).forEach((entry) => {
          predict[entry[0]] = { usage: entry[0], orders: entry[1] };
        });
        const options = [];
        if (canCreate)
          options.push({
            id: 0,
            label: "Créer une prédiction",
            onClick: () => {
              dispatch(
                setModuleData({
                  name: 1,
                  timezone: "Europe/Paris",
                  current,
                  prediction_params: {
                    model_id: id,
                    target: {
                      origin: target,
                      usage: target,
                    },
                    predictors: predict,
                    prediction_mode: 1,
                    opf_mode: 0,
                  },
                  execution_mode: 0,
                  script_name: "prediction",
                  initial_timestamp: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
                  frequency: 5,
                  shift: 600,
                  validity_days: "1-5",
                  time_slot: "9-18",
                  multiple_sources: {},
                  mode: 1,
                }),
              );
              history("/analytics/modele/predict");
            },
            path: mdiPencilPlus,
          });
        options.push(
          createDropdownOption(
            1,
            "Créer une reconstruction",
            () => {
              dispatch(
                setModuleData({
                  name: 1,
                  timezone: "Europe/Paris",
                  current,
                  reconstruction_params: {
                    model_id: id,
                    target: {
                      time_field: "",
                      index_measure: "",
                      origin: target,
                      usage: target,
                    },
                    predictors: predict,
                  },
                  execution_mode: 0,
                  script_name: "reconstruction",
                  initial_timestamp: dayjs().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
                  frequency: 5,
                  shift: 600,
                  validity_days: "1-5",
                  time_slot: "9-18",
                  mode: 1,
                }),
              );
              history("/analytics/modele/reconstruction");
            },
            mdiPencilPlus,
          ),
        );
        return <DropdownMenu options={options} />;
      },
    }),
  ];
  if (!showId) columns.splice(0, 1);
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  const dropDownOptions = [
    createDropdownOption(0, "Mettre à jour", () => update(), mdiReload),
    createDropdownOption(
      1,
      "Créer un modèle de prédiction",
      () => {
        dispatch(
          setModuleData({
            execution_mode: 0,
            script_name: "trainingmanager",
            name: 0,
            timezone: "Europe/Paris",
            current,
            model_params: {
              target: "",
              potential_predictors: [],
              correlation_method: "pearson",
              name: "",
            },
            initial_timestamp: dayjs().subtract(5, "m").format("YYYY-MM-DDTHH:mm:ss"),
            frequency: 5,
            validity_days: "1-5",
            time_slot: "9-18",
            mode: 1,
          }),
        );
        history("/analytics/modele/prediction/training");
      },
      mdiPencilPlus,
    ),
    createDropdownOption(
      2,
      "Créer un modèle de reconstruction",
      () => {
        dispatch(
          setModuleData({
            execution_mode: 0,
            script_name: "reconstructionfitter",
            name: 0,
            timezone: "Europe/Paris",
            current,
            reconstruction_params: {
              target: "",
              potential_predictors: [],
              correlation_method: "pearson",
              name: "",
            },
            initial_timestamp: dayjs().subtract(5, "m").format("YYYY-MM-DDTHH:mm:ss"),
            frequency: 5,
            validity_days: "1-5",
            time_slot: "9-18",
            mode: 1,
          }),
        );
        history("/analytics/modele/reconstruction/training");
      },
      mdiPencilPlus,
    ),
  ];
  return (
    <div className="block rounded-lg border border-gray-200 bg-white">
      <div className="flex flex-col justify-between px-4 lg:flex-row">
        <div className="flex flex-row">
          <TableFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(String(value))} />
        </div>
        <div className="flex items-center">
          <DropdownMenu options={dropDownOptions} bg />
        </div>
      </div>
      <Table table={table} />
    </div>
  );
};
