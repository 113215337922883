import { useState } from "react";
import { getValueFromSelect, mailEvent, redirect } from "./EventUtils";
import TextInput from "../../components/Selectors/TextInput";
import CreatableSelect from "react-select/creatable";
import { useNavigate } from "react-router-dom";
import { postDataApi } from "../../axios/DataManagementApi";
import { push } from "../../reducers/dataSlice";
import { useDispatch } from "react-redux";
import { PrimaryButton } from "../../components/Buttons/Button";

const MailEvent = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(mailEvent);
  //TODO destinataires with users list
  // const users = useSelector((state) => state.data.users);
  // useEffect(() => {
  //   return () => {
  //     if (users.length === 0)
  //       getUserApi("management")
  //         .then((res) => {
  //           dispatch(set({ type: "users", data: res.data["data"] }));
  //         })
  //         .catch((error) => {
  //           console.debug(error);
  //         });
  //   };
  // }, []);

  const onChange = (name, value) => {
    const param = { ...data.parameters };
    param[name] = value;
    setData({ ...data, parameters: param });
  };
  const create = () => {
    setIsLoading(true);
    const formattedData = structuredClone(data);
    formattedData.parameters.receivers = getValueFromSelect(data.parameters.receivers);
    postDataApi("event/" + sessionStorage.getItem("structure_id"), formattedData).then((res) => {
      console.debug(res.data.message);
      formattedData["creation_timestamp"] = new Date(Date.now()).toString();
      formattedData["id"] = res.data.id;
      setIsLoading(false);
      dispatch(push({ type: "event", data: formattedData }));
      history(redirect);
    });
  };
  const disabled = data.parameters.receivers.length === 0 || data.parameters.message === "" || data.parameters.subject === "";
  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <h2 className="text-2xl font-semibold text-blue-900">Paramètre de l&apos;email</h2>
      <TextInput
        extraStyle="w-full py-2 rounded-lg outline-none border border-2px opacity-50"
        disabled={true}
        value={data["parameters"].sender}
        label={"Expediteur"}
      />
      <div>
        <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Destinataires</label>
        <CreatableSelect
          placeholder={"Destinataires..."}
          openMenuOnClick={false}
          isClearable={true}
          isMulti
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          value={data.parameters.receivers}
          onChange={(option) => onChange("receivers", option)}
        />
      </div>
      <TextInput value={data["parameters"].subject} label={"Sujet"} name="subject" onChange={onChange} />
      <TextInput value={data["parameters"].message} label={"Message"} name="message" onChange={onChange} />
      <div className="flex justify-center py-2">
        <PrimaryButton label={"Créer"} disabled={disabled || isLoading} isLoading={isLoading} callback={create} />
      </div>
    </div>
  );
};
export default MailEvent;
