import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/common";
import ReactECharts from "echarts-for-react";
import { chartData } from "./chart.utils";
/*
{
  "Piscine Galin": 72,
  "Piscine Judaique - Jean Boiteux": 72,
  "Piscine Tissot": 36
}
 */
const StateCaraChart = ({ summary }) => {
  const { t } = useTranslation(["chart"]);
  const description = Object.keys(summary);
  const occurences = Object.entries(summary).map((item) => parseFloat(item[1]));
  const occurencesSum = occurences.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const tooltipFormatter = (params) => {
    const { value, name } = params;
    return `<h1>${name}</h1><p><b> ${value} </b></p>`;
  };
  const data = Object.entries(summary).map((item) => {
    return { name: item[0], value: item[1] };
  });
  return (
    <div className="flex h-[50vh] w-full justify-center rounded-lg border border-gray-200 bg-white shadow">
      <div className={"w-full py-2"}>
        <div className={"mb-1"}>
          <p className={"text-center font-semibold text-blue-900"}>{capitalizeFirstLetter(t("status_title_storage"))}</p>
          <p className={"text-center font-mono text-xs"}>
            {occurencesSum} {t("data")}
          </p>
        </div>
        <ReactECharts option={chartData(tooltipFormatter, data, description)} style={{ height: "400px" }} />
      </div>
    </div>
  );
};

export default StateCaraChart;
