import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
export const EventSlice = createSlice({
  name: "event",
  initialState: initialState,
  reducers: {
    initEvent: (state, action) => {
      Object.keys(action.payload).forEach((value) => (state[value] = action.payload[value]));
    },
    setComputationParams: (state, action) => {
      state.data[action.payload.type].computation_params[action.payload.type2] = action.payload.value;
    },
    setModelParams: (state, action) => {
      state.data.model_params[action.payload.type] = action.payload.value;
    },
    setAutoRegressiveParams: (state, action) => {
      state.data.autoregressive_params[action.payload.type] = action.payload.value;
    },
    setTimeParams: (state, action) => {
      switch (action.payload.type2) {
        case "bucket_size":
          state.data[action.payload.type].time_params.bucket_size = action.payload.value;
          break;
        case "delay":
          state.data.historical.time_params[state.count ? "count" : "time"].delay = action.payload.value;
          break;
        case "length":
          state.data.historical.time_params[state.count ? "count" : "time"].length = action.payload.value;
          break;
        default:
          break;
      }
    },
    setIndicatorParams: (state, action) => {
      state.data.indicator_params[action.payload.type] = action.payload.value;
    },
    setBase: (state, action) => {
      state[action.payload.type] = action.payload.value;
    },

    setData: (state, action) => {
      state.data[action.payload.name] = action.payload.value;
    },
    addDataParams: (state, action) => {
      const current = action.payload.current;
      state.data.current.data_params.push(current);
      if (action.payload.historical) state.data.historical.data_params.push(action.payload.historical);
      state.sourceId = false;
    },
    removeDataParams: (state, action) => {
      state.data.current.data_params = state.data.current.data_params.filter((value) => value.measure_identifier !== action.payload);
      state.data.historical.data_params = state.data.historical.data_params.filter((value) => value.measure_identifier !== action.payload);
    },
  },
});
export const {
  initEvent,
  setComputationParams,
  setTimeParams,
  setBase,
  addDataParams,
  removeDataParams,
  setModelParams,
  setAutoRegressiveParams,
  setData,
  setIndicatorParams,
} = EventSlice.actions;
export default EventSlice.reducer;
