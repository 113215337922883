import store from "../store";
import { token } from "../reducers/ApplicationSlice";

/**
 *
 * @param {string} refresh_token
 */
export const updateToken = (refresh_token) => {
  store.dispatch(token(refresh_token));
  sessionStorage.setItem("token", refresh_token);
};
