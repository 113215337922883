import { capitalizeFirstLetter, makeSelectField } from "../../../utils/common";
import Select from "react-select";
import { PrimaryButton, RefreshButton } from "../../../components/Buttons/Button";
import TimeInput from "../../../components/Inputs/TimeInput";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { setCurrentTimeParams } from "../../../reducers/indicatorSlice";
import { getDataApi } from "../../../axios/DataManagementApi";
import { set } from "../../../reducers/dataSlice";
import { useTranslation } from "react-i18next";
import { setLabel } from "../../../reducers/ApplicationSlice";
import Icon from "@mdi/react";
import { mdiSwapHorizontal } from "@mdi/js";

const SimpleTime = () => {
  const { showLabel } = useSelector((state) => state.application);
  const dispatch = useDispatch();
  const { labels, timelabel } = useSelector((state) => state.data);
  const [labelOptions, setLabelOptions] = useState(makeSelectField(labels, ["label"]));
  const [refreshLabel, setRefreshLabel] = useState(false);
  const { t } = useTranslation(["timelabel", "common", "button"]);
  const { moduleData } = useSelector((state) => state.indicator);
  const { current } = moduleData;
  const { time_params } = current;

  const { start_time, end_time, label, time_label } = time_params;
  useEffect(() => {
    setLabelOptions(makeSelectField(labels, ["label"]));
  }, [labels]);
  const timeLabelOptions = timelabel
    .map((value) =>
      Object.keys(value).map((key) => {
        return { value: key, label: t(key) };
      }),
    )
    .flat();

  function handleLabel() {
    setRefreshLabel(true);
    getDataApi("label?observed_system_id=" + sessionStorage.getItem("observed_system_id"))
      .then((res) => {
        const labels = res.data;
        const labelList = labels.id.map((value, index) => {
          return {
            id: value,
            description: labels.description[index],
            label: labels.label[index],
            value: labels.value[index],
          };
        });
        dispatch(set({ type: "labels", data: labelList }));
        setRefreshLabel(false);
      })
      .catch((error) => {
        console.debug(error);
      });
  }

  function handleTimeParams(name, value) {
    dispatch(setCurrentTimeParams({ key: name, value: value }));
  }

  function handleTimeChange() {
    dispatch(setLabel(!showLabel));
  }

  return (
    <Fragment>
      <div className="flex gap-x-2">
        {showLabel ? (
          <div>
            <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("date", { ns: "common" }))}</label>
            <Select
              className="w-72"
              value={time_label !== undefined ? { value: time_label, label: t(time_label) } : undefined}
              isClearable={false}
              onChange={(newValue) => handleTimeParams("time_label", newValue.value)}
              options={timeLabelOptions}
            />
          </div>
        ) : (
          <div className="flex gap-x-0.5">
            <TimeInput label={capitalizeFirstLetter(t("start_time", { ns: "common" }))} value={start_time} name="start_time" onChange={handleTimeParams} />
            <TimeInput label={capitalizeFirstLetter(t("end_time", { ns: "common" }))} value={end_time} name="end_time" onChange={handleTimeParams} />
          </div>
        )}

        <div>
          <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("plage", { ns: "common" }))}</label>
          <div className="flex gap-x-0.5">
            <Select
              className="w-72"
              isClearable={true}
              value={labelOptions.find((value) => value.label === label)}
              options={labelOptions}
              onChange={(newValue, actionMeta) => handleTimeParams("label", actionMeta.action === "select-option" ? newValue.label : "")}
            />
            <RefreshButton isLoading={refreshLabel} disabled={refreshLabel} callback={() => handleLabel()} />
          </div>
        </div>
      </div>

      <div className="flex justify-center py-6">
        <PrimaryButton label={<Icon path={mdiSwapHorizontal} size={1} />} callback={handleTimeChange} />
      </div>
    </Fragment>
  );
};
export default SimpleTime;
