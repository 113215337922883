import { DeleteButton, PrimaryButton } from "../../components/Buttons/Button";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import axios from "axios";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

const DatabaseFeedbackTaskModal = ({ payload, emptyFilters = false }) => {
  const [databaseCreateLoading, setDatabaseCreateLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const isDisabled = Object.values(payload.connexion_to_db.credentials).some((value) => value === "");
  console.debug(payload);
  const uploadToDatabase = () => {
    dataLoadingToast({ isLoading: true });

    axios
      .post(`https://2vpagphaxa.execute-api.eu-west-3.amazonaws.com/staging/externaldataupload/${sessionStorage.getItem("observed_system_id")}`, payload, {
        headers: {
          Authorization: `Bearer 0kdaVC4AjRZVLGfvGqNz`,
        },
      })
      .then((res) => {
        setDatabaseCreateLoading(false);
        dataLoadingToast({ success: true, successMessage: "La tâche de remontée a été créé" });

        console.debug(res);
      })
      .catch((error) => {
        dataLoadingToast({ error: false, successMessage: "La tâche de remontée n'a été créé" });
        setDatabaseCreateLoading(false);
        console.debug(error);
      });
  };
  const handleAdd = () => {
    uploadToDatabase();
  };
  const handleCancel = () => {
    setModalIsOpen(false);
  };
  return (
    <div className={"flex justify-center"}>
      <PrimaryButton
        label={"Lancer la tâche"}
        callback={() => (emptyFilters ? setModalIsOpen(true) : uploadToDatabase())}
        disabled={isDisabled}
        isLoading={databaseCreateLoading}
      />

      <div>
        <Transition appear show={modalIsOpen} as={Fragment}>
          <Dialog as="div" className={"relative z-50"} onClose={setModalIsOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            </Transition.Child>
            <div className="fixed inset-0 ">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className=" h-auto w-96 rounded-2xl  bg-white p-2 text-left align-middle shadow-xl transition-all">
                    <div className={"flex flex-col gap-2"}>
                      <p className={"flex font-semibold"}> Pas de filtre</p>
                      <p className={"flex "}>êtes vous sûr de vouloir démarer la tâche de remontée de données ?</p>
                      <div className={"flex justify-center gap-x-0.5"}>
                        <DeleteButton label={"Annuler"} callback={handleCancel} />
                        <PrimaryButton label={"Lancer la tâche"} callback={handleAdd} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
};
export default DatabaseFeedbackTaskModal;
