import IndicatorDataConfiguration from "../IndicatorDataConfiguration";
import CrosscorrelationRightPanel from "./CrosscorrelationRightPanel";

const CrossCorrelation = () => (
  <div className="mx-4 grid gap-4 py-2 lg:grid-cols-2">
    <IndicatorDataConfiguration />
    <CrosscorrelationRightPanel />
  </div>
);
export default CrossCorrelation;
