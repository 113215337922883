import { isEmptyObject } from "../../utils/common";
import BasicTooltip from "../../components/Tooltip/BasicTooltip";
import Icon from "@mdi/react";
import { mdiCheck, mdiCross } from "@mdi/js";

function Simple({ result }) {
  const metricFields = [
    {
      id: 0,
      name: "Qualité du Modèle",
      type: <p>{result["metrics"]["r2"].toFixed(3) * 100 + "%"}</p>,
      tooltip: "R2: Coefficient de détermination",
    },
    {
      id: 1,
      name: "Erreur du modèle",
      type: <p>{"±" + result["metrics"]["rmse"].toFixed(3)}</p>,
      tooltip: "RMSE: Racine de l'erreur quadratique moyenne",
    },
    {
      id: 2,
      name: "Moyenne de la cible",
      type: <p>{result["metrics"]["target_mean"].toFixed(3)}</p>,
      tooltip: false,
    },
    {
      id: 3,
      name: "Fenêtre optimale de prédiction",
      type: <p>{result["metrics"]["opf"]}</p>,
      tooltip: "OPF",
    },
  ];
  const commonFields = [
    {
      id: 2,
      name: "Status",
      type: result.status === "success" ? <Icon path={mdiCheck} size={1} /> : <Icon path={mdiCross} size={1} />,
    },
    {
      id: 2,
      name: "Message",
      type: <p>{result.message} </p>,
      tooltip: false,
    },
    {
      id: 3,
      name: "Temps de calcul (en seconde)",
      type: <p>{result["computation_duration"]} </p>,
      tooltip: false,
    },
  ];
  const resultFields = [
    {
      id: 0,
      name: "Modèle utilisée",
      type: <p>{result.model} </p>,
    },
    {
      id: 1,
      tooltip: false,
      name: "Marge d'erreur",
      type: (
        <div>
          {metricFields.map((item, index) => (
            <div key={index} className="p-2 odd:bg-gray-50 ">
              <div className=" flex items-center gap-2">
                <label className={"text-sm  font-bold tracking-wide text-gray-700"}> {item.name}</label>
                {item.tooltip !== false && <BasicTooltip tip={item.tooltip} />}
              </div>
              {item.type}
            </div>
          ))}
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="rounded-lg  bg-white p-2 px-8 pb-8  pt-4 shadow-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-2xl font-semibold text-blue-900">{"Résultat"}</h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{"Entrainement simple"}</p>
        </div>
        <div className={"grid grid-cols-2"}>
          <div>
            {commonFields.map((item, index) => (
              <div key={index} className="p-2 odd:bg-gray-50 ">
                <div className=" flex items-center gap-2">
                  <label className={"text-sm  font-bold tracking-wide text-gray-700"}> {item.name}</label>
                  {item.tooltip !== false && <BasicTooltip tip={item.tooltip} />}
                </div>
                {item.type}
              </div>
            ))}
          </div>
          <div>
            {resultFields.map((item, index) => (
              <div key={index} className="p-2 odd:bg-gray-50 ">
                <div className=" flex items-center gap-2">
                  <label className={"text-sm  font-bold tracking-wide text-gray-700"}> {item.name}</label>
                  {item.tooltip !== false && <BasicTooltip tip={item.tooltip} />}
                </div>
                {item.type}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function ReconstructionModelResult({ result, name }) {
  if (isEmptyObject(result)) return null;

  function handleResult(name) {
    switch (name) {
      case 0:
        return <Simple result={result} />;
      case 1:
        return <Simple result={result} />;
    }
  }

  return <div className="mx-4 grid gap-4 py-2 ">{handleResult(name)}</div>;
}
