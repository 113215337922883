import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { makeSelectField, makeVariableField } from "../../../utils/common";
import { useEffect, useState } from "react";
import { setCharacterizationParams, setCrudeSource, setCurrentTimeParams } from "../../../reducers/indicatorSlice";
import SwitchButton from "../../../components/Selectors/SwitchButton";
import { aggregationOptions } from "../../../utils/field";
import NumericInput from "../../../components/Inputs/NumericInput";
import ConfigurationAlerteFormula from "../ConfigurationAlerte/ConfigurationAlerteFormula";
import { PrimaryButton } from "../../../components/Buttons/Button";
import { formatBackend } from "../../../utils/date.utils";
import { postIndicatorApi } from "../../../axios/IndicatorComputationApi";
import SimpleTime from "./SimpleTime";
import { dataLoadingToast } from "../../../components/Toasts/DataLoadingToast";

const CaracterizationSimple = () => {
  const { moduleData, selected_crude_source } = useSelector((state) => state.indicator);
  const { current, characterization_params } = moduleData;
  const { target_variable } = characterization_params;
  const { time_params, computation_params } = current;
  const { start_time, end_time, bucket_size, label, time_label } = time_params;
  const { crude_source } = useSelector((state) => state.data);
  const [crudeSourceOptions] = useState(makeSelectField(crude_source, ["name", "description"]));
  console.debug(selected_crude_source);
  const [targetOptions, setTargetOptions] = useState(
    selected_crude_source.map((value) => makeVariableField(0, value, crude_source.find((source) => source.id === value)["measure"], false)).flat(),
  );
  console.debug(targetOptions);
  useEffect(() => {
    setTargetOptions(
      selected_crude_source.map((value) => makeVariableField(0, value, crude_source.find((source) => source.id === value)["measure"], false)).flat(),
    );
  }, [crude_source, selected_crude_source]);
  const dispatch = useDispatch();
  const [aggregation, setAggregation] = useState(false);
  const [aggregationMode, setAggregationMode] = useState("avg");
  const [formula, setFormula] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState([]);
  const { showLabel } = useSelector((state) => state.application);

  function handleCrudeSource(selected) {
    dispatch(setCrudeSource([selected.value]));
  }

  function handleTimeParams(name, value) {
    dispatch(setCurrentTimeParams({ key: name, value: value }));
  }

  function handleCharacterizationParam(selected) {
    console.debug(selected);
    dispatch(setCharacterizationParams({ key: "target_variable", value: selected.map((value) => value.label) }));
  }
  console.debug(target_variable);
  const parseFormulaV1 = (formula, blocks) => {
    let ret = formula;
    blocks.forEach((block) => {
      Object.keys(block.preprocess).forEach((value) => {
        ret = ret.replaceAll(value, "['" + value + "']");
      });
    });
    return ret;
  };

  function getDataBlocks(source, sourceList, agregation = "identity") {
    const dataBlocks = [];

    for (let i = 0; i < source.length; i++) {
      const sourceId = source[i];
      const sourceData = sourceList.find((item) => item.id === sourceId);

      if (sourceData && sourceData.measure) {
        const measureKeys = Object.keys(sourceData.measure);
        const timeField = measureKeys.find((key) => sourceData.measure[key].visualization_type === "date");

        const preprocess = {};
        measureKeys.forEach((key) => {
          if (key !== timeField) {
            preprocess[key] = agregation;
          }
        });

        const dataBlock = {
          configured_sources: [],
          time_field: timeField ?? "internal",
          preprocess: preprocess,
          measure_identifier: sourceData.measure_identifier,
          status: -1,
        };

        dataBlocks.push(dataBlock);
      }
    }

    return dataBlocks;
  }

  function compute() {
    setIsLoading(true);

    const obs = sessionStorage.getItem("observed_system_id");
    const payload = {
      current: {
        data_params: getDataBlocks(selected_crude_source, crude_source, aggregation ? aggregationMode : "identity"),
        time_params: {
          start_time: formatBackend(start_time),
          end_time: formatBackend(end_time),
          bucket_size: aggregation ? bucket_size : -1,
          label: label,
        },
        computation_params: structuredClone(computation_params),
      },
      characterization_params: characterization_params,
    };
    if (showLabel) payload.current.time_params.time_label = time_label;
    payload.current.computation_params.formula = formula ? parseFormulaV1(computation_params.formula, payload.current.data_params) : "";
    postIndicatorApi("indicator/datacharacterization/" + obs, payload)
      .then((res) => {
        console.debug(res);
        const tmp = [...result];
        tmp.push(res.data.graphic_url.characterization);
        if (res.data.graphic_url) setResult(tmp);
        dataLoadingToast({ success: true });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.debug(error);
      });
  }
  console.debug(result);
  return (
    <div className="mx-4 my-2">
      <div className="block rounded border border-gray-200 bg-white px-4">
        <h2 className="text-2xl font-semibold text-blue-900">Paramètres des données</h2>
        <div className={"mb-2 flex justify-between gap-x-4 px-8"}>
          <SimpleTime />
        </div>
        <div className={"mb-2 border-b px-8 pb-2"}>
          <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Source de données</label>
          <Select
            placeholder={"Source de données"}
            value={crudeSourceOptions.filter((value) => selected_crude_source.includes(value.value))}
            options={crudeSourceOptions}
            onChange={handleCrudeSource}
          />
        </div>
        <div className={"mb-2 px-8"}>
          <SwitchButton label={"Agrégation"} value={aggregation} onChange={(value) => setAggregation(value)} />
        </div>
        {aggregation && (
          <div className={"mb-2 grid grid-cols-6 gap-x-4 px-16"}>
            <div>
              <label className="text-sm font-bold tracking-wide text-gray-700">Type</label>
              <Select
                options={aggregationOptions}
                value={aggregationOptions.find((value) => value.value === aggregationMode)}
                onChange={(newValue) => setAggregationMode(newValue.value)}
              />
            </div>
            <NumericInput label={"Intervalle de temps (s)"} value={bucket_size} name={"bucket_size"} onChange={handleTimeParams} />
          </div>
        )}
        <div className={"mb-2 px-8"}>
          <SwitchButton label={"Formule"} value={formula} onChange={(value) => setFormula(value)} />
        </div>
        {formula && (
          <div className={"mb-2 w-1/2 px-16"}>
            <ConfigurationAlerteFormula mode={"current"} verify={false} />
          </div>
        )}
        <div className={"mb-2 border-b px-8 pb-2"}>
          <label className="text-sm font-bold tracking-wide text-gray-700 dark:text-white">Données à considérer</label>
          <Select
            isMulti
            value={targetOptions.filter((value) => target_variable.includes(value.label))}
            placeholder={"Toute les données"}
            options={targetOptions}
            onChange={handleCharacterizationParam}
          />
        </div>
      </div>
      <div className={"flex justify-center"}>
        <PrimaryButton disabled={isLoading || selected_crude_source.length === 0} isLoading={isLoading} label={"Calculer"} callback={() => compute()} />
      </div>
      {result.map((res, key) => (
        <div key={key}>
          <p>Résultat #{key + 1}</p>
          <iframe className="min-h-[400px] w-full" src={res} />
        </div>
      ))}
      {/*{result !== null && result.mode !== undefined && <GraphCaracterizationStateTimeline dataCharacterization={result} />}*/}
    </div>
  );
};
export default CaracterizationSimple;
