import TextInput from "../../components/Selectors/TextInput";
import { Fragment } from "react";

const AuthentificationParameters = ({ authentification, setNestedParameters }) => {
  const handleParametersChange = (name, value) => {
    setNestedParameters(`data_to_upload.api_parameters.authentification.parameters.${name}`, value);
  };
  if (authentification.type === "PREDITIC") return;
  if (authentification.type === "TOKEN")
    return (
      <Fragment>
        <div>
          <TextInput label={"Clé"} value={authentification.parameters.key} name={"key"} onChange={handleParametersChange} />
        </div>
        <div>
          <TextInput label={"Valeur"} value={authentification.parameters.value} name={"value"} onChange={handleParametersChange} />
        </div>
      </Fragment>
    );
  if (authentification.type === "PWD")
    return (
      <Fragment>
        <div>
          <TextInput label={"Identifiant"} value={authentification.parameters.id} name={"id"} onChange={handleParametersChange} />
        </div>
        <div>
          <TextInput label={"Mot de passe"} value={authentification.parameters.pwd} name={"pwd"} onChange={handleParametersChange} />
        </div>
      </Fragment>
    );
};
export default AuthentificationParameters;
