import { Tab } from "@headlessui/react";
import { getUserPreferences, isEmptyObject } from "../../utils/common";
import TextInput from "../../components/Selectors/TextInput";
import BasicTooltip from "../../components/Tooltip/BasicTooltip";
import Icon from "@mdi/react";
import { mdiCheck, mdiClose } from "@mdi/js";
import CrossCorrelationChart from "./Result/CrossCorrelationChart";
import dayjs from "dayjs";
import { FormulaResult } from "./FormulaResult";
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { TableHeader } from "../../components/Table/TableHeader";
import { TableBody } from "../../components/Table/TableBody";
import { TablePagination } from "../../components/Table/TablePagination";

export function AcpResult({ data }) {
  const pageSizePref = getUserPreferences().find((preference) => preference.name === "pageSize")?.value ?? 10;
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSizePref,
  });
  const pagination = useMemo(() => {
    return {
      pageIndex,
      pageSize,
    };
  }, [pageIndex, pageSize]);
  const features = Object.keys(data.explained_variance_percent);
  const metrics = Object.keys(data.correlation[features[0]]);
  const [explainedVariancePercentTableData] = useState(
    Object.entries(data.explained_variance_percent).map((value) => {
      return { value: value[1], key: value[0] };
    }),
  );
  const [correlationTableData] = useState(
    metrics.map((correlation) => {
      const rowData = {
        correlation,
      };

      // Adding correlation values for each feature
      features.forEach((feature) => {
        rowData[feature] = data.correlation[feature][correlation];
      });

      return rowData;
    }),
  );

  const [contributionTableData] = useState(
    metrics.map((contribution) => {
      const rowData = {
        contribution,
      };

      // Adding contribution values for each feature
      features.forEach((feature) => {
        rowData[feature] = data.contribution[feature][contribution];
      });

      return rowData;
    }),
  );

  const columnHelper = createColumnHelper();
  const explainedVariancePercentColumns = [
    columnHelper.accessor("key", {
      id: "key",
      header: "variance expliquée",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("value", {
      id: "value",
      header: "valeur (%)",
      cell: (info) => info.getValue(),
    }),
  ];
  const explainedVariancePercentTable = useReactTable({
    data: explainedVariancePercentTableData,
    columns: explainedVariancePercentColumns,
    pageCount: (explainedVariancePercentTableData.length / pageSize).toFixed(0),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  const correlationColumns = Object.keys(correlationTableData[0]).map((key) =>
    columnHelper.accessor(key, {
      id: key,
      header: key,
      cell: (info) => {
        if (info.column.id.includes("feature")) return parseFloat(info.getValue().toFixed(2));
        return info.getValue();
      },
    }),
  );
  const correlationTable = useReactTable({
    data: correlationTableData,
    columns: correlationColumns,
    pageCount: (correlationTableData.length / pageSize).toFixed(0),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  const contributionColumns = Object.keys(contributionTableData[0]).map((key) =>
    columnHelper.accessor(key, {
      id: key,
      header: key,
      cell: (info) => {
        if (info.column.id.includes("feature")) return parseFloat((info.getValue() * 100).toFixed(2));
        return info.getValue();
      },
    }),
  );
  const contributionTable = useReactTable({
    data: contributionTableData,
    columns: contributionColumns,
    pageCount: (contributionTableData.length / pageSize).toFixed(0),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className="flex flex-col pt-4">
      <div className={"grid grid-rows-3 gap-y-4"}>
        <div className="block rounded-lg border border-gray-200 shadow">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden border-b border-gray-200 shadow">
                  <table className="min-w-full divide-y divide-gray-200">
                    <TableHeader table={explainedVariancePercentTable} />
                    <TableBody table={explainedVariancePercentTable} />
                  </table>
                </div>
              </div>
            </div>
          </div>
          <TablePagination table={correlationTable} />
        </div>
        <div className="block rounded-lg border border-gray-200 shadow">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden border-b border-gray-200 shadow">
                  <table className="min-w-full divide-y divide-gray-200">
                    <TableHeader table={correlationTable} />
                    <TableBody table={correlationTable} />
                  </table>
                </div>
              </div>
            </div>
          </div>
          <TablePagination table={correlationTable} />
        </div>
        <div className="block rounded-lg border border-gray-200 shadow">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden border-b border-gray-200 shadow">
                  <table className="min-w-full divide-y divide-gray-200">
                    <TableHeader table={contributionTable} />
                    <TableBody table={contributionTable} />
                  </table>
                </div>
              </div>
            </div>
          </div>
          <TablePagination table={contributionTable} />
        </div>
      </div>
    </div>
  );
}

export const CommonField = ({ result }) => {
  const commonFields = [
    {
      id: 1,
      name: "Status",
      type: result.status === "success" ? <Icon path={mdiCheck} size={1} /> : <Icon path={mdiClose} size={1} />,
      tooltip: false,
    },
    {
      id: 2,
      name: "Message",
      type: <p>{result.message} </p>,
      tooltip: false,
    },
    {
      id: 3,
      name: "Temps de calcul (en seconde)",
      type: <p>{result["computation_duration"]} </p>,
      tooltip: false,
    },
  ];
  return (
    <div className="grid grid-cols-3 rounded border border-gray-200 bg-white p-2 px-4">
      {commonFields.map((item, index) => (
        <div key={index} className="p-2">
          <div className=" flex items-center gap-2">
            <label className={"text-sm  font-bold tracking-wide text-gray-700"}> {item.name}</label>
            {item.tooltip !== false && <BasicTooltip tip={item.tooltip} />}
          </div>
          {item.type}
        </div>
      ))}
    </div>
  );
};

function IndicatorResult({ type, result }) {
  const resultField = (type) => {
    switch (type) {
      case 2:
        return (
          <div className="mx-4 py-2">
            {result.status === "success" && (
              <div className=" col-span-4 block rounded border border-gray-200 bg-white p-2 px-4 dark:border-gray-600 dark:bg-gray-800">
                <label className={"text-sm  font-bold tracking-wide text-gray-700"}> Taille de l&apos;échantillon: </label>
                {result.nb_total_samples}
                <EmptyDatesRender dates={result.empty_dates} />
                <div className={"py-2"}>
                  <Tab.Group>
                    <Tab.List>
                      <Tab className=" mx-1 cursor-pointer  rounded-lg bg-blue-800 px-4  py-2 font-semibold  text-white shadow-lg  ui-selected:ring-2 ui-selected:ring-blue-800 ui-not-selected:opacity-90">
                        Pearson
                      </Tab>
                      <Tab className=" mx-1 cursor-pointer  rounded-lg bg-blue-800 px-4  py-2 font-semibold  text-white shadow-lg  ui-selected:ring-2 ui-selected:ring-blue-800 ui-not-selected:opacity-90">
                        Kendall
                      </Tab>
                      <Tab className=" mx-1 cursor-pointer  rounded-lg bg-blue-800 px-4  py-2 font-semibold  text-white shadow-lg  ui-selected:ring-2 ui-selected:ring-blue-800 ui-not-selected:opacity-90">
                        Spearman
                      </Tab>
                    </Tab.List>
                    <Tab.Panels className={"py-2"}>
                      <Tab.Panel>
                        <iframe className="h-[40vh] w-full " src={result.graphic_url.pearson} />
                      </Tab.Panel>
                      <Tab.Panel>
                        <iframe className="h-[40vh] w-full " src={result.graphic_url.kendall} />
                      </Tab.Panel>
                      <Tab.Panel>
                        <iframe className="h-[40vh] w-full " src={result.graphic_url.spearman} />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </div>
            )}
            <CommonField result={result} />
          </div>
        );
      case 5:
        return (
          <div className="mx-4 py-2">
            {result.status === "success" && (
              <div className="col-span-3 col-start-2 block rounded border border-gray-200 bg-white p-2 px-4 dark:border-gray-600 dark:bg-gray-800">
                <EmptyDatesRender dates={result.empty_dates} />
                <FormulaResult
                  url={result.graphic_url.formula}
                  array={result.computed_field.map((value, index) => {
                    return { value: value, date: dayjs(result.timestamp[index]).unix() };
                  })}
                />
              </div>
            )}
            <CommonField result={result} />
          </div>
        );
      case 6:
        return (
          <div className="mx-4 py-2">
            {result.status === "success" && (
              <div className="col-span-3 col-start-2 block rounded border border-gray-200 bg-white p-2 px-4 dark:border-gray-600 dark:bg-gray-800">
                <div className="grid grid-cols-3 gap-1.5">
                  <div>
                    <label className="text-sm tracking-wide text-gray-700">{"Durée moyenne dans les états non sélectionnés"}</label>
                    <p className={"font-semibold"}>{convertSecondsToFormattedTime(result?.mtbs)}</p>
                  </div>
                  <div>
                    <label className="text-sm  tracking-wide text-gray-700">{"Durée moyenne dans les états sélectionnés"}</label>
                    <p className={"font-semibold"}>{convertSecondsToFormattedTime(result?.mtis)}</p>
                  </div>
                  <div>
                    <label className="text-sm  tracking-wide text-gray-700">{"Proportion de temps passé dans les états sélectionnés"}</label>
                    <p className={"font-semibold"}>{result?.str + "%"}</p>
                  </div>
                </div>
              </div>
            )}
            <CommonField result={result} />
          </div>
        );

      case 9:
        return (
          <div className="mx-4 py-2">
            {result.status === "success" && (
              <div className="col-span-3 col-start-2 block rounded border border-gray-200 bg-white p-2 px-4 dark:border-gray-600 dark:bg-gray-800">
                <CrossCorrelationChart data={result} />
              </div>
            )}
            <CommonField result={result} />
          </div>
        );
      case 10:
        return (
          <div className="mx-4 py-2">
            {result.status === "success" && (
              <div className="col-span-3 col-start-2 block rounded border border-gray-200 bg-white p-2 px-4 dark:border-gray-600 dark:bg-gray-800">
                <AcpResult data={result} />
              </div>
            )}
            <CommonField result={result} />
          </div>
        );
      default:
        return "WIP";
    }
  };
  if (isEmptyObject(result)) return null;
  return resultField(type);
}

export default IndicatorResult;

function transformObject(obj) {
  const transformedObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (!transformedObj[value]) {
      transformedObj[value] = [];
    }
    transformedObj[value].push(key);
  });
  return transformedObj;
}

function EmptyDatesRender({ dates }) {
  const formattedDates = transformObject(dates);
  if (formattedDates.length === 0) return <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">Aucune valeur manquante</label>;
  return Object.entries(formattedDates).map((value, index) => (
    <div key={index}>
      <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{value[0]}</label>
      <div className="mx-4 grid grid-cols-3 gap-x-1.5">
        {value[1].map((date, index) => (
          <TextInput key={index} disabled={true} value={date} />
        ))}
      </div>
    </div>
  ));
}

function convertSecondsToFormattedTime(seconds) {
  const duration = dayjs.duration(seconds, "seconds");
  const formattedHours = Math.floor(duration.asHours()).toString().padStart(2, "0");
  const formattedMinutes = duration.minutes().toString().padStart(2, "0");
  const formattedSeconds = duration.seconds().toString().padStart(2, "0");

  return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
}
