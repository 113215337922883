import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { postIndicatorApi } from "../../axios/IndicatorComputationApi";
import store from "../../store";
import { GraphHeader } from "../../components/Charts/GraphHeader";
import { isEmptyString } from "../../utils/common";
import dayjs from "dayjs";
import { formatBackend } from "../../utils/date.utils";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";

const Graph = () => {
  const { payload, link } = useSelector((state) => state.analyticsGraph);
  const [startTime, setStartTime] = useState(
    payload.graph_span === "long" ? dayjs().subtract(7, "day").format("YYYY-MM-DDTHH:mm:ss") : dayjs().subtract(6, "hour").format("YYYY-MM-DDTHH:mm:ss"),
  );
  const [endTime, setEndTime] = useState(dayjs().format("YYYY-MM-DDTHH:mm:ss"));
  const [isLoading, setIsLoading] = useState(false);
  const [graphUrl, setGraphUrl] = useState(link);
  const createDataCharacterizationPayload = useCallback(
    (dataSources, start = startTime, end = endTime) => {
      dataLoadingToast({ label: "Caractérisation en cours...", isLoading: true });
      setIsLoading(true);
      const data_params = [];
      const target_variables = [];
      dataSources.forEach((source) => {
        const preprocess = {};
        source.variables.forEach((variable) => {
          preprocess[variable.name] = "identity";
          target_variables.push(variable.name);
        });
        const crudeSources = store.getState().data.crude_source;
        const crude_source = crudeSources.find((value) => value.id === source.data_source_id);
        data_params.push({
          measure_identifier: crude_source.measure_identifier,
          configured_sources: [],
          status: -1,
          preprocess: preprocess,
          time_field: source.timestamp_field,
        });
      });
      const payload = {
        current: {
          time_params: {
            start_time: formatBackend(start),
            end_time: formatBackend(end),
            bucket_size: -1,
            label: "",
          },
          data_params: data_params,
          computation_params: {
            formula: "",
            function: {
              name: "identity",
              variables: {},
            },
          },
        },
        characterization_params: {
          target_variable: target_variables,
          threshold: 0.8,
          alpha: 0.05,
          random_nb_lags: -1,
        },
      };
      postIndicatorApi("indicator/datacharacterization/" + sessionStorage.getItem("observed_system_id"), payload)
        .then((res) => {
          console.debug(res);
          if (res.data.graphic_url) setDataCharacterization(res.data.graphic_url.characterization);
          else setDataCharacterization(null);
          dataLoadingToast({ success: true });
          setIsLoading(false);
        })
        .catch((error) => {
          console.debug(error);
          dataLoadingToast({ error: true });
          setIsLoading(false);
        });
    },
    [endTime, startTime],
  );

  useEffect(() => {
    createDataCharacterizationPayload(payload.data_sources);
  }, [createDataCharacterizationPayload, payload]);

  const [dataCharacterization, setDataCharacterization] = useState(null);
  const onChange = (start, end) => {
    const urlObj = new URL(graphUrl);
    urlObj.searchParams.set("from", start);
    urlObj.searchParams.set("to", end);
    setGraphUrl(urlObj.toString());
  };
  const onCreate = (startDate, endDate) => {
    setStartTime(startDate);
    setEndTime(endDate);
    onChange(dayjs(startDate).unix() * 1000, dayjs(endDate).unix() * 1000);
    createDataCharacterizationPayload(payload.data_sources, startDate, endDate);
  };
  return (
    <div>
      <GraphHeader prev={"/analytics/graph"} onChange={onChange} onCreate={onCreate} isLoading={isLoading} startTime={startTime} endTime={endTime} />
      {!isEmptyString(graphUrl) && (
        <div className="m-8 block h-[70vh] rounded border border-gray-200 bg-white p-4">
          <iframe className="h-full  w-full " src={graphUrl} />
        </div>
      )}
      <div className={"px-8"}>{dataCharacterization !== null && <iframe className="min-h-[20vh] w-full" src={dataCharacterization} />}</div>
    </div>
  );
};
export default Graph;
