import Select from "react-select";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { isEmptyObject } from "../../utils/common";

const FieldsToUpload = ({ response, crudeSourceId, selectedFields, setSelectedFields, setFieldToUpload }) => {
  const { crude_source } = useSelector((state) => state.data);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const options = response.map((res) => {
      return { label: res, value: res };
    });
    setOptions(options.filter((option) => selectedFields.find((field) => field.value === option.value) === undefined));
  }, [response, selectedFields]);
  const [measures, setMeasures] = useState([]);
  useEffect(() => {
    const measureOjbect = crude_source.find((crude_source) => crude_source.id === crudeSourceId)?.measure;
    if (!isEmptyObject(measureOjbect)) setMeasures(Object.keys(measureOjbect));
  }, [crudeSourceId, crude_source]);
  const handleSelect = (newValue, actionMeta) => {
    if (actionMeta.action === "select-option") {
      const indexToReplace = selectedFields.findIndex((field) => field.name === actionMeta.name);
      if (indexToReplace === -1) {
        setFieldToUpload(newValue.value, actionMeta.name);
        const copySelectedFields = structuredClone(selectedFields);
        copySelectedFields.push({ ...newValue, name: actionMeta.name });
        setSelectedFields(copySelectedFields);
      } else {
        setFieldToUpload(newValue.value, actionMeta.name);
        const copySelectedFields = structuredClone(selectedFields);
        copySelectedFields[indexToReplace] = { ...newValue, name: actionMeta.name };
        setSelectedFields(copySelectedFields);
      }
    } else if (actionMeta.action === "clear") {
      const indexToReplace = selectedFields.findIndex((field) => field.name === actionMeta.name);
      setFieldToUpload(actionMeta.removedValues[0].value, null);
      const copySelectedFields = structuredClone(selectedFields);
      copySelectedFields.splice(indexToReplace, 1);
      setSelectedFields(copySelectedFields);
    }
  };
  return (
    <div className={"px-4"}>
      <label className="font-bold tracking-wide text-gray-700">{"Liaison"}</label>
      <div className={"grid grid-cols-4 gap-1 "}>
        {measures.map((measure, index) => (
          <div key={index}>
            <p>{measure}</p>
            <Select isClearable options={options} name={measure} onChange={handleSelect} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default FieldsToUpload;
