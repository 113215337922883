import TextInput from "../../components/Selectors/TextInput";
import PasswordInput from "../../components/Inputs/PasswordInput";

export const ElasticSearchParam = ({ credential, handleCredentialsChange }) => (
  <div className={" w-full p-4"}>
    <div className={"w-1/2"}>
      <div className={"flex gap-x-2"}>
        <div className={"grow"}>
          <TextInput label={"Url"} value={credential.url} name="url" onChange={handleCredentialsChange} />
        </div>
        <TextInput label={"Api KEY"} value={credential.api_key} name="api_key" onChange={handleCredentialsChange} />
      </div>
      <div className={"flex gap-x-2"}>
        <TextInput label={"User"} value={credential.user} name="user" onChange={handleCredentialsChange} />
        <form>
          <PasswordInput width={"w-fit"} label={"Password"} value={credential.pw} name={"pw"} onChange={handleCredentialsChange} />
        </form>
      </div>
    </div>
  </div>
);
