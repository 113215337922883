import IndicatorAccordion from "../IndicatorAccordion";
import IndicatorRecurentForm from "../IndicatorRecurentForm";
import OutlierValidationParameter from "./OutlierValidationParameter";

const OutlierValidationRightPanel = () => (
  <div className="block rounded border border-gray-200 bg-white px-4 dark:border-gray-600 dark:bg-gray-800">
    <IndicatorAccordion label={"Programmation d'exécution du module"} composant={<IndicatorRecurentForm />} />
    <IndicatorAccordion label={"Paramètrage"} composant={<OutlierValidationParameter />} />
  </div>
);
export default OutlierValidationRightPanel;
