import IndicatorAccordion from "../IndicatorAccordion";
import DetectionAnomalieHistorical from "./DetectionAnomalieHistorical";
import DetectionAnomalieParameters from "./DetectionAnomalieParameters";
import DetectionAnomalieAlertSetting from "./DetectionAnomalieAlertSetting";
import IndicatorRecurentForm from "../IndicatorRecurentForm";
import IndustrialCurrent from "../IndicatorIndustrialHisto/IndustrialCurrent";

const DetectionAnomalieRightPanel = () => (
  <div className="block rounded border border-gray-200 bg-white px-4 dark:border-gray-600 dark:bg-gray-800">
    <IndicatorAccordion label={"Programmation d'exécution du module"} composant={<IndicatorRecurentForm />} open={false} />
    <IndicatorAccordion label={"Paramètres du bloc courant  "} composant={<IndustrialCurrent />} />

    <IndicatorAccordion label={"Paramètres du bloc historique"} composant={<DetectionAnomalieHistorical />} />
    <IndicatorAccordion label={"Paramètres"} composant={<DetectionAnomalieParameters />} />
    <IndicatorAccordion label={"Alerte"} composant={<DetectionAnomalieAlertSetting />} />
  </div>
);
export default DetectionAnomalieRightPanel;
