//React librairies
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigation } from "react-router-dom";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

const PrivateRoute = () => {
  //Use navigate
  const navigation = useNavigation();
  //Use state
  const application = useSelector((state) => state.application);
  return application.isAuthenticated ? (
    <div className="flex min-h-screen flex-auto shrink-0 flex-col bg-gray-100 text-black antialiased">
      {application.isAuthenticated && <Header />}
      {application.isAuthenticated && <Sidebar />}
      <div
        className={`ml-14 mt-14  h-full ${application.showSidebar ? "md:ml-64" : "md:ml-14"} ${navigation.state === "loading" && "cursor-progress opacity-50"}`}
      >
        {navigation.state === "loading" && (
          <div role="status" className="absolute left-1/2 top-2/4 -translate-x-1/2 -translate-y-1/2 text-blue-900">
            <Icon className={"animate-spin "} path={mdiLoading} size={2} />
          </div>
        )}
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};
export default PrivateRoute;
