import HorizontalLine from "../HorizontalLine";

const TableWrapper = ({ label, component }) => (
  <div className={"m-4  min-h-[400px]  rounded-lg border border-gray-200 bg-white"}>
    <h2 className="ml-4 text-3xl font-semibold text-blue-900">{label}</h2>
    <HorizontalLine />
    {component}
  </div>
);
export default TableWrapper;
