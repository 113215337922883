import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import { PrimaryButton } from "../../../components/Buttons/Button";
import StatusModal from "../../../components/Modals/StatusModal";
import { mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import { setModuleData } from "../../../reducers/indicatorSlice";

const IndicatorId2 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { indicator_id, defining_status } = useSelector((state) => state.indicator.moduleData);
  const { configured_source, crude_source } = useSelector((state) => state.data);
  const displayName = (id) => {
    if (defining_status.type === "CRUDE_SOURCE") return configured_source.find((cs) => cs.id === id).description;
    if (defining_status.type === "OBSERVED_SYSTEM") return crude_source.find((cs) => cs.id === id).name;
  };
  const handleDelete = (id) => {
    const updates = structuredClone(indicator_id);
    delete updates[id];
    dispatch(setModuleData({ key: "indicator_id", value: updates }));
  };
  if (defining_status.type === "") return null;
  return (
    <div>
      <StatusModal isOpen={isOpen} setIsOpen={setIsOpen} />
      {Object.entries(indicator_id).map((value, index) => (
        <div key={index} className={"flex gap-0.5"}>
          <p> {displayName(value[0])}</p>
          <button onClick={() => handleDelete(value[0])}>
            <Icon path={mdiDelete} size={1} className={"hover:text-red-950 "} />
          </button>
        </div>
      ))}
      <div className={"flex justify-center"}>
        <PrimaryButton label={"ajouter"} callback={() => setIsOpen(true)} />
      </div>
    </div>
  );
};
export default IndicatorId2;
