import FormuleAnalytiqueRightPanel from "./FormuleAnalytiqueRightPanel";
import IndicatorDataConfiguration from "../IndicatorDataConfiguration";

const FormuleAnalytique = () => (
  <div className="mx-4 grid gap-4 py-2 lg:grid-cols-2">
    <IndicatorDataConfiguration />
    <FormuleAnalytiqueRightPanel />
  </div>
);
export default FormuleAnalytique;
