import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

//icons
import Icon from "@mdi/react";
import { mdiDotsVertical as VerticalDots } from "@mdi/js";
import classNames from "classnames";

function DropdownMenu({ options, bg = false }) {
  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button
        className={classNames(bg ? "rounded-lg bg-blue-900 text-white shadow-lg hover:bg-blue-900/90" : "text-blue-900", "z-50 cursor-pointer p-1.5")}
      >
        <Icon path={VerticalDots} className={"w-8"} />
      </Menu.Button>
      {options.length > 0 && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-50 mt-2 min-w-[16rem] origin-top-right divide-y divide-gray-100 rounded-md bg-white text-black shadow-lg ring-1 ring-black/5 focus:outline-none ">
            <div className="p-1">
              {options.map((item, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <button
                      key={item.id}
                      onClick={item.onClick}
                      className={`${active ? "text-white" : ""} group  flex w-full whitespace-nowrap rounded-md p-2 text-sm hover:bg-blue-800`}
                    >
                      <Icon path={item.path} size={0.9} />
                      <p className="ml-2">{item.label}</p>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  );
}

export default DropdownMenu;

export function createDropdownOption(id, label, onClickFunction, path = "") {
  return {
    id: id,
    label: label,
    onClick: onClickFunction,
    path: path,
  };
}
