import { isEmptyString } from "./common";

/***
 * Check if some field are empty or incorrect
 * @param value
 */
export const verifyInput = (value) => {
  console.log(value);
  if (isEmptyString(value.name) || isEmptyString(value.type)) {
    return false;
  }
  switch (value.type) {
    case "int":
      if (isEmptyString(value.mapping_information.min.toString()) || isEmptyString(value.mapping_information.max.toString())) {
        return false;
      } else {
        const min = value.mapping_information.min;
        const max = value.mapping_information.max;
        if (min > max) return false;
      }
      break;
    case "nominal":
    case "ordered":
      return value.visualization_format.label.length > 0;
    default:
      return true;
  }
  return true;
};
