import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { canRenderComponent, isEmptyString, makeSelectField } from "../../utils/common";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { COMPONENTS } from "../../constants/component";
import { PrimaryButton } from "../../components/Buttons/Button";
import { setLink, setPayload } from "../../reducers/graphSlice";
import { postDataApi } from "../../axios/DataManagementApi";
import { dataLoadingToast } from "../../components/Toasts/DataLoadingToast";
import dayjs from "dayjs";
import { formatBackend } from "../../utils/date.utils";

const GraphStatetimeline = () => {
  const initGraphValue = {
    start_time: formatBackend(dayjs().subtract(7, "days").toString()),
    end_time: formatBackend(dayjs().toString()),
    modalities: [],
    file_type: "db",
    parameters: {
      crude_source_id: "",
      configured_sources: [],
      nocheck_display: false,
      additional_parameters: [],
    },
    timestamp_field: "",
    status_field: "",
    status_description: "",
    timezone: "Europe/Paris",
  };
  const [isLoading, setIsLoading] = useState(false);
  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [authorizedGraph, setAuthorizedGraph] = useState(canRenderComponent(COMPONENTS.GRAPHIC_CREATION));

  useEffect(
    () => () => {
      setAuthorizedGraph(canRenderComponent(COMPONENTS.GRAPHIC_CREATION));
    },
    [authorizedComponent],
  );
  const dispatch = useDispatch();
  const history = useNavigate();

  const { crude_source } = useSelector((state) => state?.data);
  const [data, setData] = useState(initGraphValue);
  const [selectedVariable, setSelectedVariable] = useState("");

  function isCategorical(variable) {
    const visualizationType = variable[1].visualization_type;
    return visualizationType === "nominal_categorical" || visualizationType === "ordered_categorical";
  }

  const makeOptions = () => {
    const options = [];
    if (isEmptyString(data.parameters.crude_source_id)) return options;
    const variableData = crude_source.find((crudeSource) => crudeSource.id === data.parameters.crude_source_id).measure;
    const filter = Object.entries(variableData).filter((variable) => isCategorical(variable));
    filter.forEach((value) =>
      options.push({
        value: value[0],
        label: value[0],
      }),
    );
    return options;
  };

  const createGraph = () => {
    setIsLoading(true);
    dataLoadingToast({ label: "Création du graphique", isLoading: true });
    postDataApi("othergraphic/statetimeline/" + sessionStorage.getItem("observed_system_id"), data)
      .then((res) => {
        setIsLoading(false);
        dispatch(setPayload(data));
        dispatch(setLink(res.data["creation_data"]["panel_url"]));
        history("/analytics/graph/view/statetimeline");
        dataLoadingToast({ successMessage: "Création terminé", success: true });
      })
      .catch((error) => {
        setIsLoading(false);
        console.debug(error);
        dataLoadingToast({ errorMessage: error.response.data.message, error: true });
      });
  };

  const sourceSelect = (newValue) => {
    const newData = structuredClone(data);
    setSelectedVariable("");
    newData.parameters.crude_source_id = newValue.value;
    newData.modalities = [];
    newData.status_description = "";
    newData.timestamp_field = "";
    newData.status_field = "";
    setData(newData);
  };
  const variableSelect = (newValue) => {
    const newData = structuredClone(data);
    const variableData = crude_source.find((crudeSource) => crudeSource.id === data.parameters.crude_source_id).measure;
    const updates = variableData[newValue.value];
    const filter = Object.entries(variableData).filter((variable) => variable[1].visualization_type === "date");
    newData.timestamp_field = filter[0][0] ?? "internal";
    newData.status_field = newValue.value;
    newData.modalities = updates.label.map((modality) => modality.label);
    setSelectedVariable(newValue.value);
    newData.status_description = updates.description;
    setData(newData);
  };
  return (
    authorizedGraph && (
      <div className="m-8">
        <div className="block rounded border border-gray-200 bg-white px-4 py-2">
          <div className={"py-0.5"}>
            <label className="font-bold tracking-wide text-gray-700 ">Source de données</label>
            <Select
              isSearchable={false}
              placeholder={"..."}
              onChange={sourceSelect}
              value={makeSelectField(crude_source, ["name"]).find((value) => value.value === data.parameters.crude_source_id)}
              options={makeSelectField(crude_source, ["name"])}
            />
          </div>
          <div className={"py-0.5"}>
            <label className="font-bold tracking-wide text-gray-700 ">Variable à considérer</label>
            <Select
              isSearchable={false}
              placeholder={"..."}
              onChange={variableSelect}
              value={makeOptions().find((value) => value.value === selectedVariable) ?? ""}
              options={makeOptions()}
            />
          </div>
        </div>

        <div className="col-span-5 flex justify-center">
          <PrimaryButton label={"Créer"} disabled={isLoading} callback={createGraph} />
        </div>
      </div>
    )
  );
};
export default GraphStatetimeline;
