import { useSelector } from "react-redux";

const VariableRecap = ({ crude_source_id }) => {
  console.debug(crude_source_id);
  const { current, historical, mode } = useSelector((state) => state.indicator.moduleData);
  const { crude_source } = useSelector((state) => state.data);
  const data_params_current = current.data_params;
  const data_params_histo = historical?.data_params ?? [];
  const source = crude_source.find((value) => value.id === crude_source_id);
  const data_param_current = data_params_current.find((value) => value.measure_identifier === source.measure_identifier);
  const data_param_histo = data_params_histo.find((value) => value.measure_identifier === source.measure_identifier);

  const variablesCurrent = Object.entries(data_param_current.preprocess);
  const variablesHisto = data_param_histo === undefined ? [] : Object.entries(data_param_histo.preprocess);
  const rows = variablesCurrent.map((variable, index) => (
    <div className={"flex gap-x-4 px-2 py-0.5 even:bg-white"} key={index}>
      <div className={"w-32 truncate font-semibold"}>{variable[0]}</div>
      <div className={"w-20 truncate italic"}>{variable[1]}</div>
      {mode === 0 && <div className={"w-20 truncate italic"}>{variablesHisto[index][1]}</div>}
    </div>
  ));
  return (
    <div className={"w-fit rounded-t border border-blue-900 bg-gray-100 text-black shadow-md"}>
      <div className={"flex gap-x-4 rounded-t  bg-gray-200 px-2 py-1 font-semibold"}>
        <span className={"w-32"} />
        <p className={"w-20 truncate font-bold tracking-wide"}>Courrant</p>
        {mode === 0 && <p className={"w-20 truncate font-bold tracking-wide"}>Historique</p>}
      </div>
      <div className={"flex gap-x-4 px-2 py-1 text-blue-900  even:bg-white"}>
        <div className={"w-32 truncate font-semibold"}>Variable temporelle</div>
        <div className={"w-20 truncate italic"}>{data_param_current.time_field}</div>
        {mode === 0 && <div className={"w-20 truncate italic"}>{data_param_histo.time_field}</div>}
      </div>
      {rows}
    </div>
  );
};
export default VariableRecap;
