import { useState } from "react";
import { useParams } from "react-router-dom";
import { PrevButton, PrimaryButton } from "../Buttons/Button";
import { postDataApi } from "../../axios/DataManagementApi";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Select from "react-select";
import { capitalizeFirstLetter, getConfigurationsFromCrudeSource, isEmptyString, makeSelectField, makeVariableField } from "../../utils/common";
import { useTranslation } from "react-i18next";
import HorizontalLine from "../HorizontalLine";
import TimeSelector from "../TimeSelector"; // Import the required modules

// Import the required modules
const XLSX = require("xlsx");

const fileFormatOptions = [
  {
    value: "csv",
    label: "CSV",
  },
  {
    value: "xlsx",
    label: "Excel XLSX",
  },
];

function downloadCSV(data) {
  // Create the CSV content
  let csvContent = "";
  const headers = Object.keys(data);
  csvContent += headers.join(",") + "\n";
  const rows = data[headers[0]].length;
  for (let i = 0; i < rows; i++) {
    const rowValues = headers.map((header) => data[header][i]);
    csvContent += rowValues.join(",") + "\n";
  }

  // Create a Blob object with the CSV content
  const blob = new Blob([csvContent], { type: "text/csv" });

  // Create a temporary anchor element
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "data.csv";

  // Append the anchor element to the DOM
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the anchor element from the DOM
  document.body.removeChild(link);
}

function downloadXLSX(data) {
  // Create a new workbook and sheet
  const workbook = XLSX.utils.book_new();
  const sheetName = "Sheet1";
  const sheetData = [];

  // Add the headers to the sheet data
  const headers = Object.keys(data);
  sheetData.push(headers);

  // Add the rows to the sheet data
  const rows = data[headers[0]].length;
  for (let i = 0; i < rows; i++) {
    const rowValues = headers.map((header) => data[header][i]);
    sheetData.push(rowValues);
  }

  // Create a worksheet and add it to the workbook
  const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

  // Convert the workbook to a buffer
  const buffer = XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });

  // Create a Blob object from the buffer
  const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

  // Create a temporary anchor element
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "data.xlsx";

  // Append the anchor element to the DOM
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the anchor element from the DOM
  document.body.removeChild(link);
}

const CrudeSourceExport = () => {
  const { t } = useTranslation(["common", "tooltip", "button"]);

  const { id } = useParams();
  const structure_id = useSelector((state) => state.selected.structure);
  const observed_system_id = useSelector((state) => state.selected.observed_system);
  const source = useSelector((state) => state.data.crude_source).find((value) => value.id === id);
  const [fileFormat, setFileFormat] = useState(fileFormatOptions[0]);
  const [startTime, setStartTime] = useState(dayjs().format("YYYY-MM-DD HH:mm"));
  const [endTime, setEndTime] = useState(dayjs().format("YYYY-MM-DD HH:mm"));
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [collectedBy, setCollectedBy] = useState([]);
  const [timestampName, setTimestampName] = useState({
    label: "internal",
    value: id + "_internal",
    crude_source_id: id,
  });
  const handleExport = () => {
    setLoading(true);
    const payload = {
      crude_source_id: id,
      collected_by: collectedBy.length === 0 ? [] : collectedBy.map((value) => value.value),
      structure_id: structure_id,
      observed_system_id: observed_system_id,
      fields: fields.map((field) => field.label),
      timestamp_name: timestampName.label,
      time_range: isEmptyString(startTime) && isEmptyString(endTime) ? [] : [dayjs(startTime).toISOString(), dayjs(endTime).toISOString()],
    };

    postDataApi("export", payload)
      .then((res) => {
        setLoading(false);
        switch (fileFormat.value) {
          case "csv":
            downloadCSV(res.data.data);
            break;
          case "xlsx":
            downloadXLSX(res.data.data);
            break;
          default:
            console.debug(res.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.debug(error);
      });
  };
  const handleTime = (start, end) => {
    setStartTime(start);
    setEndTime(end);
    console.debug(start, end);
  };
  const handleFieldsChange = (newValue) => {
    setFields(newValue);
  };
  const handleCollectedByChange = (newValue) => {
    setCollectedBy(newValue);
  };
  const handleTimestampNameChange = (newValue) => {
    setTimestampName(newValue);
  };
  const handleFileFormatChange = (newValue) => {
    setFileFormat(newValue);
  };
  return (
    <div className={"p-8"}>
      <div className="w-full rounded-lg border border-gray-300 bg-white shadow-md">
        <div className={"flex gap-x-0.5 px-4 pt-4"}>
          <PrevButton url={"/"} />
          <h2 className="text-3xl font-semibold text-blue-800">{capitalizeFirstLetter(t("data_export"))}</h2>
        </div>
        <HorizontalLine />
        <div className="grid grid-cols-1 gap-4 p-4 lg:grid-cols-3">
          <div>
            <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("plage"))}</label>
            <div className={"-my-1"}>
              <TimeSelector end_time={endTime} start_time={startTime} callback={handleTime} />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 p-4 lg:grid-cols-3">
          <div>
            <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("configurations"))}</label>
            <Select
              isSearchable={false}
              placeholder={"Toutes..."}
              isMulti
              value={collectedBy}
              options={makeSelectField(getConfigurationsFromCrudeSource(id), ["description"])}
              onChange={handleCollectedByChange}
            />
          </div>

          <div>
            <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("target"))}</label>
            <Select
              isSearchable={false}
              placeholder={"Toutes les variables..."}
              isMulti
              value={fields}
              options={makeVariableField(4, id, source.measure)}
              onChange={handleFieldsChange}
              closeMenuOnSelect={false}
            />
          </div>
          <div>
            <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("time_variable"))}</label>
            <Select
              isSearchable={false}
              isClearable={false}
              value={timestampName}
              options={makeVariableField(1, id, source.measure)}
              onChange={handleTimestampNameChange}
            />
          </div>
          <div>
            <label className="text-sm font-bold tracking-wide text-gray-700">{capitalizeFirstLetter(t("file_type"))}</label>
            <Select isSearchable={false} isClearable={false} value={fileFormat} options={fileFormatOptions} onChange={handleFileFormatChange} />
          </div>
        </div>
        <div className={"px-4 pb-4 lg:flex lg:justify-end"}>
          <PrimaryButton label={capitalizeFirstLetter(t("export", { ns: "button" }))} callback={handleExport} disabled={loading} isLoading={loading} />
        </div>
      </div>
    </div>
  );
};
export default CrudeSourceExport;
