import { useEffect, useState } from "react";
import Select from "react-select";
import { setAutoRegressiveParams } from "../../reducers/EventSlice";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/Selectors/TextInput";

const AutoRegressiveParam = () => {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.event.data.autoregressive_params);
  const data_params = useSelector((state) => state.event.data.current.data_params);
  const [variables, setVariables] = useState([]);
  useEffect(() => {
    const tmp = [];
    setVariables([]);
    data_params.forEach((bloc) => {
      Object.keys(bloc.preprocess).forEach((variable) =>
        tmp.push({
          label: variable,
          value: variable,
        }),
      );
    });
    if (
      !tmp.includes({
        value: params.target_variable,
        label: params.target_variable,
      })
    )
      dispatch(setAutoRegressiveParams({ type: "target_variable", value: "" }));
    setVariables(tmp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_params]);
  return (
    <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
      <h2 className="text-2xl font-semibold text-blue-900">autoregressive_params</h2>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"Variable cible"}</label>
          <Select
            value={variables[variables.findIndex((value) => value.value === params.target_variable)]}
            isSearchable={true}
            onChange={(option) => {
              dispatch(setAutoRegressiveParams({ type: "target_variable", value: option.label }));
            }}
            options={variables}
          />
        </div>
        <div>
          <label className=" text-sm font-bold tracking-wide text-gray-700 dark:text-white">{"Variable(s) de prédiction"}</label>
          <Select
            value={params.predictor_variables}
            isSearchable={true}
            isMulti={true}
            onChange={(option) => {
              console.debug(option);
              dispatch(setAutoRegressiveParams({ type: "predictor_variables", value: option }));
            }}
            options={variables}
          />
        </div>
        <TextInput
          name={"order"}
          min={1}
          step={"1"}
          label={"order"}
          value={params.order}
          type={"number"}
          onChange={(name, value) =>
            dispatch(
              setAutoRegressiveParams({
                type: name,
                value: parseFloat(value),
              }),
            )
          }
        />
        <TextInput
          name={"training_data_ratio"}
          min={0}
          max={1}
          step={"0.01"}
          label={"training_data_ratio"}
          value={params.training_data_ratio}
          type={"number"}
          onChange={(name, value) =>
            dispatch(
              setAutoRegressiveParams({
                type: name,
                value: parseFloat(value),
              }),
            )
          }
        />
      </div>
    </div>
  );
};
export default AutoRegressiveParam;
