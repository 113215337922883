import { postRequest } from "../axios/axios";
import store from "../store";
import { push, set } from "../reducers/dataSlice";
import { dataLoadingToast } from "../components/Toasts/DataLoadingToast";
import { getDataApi } from "../axios/DataManagementApi";
import { set as setApp } from "../reducers/ApplicationSlice";
import { dataList } from "../utils/common";

const makeUrl = (url) => {
  const { REACT_APP_API } = process.env;
  return REACT_APP_API + url;
};

export const formatThreshold = (data) => {
  const threshold = {
    formula: {},
    description: "",
    label: "",
    function: {},
    creation_timestamp: "",
    observed_system_id: "",
  };

  const thresholdList = [];
  if (data.label !== undefined)
    for (let i = 0; i < data.label.length; i++) {
      const tmp = Object.create(threshold);
      tmp.label = data.label[i];
      tmp.description = data.description[i];
      tmp.function = data.function[i];
      tmp.formula = data.formula[i];
      tmp.creation_timestamp = data.creation_timestamp[i];
      if (data.observed_system_id) tmp.observed_system_id = data.observed_system[i];
      else tmp.observed_system_id = sessionStorage.getItem("observed_system_id");
      thresholdList.push(tmp);
    }
  store.dispatch(
    set({
      type: "threshold",
      data: thresholdList,
    }),
  );
};

export const getUpperLevel = () => {
  const init_time = Date.now();
  dataLoadingToast({ isLoading: true });

  const structurePromise = getDataApi("structure?structure_id=" + sessionStorage.getItem("structure_id"))
    .then((res) => res.data.data)
    .catch((error) => {
      console.debug(error);
      return [];
    });

  const storageSystemPromise = getDataApi("storage_system?stat=true&structure_id=" + sessionStorage.getItem("structure_id"))
    .then((res) => dataList(res.data.data))
    .catch((error) => {
      console.debug(error);
      return [];
    });

  const observedSystemPromise = getDataApi("observed_system?structure_id=" + sessionStorage.getItem("structure_id"))
    .then((res) => dataList(res.data.data))
    .catch((error) => {
      console.debug(error);
      return [];
    });

  Promise.all([structurePromise, storageSystemPromise, observedSystemPromise])
    .then(([structureData, storageSystemData, observedSystemData]) => {
      store.dispatch(set({ type: "structure", data: structureData }));
      store.dispatch(set({ type: "storage_system", data: storageSystemData }));
      store.dispatch(set({ type: "observed_system", data: observedSystemData }));

      store.dispatch(setApp({ type: "dataLoaded", payload: true }));
      sessionStorage.setItem("dataLoadingTime", (Date.now() - init_time).toString());

      if (sessionStorage.getItem("observed_system_id")) {
        getMiddleLevel(sessionStorage.getItem("observed_system_id"));
      } else dataLoadingToast({ success: true });
    })
    .catch((error) => console.debug(error));
};

export const getMiddleLevel = (observed_system_id) => {
  const init_time = Date.now();
  Promise.all([
    getDataApi("crude_source?stat=true&observed_system_id=" + observed_system_id),
    getDataApi("configured_source?observed_system_id=" + observed_system_id),
  ])
    .then((res) => {
      dataLoadingToast({ success: true });
      store.dispatch(set({ type: "crude_source", data: dataList(res[0]["data"]["data"]) }));
      store.dispatch(
        set({
          type: "configured_source",
          data: dataList(res[1]["data"]["data"]),
        }),
      );
      sessionStorage.setItem("dataSourceLoadingTime", (Date.now() - init_time).toString());
    })
    .catch((error) => {
      console.debug(error);
    });
};
export const addThreshold = (data, obs, callback) => {
  postRequest(makeUrl("threshold?observed_system_id=" + obs), data).then(() => {
    store.dispatch(
      push({
        type: "threshold",
        data: {
          label: data.label,
          observed_system_id: obs,
          description: data.description,
          formula: data.formula,
          function: {},
          creation_timestamp: new Date(Date.now()).toLocaleString(),
        },
      }),
    );
    callback();
  });
};
