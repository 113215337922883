import { TableHeader } from "./TableHeader";
import { TableBody } from "./TableBody";
import { TablePagination } from "./TablePagination";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { canRenderAction, canRenderComponent, dataList, getUserPreferences, isEmptyObject, makeSelectField } from "../../utils/common";
import { COMPONENTS } from "../../constants/component";
import { useNavigate } from "react-router-dom";

import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Select from "react-select";
import { remove, set } from "../../reducers/dataSlice";
import { deleteDataApi, getDataApi } from "../../axios/DataManagementApi";
import { PrimaryButton, SecondaryButton } from "../Buttons/Button";
import { toast } from "react-toastify";
import store from "../../store";
import LabelTooltip from "../Tooltip/LabelTooltip";
import { paramHandling } from "./TableLogAction";
import { ACTION_FLAGS } from "../../constants/action_flags";
import ActionButtons from "../Selectors/ActionButtons";
import DeleteModal from "../Selectors/DeleteModal";
import { useTranslation } from "react-i18next";
import { setSelectedVirtualCrudeSource } from "../../reducers/SelectedSlice";

const IndicatorList = () => {
  const { t } = useTranslation(["common", "button"]);

  const authorizedComponent = useSelector((state) => state.application.authorized_components);
  const [authorizedIndicatorCreation, setAuthorizedIndicatorCreation] = useState(canRenderComponent(COMPONENTS.INDICATOR_CREATION));
  const [authorizedIndicatorComputation, setAuthorizedIndicatorComputation] = useState(canRenderComponent(COMPONENTS.INDICATOR_COMPUTATION));
  const [canDelete] = useState(canRenderAction(ACTION_FLAGS.CANDELETE, COMPONENTS.INDICATOR_DISPLAY));
  const [deployedScriptToDelete, setdeployedScriptToDelete] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  useEffect(
    () => () => {
      setAuthorizedIndicatorCreation(canRenderComponent(COMPONENTS.INDICATOR_CREATION));
      setAuthorizedIndicatorComputation(canRenderComponent(COMPONENTS.INDICATOR_COMPUTATION));
    },
    [authorizedComponent],
  );
  const { virtual_crude_source, virtual_configured_source } = useSelector((state) => state.data);
  const selectedVirtualCrudeSource = useSelector((state) => state.selected.virtual_crude_source);

  const updateIndicator = () => {
    toast.info("Chargement des données", {
      toastId: "loading-toast",
      containerId: "loadingToast",
      isLoading: true,
    });
    getDataApi("configuration/element/" + sessionStorage.getItem("observed_system_id") + "/" + selectedVirtualCrudeSource)
      .then((res) => {
        toast.update("loading-toast", {
          render: "Chargement terminé",
          type: toast.TYPE.SUCCESS,
          hideProgressBar: false,
          autoClose: 5000,
          isLoading: false,
        });
        store.dispatch(
          set({
            type: "virtual_configured_source",
            data: dataList(res.data.data),
          }),
        );
      })
      .catch((error) => {
        toast.update("loading-toast", {
          render: <p className={"truncate"}> {error.response.data.message}</p>,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
          autoClose: 5000,
          isLoading: false,
        });
      });
  };

  useEffect(() => {
    if (selectedVirtualCrudeSource === "") return;
    store.dispatch(
      set({
        type: "virtual_configured_source",
        data: [],
      }),
    );
    const toastId = toast.info("Chargement des données", { containerId: "loadingToast", isLoading: true });
    getDataApi("configuration/element/" + sessionStorage.getItem("observed_system_id") + "/" + selectedVirtualCrudeSource)
      .then((res) => {
        toast.update(toastId, {
          render: "Chargement terminé",
          type: toast.TYPE.SUCCESS,
          hideProgressBar: false,
          autoClose: 5000,
          isLoading: false,
        });
        store.dispatch(
          set({
            type: "virtual_configured_source",
            data: dataList(res.data.data),
          }),
        );
      })
      .catch((error) => {
        toast.update(toastId, {
          render: <p className={"truncate"}> {error.response.data.message}</p>,
          type: toast.TYPE.ERROR,
          hideProgressBar: false,
          autoClose: 5000,
          isLoading: false,
        });
      });
  }, [selectedVirtualCrudeSource]);
  const [refresh, setRefresh] = useState(false);
  const history = useNavigate();
  const createIndicator = () => history("/analytics/indicator/create");
  const computeIndicator = () => history("/analytics/indicator/compute");
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: "id",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "active",
      cell: (info) => {
        const row = info.row.original;
        const { deployment_params } = row;
        if (isEmptyObject(deployment_params)) return "Simple";
        const { frequency, time_slot, validity_days } = deployment_params;
        const days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
        const formatDay = validity_days.split("-").map((day) => days[parseInt(day)]);
        const display = (
          <div>
            <p>{frequency}min</p>
            <p>{formatDay.join("-")}</p>
            <p>{time_slot}</p>
          </div>
        );
        return <LabelTooltip label={"Récurrent"} tip={display} />;
      },
      header: "Mode",
    }),
    columnHelper.display({
      id: "info",
      header: "Informations",
      cell: (info) => {
        const row = info.row.original;
        const { execution_params } = row;

        return <LabelTooltip label={"Information d'execution"} tip={paramHandling(execution_params)} />;
      },
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => {
        const row = info.row.original;
        if (isEmptyObject(row.deployment_params)) return;
        return (
          <ActionButtons
            handleDelete={() => {
              setdeployedScriptToDelete(row.deployment_params.deployment_id);
            }}
            canDelete={canDelete}
          />
        );
      },
    }),
  ];
  const showId = getUserPreferences().find((preference) => preference.name === "showId")?.value ?? true;
  if (!showId) columns.splice(0, 1);
  const table = useReactTable({
    data: virtual_configured_source,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  return (
    <div className=" m-4 block rounded-lg border border-gray-200 bg-white">
      <div className="mt-2 flex flex-col justify-between lg:flex-row">
        <div className="flex flex-row px-3">
          <Select
            placeholder={t("indicator_type")}
            id="name"
            className={"w-60 py-2"}
            isSearchable={false}
            value={makeSelectField(virtual_crude_source, ["name"]).find((value) => value.value === selectedVirtualCrudeSource)}
            onChange={(newValue) => {
              store.dispatch(setSelectedVirtualCrudeSource(newValue.value));
            }}
            options={makeSelectField(virtual_crude_source, ["name"])}
          />
        </div>

        <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
          <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
            <SecondaryButton
              disabled={refresh || selectedVirtualCrudeSource === ""}
              callback={() => {
                setRefresh(true);
                updateIndicator();
              }}
              label={t("update", { ns: "button" })}
            />
          </div>
          {authorizedIndicatorCreation && (
            <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
              <PrimaryButton callback={createIndicator} label={t("create_indicator", { ns: "button" })} />
            </div>
          )}
          {authorizedIndicatorComputation && (
            <div className="mt-3 flex-row items-center px-3 sm:flex lg:mt-0">
              <PrimaryButton callback={computeIndicator} label={t("compute_indicator", { ns: "button" })} />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border-b border-gray-200 shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader table={table} />
                <TableBody table={table} />
              </table>
            </div>
          </div>
        </div>
      </div>
      <TablePagination table={table} />
      {deployedScriptToDelete && (
        <DeleteModal
          callback={() => {
            toast.info("Suppression...", { toastId: "loading-toast", containerId: "loadingToast", isLoading: true });
            setIsDeleting(true);
            deleteDataApi("configuration/module/" + sessionStorage.getItem("observed_system_id") + "/" + deployedScriptToDelete)
              .then(() => {
                toast.update("loading-toast", {
                  render: "Suppresion terminé",
                  type: toast.TYPE.SUCCESS,
                  hideProgressBar: false,
                  autoClose: 5000,
                  isLoading: false,
                });
                setIsDeleting(false);
                setdeployedScriptToDelete(undefined);
                store.dispatch(remove({ type: "virtual_configured_source", id: deployedScriptToDelete }));
              })
              .catch((error) => {
                toast.update("loading-toast", {
                  render: <p className={"truncate"}> {error.response.data.message}</p>,
                  type: toast.TYPE.ERROR,
                  hideProgressBar: false,
                  autoClose: 5000,
                  isLoading: false,
                });
                setIsDeleting(false);
              });
          }}
          loading={isDeleting}
          text={t("DELETE")}
          modalVisible={deployedScriptToDelete}
          setModalVisible={setdeployedScriptToDelete}
        />
      )}
    </div>
  );
};
export default IndicatorList;
