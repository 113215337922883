import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import MailView from "./IndicatorViewTemplate/MailView";
import IconButton from "../../components/IconButton";
import { MdArrowLeft as Prev } from "react-icons/md";
import KalmanView from "./IndicatorViewTemplate/KalmanView";
import { useEffect } from "react";

const IndicatorView = () => {
  let { id_indic } = useParams();
  const history = useNavigate();
  const indicator = useSelector((state) => state.data.event)[id_indic];
  useEffect(() => {
    if (indicator === undefined) history("/analytics/indicator");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderIndicator = () => {
    switch (indicator.module_name) {
      case "Email":
        return <MailView data={indicator} />;
      case "Kalman":
        return <KalmanView data={indicator} />;
      case "Autoregressiveprediction":
        return <KalmanView data={indicator} />;
      default:
        return "WIP";
    }
  };
  return (
    indicator !== undefined && (
      <div>
        <div className="m-8  rounded-lg bg-white px-8 pb-8  pt-4 shadow-lg">
          <div className="flex">
            <IconButton bg={true} Icon={<Prev size={40} />} value={""} callback={() => history("/analytics/indicator")} />
            <h2 className="text-3xl font-semibold text-blue-900">Indicateur {indicator.module_name}</h2>
          </div>
        </div>
        {renderIndicator()}
      </div>
    )
  );
};
export default IndicatorView;
