import IconButton from "../IconButton";
import { MdClose as Close, MdWarningAmber as Warning } from "react-icons/md";
import { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { isEmptyObject } from "../../utils/common";
import { DeleteButton, SecondaryButton } from "../Buttons/Button";
import { useTranslation } from "react-i18next";

const DeleteModal = ({ loading, callback, text, modalVisible, setModalVisible }) => {
  const { t } = useTranslation(["button"]);

  return (
    <Transition appear show={!isEmptyObject(modalVisible)} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setModalVisible(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  <IconButton
                    callback={() => setModalVisible(undefined)}
                    Icon={
                      <Close
                        className={
                          "absolute right-2.5 top-3 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
                        }
                        size={40}
                      />
                    }
                  />
                </Dialog.Title>
                <div className="p-6 text-center text-blue-900">
                  <Warning size={40} className={"w-full text-red-700"} />
                  <h3 className="mb-5 text-lg font-normal">{text}</h3>
                  <DeleteButton disabled={loading} callback={callback} label={t("delete")} />
                  <SecondaryButton callback={() => setModalVisible(false)} label={t("cancel")} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default DeleteModal;
